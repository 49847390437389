import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

function DoughnutChart(props) {
    ChartJS.register(ArcElement, Tooltip, Legend);
    var {data} = props
    if(data == null){
        data = {
            labels: [],
            datasets: [
                {
                    label: '',
                    data: []
                }
            ]
        };
    }
    return (
        <div>
            <Doughnut data={data} />
        </div>
    );
}

export default DoughnutChart;