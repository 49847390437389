import React, {useRef, useState} from 'react';
import { searchFieldStyle } from '../../js/standardStyle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ShareIcon from '@mui/icons-material/Share';
import {Typography,TextField,Grid,Button,Divider} from '@mui/material';
import style from '../../css/ViewRiskRegisterDetailForModal.module.css';
import { getStringOfObjectBasedOnKey, scrollToRef } from '../../js/util';
import FieldsetCustom from '../wrapper/FieldsetCustom';
import { useDispatch } from 'react-redux';
import { setUiWithCustomProp } from '../../redux/actions/customProp';
import { setPage } from '../../redux/actions/displayPage';

function ViewRiskRegisterDetailForModal(props) {
    
    // init use state to hide elements
    const [hideElement,setHideElement] = useState({"content":true});
    // check risk register details present from props
    var isValuePresent = props.riskRegister !== undefined ? true : false;
    // get risk register details from props
    var riskRegister = isValuePresent ? props.riskRegister : {};
    // init ref for the content section
    const modalContent = useRef(null);
    // function to toggle visibility for the hidden elements
    const toggleVisibility = () => {
        var updatedHideElement = hideElement;
        updatedHideElement["content"] = !hideElement["content"];
        setHideElement({...updatedHideElement});
    }
    // get use dispatch from redux
    const dispatch = useDispatch();
    
    return (
        <div style={{height:"100%"}}>
            <Grid container spacing={2}>
                <Grid item sm={12} md={6}>
                    <Typography className='cDark' id="modal-modal-title" variant="h6" component="h2">
                        {isValuePresent ? riskRegister.riskIdentification.riskIndex : ""} <ShareIcon style={{verticalAlign:"sub",cursor:"pointer"}}/>
                    </Typography>
                </Grid>
                <Grid item sm={12} md={6}>
                    <div style={{textAlign:'right'}}>
                        <TextField label="Search" type="search" sx={searchFieldStyle.sx} InputLabelProps={searchFieldStyle.label} size="small" variant="outlined" />
                    </div>
                </Grid>
            </Grid>
            <div ref={modalContent} className={style.content}>
                <div className='ptb15'>
                    <div className={`${style.fieldsetSection}`}>
                        <Grid container spacing={2}>
                            <Grid item sm={12} md={6}>
                                <Typography className={style.fieldContent}><strong>Risk Status :</strong> {isValuePresent ? riskRegister.status : ""}</Typography>
                            </Grid>
                            <Grid item sm={12} md={6} style={{textAlign:"right"}}>
                                <Typography className={style.fieldContent}><strong>Created At :</strong> {isValuePresent ? riskRegister.createdAt : ""}</Typography>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={`${style.fieldsetSection} mt15`}>
                        <FieldsetCustom legend="RISK IDENTIFICATION">
                            <Grid container spacing={2}>
                                <Grid item sm={6} md={3} lg={2}>
                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Risk Category</strong><strong>:</strong></Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={4}>
                                    <Typography className={style.fieldContent}>{isValuePresent ? riskRegister.riskIdentification.riskCategory : ""}</Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={2}>
                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Risk Name</strong><strong>:</strong></Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={4}>
                                    <Typography className={style.fieldContent}>{isValuePresent ? riskRegister.riskIdentification.riskName : ""}</Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={2}>
                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Risk Description</strong><strong>:</strong></Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={4}>
                                    <Typography className={style.fieldContent}>{isValuePresent ? riskRegister.riskIdentification.companyRiskDesc : ""}</Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={2}>
                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Risk Owner</strong><strong>:</strong></Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={4}>
                                    <Typography className={style.fieldContent}>{isValuePresent ? riskRegister.riskIdentification.riskOwner : ""}</Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={2}>
                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Assigned Resource</strong><strong>:</strong></Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={4}>
                                    <Typography className={style.fieldContent}>{isValuePresent ? riskRegister.riskIdentification.assignedResource : ""}</Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={2}>
                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Risk Hierarchy</strong><strong>:</strong></Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={4}>
                                    <Typography className={style.fieldContent}>{isValuePresent ? riskRegister.riskIdentification.riskHeirarchy : ""}</Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={2}>
                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Risk Source</strong><strong>:</strong></Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={4}>
                                    <Typography className={style.fieldContent}>{isValuePresent ? riskRegister.riskIdentification.riskSource : ""}</Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={2}>
                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Risk Identifier</strong><strong>:</strong></Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={4}>
                                    <Typography className={style.fieldContent}>{isValuePresent ? riskRegister.riskIdentification.identifier : ""}</Typography>
                                </Grid>
                            </Grid>
                        </FieldsetCustom>
                    </div>

                    {
                        isValuePresent 
                        ? Object.keys(riskRegister.riskResponse).length !== 0 
                            ?   <div>
                                    <div className='mtb5' style={{textAlign:"right"}}>
                                        <Button className='mlr5 roButton' variant="text" onClick={toggleVisibility} >{hideElement["content"] ? 'show more' : 'show less'}</Button>
                                    </div>
                                    <div style={{display: (hideElement["content"]) ? "none" : "block"}} className={`${style.fieldsetSection}`}>
                                        <FieldsetCustom legend='RISK RESPONSE'>
                                            <Grid container spacing={2}>
                                                <Grid item sm={6} md={3} lg={2}>
                                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Business Objective</strong><strong>:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={6} md={3} lg={4}>
                                                    <Typography className={style.fieldContent}>{isValuePresent ? riskRegister.riskResponse.businessObjective : ""}</Typography>
                                                </Grid>
                                                <Grid item sm={6} md={3} lg={2}>
                                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Objective Trigger</strong><strong>:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={6} md={3} lg={4}>
                                                    <Typography className={style.fieldContent}>{isValuePresent ? riskRegister.riskResponse.businessObjectiveTrigger : ""}</Typography>
                                                </Grid>
                                                <Grid item sm={6} md={3} lg={2}>
                                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Objective Impact</strong><strong>:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={6} md={3} lg={4}>
                                                    <Typography className={style.fieldContent}>{isValuePresent ? riskRegister.riskResponse.businessObjectiveImpactType : ""}</Typography>
                                                </Grid>
                                                <Grid item sm={6} md={3} lg={2}>
                                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Business Criticality</strong><strong>:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={6} md={3} lg={4}>
                                                    <Typography className={style.fieldContent}>{isValuePresent ? riskRegister.riskResponse.businessCriticalityType : ""}</Typography>
                                                </Grid>
                                                <Grid item sm={6} md={3} lg={2}>
                                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Response Action</strong><strong>:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={6} md={3} lg={4}>
                                                    <Typography className={style.fieldContent}>{isValuePresent ? riskRegister.riskResponse.action : ""}</Typography>
                                                </Grid>
                                                <Grid item sm={6} md={3} lg={2}>
                                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Stakeholder Inclusion</strong><strong>:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={6} md={3} lg={4}>
                                                    <Typography className={style.fieldContent}>{isValuePresent ? getStringOfObjectBasedOnKey(riskRegister.riskResponse.riskStakeHolder,'name') : ""}</Typography>
                                                </Grid>
                                                <Grid item sm={6} md={3} lg={2}>
                                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Risk Type</strong><strong>:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={6} md={3} lg={4}>
                                                    <Typography className={style.fieldContent}>{isValuePresent ? riskRegister.riskResponse.type : ""}</Typography>
                                                </Grid>
                                                <Grid item sm={6} md={3} lg={2}>
                                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Response Contingency</strong><strong>:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={6} md={3} lg={4}>
                                                    <Typography className={style.fieldContent}>{isValuePresent ? riskRegister.riskResponse.contingency : ""}</Typography>
                                                </Grid>
                                                <Grid item sm={6} md={3} lg={2}>
                                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Projected Time Period</strong><strong>:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={6} md={3} lg={4}>
                                                    <Typography className={style.fieldContent}>{isValuePresent ? `${riskRegister.riskResponse.projectedTime} ${riskRegister.riskResponse.projectedTimeType}` : ""}</Typography>
                                                </Grid>
                                                <Grid item sm={6} md={3} lg={2}>
                                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Treatment Status</strong><strong>:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={6} md={3} lg={4}>
                                                    <Typography className={style.fieldContent}>{isValuePresent ? riskRegister.riskResponse.treatmentStatus : ""}</Typography>
                                                </Grid>
                                            </Grid>
                                        </FieldsetCustom>
                                    </div>
                                    <div style={{display: (hideElement["content"]) ? "none" : "block"}} className={`${style.fieldsetSection}`}>
                                        <FieldsetCustom legend="RISK IMPACT CLASSIFICATION">
                                            <Grid container spacing={2}>
                                                <Grid item sm={6} md={3} lg={2}>
                                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Risk Classification</strong><strong>:</strong></Typography>
                                                </Grid>
                                                <Grid item sm={6} md={3} lg={4}>
                                                    <Typography className={style.fieldContent}>{isValuePresent ? riskRegister.riskImpactClassification : ""}</Typography>
                                                </Grid>
                                            </Grid>
                                        </FieldsetCustom>
                                    </div>
                                </div>
                            :   <div className='buttonSection'>
                                    <Button onClick={()=>{dispatch(setUiWithCustomProp({"ui":"riskRegister","props":{"manipulate":{"type":"setRiskReponse","data":{"riskRegisterId":props.currentId,"companyId":riskRegister.riskIdentification.companyId}}}}));dispatch(setPage("riskRegister"));}} variant='contained'>assign</Button>
                                </div>
                        : ""
                    }
                </div>
            </div>
            <Divider/>
            <div className='mt10'>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item><Button style={{color:(props.buttonDisable["prev"])?"gray":"black"}} className='mlr5 roButton' variant="text" disabled={props.buttonDisable["prev"]} onClick={()=>{scrollToRef(modalContent);props.nextPrevPageHandler("previous");}} startIcon={<ChevronLeftIcon/>}>Previous</Button></Grid>
                    <Grid item><Button style={{color:(props.buttonDisable["next"])?"gray":"black"}} className='mlr5 roButton' variant="text" disabled={props.buttonDisable["next"]} onClick={()=>{scrollToRef(modalContent);props.nextPrevPageHandler("next");}} endIcon={<ChevronRightIcon/>}>Next</Button></Grid>
                </Grid>
            </div>
        </div>
    );
}

export default ViewRiskRegisterDetailForModal;