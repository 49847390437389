import React, { useState, useEffect } from 'react';
import { Autocomplete, Button, Grid } from '@mui/material';
import FieldsetCustom from '../../wrapper/FieldsetCustom';
import { autocompleteStyle } from '../../../js/standardStyle';
import { useDispatch, useSelector } from 'react-redux';
import { toastUp } from '../../../redux/actions/toast';
import { renderDisplayValueForAutocomplete } from '../../../js/util';
import SendIcon from '@mui/icons-material/Send';
import QuillTextEditor from '../../wrapper/QuillTextEditor';
import { SERVICE_URL } from '../../../config';
import axios from 'axios';
import { getErrorMsg, generateArrayForAutoCompleteBasedOnkey } from '../../../js/util';
import { setSpinner } from '../../../redux/actions/spinner';

function New(props) {

    // get use dispatch
    const dispatch = useDispatch();
    // get loggedin user from redux
    const loggedInUser = useSelector((state) => state.loggedInUser);
    // init use state for search form
    const [formData, setFormData] = useState({ "framework": null,"objective":null ,"mailTo":null,"content":""});
   
    // function to set form data for auto complete
    const handleFormDataAutoCompleteChange = key => (event, object) => {
        var temp = formData;
        var value;
        if(['content'].includes(key)){
            value = event;
        }
        else{
            value = object;
        }
        temp[key] = value;
        setFormData({ ...temp });
    }

    // init use state for form options meta data
    const [formOptionsMetaData, setFormOptionsMetaData] = useState({ "frameworkCitation": [] ,"objective":[],"mailToOption":[]});

    useEffect(() => {
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}assessment/register/ui/name?assignedTo=${loggedInUser["id"]}`)
        .then(res => {
            if (res.status === 200) {
                var data = res["data"];
                var temp = formOptionsMetaData;
                temp["frameworkCitation"] = data["data"];
                setFormOptionsMetaData({ ...temp });
            } else {
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        // eslint-disable-next-line

        axios.get(`${SERVICE_URL}user?companyId=${loggedInUser["company_id"]}&roles=Line Manager`)
        .then((res)=>{
            if (res.status === 200) {
            var data = res["data"];
            var temp = formOptionsMetaData;
            temp["mailToOption"] = generateArrayForAutoCompleteBasedOnkey(data["data"], 'email');
            setFormOptionsMetaData({ ...temp });
        } else {
            dispatch(toastUp("unable to connect server"));
        }
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
        // eslint-disable-next-line
    }, []);


    return (
        <div>
            <FieldsetCustom legend="NEW ACTIVITY">
                <div className='mlr15 mtb30'>
                    <Grid container spacing={3}>
                        <Grid item sm={12} md={12}>
                            <span className='lable'>Mail To </span><span className='required'>*</span><br/>
                            <Autocomplete value={formData["mailTo"]} freeSolo sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["mailToOption"]} size="small" disablePortal  onChange={handleFormDataAutoCompleteChange("mailTo")} renderInput={renderDisplayValueForAutocomplete} />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <span className='lable'>Assessment </span><span className='required'>*</span><br/>
                            <Autocomplete value={formData["framework"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["frameworkCitation"]} getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.id === value.id} size="small" disablePortal onChange={handleFormDataAutoCompleteChange("framework")} renderInput={renderDisplayValueForAutocomplete} />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <span className='lable'>Objective </span><span className='required'>*</span><br/>
                            <Autocomplete value={formData["objective"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["objective"]} size="small" disablePortal onChange={handleFormDataAutoCompleteChange("objective")} renderInput={renderDisplayValueForAutocomplete} />
                        </Grid>
                        <Grid item sm={12} md={12}>
                            <span className='lable'>Content </span><span className='required'>*</span><br/>
                            <QuillTextEditor name={"content"} value={formData["content"]} onChange={handleFormDataAutoCompleteChange("content")} />
                        </Grid>
                    </Grid>
                </div>
            </FieldsetCustom>
            <div className='mtb30' style={{textAlign:"center"}}><Button className='mlr5 roButton' variant="contained" endIcon={<SendIcon/>} > send</Button></div>
        </div>
    );
}

export default New;