import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, Grid } from '@mui/material';
import FieldsetCustom from '../../wrapper/FieldsetCustom';
import { autocompleteStyle } from '../../../js/standardStyle';
import { getArrayOfObjectBasedOnKey, getErrorMsg, getIdOrNullOfArrayOfObject, getKeyOrNullOfObject, getSelectedOptionsForAutocompleteOfArrayObject, renderDisplayValueForAutocomplete } from '../../../js/util';
import axios from "axios";
import { SERVICE_URL } from '../../../config';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from '../../../redux/actions/spinner';
import { toastUp } from '../../../redux/actions/toast';
import { policyDropDown } from '../../../js/constants';
import { setUiWithCustomProp } from '../../../redux/actions/customProp';

function PolicyActionForm(props) {

    const loggedInUser = useSelector(state=>state.loggedInUser);
    // get use dispatch from redux
    const dispatch = useDispatch();
    // destructure props
    const {mode} = props;
    var {legend} = props;
    // set legend
    if(!legend){
        legend = "PRE-CONFIGURED POLICY - POLICY CONDITIONS AND ACTIONS";
    }
    // init use state for form data
    const [formData, setFormData] = useState({"owner":[],"severity":null,"situation":null,"notification":[]})
    // init use state for form options meta data
    const [formOptionsMetaData, setFormOptionsMetaData] = useState({"owner":[],"severity":policyDropDown["severity"],"situation":policyDropDown["situation"],"notification":[]});
    // function to set form data for auto complete
    const handleFormDataAutoCompleteChange = key => (event,object) => {
        var temp = formData;
        temp[key] = object;
        setFormData({...temp});
    }
    // function to handle form submit
    const handleFormSubmit = () => {
        var data = validateAndGetFormData();
        if(data["status"] === "valid"){
            if(mode === "add"){
                createActionForPolicy(data["data"]);
            }else if(mode === "edit"){
                editActionForPolicy(data["data"]);
            }
        }else{
            dispatch(toastUp(data["msg"]));
        }
    }
    // function to edit action for the policy
    const editActionForPolicy = (data) => {
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}policy/action/edit?id=${props.policyDetails["policyId"]}`,data)
        .then((res)=>{
            if(res["status"] === 200){
                dispatch(toastUp("Policy Action edited successfully"));
                setTimeout(()=>{
                    dispatch(setUiWithCustomProp({"ui":"policy","props":{'manipulate':{"type":"redirectToChangePolicy","data":props.manipulate.callback}}}))
                }, 2000);
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
    }
    // function to save action for the policy
    const createActionForPolicy = (data) => {
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}policy/action?id=${props.policyDetails["policyId"]}`,data)
        .then((res)=>{
            if(res["status"] === 200){
                dispatch(toastUp("Policy Action created successfully"));
                setTimeout(()=>{
                    props.setPolicyDetails((old)=>({...old,"policyId":""}))
                    props.setPreConfiguredUI("policyDetails");
                }, 2000);
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
    }
    // function to validate and get form data
    const validateAndGetFormData = () => {
        var data = {}
        data["status"] = "invalid";
        if(formData["severity"] === null){
            data["msg"] = "Policy Request Severity field cannot be empty";
            return data;
        }
        if(formData["situation"] === null){
            data["msg"] = "Policy Request Status field cannot be empty";
            return data;
        }
        if(formData["owner"].length === 0){
            data["msg"] = "Select one or more value from Send Policy field";
            return data;
        }
        if(formData["notification"].length === 0){
            data["msg"] = "Select one or more value from Send Notification field";
            return data;
        }
        data["status"] = "valid";
        var formValue = {};
        formValue["owners"] = getIdOrNullOfArrayOfObject(formData["owner"]);
        formValue["priority"] = getKeyOrNullOfObject(formData["severity"],'value');
        formValue["situation"] = getKeyOrNullOfObject(formData["situation"],'value');
        formValue["notification"] = getIdOrNullOfArrayOfObject(formData["notification"]);
        data["data"] = formValue;
        return data;
    }
    // use effect to populate auto complete when the component loads
    useEffect(()=>{
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}user?&roles=Line Manager,Organizational Executive&companyId=${loggedInUser["company_id"]}`)
        .then((res)=>{
            var data = res["data"];
            var updatedFormOptionsMetaData = formOptionsMetaData;
            updatedFormOptionsMetaData["owner"] = data["data"];
            setFormOptionsMetaData({...updatedFormOptionsMetaData});
            if ('manipulate' in props){
                if(props["manipulate"]["type"]==="editPolicy" || props["manipulate"]["type"]==="editPolicyCustomise"){
                    var policyDetails = props["manipulate"]["data"];
                    setFormData((old)=>({...old,"owner":getSelectedOptionsForAutocompleteOfArrayObject(policyDetails["assignedUsersId"],data["data"])}));
                }
            }
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })

        axios.get(`${SERVICE_URL}user/group/role?group=rbsu`)
        .then((res)=>{
            var data = res["data"];
            var updatedFormOptionsMetaData = formOptionsMetaData;
            updatedFormOptionsMetaData["notification"] = data["data"];
            setFormOptionsMetaData({...updatedFormOptionsMetaData});
            if ('manipulate' in props){
                if(props["manipulate"]["type"]==="editPolicy" || props["manipulate"]["type"]==="editPolicyCustomise"){
                    var policyDetails = props["manipulate"]["data"];
                    setFormData((old)=>({...old,"notification":getSelectedOptionsForAutocompleteOfArrayObject(policyDetails["notificationId"],data["data"])}))
                }
            }
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })

        if ('manipulate' in props){
            if(props["manipulate"]["type"]==="editPolicy" || props["manipulate"]["type"]==="editPolicyCustomise"){
                var data = props["manipulate"]["data"];
                var temp = formData;
                temp["severity"] = getArrayOfObjectBasedOnKey(policyDropDown["severity"],'value',data["priority"]);
                temp["situation"] = getArrayOfObjectBasedOnKey(policyDropDown["situation"],'value',data["situation"]);
                setFormData({...temp});
            }
        }
        // eslint-disable-next-line
    },[]);
    return (
        <div>
            <FieldsetCustom legend={legend}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Policy Request Severity </span><span className='required'>*</span><br/>
                        <Autocomplete sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} value={formData["severity"]} options={formOptionsMetaData["severity"]} size="small" disablePortal getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.value === value.value} onChange={handleFormDataAutoCompleteChange("severity")} renderInput={renderDisplayValueForAutocomplete} />
                    </Grid>
                    <Grid  item sm={12} md={6}>
                        <span className='lable'>Policy Request Status </span><span className='required'>*</span><br/>
                        <Autocomplete sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} value={formData["situation"]} options={formOptionsMetaData["situation"]} size="small" disablePortal getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.value === value.value} onChange={handleFormDataAutoCompleteChange("situation")} renderInput={renderDisplayValueForAutocomplete} />
                    </Grid>
                    <Grid  item sm={12} md={6}>
                        <span className='lable'>Send Policy </span><span className='required'>*</span><br/>
                        <Autocomplete multiple filterSelectedOptions disableCloseOnSelect sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} value={formData["owner"]} options={formOptionsMetaData["owner"]} size="small" disablePortal getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.id === value.id} onChange={handleFormDataAutoCompleteChange("owner")} renderInput={renderDisplayValueForAutocomplete} />
                    </Grid>
                    <Grid  item sm={12} md={6}>
                        <span className='lable'>Send Notification </span><span className='required'>*</span><br/>
                        <Autocomplete multiple filterSelectedOptions disableCloseOnSelect sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} value={formData["notification"]} options={formOptionsMetaData["notification"]} size="small" disablePortal getOptionLabel={(option) => option.role} isOptionEqualToValue={(option, value) => option.id === value.id} onChange={handleFormDataAutoCompleteChange("notification")} renderInput={renderDisplayValueForAutocomplete} />
                    </Grid>
                </Grid>
            </FieldsetCustom>
            <div className='mtb30 taCenter'>
                <Button className={`mlr5`} variant="contained" onClick={handleFormSubmit}>save</Button>
            </div>
        </div>
    );
}

export default PolicyActionForm;