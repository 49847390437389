import {MenuItem,Checkbox,ListItemText,Tooltip,ListItemIcon,TextField} from '@mui/material';
import { tooltipStyle } from './standardStyle';
import InfoIcon from '@mui/icons-material/Info';
import { textFieldStyle } from './standardStyle';

export const getArrayOfObjectBasedOnKey = (data,key,target) => {
    for(const row of data){
        if(row[key] === target){
            return row;
        }
    }
    return null;
}

export const replaceObjectInArrayBasedOnKey = (data,key,target,replace) => {
    var newData = data.map(row => {
        if(row[key] === target){
            return replace;
        }
        return row;
    });
    return newData;
}

export const removeObjectInArrayBasedOnKey = (data,key,target) => {
    var newData = data.filter(row => row[key] !== target);
    return newData;
}

export const generateDropDown = (options,displayKey) => {
    return options.map((value) => {
        return <MenuItem key={value.id} value={value.id}>{value[displayKey]}</MenuItem>
    });
}

export const generateDropDownBasedOnKey = (options,displayKey,valueKey) => {
    if(!Array.isArray(options)){
        options = [];
    }
    return options.map((value) => {
        return <MenuItem key={value[valueKey]} value={value[valueKey]}>{value[displayKey]}</MenuItem>
    });
}

export const generateDropDownMultiBasedOnKey = (options,displayKey,valueKey,parentData) => {
    return options.map((value) => {
        return(<MenuItem style={{padding:"0px"}} key={value[valueKey]} value={value[valueKey]}>
                    <Checkbox checked={parentData.indexOf(value[valueKey]) > -1} />
                    <ListItemText primary={value[displayKey]} />
                </MenuItem>
        )
    });
}

export const generateDropDownWithToolTipBasedOnKey = (options,displayKey,valueKey,tooltipDesc) => {
    return options.map((value) => {
        return(<MenuItem key={value[valueKey]} value={value[valueKey]}>
                <ListItemText primary={value[displayKey]} />
                <ListItemIcon>
                    <Tooltip arrow componentsProps={tooltipStyle} disableFocusListener disableTouchListener placement="right-start" title={<span style={{ whiteSpace: 'pre-line' }}>{tooltipDesc[value[displayKey]]}</span>}>
                        <InfoIcon className='dropdownIcon'/>
                    </Tooltip>
                </ListItemIcon>
            </MenuItem>
        )
    });
}

export const getStringOfObjectBasedOnKey = (object,key) => {
    var arr = object.map((value) => {
        return value[key];
    });
    return arr.join(", ")
}

export const scrollToRef = (ref) => {
    ref.current.scroll({
        top: 0,
        behavior: 'smooth'
    });
}

// function to get index of given id
export const getIdIndexOfArrayObject = (id,data) => {
    var index = null
    for(var i=0;i<data.length;i++){
        if(id === data[i]["id"]){
            index = i;
            break;
        }
    }
    return index;
}

// function to get value based on key
export const getNameBasedOnValue = (object,value) => {
    for(const row of object){
        if(row["value"] === value){
            return row["name"];
        }
    }
    return value;
}

// function to get value based on key
export const getKeyBasedOnValue = (object,targetKey,value,returnKey) => {
    for(const row of object){
        if(row[targetKey] === value){
            return row[returnKey];
        }
    }
    return null;
}

// function to concat two key in array of object
export const concatTwoKeyInArrayOfObject = (object,keyOne,keyTwo,newKey,seperator) => {
    return object.map((row)=>{
        row[newKey] = row[keyOne]+seperator+row[keyTwo];
        return row;
    })
}

// function render display string for multiple select
export const renderDisplayValueForMultiSelect = (selectedValues,displayKey,parentData) => {
    if(selectedValues.length === 0){
        return <em>-- please select--</em>;
    }
    var displayValue = "";
    displayValue = selectedValues.map((id)=>{
        var value = "";
        for(var row of parentData){
            if(row.id === id){
                value = row[displayKey];
                break;
            }
        }
        return value;
    })
    return displayValue.join(', ');
}

export const getErrorMsg = err => {
    try{
        if(err["response"]["data"]["message"] !== undefined){
            return err["response"]["data"]["message"];
        }
        console.log(err);
        return "unable to connect server";
    }catch{
        console.log(err);
        return "unable to connect server";
    }
}

export const wrapEmailInArrayOfObject = (object,newKey) => {
    return object.map((row)=>{
        row[newKey] = row["name"]+" ("+row["email"]+")";
        return row;
    })
}

export const wrapEmailOfObject = (object,newKey) => {
    object[newKey] = object["name"]+" ("+object["email"]+")";
    return object;
}

export const renderDisplayValueForAutocomplete = (params) => {
    return <TextField {...params} />
}

export const renderDisplayValueWithLableForAutocomplete = (label) => (params) => {
    return <TextField {...params} label={label} sx={textFieldStyle} />
}

export const getIdOrNullOfObject = (object) => {
    if(object === null){
        return null;
    }
    return object["id"];
}

export const getKeyOrNullOfObject = (object,key) => {
    if(object === null){
        return null;
    }
    return object[key];
}

export const getIdOrNullOfArrayOfObject = (object) => {
    var ids = [];
    for(var row of object){
        ids.push(row["id"]);
    }
    return ids;
}

export const getKeyOfArrayOfObject = (object,key) => {
    var arr = [];
    for(var row of object){
        arr.push(row[key].toString());
    }
    return arr;
}

export const removeDuplicateInArray = (array) => {
    var arr = [];
    for(var item of array){
        if(!arr.includes(item)){
            arr.push(item);
        }
    }
    return arr;
}

export const generateArrayForAutoCompleteBasedOnkey = (object,key) => {
    var arr = [];
    for(var row of object){
        if(!arr.includes(row[key].toString())){
            arr.push(row[key].toString());
        }
    }
    return arr;
}

export const getSelectedOptionsForAutocompleteOfArrayObject = (selectedObject,options) => {
    var arr = [];
    for(var option of selectedObject){
        for (var row of options){
            if(option === row["id"]){
                arr.push(row);
            }
        }
    }
    return arr;
}

export const removeHtmlContent = (markup) => {
    return markup.replace( /(<([^>]+)>)/ig, '');
}

export const getProjectFinancialFormulatedValue = (data) => {
    var res = {}
    try{
        res["plannedValue"] = data["plannedCompletionPercentage"]*data["plannedBudget"];
        res["earnedValue"] = data["workCompletionPercentage"]*data["plannedBudget"];
        res["scheduleVariance"] = res["earnedValue"] - res["plannedValue"];
        res["schedulePerformanceIndex"] = res["earnedValue"] / res["plannedValue"];
        res["costVariance"] = res["earnedValue"] - data["actualCost"];
        res["costPerformanceIndex"] = res["earnedValue"] / data["actualCost"];
    }catch{
        res["plannedValue"] = "";
        res["earnedValue"] = "";
        res["scheduleVariance"] = "";
        res["schedulePerformanceIndex"] = "";
        res["costVariance"] = "";
        res["costPerformanceIndex"] = "";
    }
    return res;
}

export const getBudgetPercent = (plannedValue,actualValue) => {
    return (plannedValue - actualValue) / plannedValue * 100;
}

export const getScoreForInherentRisk = (impack,likelihood) => {
    if(impack === null || likelihood === null){
        return "null";
    }else{
        var value = impack * likelihood;
        if(value >= 1 && value <=3){
            return "Low";
        }else if(value >= 4 && value <=6){
            return "Medium";
        }else if(value >= 7 && value <=9){
            return "High";
        }else{
            return "null";
        }
    }
}

export const getValueForPriorityScore = (score) => {
    if(score > 0 && score <= 1){
        return "Low";
    }else if(score > 1 && score <= 2){
        return "Medium";
    }else if(score > 2 && score <= 3){
        return "High";
    }else{
        return "";
    }
}

export const getValueForStatusScore = (score) => {
    if(score > 0 && score <= 1){
        return "Open";
    }else if(score > 1 && score <= 2){
        return "Active";
    }else if(score > 2 && score <= 3){
        return "Completed";
    }else if(score > 3 && score <= 4){
        return "Closed";
    }else{
        return "";
    }
}

export const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export const fileToDataUri = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result)
    };
    reader.readAsDataURL(file);
})

export const fileToBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        var uri = event.target.result
        var base64str = uri.split(',')[1];
        resolve(base64str);
    };
    reader.readAsDataURL(file);
})

export const fileToBlob = file => new Promise((resolve,reject)=>{
    const reader = new FileReader();
    reader.onload = (event) => {
        var uri = event.target.result
        var base64str = uri.split(',')[1];
        var binary = atob(base64str.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }               
        var blob = new Blob( [view], { type: "application/pdf" });
        var url = URL.createObjectURL(blob);
        resolve(url);
    };
    reader.readAsDataURL(file);
})

export const renderDisplayValueForSelectTooltip = (selected) => (
    selected === "" ?
        <em>-- please select--</em> 
    : 
        selected
)

export const removeValueInArray = (array,value) => {
    var data = array.filter(row => row !== value);
    return data;
}