import React, { useEffect, useState } from 'react';
import HeaderSection from '../HeaderSection';
import { Button, Divider, Grid, TextField, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from '../../redux/actions/spinner';
import axios from 'axios';
import { SERVICE_URL } from '../../config';
import { toastUp } from '../../redux/actions/toast';
import { getErrorMsg, removeHtmlContent, removeValueInArray } from '../../js/util';
import style from '../../css/EmailView.module.css'
import htmlParser from "html-react-parser";
import ReplyIcon from '@mui/icons-material/Reply';
import ModalFormDisplay from '../wrapper/ModalFormDisplay';
import { textFieldStyle } from '../../js/standardStyle';
import SendIcon from '@mui/icons-material/Send';
import QuillTextEditor from '../wrapper/QuillTextEditor';
import ModalViewInfo from '../wrapper/ModalViewInfo';

function EmailView(props) {
    const dispatch = useDispatch();
    const loggedInUser = useSelector(state=>state.loggedInUser);
    const {selectedEmailId,setSelectedEmailId,setEmailUi} = props;
    const [content,setContent] = useState(null);
    const loadEmailContent = () => {
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}email?emailThreadId=${selectedEmailId}`)
        .then(res=>{
            setContent(res["data"]["data"])
        })
        .catch(err=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false))
        })
    }
    useEffect(()=>{
        loadEmailContent();
        // eslint-disable-next-line
    },[])
    const [identifiedIssueInvestigateModal, setIdentifiedIssueInvestigateModal] = useState({"isOpen":false,"value":{"responseType":null,"response":""}});
    
    const openIdentifiedIssueInvestigateModal = (responseType) => {
        var temp = identifiedIssueInvestigateModal;
        temp["isOpen"] = true;
        temp["value"]["responseType"] = responseType;
        setIdentifiedIssueInvestigateModal({...temp});
    }
    const closeIdentifiedIssueInvestigateModal = () => {
        var temp = identifiedIssueInvestigateModal;
        temp["isOpen"] = false;
        temp["value"]["responseType"] = null;
        temp["value"]["response"] = "";
        setIdentifiedIssueInvestigateModal({...temp})
    }
    const handleValueChangeForIdentifiedIssueInvestigate = (key) => (event,object) => {
        var temp = identifiedIssueInvestigateModal;
        var value = event.target.value;
        if(['responseType'].includes(key)){
            value = object;
        }
        temp["value"][key] = value;
        setIdentifiedIssueInvestigateModal({...temp});
    }
    const [replyModal,setReplyModal] = useState({"isOpen":false,"content":""});
    const openReplyModal = () => {
        setReplyModal((old)=>({...old,isOpen:true}));
    }
    const closeReplyModal= () => {
        setReplyModal((old)=>({...old,isOpen:false,content:""}));
    }
    const handlerValueChangeForReplyModal = (key) => (event) =>{
        setReplyModal((old)=>({...old,content:event}));
    }
    const sendMail = () => {
        if(removeHtmlContent(replyModal["content"]) === ""){
            dispatch(toastUp("Reply field cannot be empty"));
            return false;
        }
        var data={};
        data["content"] = replyModal["content"];
        data["createdBy"] = loggedInUser["id"];
        axios.post(`${SERVICE_URL}email/reply?id=${selectedEmailId}`,data)
        .then((res)=>{
            dispatch(toastUp("Email sent successfully"));
            closeReplyModal();
            loadEmailContent();
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
    }
    const generateViewUi = (data) => {
        let htmlMarkUp = []
        if(data !== null){
            htmlMarkUp.push(
                <div key='header' className={`${style.header} mb20`}>
                    {data["basic"]["header"]}
                </div>
            )
            for(let [i,row] of data["data"].entries()){
                htmlMarkUp.push(
                    <div key={row["id"]}>
                        <div className='ml15'>
                            <div>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        <Typography className={`${style.userName}`} component={"span"}>{row["createdBy"]["name"]}</Typography>
                                        <Typography className={`${style.userEmail} mlr5`} component={"span"}>{`<${row["createdBy"]["email"]}>`}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography className={`${style.timeStamp} mlr5`} component={"span"}>{`${row["createdAt"]}`}</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                            <Typography className={`${style.receivers}`}>to {removeValueInArray(data["basic"]["userPool"],row["createdBy"]["name"]).join(', ')}</Typography>
                            <div className={`mt10 mb20 mlr10`}>
                                <Typography className='fs14' component={"span"} style={{"whiteSpace": "pre-line"}}>
                                    {htmlParser(row["content"])}
                                </Typography>
                            </div>
                        </div>
                        {
                            (i !== data["data"].length - 1) ?
                                <Divider className='mtb10'/>
                            :
                                <></>
                        }
                    </div>
                )
            }
            if(data["basic"]["status"] === "closed"){
                htmlMarkUp.push(<Typography key={'closed'} className={`taCenter m10`}>Email Conversation Closed</Typography>)
                return htmlMarkUp;
            }
            if(data["basic"]["category"] === "identified_issue_invetigate"){
                if(data["basic"]["assessmentDetails"]["assignedToId"] === loggedInUser["id"]){
                    htmlMarkUp.push(<Typography key={'noReply'} className={`taCenter m10`}>Initiator Cannot Reply</Typography>)
                }else{
                    htmlMarkUp.push(
                        ...[
                            <Button key={"knownIssue"} variant='text' onClick={()=>openIdentifiedIssueInvestigateModal("known_issue")} className={`${style.replyBtn} m10`} startIcon={<ReplyIcon/>} >known Issue</Button>,
                            <Button key={"takeNoAction"} variant='text' onClick={()=>openIdentifiedIssueInvestigateModal("take_no_action")} className={`${style.replyBtn} m10`} startIcon={<ReplyIcon/>} >take no action</Button>,
                            <Button key={"actionPlan"} variant='text' onClick={()=>{setEmailUi("actionPlan")}} className={`${style.replyBtn} m10`} startIcon={<ReplyIcon/>} >action plan</Button>
                        ]
                    )
                }
            }else{
                htmlMarkUp.push(
                    <Button key={"reply"} variant='text' className={`${style.replyBtn} m10`} onClick={openReplyModal} startIcon={<ReplyIcon/>} >reply</Button>
                )
            }
        }else{
            htmlMarkUp.push(
                <div key={'dummy'} className='taCenter'>
                    No Email Found
                </div>
            )
        }
        return htmlMarkUp;
    }
    const saveResponse = () => {
        var value = identifiedIssueInvestigateModal["value"];
        if (value.responseType === null){
            dispatch(toastUp("Response Type cannot be empty"));
            return false;
        }
        if (value.response === ""){
            dispatch(toastUp("Response field cannot be empty"));
            return false;
        }
        var data={}
        data["responseType"] = value["responseType"];
        data["response"] = value["response"];
        data["createdBy"] = loggedInUser["id"]
        setSpinner(true)
        axios.post(`${SERVICE_URL}email/investigateIssue?emailThreadId=${content["basic"]["id"]}`,data)
        .then(res=>{
            dispatch(toastUp("Email sent successfully"));
            closeIdentifiedIssueInvestigateModal();
            loadEmailContent();
        })
        .catch(err=>{
            console.log(err)
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
    }
    return (
        <div style={{height:"100%"}}>
            <HeaderSection header='Email' breadcrumbs={["Email","View"]} specialButtons={[<Button onClick={()=>{setSelectedEmailId("");setEmailUi("inbox")}} style={{color: "black"}} className='mlr5 roButton' variant="text" startIcon={<ArrowBackIosIcon/>}>back</Button>]}/>
            <div className='m20' style={{height:"calc(100vh - 240px)",overflowX:"hidden"}}>
                {generateViewUi(content)}
            </div>
            <ModalFormDisplay open={identifiedIssueInvestigateModal.isOpen} saveHandler={saveResponse} saveName={'compose'} closeHandler={closeIdentifiedIssueInvestigateModal}>
                <div style={{height:"100%"}}>
                    <Typography variant="h6" component="h2">Assessment Issue Response</Typography>
                    <div className='m10 ptb10' style={{height:"calc(100% - 125px)",overflowX:"hidden"}}>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <span className='lable'>Response </span><span className='required'>*</span><br/>
                                <TextField multiline fullWidth onChange={handleValueChangeForIdentifiedIssueInvestigate("response")} value={identifiedIssueInvestigateModal.value.response} size='small' sx={textFieldStyle} />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </ModalFormDisplay>
            <ModalViewInfo open={replyModal.isOpen} closeHandler={closeReplyModal}>
                <div>
                    <Typography variant="h6" component="h2">
                        REPLY
                    </Typography>
                </div>
                <div style={{height:"100%"}}>
                    <div className='m20 ptb10' style={{height:"calc(100% - 125px)",overflowX:"hidden"}}>
                        <div>
                            <QuillTextEditor name={"replyModalContent"} value={replyModal["content"]} onChange={handlerValueChangeForReplyModal("content")} />
                        </div>
                    </div>
                    <div className='mtb30 taCenter'>
                        <Button className='mlr5' variant='contained' onClick={sendMail} endIcon={<SendIcon/>}>send</Button>
                        <Button className='mlr5' variant='text' onClick={closeReplyModal}>close</Button>
                    </div>
                </div>
            </ModalViewInfo>
        </div>
    );
}

export default EmailView;