import React, {useState,useEffect} from 'react';
import {Grid,Select,MenuItem,Button,TextField} from '@mui/material';
import {selectFieldStyle,textFieldStyle} from '../../js/standardStyle.js';
import selectStyle from '../../css/SelectWithMoreAED.module.css';
import ModalForm from '../wrapper/ModalForm.js';
import axios from 'axios';
import { generateDropDownBasedOnKey,generateDropDownMultiBasedOnKey,generateDropDownWithToolTipBasedOnKey, getArrayOfObjectBasedOnKey, getErrorMsg,generateDropDown,removeObjectInArrayBasedOnKey,replaceObjectInArrayBasedOnKey, renderDisplayValueForSelectTooltip } from '../../js/util.js';
import { SERVICE_URL } from '../../config.js';
import { riskResponseRiskType,riskIdentifierObjectiveImpact,riskIdentifierBusinessCriticality  } from '../../js/tiptoolDescription.js';
import { useDispatch } from 'react-redux';
import { setSpinner } from '../../redux/actions/spinner.js';
import { toastUp } from '../../redux/actions/toast.js';
import FieldsetCustom from '../wrapper/FieldsetCustom.js';
import SelectWithMoreAED from '../wrapper/SelectWithMoreAED.js';
import {setConfirmationModal,closeConfirmationModal} from '../../redux/actions/confirmationModal.js';


function RiskResponse(props) {

    // get use dispatch from redux
    const dispatch = useDispatch();
    const companyId = props.riskRegisterDetails.companyId;
    // init state for store form values
    const [formData,setFormData] = useState({"action":"","projectedTimePeriod":"","stakeholderInclusion":[],"riskType":"","contigency":"","treatmentStatus":"","businessObjective":"","businessObjectiveTrigger":"","objectiveImpact":"","businessCriticality":""})
    
    const [businessObjectiveModal,setBusinessObjectiveModal] = useState({"isOpen":false,"mode":"","header":"","value":{"name":""}});
    
    // function to set form value to formData
    const handleFormValueChange = key => event => {
        var temp = formData;
        var value = event.target.value;
       

        if(key === "projectedTimePeriod"){
            if(value === "other"){
                openProjectedTimePeriodModal();
                return false;
            }
        }
        if(key === "stakeholderInclusion"){
            if(value !== ""){
                value = typeof value === 'string' ? value.split(',') : value;
            }
        }
      
        temp[key] = value;
        setFormData({...temp});
        if(key === "businessObjective"){
            generateBusinessObjectiveTriggerForBusinessObjective(formData["businessObjective"]);
        }
    }

    const generateBusinessObjectiveTriggerForBusinessObjective = (businessObjectiveId) => {

        var updatedFormData = formData;
        updatedFormData["businessObjectiveTrigger"] = "";
        setFormData({...updatedFormData});
        var updatedFormOptionsMetaData = formOptionsMetaData;
        updatedFormOptionsMetaData["businessObjectiveTrigger"] = [];
        setFormOptionsMetaData({...updatedFormOptionsMetaData});
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}risk/company/business/objectiveTrigger/businessObjectiveId?businessObjectiveId=${businessObjectiveId}`)
        .then(res => {
            if(res.status === 200){
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["businessObjectiveTrigger"] = res.data;
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    
    

    // init and store form meta data values for dropdown
    const [formOptionsMetaData,setFormOptionsMetaData] = useState({"action":[],"stakeholderInclusion":[],"contigency":[],"riskType":[],"projectedTimePeriod":[],"treatmentStatus":[],"businessObjective":[],"businessObjectiveTrigger":[],"objectiveImpact":[],"businessCriticality":[]})
     // init business objective trigger modal
     const [businessObjectiveTriggerModal,setBusinessObjectiveTriggerModal] = useState({"isOpen":false,"mode":"","header":"","value":{"name":""}});
    
    // function to generate projected time period dropdown
    const generateDropDownForProjectedTimePeriod = () => {
        var options = formOptionsMetaData["projectedTimePeriod"].map((value) => {
            return <MenuItem key={value.id} value={value.id}>{`${value.time} ${value.unit}`}</MenuItem>
        });
        return options;
    }

    // init state for projected time period modal
    const [projectedTimePeriodModal,setProjectedTimePeriodModal] = useState({"isOpen":false,"header":"Create Projected Time Period","value":{"time":"","unit":"HRS","sign":"<"}});
    // function to open pojected time period modal
    const openProjectedTimePeriodModal = () => {
        var temp = projectedTimePeriodModal;
        temp["isOpen"] = true;
        setProjectedTimePeriodModal({...temp});
    }   
    // function to clean and close projected time period modal
    const handleProjectedTimePeriodModalClose = () => {
        var temp = projectedTimePeriodModal;
        temp["isOpen"] = false;
        temp["value"]["sign"] = "<";
        temp["value"]["time"] = "";
        setProjectedTimePeriodModal({...temp});
    }
    // function to handle sumbit for projected time period modal
    const handleProjectedTimePeriodModalSubmit = () => {
        var time = projectedTimePeriodModal["value"]["time"];
        if(time === ""){
            dispatch(toastUp("time field cannot be empty"));
            return false;
        }
        var sign = projectedTimePeriodModal["value"]["sign"];
        var recentData = formOptionsMetaData["projectedTimePeriod"].slice(-1).pop();
        var recentId = parseInt(recentData["id"]) + 1;
        var data = {};
        data["id"] = recentId.toString();
        data["time"] = `${sign} ${time}`;
        data["unit"] = "HRS";
        var updatedFormOptionsMetaData = formOptionsMetaData;
        updatedFormOptionsMetaData["projectedTimePeriod"].push(data);
        setFormOptionsMetaData({...updatedFormOptionsMetaData});
        var updatedFormData = formData;
        updatedFormData["projectedTimePeriod"] = data["id"];
        handleProjectedTimePeriodModalClose();
    }
    // function to handle value change for projected time period modal
    const projectedTimePeriodModalValueHandler = key => event => {
        var temp = projectedTimePeriodModal;
        var value = event.target.value;
        temp['value'][key] = value;
        setProjectedTimePeriodModal({...temp});
    }


    // function to save risk response
    const saveRiskResponse = () => {
        var data = validateAndGetFormData();
        if(data["status"] === "valid"){
            dispatch(setSpinner(true));
            axios.post(`${SERVICE_URL}risk/riskResponse`,data["data"])
            .then(res => {
                if(res.status === 201){
                    dispatch(toastUp("risk response created successfully"));
                    setTimeout(()=>{props.riskRegisterUIHandler("riskImpactClassification");}, 2000);
                }else{
                    dispatch(toastUp("unable to connect server"));
                }
            })
            .catch(err => {
                dispatch(toastUp(getErrorMsg(err)));
            })
            .finally(() => {
                dispatch(setSpinner(false));
            });
        }else{
            dispatch(toastUp(data["msg"]));
        }
    }

    // function to validate form data
    const validateAndGetFormData = () => {
        var data = {};
        data["status"] = "invalid";
        if(props.riskRegisterDetails.id === ""){
            data["msg"] = "Please create Risk Identification";
            return data;
        }
        if(formData["action"] === ""){
            data["msg"] = "response strategy/action field cannot be empty";
            return data;
        }
        if(formData["stakeholderInclusion"].length === 0){
            data["msg"] = "stakeholder inclusion field cannot be empty";
            return data;
        }
        if(formData["riskType"] === ""){
            data["msg"] = "risk type field cannot be empty";
            return data;
        }
        if(formData["contigency"] === ""){
            data["msg"] = "response contingency field cannot be empty";
            return data;
        }
        if(formData["projectedTimePeriod"] === ""){
            data["msg"] = "projected time period field cannot be empty";
            return data;
        }
        if(formData["treatmentStatus"] === ""){
            data["msg"] = "treatment status field cannot be empty";
            return data;
        }

        data["status"] = "valid";
        var formValue = {};
        formValue["action"] = formData["action"];
        formValue["riskStakeHolder"] = formData["stakeholderInclusion"];
        formValue["type"] = formData["riskType"];
        formValue["contingency"] = formData["contigency"];
        var projectedTimeDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["projectedTimePeriod"],"id",formData["projectedTimePeriod"]);
        formValue["projectedTime"] = projectedTimeDetails["time"];
        formValue["projectedTimeType"] = projectedTimeDetails["unit"];
        formValue["treatmentStatus"] = formData["treatmentStatus"];
        formValue["riskRegisterId"] = props.riskRegisterDetails.id;
        formValue["businessObjectiveTriggerId"] = formData["businessObjectiveTrigger"];
        formValue["businessObjectiveImpactType"] = formData["objectiveImpact"];
        formValue["businessCriticalityType"] = formData["businessCriticality"];
        formValue["businessObjectiveId"] = formData["businessObjective"];
        data["data"] = formValue;
        return data;
    }

    useEffect(() => {
        
        // get action dropdown
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}risk/getConfig/Action`)
        .then(res => {
            if(res.status === 201){
                setFormOptionsMetaData(state => ({ ...state, "action": res.data }));
            }else{
                console.log(res)
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        // get stakeholders dropdown
        axios.get(`${SERVICE_URL}risk/getConfig/StakeholderInclusion`)
        .then(res => {
            if(res.status === 201){
                setFormOptionsMetaData(state => ({ ...state, "stakeholderInclusion": res.data }));
            }else{
                console.log(res)
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        // get Contingency dropdown
        axios.get(`${SERVICE_URL}risk/getConfig/ResponseContigency`)
        .then(res => {
            if(res.status === 201){
                setFormOptionsMetaData(state => ({ ...state, "contigency": res.data }));
            }else{
                console.log(res)
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        // get risk type dropdown
        axios.get(`${SERVICE_URL}risk/getConfig/RiskType`)
        .then(res => {
            if(res.status === 201){
                setFormOptionsMetaData(state => ({ ...state, "riskType": res.data }));
            }else{
                console.log(res)
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        // populate projected time period dropdown
        var projectedTimePeriodOptions = [{"id":"1","time":"< 40","unit":"HRS"},{"id":"2","time":"< 80","unit":"HRS"},{"id":"3","time":"> 40","unit":"HRS"},{"id":"4","time":"> 80","unit":"HRS"}]
        setFormOptionsMetaData(state => ({ ...state, "projectedTimePeriod": projectedTimePeriodOptions }));
        // get risk type dropdown
        axios.get(`${SERVICE_URL}risk/getConfig/treatmentStatus`)
        .then(res => {
            if(res.status === 201){
                setFormOptionsMetaData(state => ({ ...state, "treatmentStatus": res.data }));
            }else{
                console.log(res)
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        // eslint-disable-next-line

     // get objective impact dropdown
        axios.get(`${SERVICE_URL}risk/businessImpact`)
        .then(res => {
            if(res.status === 201){
                setFormOptionsMetaData(state => ({ ...state, "objectiveImpact": res.data }));
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });

        // get business criticality  dropdown
        axios.get(`${SERVICE_URL}risk/businessCriticality`)
        .then(res => {
            if(res.status === 201){
                setFormOptionsMetaData(state => ({ ...state, "businessCriticality": res.data }));
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });

        if(companyId !== ""){
            axios.get(`${SERVICE_URL}risk/company/business/objective/companyId?companyId=${companyId}`)
            .then(res => {
                if(res.status === 200){
                    // var updatedFormOptionsMetaData = formOptionsMetaData;
                    // updatedFormOptionsMetaData["businessObjective"] = res.data;
                    // setFormOptionsMetaData({...updatedFormOptionsMetaData});
                    setFormOptionsMetaData(state => ({ ...state, "businessObjective": res.data }));
                }else{
                    dispatch(toastUp("unable to connect server"));
                }
            })
            .catch(err => {
                dispatch(toastUp(getErrorMsg(err)));
            })
            .finally(() => {
                dispatch(setSpinner(false));
            });   
        }
    // eslint-disable-next-line
    },[]);


    // function to handle business objective modal submit
    const handleBusinessObjectiveModalSubmit = mode => event => {
        if(mode === "add"){
            handleBusinessObjectiveModalSave();  
        }else if(mode === "edit"){
            handleBusinessObjectiveModalEdit();
        }
    }
    // function to validate, save and auto select the new value of business objective
    const handleBusinessObjectiveModalSave = () => {
        var data = {};
        data["description"] = businessObjectiveModal["value"]["name"];
        data["companyid"]=companyId;
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}risk/company/business/objective`,data)
        .then(res => {
            if(res.status === 201){
                dispatch(toastUp("Business Objective created successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData['businessObjective'].push(res.data);
                updatedFormOptionsMetaData["businessObjectiveTrigger"] = [];
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["businessObjective"] = res.data.id;
                updatedFormData["businessObjectiveTrigger"] = "";
                setFormData({...updatedFormData});
                handleBusinessObjectiveModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to validate, edit the existing value of business objective
    const handleBusinessObjectiveModalEdit = () => {
        if(businessObjectiveModal["value"]["name"] === ""){
            dispatch(toastUp("business objective field cannot be empty"));
            return false;
        }
        var data = {};
        data["description"] = businessObjectiveModal["value"]["name"];
        data["companyId"] = companyId;
        var businessObjectiveId = formData["businessObjective"]
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}risk/company/business/objective?Id=${businessObjectiveId}`,data)
        .then(res => {
            if(res.status === 200){
                dispatch(toastUp("Business Objective edited successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["businessObjective"] = replaceObjectInArrayBasedOnKey(formOptionsMetaData["businessObjective"],"id",businessObjectiveId,res.data);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                handleBusinessObjectiveModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // handle to clean and close business objective trigger modal
    const handleBusinessObjectiveTriggerModalClose = () => {
        var temp = businessObjectiveTriggerModal;
        temp["value"]["name"] = "";
        temp["header"] = "";
        temp["mode"] = "";
        temp["isOpen"] = false;
        setBusinessObjectiveTriggerModal({...temp});
    }

    // function to handle organizational point modal submit
    const handleBusinessObjectiveTriggerModalSubmit = mode => event => {
        if(mode === "add"){
            handleBusinessObjectiveTriggerModalSave();  
        }else if(mode === "edit"){
            handleBusinessObjectiveTriggerModalEdit();
        }
    }
    // function to validate, save and auto select the new value of business objective trigger
    const handleBusinessObjectiveTriggerModalSave = () => {
        if(businessObjectiveTriggerModal["value"]["name"] === ""){
            dispatch(toastUp("business objective trigger field cannot be empty"));
            return false;
        }
        var data = {};
        data["description"] = businessObjectiveTriggerModal["value"]["name"];
        data["businessObjectiveId"] = formData['businessObjective'];
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}risk/company/business/objectiveTrigger`,data)
        .then(res => {
            if(res.status === 201){
                dispatch(toastUp("Business Objective trigger created successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData['businessObjectiveTrigger'].push(res.data);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["businessObjectiveTrigger"]=res.data.id;
                setFormData({...updatedFormData});
                handleBusinessObjectiveTriggerModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to validate, edit the existing value of business objective triger
    const handleBusinessObjectiveTriggerModalEdit = () => {
        if(businessObjectiveTriggerModal["value"]["name"] === ""){
            dispatch(toastUp("business objective trigger field cannot be empty"));
            return false;
        }
        var data = {};
        data["description"] = businessObjectiveTriggerModal["value"]["name"];
        data["businessObjectiveId"] = formData['businessObjective'];
        var businessObjectiveTriggerId = formData["businessObjectiveTrigger"];
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}risk/company/business/objectiveTrigger?Id=${businessObjectiveTriggerId}`,data)
        .then(res => {
            if(res.status === 200){
                dispatch(toastUp("Business Objective trigger edited successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["businessObjectiveTrigger"] = replaceObjectInArrayBasedOnKey(formOptionsMetaData["businessObjectiveTrigger"],"id",businessObjectiveTriggerId,res.data);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                handleBusinessObjectiveTriggerModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to set form value to business objective trigger modal
    const businessObjectiveTriggerModalValueHandler = key => event => {
        var temp = businessObjectiveTriggerModal;
        temp['value'][key] = event.target.value;
        setBusinessObjectiveTriggerModal({...temp});
    }
   // function to set form value to business objective modal
   const businessObjectiveModalValueHandler = key => event => {
    var temp = businessObjectiveModal;
    temp['value'][key] = event.target.value;
    setBusinessObjectiveModal({...temp});
}
    // handle to clean and close business objective modal
    const handleBusinessObjectiveModalClose = () => {
        var temp = businessObjectiveModal;
        temp["value"]["name"] = "";
        temp["header"] = "";
        temp["mode"] = "";
        temp["isOpen"] = false;
        setBusinessObjectiveModal({...temp});
    }
    const openBusinessObjectiveModal = () => {
        if(companyId === ""){
            dispatch(toastUp("Please create Risk Identification"));
            return false;
        }
        var temp = businessObjectiveModal;
        temp["header"] = "Create Business Objective";
        temp["mode"] = "add";
        temp["isOpen"] = true;
        setBusinessObjectiveModal({...temp});
    }
    const editBusinessObjectiveModal = () => {
        if(formData["businessObjective"] === ""){
            dispatch(toastUp("please select business objective to edit"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["businessObjective"],"id",formData["businessObjective"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting business objective to edit"));
            return false;
        }
        var temp = businessObjectiveModal;
        temp['value']["name"] = selectedDataDetails["description"];
        temp["mode"] = "edit";
        temp["header"] = "Edit Business Objective";
        temp['isOpen'] = true;
        setBusinessObjectiveModal({...temp});
    }
    const deleteBusinessObjectiveModal = () =>{
        if(formData["businessObjective"] === ""){
            dispatch(toastUp("please select business objective to delete"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["businessObjective"],"id",formData["businessObjective"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting business objective to delete"));
            return false;
        }
        dispatch(setConfirmationModal({"content":`Are You Sure You Want To Delete ${selectedDataDetails['description']} From Business Objective`,"confirmationHandler":()=>handleBusinessObjectiveModalDelete(selectedDataDetails["id"])}));
    }
    const openBusinessObjectiveTriggerModal = () => {
        if(formData["businessObjective"] === ""){
            dispatch(toastUp("please select business objective to add business objective trigger"));
        }else{
            var temp = businessObjectiveTriggerModal;
            temp["header"] = "Create Business Objective Trigger";
            temp["mode"] = "add";
            temp["isOpen"] = true;
            setBusinessObjectiveTriggerModal({...temp});
        }
    }
    const handleBusinessObjectiveModalDelete = (id) => {
        dispatch(setSpinner(true));
        axios.delete(`${SERVICE_URL}risk/company/business/objective?Id=${id}`)
        .then(res => {
            if(res.status === 204){
                dispatch(toastUp("Business Objective deleted successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["businessObjective"] = removeObjectInArrayBasedOnKey(formOptionsMetaData["businessObjective"],"id",id);
                updatedFormOptionsMetaData["businessObjectiveTrigger"] = [];
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["businessObjective"] = "";
                updatedFormData["businessObjectiveTrigger"] = "";
                setFormData({...updatedFormData});
                dispatch(closeConfirmationModal());
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    const editBusinessObjectiveTriggerModal = () => {
        if(formData["businessObjective"] === ""){
            dispatch(toastUp("please select business objective"));
            return false;
        }
        if(formData["businessObjectiveTrigger"] === ""){
            dispatch(toastUp("please select business objective trigger to edit"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["businessObjectiveTrigger"],"id",formData["businessObjectiveTrigger"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting business objective trigger to edit"));
            return false;
        }
        var temp = businessObjectiveTriggerModal;
        temp['value']["name"] = selectedDataDetails["description"];
        temp["mode"] = "edit";
        temp["header"] = "Edit Business Objective Trigger";
        temp['isOpen'] = true;
        setBusinessObjectiveTriggerModal({...temp});
    }

    const deleteBusinessObjectiveTriggerModal = () =>{
        if(formData["businessObjectiveTrigger"] === ""){
            dispatch(toastUp("please select business objective trigger to delete"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["businessObjectiveTrigger"],"id",formData["businessObjectiveTrigger"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting business objective trigger to delete"));
            return false;
        }
        dispatch(setConfirmationModal({"content":`Are You Sure You Want To Delete ${selectedDataDetails['description']} From Business Objective Trigger`,"confirmationHandler":()=>handleBusinessObjectiveTriggerModalDelete(selectedDataDetails["id"])}));
    }
    // function delete the existing organizational domain value
    const handleBusinessObjectiveTriggerModalDelete = (id) => {
        dispatch(setSpinner(true));
        axios.delete(`${SERVICE_URL}risk/company/business/objectiveTrigger?Id=${id}`)
        .then(res => {
            if(res.status === 204){
                dispatch(toastUp("Business Objective trigger deleted successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["businessObjectiveTrigger"] = removeObjectInArrayBasedOnKey(formOptionsMetaData["businessObjectiveTrigger"],"id",id);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["businessObjectiveTrigger"] = "";
                setFormData({...updatedFormData});
                dispatch(closeConfirmationModal());
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }

    return (
        <div className='p15'>
            <FieldsetCustom legend={'BUSINESS DETAILS'}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Business Objective </span><br/>
                        <SelectWithMoreAED onChangeHandler={handleFormValueChange("businessObjective")} value={formData["businessObjective"]} options={generateDropDown(formOptionsMetaData["businessObjective"],"description")} addHandler={openBusinessObjectiveModal} editHandler={editBusinessObjectiveModal} deleteHandler={deleteBusinessObjectiveModal}/>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Business Objective Trigger </span><br/>
                        <SelectWithMoreAED onChangeHandler={handleFormValueChange("businessObjectiveTrigger")} value={formData["businessObjectiveTrigger"]} options={generateDropDown(formOptionsMetaData["businessObjectiveTrigger"],"description")} addHandler={openBusinessObjectiveTriggerModal} editHandler={editBusinessObjectiveTriggerModal} deleteHandler={deleteBusinessObjectiveTriggerModal}/>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Objective Impact</span><br/>
                        <Select className={selectStyle.selectFieldSection} MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFormValueChange("objectiveImpact")} value={formData["objectiveImpact"]} renderValue={renderDisplayValueForSelectTooltip} displayEmpty>
                            <MenuItem disabled value=""><em>-- please select--</em></MenuItem>
                            {generateDropDownWithToolTipBasedOnKey(formOptionsMetaData["objectiveImpact"],"name","name",riskIdentifierObjectiveImpact)}
                        </Select>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Business Criticality </span><br/>
                        <Select className={selectStyle.selectFieldSection} MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFormValueChange("businessCriticality")} value={formData["businessCriticality"]} renderValue={renderDisplayValueForSelectTooltip} displayEmpty>
                            <MenuItem disabled value=""><em>-- please select--</em></MenuItem>
                            {generateDropDownWithToolTipBasedOnKey(formOptionsMetaData["businessCriticality"],"name","name",riskIdentifierBusinessCriticality)}
                        </Select>
                    </Grid>
                </Grid>
            </FieldsetCustom>
            <FieldsetCustom legend={'RISK RESPONSE'}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Response Strategy/Action <span className='required'>*</span></span><br/>
                        <Select className={selectStyle.selectFieldSection} MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFormValueChange("action")} value={formData["action"]} displayEmpty>
                            <MenuItem disabled value=""><em>-- please select--</em></MenuItem>
                            {generateDropDownBasedOnKey(formOptionsMetaData["action"],"name","value")}
                        </Select>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Stakeholder Inclusion <span className='required'>*</span></span><br/>
                        <Select multiple className={selectStyle.selectFieldSection} MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFormValueChange("stakeholderInclusion")} value={formData["stakeholderInclusion"]} renderValue={(selected) => (selected.length > 0) ? selected.join(', ') : <em>-- please select--</em>} displayEmpty>
                            {generateDropDownMultiBasedOnKey(formOptionsMetaData["stakeholderInclusion"],"name","name",formData["stakeholderInclusion"])}
                        </Select>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Risk Type <span className='required'>*</span></span><br/>
                        <Select className={selectStyle.selectFieldSection} MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFormValueChange("riskType")} value={formData["riskType"]} renderValue={renderDisplayValueForSelectTooltip} displayEmpty >
                            <MenuItem disabled value=""><em>-- please select--</em></MenuItem>
                            {generateDropDownWithToolTipBasedOnKey(formOptionsMetaData["riskType"],"name","name",riskResponseRiskType)}
                        </Select>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Response Contingency <span className='required'>*</span></span><br/>
                        <Select className={selectStyle.selectFieldSection} MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFormValueChange("contigency")} value={formData["contigency"]} displayEmpty>
                            <MenuItem disabled value=""><em>-- please select--</em></MenuItem>
                            {generateDropDownBasedOnKey(formOptionsMetaData["contigency"],"name","name")}
                        </Select>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Projected Time Period <span className='required'>*</span></span><br/>
                        <Select className={selectStyle.selectFieldSection} MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFormValueChange("projectedTimePeriod")} value={formData["projectedTimePeriod"]} displayEmpty>
                            <MenuItem disabled value=""><em>-- please select--</em></MenuItem>
                            {generateDropDownForProjectedTimePeriod()}
                            <MenuItem value="other"> Other</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Treatment Status <span className='required'>*</span></span><br/>
                        <Select className={selectStyle.selectFieldSection} MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFormValueChange("treatmentStatus")} value={formData["treatmentStatus"]} displayEmpty>
                            <MenuItem disabled value=""><em>-- please select--</em></MenuItem>
                            {generateDropDownBasedOnKey(formOptionsMetaData["treatmentStatus"],"name","name")}
                        </Select>
                    </Grid>
                </Grid>
            </FieldsetCustom>

            <div className='mtb30' style={{textAlign:"center"}}>
                <Button className='mlr5 roButton' variant="contained" onClick={saveRiskResponse}>save and continue</Button>
            </div>

            {/* add business objective modal for form popup */}
            <ModalForm open={businessObjectiveModal.isOpen} mode={businessObjectiveModal.mode} closeHandler={handleBusinessObjectiveModalClose} header={businessObjectiveModal.header} saveHandler={handleBusinessObjectiveModalSubmit(businessObjectiveModal.mode)}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                        <span className='lable'>Business Objective <span className='required'>*</span></span><br/>
                        <TextField sx={textFieldStyle} value={businessObjectiveModal.value.name} fullWidth size="small" onChange={businessObjectiveModalValueHandler("name")} variant="outlined" />
                    </Grid>
                </Grid>
            </ModalForm>
            {/* add business objective trigger modal for form popup */}
            <ModalForm open={businessObjectiveTriggerModal.isOpen} mode={businessObjectiveTriggerModal.mode} closeHandler={handleBusinessObjectiveTriggerModalClose} header={businessObjectiveTriggerModal.header} saveHandler={handleBusinessObjectiveTriggerModalSubmit(businessObjectiveTriggerModal.mode)}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                        <span className='lable'>Business Objective Trigger <span className='required'>*</span></span><br/>
                        <TextField sx={textFieldStyle} value={businessObjectiveTriggerModal.value.name} fullWidth size="small" onChange={businessObjectiveTriggerModalValueHandler("name")} variant="outlined" />
                    </Grid>
                </Grid>
            </ModalForm>

            {/* add projected time period modal form popup */}
            <ModalForm open={projectedTimePeriodModal.isOpen} mode={projectedTimePeriodModal.mode} closeHandler={handleProjectedTimePeriodModalClose} header={projectedTimePeriodModal.header} saveHandler={handleProjectedTimePeriodModalSubmit}>
                <Grid container spacing={2}>
                    <Grid item sm={6} md={6}>
                        <span className='lable'>Sign <span className='required'>*</span></span><br/>
                        <Select className={selectStyle.selectFieldSection} MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={projectedTimePeriodModalValueHandler("sign")} value={projectedTimePeriodModal.value.sign} displayEmpty>
                            <MenuItem value="<"> {"<"}</MenuItem>
                            <MenuItem value=">"> {">"}</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item sm={6} md={6}>
                        <span className='lable'>HRS <span className='required'>*</span></span><br/>
                        <TextField sx={textFieldStyle} value={projectedTimePeriodModal.value.time} fullWidth size="small" type="number" inputProps={{maxLength: 4,step: "10", onKeyDown: (event) => {event.preventDefault();},min: 10}} onChange={projectedTimePeriodModalValueHandler("time")} variant="outlined" />
                    </Grid>
                </Grid>
            </ModalForm>
        </div>
    );
}

export default RiskResponse;