const riskResponseRiskType = {
    "Intial/Identified":"Initial Identified Risk Follow the Enterprise, Organization, Institution, Agency Risk Identification process steps to manage identified risks. \n\nInquire of the GRC Team or Risk Team if there is no GRC team, on policy status [in place/not in place] v. policy enforcement compliance. \n\nCommunicate with appropriate stakeholders on risk and security posture to address preventative measures in place or required",
    "Secondary":"Prevent/Manage Secondary Risk Occurrence Review policies and processes to determine which would have a higher probability of preventing the risk from occurring. \n\nInquire of the GRC Team or Risk Team if there is no GRC team, on policy status [in place/not in place] v. policy enforcement compliance. \n\nCommunicate with appropriate stakeholders on risk and security posture to address preventative measures in place or required.",
    "Residual":"Residual Risk Management, Review Critical Factors, Risk Tolerance, Threat Landscape and Mitigation Controls that exist. If none exist, develop, assess, test, implement (if appropriate) and monitor. \n\nThe goal is to Avoid, Outsource or Monitor or where possible, treat Residual Risks with new, effective controls that were not present, at time of the initial assessment and risk treatment processes. \n\nNotables:\n Threat Landscape Internal (TLI) addresses the environment in which the technology component (applications, devices [Servers, CSU/DSU, Wireless Access Points, Firewalls, Routers, Domain Controllers, NICs, DNS, NAT] etc] reside."
}

export {riskResponseRiskType}

const riskIdentifierObjectiveImpact = {
    "Primary":"Objective must be fully achieved.",
    "Secondary":"Objective must be met or a percent greater than 75% of the objective, must be shown to be implemented/achieved",
    "Tertiary":"Objective must be met or supplemental processes to achieve 50% objective intent, must be shown to be implemented/achieved"
}

export {riskIdentifierObjectiveImpact}

const riskIdentifierBusinessCriticality = {
    "Critical":"Apply highest level of business and most restrictive level of technical preventative measures to protect business objective.",
    "Severe":"Apply highest level of business and restrictive technical preventative measures to protect business objective.",
    "Negligible":"Apply appropriate business and technical measures to protect business objective."
}

export {riskIdentifierBusinessCriticality}