import React, {useState} from 'react';
import style from '../../css/LogIn.module.css'
import { Typography, TextField, Button } from '@mui/material';
import { textFieldStyle } from '../../js/standardStyle';
import axios from 'axios';
import { SERVICE_URL } from '../../config';
import { getErrorMsg } from '../../js/util';
import { useDispatch } from 'react-redux';
import { setSpinner } from '../../redux/actions/spinner';
import { toastUp } from '../../redux/actions/toast';

function ForgotPassword(props) {

    // init use state for form data
    const [formData,setFormData] = useState({"email":"","password":"","confirmPassword":"","userId":""});
    // function to handle form value change
    const handleFormValueChange = key => event => {
        var temp = formData;
        var value = event.target.value;
        temp[key] = value;
        setFormData({...temp});
    }

    // get use dispactch from redux
    const dispatch = useDispatch();

    // function to check if the email is already exists
    const checkIfUserEmailExists = () => {
        var email = formData["email"];
        if(email === ""){
            dispatch(toastUp("Please enter email-id"));
            return false;
        }
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}user/validBy?email=${email}`)
        .then((res) => {
            if(res.status === 200){
                var data = res["data"]
                formData["userId"] = data["id"];
            }else{
                console.log(res)
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch((err) => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        })
    }

    // function to change password
    const changePassword = () => {
        var password = formData["password"];
        var confirmPassword = formData["confirmPassword"]
        if(password === ""){
            dispatch(toastUp("Please enter new password"));
            return false;
        }
        if(confirmPassword !== password){
            dispatch(toastUp("Password and confirm new password is not matching"));
            return false;
        }
        if(formData["userId"] === ""){
            dispatch(toastUp("Issue selecting user"));
            return false;
        }
        var data = {};
        data["newPassword"] = password;
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}/user/changePassword?userId=${formData["userId"]}`,data)
        .then((res) => {
            if(res.status === 200){
                dispatch(toastUp("Password changed successfully"));
                setTimeout(() => {
                    props.setLoginUiHandler("logInForm");
                }, 2000);
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch((err) => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        })
    }

    return (
        <div className={style.form}>
            {/* <Typography className={style.companyName}>RISK ONE</Typography> */}
            <Typography className={style.Formheader}>FORGOT PASSWORD</Typography>
            <br/>
            <div style={{width:"100%"}}>
                <div style={{display: (formData["userId"] !== "" ? "none" : "block")}} className='mb15'>
                    <span className='lable'>Email <span className='required'>*</span></span><br/>
                    <TextField value={formData["email"]} onChange={handleFormValueChange("email")} sx={textFieldStyle} fullWidth size="small" variant="outlined" />
                </div>
                <div style={{display: (formData["userId"] === "" ? "none" : "block")}} className='mb15'>
                    <span className='lable'>New Password <span className='required'>*</span></span><br/>
                    <TextField value={formData["password"]} onChange={handleFormValueChange("password")} sx={textFieldStyle} fullWidth size="small" type="password" variant="outlined" />
                </div>
                <div style={{display: (formData["userId"] === "" ? "none" : "block")}}>
                    <span className='lable'>Confirm New Password <span className='required'>*</span></span><br/>
                    <TextField value={formData["confirmPassword"]} onChange={handleFormValueChange("confirmPassword")} sx={textFieldStyle} fullWidth size="small" type="password" variant="outlined" />
                </div>
            </div>
            <div className='mtb30 taCenter'>
                <Button style={{display: (formData["userId"] !== "" ? "none" : "inline")}} className={`mlr10 roButton`} variant="contained" onClick={checkIfUserEmailExists}>reset password</Button>
                <Button style={{display: (formData["userId"] === "" ? "none" : "inline")}} className={`mlr10 roButton`} variant="contained" onClick={changePassword}>change password</Button>
                <Button className={`mlr10 roButton`} variant="text" onClick={()=>props.setLoginUiHandler("logInForm")}>cancel</Button>
            </div>
        </div>
    );
}

export default ForgotPassword;