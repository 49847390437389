import React, { useEffect, useState } from 'react';
import HeaderSection from '../HeaderSection';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Autocomplete, Button, Grid, IconButton, TextField, Typography } from '@mui/material';
import { autocompleteStyle, getPriorityStyleForActionPlan, getStatusStyleForActionPlan, textFieldStyle } from '../../js/standardStyle';
import FieldsetCustom from '../wrapper/FieldsetCustom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getValueForPriorityScore, renderDisplayValueWithLableForAutocomplete } from '../../js/util';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { toastUp } from '../../redux/actions/toast';
import { setSpinner } from '../../redux/actions/spinner';
import { getErrorMsg } from '../../js/util';
import axios from 'axios';
import { SERVICE_URL } from '../../config';
import LinearProgress from '@mui/material/LinearProgress';
import { ACTION_PLAN_DROPDOWN, DATE_FORMAT } from '../../js/constants';

function ActionPlan(props) {
    const dispatch = useDispatch();
    const loggedInUser = useSelector(state=>state.loggedInUser);
    const {setEmailUi,selectedEmailId} = props;
    const [actionPlan,setActionPlan] = useState({"name":"","owner":"","priority":"","status":"Open","timeline":"","progress":""});
    const [actionStep,setActionStep] = useState([{"action":"","owner":null,"description":"","priority":null,"status":"Open","startDate":null,"endDate":null}]);
    const [formMetaData,setFormMetaData] = useState({"owner":[],"priority":[...ACTION_PLAN_DROPDOWN["priority"]],"status":['Open']});
    const addNewActionStep = () => {
        setActionStep((old)=>([...old,{"action":"","owner":null,"description":"","priority":null,"status":"Open","startDate":null,"endDate":null}]));
    }
    const handleValueChangeForActionPlan = (key) => event => {
        var temp = actionPlan;
        temp[key] = event.target.value;
        setActionPlan({...temp});
    }
    const populateStats = (data) =>{
        let temp = actionPlan;
        let startDate = null;
        let endDate = null;
        let priorityScore = 0;
        let priorityScoreMatch = 0;
        for(let [i,row] of data.entries()){
            if(i === 0){
                if(row["owner"]!== null){
                    temp["owner"] = row["owner"]["name"]
                }else{
                    temp["owner"] = ""
                }
                startDate = row["startDate"]
                endDate = row["endDate"]
                temp["status"] = row["status"]
            }
            if(row["startDate"] !== null && startDate > row["startDate"]){
                startDate = row["startDate"]
            }
            if(row["endDate"] !== null && endDate < row["endDate"]){
                endDate = row["endDate"]
            }
            if(row["priority"] !== null){
                priorityScore += row["priority"]["score"];
                priorityScoreMatch+=1
            }
        }
        if(startDate !== null){
            temp["timeline"] = `${startDate.format('MM/DD/YY')} - `
        }else{
            temp["timeline"] = ` - `
        }
        if(endDate !== null){
            temp["timeline"] += `${endDate.format('MM/DD/YY')}`
        }
        temp["priority"] = getValueForPriorityScore(priorityScore/priorityScoreMatch);
        setActionPlan({...temp})
    }

    const handleValueChangeForActionStep = (index,key) => (event,object) => {
        var temp = actionStep;
        for(var [i,row] of temp.entries()){
            if(i === index){
                var value
                if(["owner","priority","status"].includes(key)){
                    value = object;
                }else if(["startDate","endDate"].includes(key)){
                    if(key === "endDate"){
                        if(row["startDate"] === null){
                            dispatch(toastUp("Please select start date"));
                            return false;
                        }
                    }
                    value = event;
                }else{
                    value = event.target.value;
                }
                row[key] = value;
            }
        }
        setActionStep([...temp]);
        populateStats(temp);
    }
    const removeActionPlan = (position) => {
        if(actionStep.length === 1){
            dispatch(toastUp("Atleast one Action Step should be present"));
            return false;
        }
        var temp = actionStep;
        temp = temp.filter((row,i)=>{
            if(i!==position){
                return true;
            }
            return false;
        });
        setActionStep([...temp]);
        populateStats(temp);
    }
    const generateActionSteps = (data) => {
        let htmlMarkUp= []
        for(let [i,row] of data.entries()){
            htmlMarkUp.push(
                <Grid container key={i} justifyContent="space-between" style={{border:"1px solid rgba(0, 0, 0, 0.12)",borderRadius:"4px"}} className='p10 mtb10' alignItems="center">
                    <Grid style={{minWidth:"150px"}} className='mtb5'>
                        <TextField value={row["action"]} onChange={handleValueChangeForActionStep(i,"action")} sx={textFieldStyle} fullWidth size='small' label={"Name"}/>
                    </Grid>
                    <Grid style={{minWidth:"200px"}} className='mtb5'>
                        <Autocomplete value={row["owner"]} onChange={handleValueChangeForActionStep(i,"owner")} options={formMetaData["owner"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} disablePortal getOptionLabel={(option)=>option.name+' ('+option.email+')'} isOptionEqualToValue={(selected,option)=>selected.id===option.id} size='small' renderInput={renderDisplayValueWithLableForAutocomplete("Owner")}/>
                    </Grid>
                    <Grid style={{minWidth:"150px"}} className='mtb5'>
                        <TextField multiline value={row["description"]} onChange={handleValueChangeForActionStep(i,"description")} sx={textFieldStyle} fullWidth size='small' label={"Description"}/>
                    </Grid>
                    <Grid style={{minWidth:"150px"}} className='mtb5'>
                        <Autocomplete value={row["priority"]} onChange={handleValueChangeForActionStep(i,"priority")} options={formMetaData["priority"]} sx={{...autocompleteStyle.sx,'.MuiAutocomplete-input':getPriorityStyleForActionPlan(row["priority"])}} ListboxProps={autocompleteStyle.ListboxProps} disablePortal getOptionLabel={(option)=>option.name} isOptionEqualToValue={(selected,option)=>selected.value===option.value} size='small' renderInput={renderDisplayValueWithLableForAutocomplete("Priority")}/>
                    </Grid>
                    <Grid style={{minWidth:"150px"}} className='mtb5'>
                        <Autocomplete value={row["status"]} disabled onChange={handleValueChangeForActionStep(i,"status")} options={formMetaData["status"]} sx={{...autocompleteStyle.sx,'.MuiAutocomplete-input':getStatusStyleForActionPlan(row["status"])}} ListboxProps={autocompleteStyle.ListboxProps} disablePortal size='small' renderInput={renderDisplayValueWithLableForAutocomplete("Status")}/>
                    </Grid>
                    <Grid style={{minWidth:"150px"}} className='mtb5'>
                        <LocalizationProvider  size="small" dateAdapter={AdapterDayjs}>
                            <DatePicker value={row["startDate"]} onChange={handleValueChangeForActionStep(i,"startDate")} slotProps={{ textField: { size: 'small',fullWidth: true, label: "Start Date" } }} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid style={{minWidth:"150px"}} className='mtb5'>
                        <LocalizationProvider  size="small" dateAdapter={AdapterDayjs}>
                            <DatePicker value={row["endDate"]} onChange={handleValueChangeForActionStep(i,"endDate")} minDate={actionStep[i]["startDate"]} slotProps={{ textField: { size: 'small',fullWidth: true, label: "End Date" } }} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid>
                        <IconButton onClick={()=>removeActionPlan(i)}><DeleteForeverIcon className='deleteIcon'/></IconButton>
                    </Grid>
                </Grid>
            )
        }
        htmlMarkUp.push(
            <div key={"submit"} className='mtb10'>
                <Button variant='text' size='small' onClick={addNewActionStep} startIcon={<AddIcon/>} style={{color:"rgba(0, 0, 0, 0.5)"}}> add action step </Button>
            </div>
        )
        return htmlMarkUp;
    }
    useEffect(()=>{
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}user?companyId=${loggedInUser["company_id"]}&roles=Line Manager`)
        .then(res => {
            var temp = formMetaData;
            temp["owner"] = res["data"]["data"];
            setFormMetaData({...temp});
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        // eslint-disable-next-line
    },[])
    const composeActionPlan = () => {
        if(actionPlan["name"] === ""){
            dispatch(toastUp("Goal field cannot be empty"));
            return false;
        }
        var actionStepData = [];
        for(var row of actionStep){
            if(row["action"] === ""){
                dispatch(toastUp(`Name field cannot be empty of Action Step`));
                return false;
            }
            if(row["owner"] === null){
                dispatch(toastUp(`Owner field cannot be empty of Action Step`));
                return false;
            }
            if(row["description"] === ""){
                dispatch(toastUp(`Description field cannot be empty of Action Step`));
                return false;
            }
            if(row["priority"] === null){
                dispatch(toastUp(`Priority field cannot be empty of Action Step`));
                return false;
            }
            if(row["startDate"] === null){
                dispatch(toastUp(`Start Date field cannot be empty of Action Step`));
                return false;
            }
            if(row["endDate"] === null){
                dispatch(toastUp(`End Date field cannot be empty of Action Step`));
                return false;
            }
            actionStepData.push({
                'name':row["action"],
                'owner':row["owner"]["id"],
                'description':row["description"],
                'priority':row["priority"]["value"],
                'startDate':row["startDate"].format(DATE_FORMAT),
                'endDate':row["endDate"].format(DATE_FORMAT)
            })
        }
        var data = {};
        data["name"] = actionPlan["name"];
        data["steps"] = actionStepData;
        data["createdBy"] = loggedInUser["id"]
        data["responseType"] = "action_plan";
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}email/investigateIssue?emailThreadId=${selectedEmailId}`,data)
        .then(res=>{
            setEmailUi("view");
        })
        .catch(err=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
    }
    return (
        <div>
            <HeaderSection header='Action Plan' specialButtons={[<Button onClick={()=>{setEmailUi("view")}} style={{color: "black"}} className='mlr5 roButton' variant="text" startIcon={<ArrowBackIosIcon/>}>back</Button>]}/>
            <div className='m20'>
                <FieldsetCustom legend={'ACTION PLAN'}>
                    <Grid className='mtb10' container spacing={2}>
                        <Grid item xs={12} sm={6} md={6} lg={2}>
                            <TextField value={actionPlan["name"]} onChange={handleValueChangeForActionPlan("name")} sx={textFieldStyle} fullWidth size='small' label={"Goal"}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={2}>
                            <TextField disabled value={actionPlan["owner"]} sx={textFieldStyle} fullWidth size='small' label={"Owner"}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={2}>
                            <TextField disabled value={actionPlan["priority"]} sx={{...textFieldStyle,'.MuiInputBase-input':{...getPriorityStyleForActionPlan(actionPlan["priority"]),"padding": "2px 6px","margin": "6.5px 8px"}}} fullWidth size='small' label={"Priority"}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={2}>
                            <TextField disabled value={actionPlan["status"]} sx={{...textFieldStyle,'.MuiInputBase-input':{...getStatusStyleForActionPlan(actionPlan["status"]),"padding": "2px 6px","margin": "6.5px 8px"}}} fullWidth size='small' label={"Status"}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={2}>
                            <TextField disabled value={actionPlan["timeline"]} sx={textFieldStyle} fullWidth size='small' label={"Timeline"}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={2}>
                            <div style={{display:"flex",height:"100%",justifyContent:"space-between",alignItems:"center"}}>
                                <LinearProgress variant="determinate" value={0} style={{width:"calc(100% - 110px)"}} />
                                <Typography variant="body2" color="text.secondary">0 % Progress</Typography>
                            </div>
                        </Grid>
                    </Grid>
                    {/* <Grid className='mtb10' container spacing={2}> */}
                    <Typography className='mt20 mb10 legendColour'>ACTION STEPS</Typography>
                    {generateActionSteps(actionStep)}
                    {/* </Grid> */}
                    <div className='mtb30' style={{textAlign:"center"}}>
                        <Button variant='contained' onClick={composeActionPlan}>compose</Button>
                    </div>
                </FieldsetCustom>
            </div>
        </div>
    );
}

export default ActionPlan;