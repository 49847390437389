import React, { useState } from 'react';
import HeaderSection from '../HeaderSection';
import { Button, Grid, TextField, Select, MenuItem } from '@mui/material';
import { textFieldStyle, selectFieldStyle } from '../../js/standardStyle';
import FieldsetCustom from '../wrapper/FieldsetCustom';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { generateDropDown, getErrorMsg } from '../../js/util';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from '../../redux/actions/spinner';
import { toastUp } from '../../redux/actions/toast';
import { SERVICE_URL } from '../../config';
import axios from 'axios';

function CreateUser(props) {

    // get use dispatch from redux
    const dispatch = useDispatch();

    // get logged in user details
    const loggedInUser = useSelector((state) => state.loggedInUser);

    // init use state for form data 
    const [formData,setFormData] = useState({"email":"","name":"","password":"","confirmPassword":"","group":"","role":""})
    // function to handle value change for form
    const handleFormValueChange = key => event => {
        var temp = formData;
        var value = event.target.value;
        temp[key] = value;
        setFormData({...temp});

        if(key === "group"){
            populateRoleDropDownBasedOnGroup(value);
        }
    }
    // init use state for form meta data
    const [formOptionsMetaData,setFormOptionsMetaData] = useState({"role":[]});

    // function to populate dropdown for role
    const populateRoleDropDownBasedOnGroup = (group) => {
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}user/group/role?group=${group}`)
        .then((res)=>{
            var data = res["data"];
            var temp = formOptionsMetaData;
            temp["role"] = data["data"];
            setFormOptionsMetaData({...temp});
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
    }

    // function to validate form data
    const validateAndGetFormData = () => {
        var data = {};
        data["status"] = "invalid";
        if(formData["email"] === ""){
            data["msg"] = "Email field cannot be empty";
            return data;
        }
        if(formData["name"] === ""){
            data["msg"] = "Name field cannot be empty";
            return data;
        }
        if(formData["password"] === ""){
            data["msg"] = "Password field cannot be empty";
            return data;
        }
        if(formData["confirmPassword"] === ""){
            data["msg"] = "Confirm password field cannot be empty";
            return data;
        }
        if(formData["password"] !== formData["confirmPassword"]){
            data["msg"] = "Password and confirm password is not matching";
            return data;
        }
        if(formData["group"] === ""){
            data["msg"] = "Group field cannot be empty";
            return data;
        }
        if(formData["role"] === ""){
            data["msg"] = "Role field cannot be empty";
            return data;
        }

        if(loggedInUser["company_id"] === undefined){
            data["msg"] = "issue identifying user";
            return data;
        }
        data["status"] = "valid";

        var formValue = {}
        formValue["name"] = formData["name"];
        formValue["email"] = formData["email"];
        formValue["roleId"] = formData["role"];
        formValue["password"] = formData["password"];
        formValue["category"] = formData["group"]
        formValue["companyId"] = loggedInUser["company_id"];

        data["data"] = formValue;
        return data;
    }

    // function to reset form
    const resetForm = () => {
        var updatedFormData = formData;
        updatedFormData["email"] = "";
        updatedFormData["name"] = "";
        updatedFormData["password"] = "";
        updatedFormData["confirmPassword"] = "";
        updatedFormData["group"] = "";
        updatedFormData["role"] = "";
        setFormData({...updatedFormData});

        var updatedFormOptionsMetaData = formOptionsMetaData;
        updatedFormOptionsMetaData["role"] = [];
        setFormOptionsMetaData({...updatedFormOptionsMetaData});
    }
    // function to create user
    const createUser = () => {
        var data=validateAndGetFormData();
        if(data["status"] === "valid"){
            dispatch(setSpinner(true));
            axios.post(`${SERVICE_URL}user`,data["data"])
            .then((res) => {
                if(res.status === 200){
                    dispatch(toastUp("User created successfully"));
                    resetForm();
                }else{
                    console.log(res)
                    dispatch(toastUp("unable to connect server"));
                }
            })
            .catch((err) => {
                dispatch(toastUp(getErrorMsg(err)));
            })
            .finally(() => {
                dispatch(setSpinner(false));
            })
        }else{
            dispatch(toastUp(data["msg"]));
        }
        
    }

    return (
        <div>
            {/* selected menu details */}
            <HeaderSection header='Create User' breadcrumbs={["User Managment","Create User"]}/>
            <div className='m20'>
                <FieldsetCustom legend="CREATE USER">
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={6}>
                            <span className='lable'>Email <span className='required'>*</span></span><br/>
                            <TextField sx={textFieldStyle} fullWidth size="small" variant="outlined" onChange={handleFormValueChange("email")} value={formData["email"]} />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <span className='lable'>Name <span className='required'>*</span></span><br/>
                            <TextField sx={textFieldStyle} fullWidth size="small" variant="outlined" onChange={handleFormValueChange("name")} value={formData["name"]} />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <span className='lable'>Password <span className='required'>*</span></span><br/>
                            <TextField sx={textFieldStyle} fullWidth size="small" type="password" variant="outlined" onChange={handleFormValueChange("password")} value={formData["password"]} />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <span className='lable'>Confirm Password <span className='required'>*</span></span><br/>
                            <TextField sx={textFieldStyle} fullWidth size="small" type="password" variant="outlined" onChange={handleFormValueChange("confirmPassword")} value={formData["confirmPassword"]} />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <span className='lable'>Group <span className='required'>*</span></span><br/>
                            <Select MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFormValueChange("group")} value={formData["group"]} displayEmpty>
                                <MenuItem disabled value=""><em>-- please select--</em></MenuItem>
                                <MenuItem value="global_super_admin">Global Super Admin</MenuItem>
                                <MenuItem disabled value="lgsa_backup">Lite Global Super Admin</MenuItem>
                                <MenuItem disabled value="local_admin">Local Admin</MenuItem>
                                <MenuItem value="rbr">RBR Group</MenuItem>
                                <MenuItem value="rbsu">RBSU Group</MenuItem>
                                <MenuItem disabled value="external">External Group</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <span className='lable'>Role <span className='required'>*</span></span><br/>
                            <Select MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFormValueChange("role")} value={formData["role"]} displayEmpty>
                                <MenuItem disabled value=""><em>-- please select--</em></MenuItem>
                                {generateDropDown(formOptionsMetaData["role"],"role")}
                            </Select>
                        </Grid>
                    </Grid>
                    <div className='mtb30 taCenter'>
                        <Button className={`mlr5`} variant="contained" endIcon={<PersonAddIcon/>} onClick={createUser}>create</Button>
                    </div>
                </FieldsetCustom>
            </div>
        </div>
    );
}

export default CreateUser;