import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { SERVICE_URL } from '../../../config';
import { Paper, Button } from '@mui/material';
import DataGridCustomFull from '../../wrapper/DataGridCustomFull';
import { getErrorMsg } from '../../../js/util';
import { useDispatch, useSelector } from 'react-redux';
import { toastUp } from '../../../redux/actions/toast';
import ModalViewInfo from '../../wrapper/ModalViewInfo';
import IdentifiedIssueResponseForModal from './IdentifiedIssueResponseForModal';
import { setSpinner } from "../../../redux/actions/spinner";


function IdentifiedIssues(props) {

    // get dispatch from redux
    const dispatch = useDispatch();

    // get logged in user details
    const loggedInUser = useSelector(state => state.loggedInUser);
    // init use state handle page for table
    const [pageState, setPageState] = useState({
        page: 0,
        pageSize: 30
    })
    // init use state to store table contents
    const [tableContent, setTableContent] = useState({
        isLoading: false,
        data: [],
        total: 0,
    })
    // function to get action cell content
    const getActionCellContent = (params) => (
        <div>
            {
                params.row.status === "completed"
                    ?
                    <Button onClick={()=>{openRegisterAssessmentIssue(params.id)}} className='ptb2 plr10 riskOneGreenSmallBtn' size="small" variant="contained">Inspect Issue</Button>
                    
                    :
                    <></>
            }
        </div>
    )
    // init table column details
    const columns = [
        { field: 'name', sortable: false, flex: 1, renderHeader: () => (<strong>{'Assessment Name'}</strong>) },
        { field: 'framework', sortable: false, flex: 1, renderHeader: () => (<strong>{'Framework Source'}</strong>) },
        { field: 'category', sortable: false, flex: 1, renderHeader: () => (<strong>{'Risk Category'}</strong>) },
        { field: 'type', sortable: false, flex: 1, renderHeader: () => (<strong>{'Assessment Type'}</strong>) },
        { field: 'assigned_by', sortable: false, flex: 1, renderHeader: () => (<strong>{'Assigned By'}</strong>) },
        { field: 'action', sortable: false, width: 150, renderHeader: () => (<strong>{'Action'}</strong>), renderCell: getActionCellContent }
    ]


    // const getRepoButton = () => {
    //     return(
    //         <Button size='small' 
    //             variant='contained' 
    //             startIcon={<StorageIcon/>} 
    //             onClick={()=>setPolicyUi('repository')}
    //             className={`${style.changePolicy} mlr5`}
    //         >
    //             Repository
    //         </Button>
    //     )
    // }
    // const getActivityButton = () =>{
    //     return(
    //         <>
    //             <Button size='small' 
    //                 variant='contained' 
    //                 startIcon={<HistoryIcon/>} 
    //                 aria-controls='addPolicyMenu'
    //                 aria-haspopup="true"
    //                 onClick={openMenuAnchor("activity")}
    //                 className={`${style.addPolicy} mlr5`}
    //             >
    //                 Activity
    //             </Button>
    //             <Menu
    //                 id="activityMenu"
    //                 anchorEl={menuPolicy["activity"]["anchor"]}
    //                 open={Boolean(menuPolicy["activity"]["anchor"])}
    //                 onClose={()=>{closeMenuAnchor("activity")}}
    //                 anchorOrigin={{
    //                     vertical: 'bottom',
    //                     horizontal: 'right',
    //                 }}
    //                 transformOrigin={{
    //                     vertical: 'top',
    //                     horizontal: 'center',
    //                 }}
    //                 PaperProps={{
    //                     style: {
    //                         minWidth: '260px',
    //                     }
    //                 }}
    //             >   
    //                 <MenuItem onClick={()=>setPolicyUIandCloseAnchor("activity","viewActivity")}>View Activity</MenuItem>
    //                 <MenuItem onClick={()=>setPolicyUIandCloseAnchor("activity","new")}>New</MenuItem>
    //                 <MenuItem onClick={()=>setPolicyUIandCloseAnchor("activity","tickler")}>Tickler</MenuItem>
    //             </Menu>
    //         </>
    //     );
    // }

    // const getAllSpecialButtons = () => {
    //     var allSpecialButtons = [getActivityButton(),getRepoButton()]
    //     return allSpecialButtons;
    // }

    // init regiterAssessmentIssueModal
    const [registerAssessmentIssueModal, SetRegisterAssessmentIssueModal] = useState({"isOpen":false,"data":{}})
    const openRegisterAssessmentIssue = id => {
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}assessment/register/identifiedIssues?id=${id}`)
        .then(res => {
            var temp = registerAssessmentIssueModal;
            temp["isOpen"] = true;
            temp["data"] = res["data"]["data"];
            SetRegisterAssessmentIssueModal({...temp});
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    const closeRegisterAssessmentIssue = () => {
        var temp = registerAssessmentIssueModal;
        temp["isOpen"] = false;
        temp["data"] = {};
        SetRegisterAssessmentIssueModal({...temp});
    }
    const refreshAndCloseRegisterAssessmentIssue= () => {
        fetchData();
        closeRegisterAssessmentIssue();
    }
    // use effect to call the function when ever the page changes 
    const fetchData = async () => {
        setTableContent(old => ({ ...old, isLoading: true }))
        axios.get(`${SERVICE_URL}assessment/register/view?page=${pageState.page + 1}&size=${pageState.pageSize}&groupBy=idenfitiedIssue&assignedTo=${loggedInUser["id"]}`)
            .then(res => {
                var data = res.data;
                var temp = tableContent;
                temp["isLoading"] = false;
                temp["data"] = data["data"];
                temp["total"] = data["total"];
                setTableContent({ ...temp });
            }).catch(err => {
                dispatch(toastUp(getErrorMsg(err)));
                setTableContent(old => ({ ...old, isLoading: false }));
            })
    }
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [pageState.page, pageState.pageSize])



    return (
        <div>
            {/* <HeaderSection header='Identified Issues' breadcrumbs={["Cyber Risk Management","Manage Risk","Identified Issues"]} specialButtons={[...getAllSpecialButtons()]}/>
             */}
            <div className='m20'>
                <Paper elevation={16}>
                    <DataGridCustomFull rows={tableContent.data} rowCount={tableContent.total} isLoading={tableContent.isLoading} pageSizeOptions={[30,50,100]} paginationModel={pageState} onPaginationModelChange={setPageState} columns={columns}/>
                </Paper>
            </div>
            <ModalViewInfo max open={registerAssessmentIssueModal.isOpen} closeHandler={closeRegisterAssessmentIssue}>
                <IdentifiedIssueResponseForModal refreshAndCloseHandler={refreshAndCloseRegisterAssessmentIssue} data={registerAssessmentIssueModal.data} />
            </ModalViewInfo>
        </div>
    );
}
export default IdentifiedIssues;