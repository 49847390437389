import { Grid, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import style from '../../../css/ViewProjectDetailsForModal.module.css';
import FieldsetCustom from '../../wrapper/FieldsetCustom';
import { SERVICE_URL } from '../../../config';
import { getNameBasedOnValue } from '../../../js/util';
import { ynDropDown } from '../../../js/constants';

function ViewActivityForModal(props) {
    const { activityDetails } = props;
    const { framework, frameworkControlResponse } = activityDetails.data;
    return (
        <div style={{ height: "100%" }}>
            <div>
                <Grid container direction="row">
                    <Grid item>
                        <Typography variant="h6" component="h2">{framework}</Typography>
                    </Grid>
                </Grid>
            </div>
            <div className={style.content}>
                <div className={`${style.fieldsetSection}`}>
                    <>
                        {Object.entries(frameworkControlResponse).map(([key, value]) => {
                            const { response } = value;
                            const legend = `${value.controlDetails.code}-${value.controlDetails.name}`;
                            return (
                                <FieldsetCustom key={key} legend={legend}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={6} md={3} lg={4}>
                                            <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Objective</strong></Typography>
                                        </Grid>
                                        <Grid item sm={6} md={3} lg={2}>
                                            <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Answer</strong></Typography>
                                        </Grid>
                                        <Grid item sm={6} md={3} lg={2}>
                                            <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Reason/Evidence</strong></Typography>
                                        </Grid>
                                        <Grid item sm={6} md={3} lg={4}>
                                            <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Value</strong></Typography>
                                        </Grid>
                                        {response.map((item) => {
                                            const { answer, question, issue } = item;
                                            const { changeAnswer, changeAnswerType, reason, evidenceFileName } = issue;
                                            return (
                                                <Fragment key={issue.id}>
                                                    <Grid item sm={6} md={3} lg={4}>
                                                        <Typography>{question}</Typography>
                                                    </Grid>
                                                    <Grid item sm={6} md={3} lg={2}>
                                                        <Typography>{getNameBasedOnValue(ynDropDown,answer)}</Typography>
                                                    </Grid>
                                                    {changeAnswer === true ?
                                                        <>
                                                            {changeAnswerType === "reason" ?
                                                                <>
                                                                    <Grid item sm={6} md={3} lg={2}>
                                                                        <Typography>Reason</Typography>
                                                                    </Grid>
                                                                    <Grid item sm={6} md={3} lg={4}>
                                                                        <Typography> {reason}</Typography>
                                                                    </Grid>
                                                                </>
                                                            :
                                                                <>
                                                                    <Grid item sm={6} md={3} lg={2}>
                                                                        <Typography>Evidence</Typography>
                                                                    </Grid>
                                                                    <Grid item sm={6} md={3} lg={4}>
                                                                        <Typography>
                                                                            <a
                                                                                href={`${SERVICE_URL}assessment/register/identifiedIssues/evidence?fileName=${evidenceFileName}`}
                                                                                rel='noopener noreferrer'
                                                                                target='_blank'
                                                                            >
                                                                                {evidenceFileName}
                                                                            </a>
                                                                        </Typography>
                                                                    </Grid>
                                                                </>
                                                            }
                                                        </>
                                                    :
                                                        <>
                                                            <Grid item sm={6} md={3} lg={2}>-
                                            
                                                            </Grid>
                                                            <Grid item sm={6} md={3} lg={4}>-
                                                        
                                                            </Grid>
                                                        </>
                                                    }
                                                </Fragment>
                                            );
                                        }
                                    )
                                }
                                    </Grid>
                                </FieldsetCustom>

                            );
                        })}
                    </>
                </div>
            </div>
        </div>
    );
}

export default ViewActivityForModal;