import React,{useState,useEffect} from 'react';
import {TextField,Grid,MenuItem,Select,Button, Tooltip, Switch} from '@mui/material';
import {textFieldStyle, selectFieldStyle, tooltipStyle} from '../../js/standardStyle.js';
import ModalForm from '../wrapper/ModalForm';
import axios from 'axios';
import { SERVICE_URL } from '../../config.js';
import SelectWithMoreAED from '../wrapper/SelectWithMoreAED.js';
import { getArrayOfObjectBasedOnKey,replaceObjectInArrayBasedOnKey, generateDropDown, generateDropDownBasedOnKey, removeObjectInArrayBasedOnKey, getErrorMsg, wrapEmailInArrayOfObject, wrapEmailOfObject } from '../../js/util.js';
import ModalConfirmation from '../wrapper/ModalConfirmation.js';
import selectStyle from '../../css/SelectWithMoreAED.module.css';
import FieldsetCustom from '../wrapper/FieldsetCustom.js';
import { useDispatch } from 'react-redux';
import { setSpinner } from '../../redux/actions/spinner.js';
import { toastUp } from '../../redux/actions/toast.js';
// import { riskIdentifierObjectiveImpact,riskIdentifierBusinessCriticality } from '../../js/tiptoolDescription.js';

function RiskIdentification(props) {
    
    // get use dispatch from redux
    const dispatch = useDispatch();

    // init useState for delete confirmation modal
    const [confirmationModal, setConfirmationModal] = useState({"isOpen":false,"content":"","confirmationHandler":()=>{}});
    // close and reset the confirmation modal
    const closeConfirmationModal = event => {
        var temp = confirmationModal;
        temp["content"] = "";
        temp["confirmationHandler"] = ()=>{};
        temp["isOpen"] = false;
        setConfirmationModal({...temp});
    }

    // init useState to store form values
    const [formData,setFormData] = useState({"company":"","organizationalDomain":"","riskCategory":"","businessUnit":"","organizationalPoint":"","organizationalExecutive":"","businessExecutive":"","lineManager":"","riskOwner":"","assignedResource":"","riskIndex":"","riskIdentifier":"","riskSource":"","riskHierarchy":"","riskName":"","riskDescription":""});
    // function to set form value to formData
    const handleFormValueChange = key => event => {
        var temp = formData;
        var value = event.target.value;
        temp[key] = value;
        setFormData({...temp});
        // check if the selected value is not empty and populate drop down values based on company
        if(key === "company"){
            if(value !== ""){
                generateOrganizationalDomainForCompany(formData["company"]);
                generateBusinessUnitForCompany(formData["company"]);
                generateOrganizationalPointForCompany(formData["company"]);
                // generateBusinessObjectiveForCompany(formData["company"]);
                generateOrganizationalExecutiveForcompany(formData["company"]);
                generateBusinessExecutiveForcompany(formData["company"]);
                generateLineManagerForcompany(formData["company"]);
                generateRiskCategoryForCompany(formData["company"]);
                generateRiskIndexForCompany(formData["company"]);
                generateRiskSourceForCompany(formData["company"]);
                generateRiskHierarchyForCompany(formData["company"]);
                generateRiskNameForCompany(formData["company"]);
                var updatedFormData = formData;
                // updatedFormData["objectiveImpact"] = "";
                // updatedFormData["businessCriticality"] = "";
                updatedFormData["riskIdentifier"] = "";
                updatedFormData["riskOwner"] = "";
                updatedFormData["assignedResource"] = "";
                setFormData({...updatedFormData});
            }
        }

        // if(key === "businessObjective"){
        //     if(value !== ""){
        //         generateBusinessObjectiveTriggerForBusinessObjective(formData["businessObjective"]);
        //     }
        // }

        if(key === "riskName"){
            if(value !== ""){
                generateRiskDescriptionForRiskName(formData["riskName"])
            }
        }
    };
    
    // init useState to store metadata for dropdown
    const [formOptionsMetaData, setFormOptionsMetaData] = useState({"company":[],"organizationalDomain":[],"businessUnit":[],"organizationalPoint":[],"riskCategory":[],"organizationalExecutive":[],"businessExecutive":[],"lineManager":[],"riskIndex":[],"riskIdentifier":[],"riskSource":[],"riskHierarchy":[],"riskName":[],"riskDescription":[]});

    // init use state for support data
    const [supportData,setSupportData] = useState({"role":[]});

    // init company modal state and values
    const [companyModal, setCompanyModal] = useState({"isOpen":false,"mode":"","header":"","value":{"name":"","agency":"No"}});
    // open company modal to create company
    const openCompanyModal = () => {
        var temp = companyModal;
        temp['value']["name"] = "";
        temp['value']["agency"] = "No";
        temp['mode'] = "add";
        temp["header"] = "Create Company/Agency";
        temp['isOpen'] = true;
        setCompanyModal({...temp});
    }
    // open company modal to edit company
    const editCompanyModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency to edit"));
            return false;
        }
        var temp = companyModal;
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["company"],"id",formData["company"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting company/agency to edit"));
            return false;
        }
        temp['value']["name"] = selectedDataDetails["name"];
        temp['value']["agency"] = selectedDataDetails["agency"];
        temp['isOpen'] = true;
        temp['mode'] = "edit";
        temp["header"] = "Edit Company/Agency";
        setCompanyModal({...temp});
    }
    // handler to clean and close company modal
    const handleCompanyModalClose = () => {
        var temp = companyModal;
        temp['value']["name"] = "";
        temp['value']["agency"] = "No";
        temp['header'] = "";
        temp['isOpen'] = false;
        setCompanyModal({...temp});
    }
    // function to set form value to companyModal value field
    const companyModalValueHandler = key => event => {
        var temp = companyModal;
        temp['value'][key] = event.target.value;
        setCompanyModal({...temp});
    }
    // function to handle company modal submit
    const handleCompanyModalSubmit = mode => event => {
        if(mode === "add"){
            handleCompanyModalSave();  
        }else if(mode === "edit"){
            handleCompanyModalEdit();
        }
    }
    // function to validate company modal, send request to service and select the newly added value in the dropdown for company
    const handleCompanyModalSave = () => {
        if(companyModal.value.name === ""){
            dispatch(toastUp("Name Field Cannot Be Empty"));
            return false;
        }
        var data = {};
        data["multitenancyOwnerId"] = "0";
        data["name"] = companyModal["value"]["name"];
        data["agency"] = companyModal["value"]["agency"];
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}risk/company`,data)
        .then(res => {
            if(res.status === 201){
                dispatch(toastUp("Company/Agency created successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["company"].push(res.data);
                updatedFormOptionsMetaData["organizationalDomain"] = [];
                updatedFormOptionsMetaData["businessUnit"] = [];
                updatedFormOptionsMetaData["organizationalPoint"] = [];
                // updatedFormOptionsMetaData["businessObjective"] = [];
                // updatedFormOptionsMetaData["businessObjectiveTrigger"] = [];
                updatedFormOptionsMetaData["riskCategory"] = [];
                updatedFormOptionsMetaData["organizationalExecutive"] = [];
                updatedFormOptionsMetaData["businessExecutive"] = [];
                updatedFormOptionsMetaData["lineManager"] = [];
                updatedFormOptionsMetaData["riskIndex"] = [];
                updatedFormOptionsMetaData["riskSource"] = [];
                updatedFormOptionsMetaData["riskHierarchy"] = [];
                updatedFormOptionsMetaData["riskName"] = [];
                updatedFormOptionsMetaData["riskDescription"] = [];
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["company"] = res.data.id;
                updatedFormData["organizationalDomain"] = "";
                updatedFormData["businessUnit"] = "";
                updatedFormData["organizationalPoint"] = "";
                // updatedFormData["businessObjective"] = "";
                // updatedFormData["businessObjectiveTrigger"] = "";
                updatedFormData["organizationalExecutive"] = "";
                updatedFormData["businessExecutive"] = "";
                updatedFormData["lineManager"] = "";
                // updatedFormData["objectiveImpact"] = "";
                updatedFormData["riskCategory"] = "";
                updatedFormData["riskOwner"] = "";
                updatedFormData["assignedResource"] = "";
                updatedFormData["riskIndex"] = "";
                updatedFormData["riskSource"] = "";
                updatedFormData["riskHierarchy"] = "";
                updatedFormData["riskName"] = "";
                updatedFormData["riskDescription"] = "";
                // updatedFormData["businessCriticality"] = "";
                updatedFormData["riskIdentifier"] = "";
                setFormData({...updatedFormData});
                handleCompanyModalClose();
                generateRiskCategoryForCompany(res.data.id);
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to edit existing company
    const handleCompanyModalEdit = () => {
        if(companyModal.value.name === ""){
            dispatch(toastUp("Name Field Cannot Be Empty"));
            return false;
        }
        var data = {};
        data["multitenancyOwnerId"] = "0";
        data["name"] = companyModal["value"]["name"];
        data["agency"] = companyModal["value"]["agency"];
        var companyId = formData["company"];
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}risk/company?Id=${companyId}`,data)
        .then(res => {
            if(res.status === 200){
                dispatch(toastUp("Company/Agency edited successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["company"] = replaceObjectInArrayBasedOnKey(formOptionsMetaData["company"],"id",companyId,res.data);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                handleCompanyModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }

    // init organizational domain modal state and values
    const [organizationalDomainModal, setOrganizationalDomainModal] = useState({"isOpen":false,"mode":"","header":"","value":{"name":""}});
    // open organizational domain modal to create organizational domain
    const openOrganizationalDomainModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency to add organizational domain"));
        }else{
            var temp = organizationalDomainModal;
            temp["header"] = "Create Organizational Domain";
            temp["mode"] = "add"
            temp["isOpen"] = true;
            setOrganizationalDomainModal({...temp})
        }
    }
    // open organizational domain modal to edit organizational domain
    const editOrganizationalDomainModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["organizationalDomain"] === ""){
            dispatch(toastUp("please select organizational domain to edit"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["organizationalDomain"],"id",formData["organizationalDomain"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting organizational domain to edit"));
            return false;
        }
        var temp = organizationalDomainModal;
        temp['value']["name"] = selectedDataDetails["name"];
        temp["mode"] = "edit";
        temp["header"] = "Edit Organizational Domain";
        temp['isOpen'] = true;
        setOrganizationalDomainModal({...temp});
    }
    // open confirmation modal to delete organizational domain
    const deleteOrganizationalDomainModal = () =>{
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["organizationalDomain"] === ""){
            dispatch(toastUp("please select organizational domain to delete"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["organizationalDomain"],"id",formData["organizationalDomain"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting organizational domain to delete"));
            return false;
        }
        var temp = confirmationModal;
        temp["content"] = `Are You Sure You Want To Delete ${selectedDataDetails['name']} From Organizational Domain`;
        temp["confirmationHandler"] = ()=>{handleOrganizationalDomainModalDelete(selectedDataDetails["id"])};
        temp["isOpen"] = true;
        setConfirmationModal({...temp});
    }
    // handler to clean and close organizational domain modal
    const handleOrganizationalDomainModalClose = () => {
        var temp = organizationalDomainModal;
        temp['value']["name"] = "";
        temp["header"] = "";
        temp["mode"] = "";
        temp['isOpen'] = false;
        setOrganizationalDomainModal({...temp});
    }
    // function to set form value to organizational domain modal value field
    const organizationalDomainModalValueHandler = key => event => {
        var temp = organizationalDomainModal;
        temp['value'][key] = event.target.value;
        setOrganizationalDomainModal({...temp});
    }
    // function to handle organizational domain modal submit
    const handleOrganizationalDomainModalSubmit = mode => event => {
        if(mode === "add"){
            handleOrganizationalDomainModalSave();  
        }else if(mode === "edit"){
            handleOrganizationalDomainModalEdit();
        }
    }
    // function to validate company modal, send request to service and select the newly added value in the dropdown of organizational domain modal
    const handleOrganizationalDomainModalSave = () => {
        if(organizationalDomainModal["value"]["name"] === ""){
            dispatch(toastUp("Domain Name Field Cannot Be Empty"));
            return false;
        }
        var data = {}
        data["name"] = organizationalDomainModal["value"]["name"];
        data["companyId"] = formData["company"];
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}risk/company/organizational/domain`,data)
        .then(res => {
            if(res.status === 201){
                dispatch(toastUp("Organizational Domain created successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData['organizationalDomain'].push(res.data);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["organizationalDomain"] = res.data.id;
                setFormData({...updatedFormData});
                handleOrganizationalDomainModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to save edited value
    const handleOrganizationalDomainModalEdit = () => {
        if(organizationalDomainModal["value"]["name"] === ""){
            dispatch(toastUp("Domain Name Field Cannot Be Empty"));
            return false;
        }
        var data = {}
        data["name"] = organizationalDomainModal["value"]["name"];
        data["companyId"] = formData["company"];
        var organizationalDomainId = formData["organizationalDomain"];
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}risk/company/organizational/domain?Id=${organizationalDomainId}`,data)
        .then(res => {
            if(res.status === 200){
                dispatch(toastUp("Organizational Domain edited successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["organizationalDomain"] = replaceObjectInArrayBasedOnKey(formOptionsMetaData["organizationalDomain"],"id",organizationalDomainId,res.data);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                handleOrganizationalDomainModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function delete the existing organizational domain value
    const handleOrganizationalDomainModalDelete = (id) => {
        dispatch(setSpinner(true));
        axios.delete(`${SERVICE_URL}risk/company/organizational/domain?Id=${id}`)
        .then(res => {
            if(res.status === 204){
                dispatch(toastUp("Organizational Domain deleted successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["organizationalDomain"] = removeObjectInArrayBasedOnKey(formOptionsMetaData["organizationalDomain"],"id",id);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["organizationalDomain"] = "";
                setFormData({...updatedFormData});
                closeConfirmationModal();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // generate organizational domain dropdown value based on selected company
    const generateOrganizationalDomainForCompany = (companyId) => {
        var updatedFormData = formData;
        updatedFormData["organizationalDomain"] = "";
        setFormData({...updatedFormData});
        var updatedFormOptionsMetaData = formOptionsMetaData;
        updatedFormOptionsMetaData["organizationalDomain"] = [];
        setFormOptionsMetaData({...updatedFormOptionsMetaData});
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}risk/company/organizational/domain/companyId?companyId=${companyId}`)
        .then(res => {
            if(res.status === 200){
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["organizationalDomain"] = res.data;
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }

    // init business unit modal
    const [businessUnitModal,setBusinessUnitModal] = useState({"isOpen":false,"mode":"","header":"","value":{"name":""}});
    // open business unit modal
    const openBusinessUnitModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency to add business unit"));
        }else{
            var temp = businessUnitModal;
            temp["header"] = "Create Business Unit";
            temp["mode"] = "add";
            temp["isOpen"] = true;
            setBusinessUnitModal({...temp});
        }
    }
    // open business unit modal to edit business unit
    const editBusinessUnitModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["businessUnit"] === ""){
            dispatch(toastUp("please select business unit to edit"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["businessUnit"],"id",formData["businessUnit"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting business unit to edit"));
            return false;
        }
        var temp = businessUnitModal;
        temp['value']["name"] = selectedDataDetails["name"];
        temp["mode"] = "edit";
        temp["header"] = "Edit Business Unit";
        temp['isOpen'] = true;
        setBusinessUnitModal({...temp});
    }
    // open confirmation modal to delete business unit
    const deleteBusinessUnitModal = () =>{
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["businessUnit"] === ""){
            dispatch(toastUp("please select business unit to delete"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["businessUnit"],"id",formData["businessUnit"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting business unit to delete"));
            return false;
        }
        var temp = confirmationModal;
        temp["content"] = `Are You Sure You Want To Delete ${selectedDataDetails['name']} From Business Unit`;
        temp["confirmationHandler"] = ()=>{handleBusinessUnitModalDelete(selectedDataDetails["id"])};
        temp["isOpen"] = true;
        setConfirmationModal({...temp});
    }
    // handle to clean and close businessUnit modal
    const handleBusinessUnitModalClose = () => {
        var temp = businessUnitModal;
        temp["value"]["name"] = "";
        temp["header"] = "";
        temp['mode'] = "";
        temp["isOpen"] = false;
        setBusinessUnitModal({...temp});
    }
    // function to set form value to business unit modal
    const businessUnitModalValueHandler = key => event => {
        var temp = businessUnitModal;
        temp['value'][key] = event.target.value;
        setBusinessUnitModal({...temp});
    }
    // function to handle business unit modal submit
    const handleBusinessUnitModalSubmit = mode => event => {
        if(mode === "add"){
            handleBusinessUnitModalSave();  
        }else if(mode === "edit"){
            handleBusinessUnitModalEdit();
        }
    }
    // function to validate, save and auto select the new value
    const handleBusinessUnitModalSave = () => {
        if(businessUnitModal["value"]["name"] === ""){
            dispatch(toastUp("business unit field cannot be empty"));
            return false;
        }
        var data = {};
        data["name"] = businessUnitModal["value"]["name"];
        data["companyId"] = formData['company'];
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}risk/company/business/unit`,data)
        .then(res => {
            if(res.status === 201){
                dispatch(toastUp("Business Unit created successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData['businessUnit'].push(res.data);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["businessUnit"] = res.data.id;
                setFormData({...updatedFormData});
                handleBusinessUnitModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to validate, edit the existing business unit value
    const handleBusinessUnitModalEdit = () => {
        if(businessUnitModal["value"]["name"] === ""){
            dispatch(toastUp("business unit field cannot be empty"));
            return false;
        }
        var data = {};
        data["name"] = businessUnitModal["value"]["name"];
        data["companyId"] = formData['company'];
        var businessUnitId = formData["businessUnit"];
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}risk/company/business/unit?Id=${businessUnitId}`,data)
        .then(res => {
            if(res.status === 200){
                dispatch(toastUp("Business Unit edited successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["businessUnit"] = replaceObjectInArrayBasedOnKey(formOptionsMetaData["businessUnit"],"id",businessUnitId,res.data);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                handleBusinessUnitModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function delete the existing business unit value
    const handleBusinessUnitModalDelete = (id) => {
        dispatch(setSpinner(true));
        axios.delete(`${SERVICE_URL}risk/company/business/unit?Id=${id}`)
        .then(res => {
            if(res.status === 204){
                dispatch(toastUp("Business Unit deleted successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["businessUnit"] = removeObjectInArrayBasedOnKey(formOptionsMetaData["businessUnit"],"id",id);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["businessUnit"] = "";
                setFormData({...updatedFormData});
                closeConfirmationModal();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // generate business unit dropdown value based on selected company
    const generateBusinessUnitForCompany = (companyId) => {
        var updatedFormData = formData;
        updatedFormData["businessUnit"] = "";
        setFormData({...updatedFormData});
        var updatedFormOptionsMetaData = formOptionsMetaData;
        updatedFormOptionsMetaData["businessUnit"] = [];
        setFormOptionsMetaData({...updatedFormOptionsMetaData});
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}risk/company/business/unit/companyId?companyId=${companyId}`)
        .then(res => {
            if(res.status === 200){
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["businessUnit"] = res.data;
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }

    // init organizational point modal
    const [organizationalPointModal,setOrganizationalPointModal] = useState({"isOpen":false,"mode":"","header":"","value":{"name":""}});
    // open organizational point modal
    const openOrganizationalPointModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency to add organizational point"));
        }else{
            var temp = organizationalPointModal;
            temp["header"] = "Create Orgaizational Point";
            temp["mode"] = "add";
            temp["isOpen"] = true;
            setOrganizationalPointModal({...temp});
        }
    }
    // open organizational point modal to edit organizational point
    const editOrganizationalPointModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["organizationalPoint"] === ""){
            dispatch(toastUp("please select organizational point to edit"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["organizationalPoint"],"id",formData["organizationalPoint"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting organizational point to edit"));
            return false;
        }
        var temp = organizationalPointModal;
        temp['value']["name"] = selectedDataDetails["name"];
        temp["mode"] = "edit";
        temp["header"] = "Edit Organizational Point";
        temp['isOpen'] = true;
        setOrganizationalPointModal({...temp});
    }
    // open confirmation modal to delete organizational point
    const deleteOrganizationalPointModal = () =>{
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["organizationalPoint"] === ""){
            dispatch(toastUp("please select organizational point to delete"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["organizationalPoint"],"id",formData["organizationalPoint"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting organizational point to delete"));
            return false;
        }
        var temp = confirmationModal;
        temp["content"] = `Are You Sure You Want To Delete ${selectedDataDetails['name']} from Organizational Point`;
        temp["confirmationHandler"] = ()=>{handleOrganizationalPointModalDelete(selectedDataDetails["id"])};
        temp["isOpen"] = true;
        setConfirmationModal({...temp});
    }
    // handle to clean and close organizational point modal
    const handleOrganizationalPointModalClose = () => {
        var temp = organizationalPointModal;
        temp["value"]["name"] = "";
        temp["header"] = "";
        temp["mode"] = "";
        temp["isOpen"] = false;
        setOrganizationalPointModal({...temp});
    }
    // function to set form value to organizational point modal
    const organizationalPointModalValueHandler = key => event => {
        var temp = organizationalPointModal;
        temp['value'][key] = event.target.value;
        setOrganizationalPointModal({...temp});
    }
    // function to handle organizational point modal submit
    const handleOrganizationalPointModalSubmit = mode => event => {
        if(mode === "add"){
            handleOrganizationalPointModalSave();  
        }else if(mode === "edit"){
            handleOrganizationalPointModalEdit();
        }
    }
    // function to validate, save and auto select the new value of organizational point
    const handleOrganizationalPointModalSave = () => {
        if(organizationalPointModal["value"]["name"] === ""){
            dispatch(toastUp("organizational point field cannot be empty"));
            return false;
        }
        var data = {};
        data["name"] = organizationalPointModal["value"]["name"];
        data["companyId"] = formData['company'];
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}risk/company/organizational/point`,data)
        .then(res => {
            if(res.status === 201){
                dispatch(toastUp("Organizational Point created successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData['organizationalPoint'].push(res.data);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["organizationalPoint"] = res.data.id;
                setFormData({...updatedFormData});
                handleOrganizationalPointModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to validate, edit the existing value of organizational point
    const handleOrganizationalPointModalEdit = () => {
        if(organizationalPointModal["value"]["name"] === ""){
            dispatch(toastUp("organizational point field cannot be empty"));
            return false;
        }
        var data = {};
        data["name"] = organizationalPointModal["value"]["name"];
        data["companyId"] = formData['company'];
        var organizationalPointId = formData["organizationalPoint"];
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}risk/company/organizational/point?Id=${organizationalPointId}`,data)
        .then(res => {
            if(res.status === 200){
                dispatch(toastUp("Organizational Point edited successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["organizationalPoint"] = replaceObjectInArrayBasedOnKey(formOptionsMetaData["organizationalPoint"],"id",organizationalPointId,res.data);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                handleOrganizationalPointModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function delete the existing organizational point value
    const handleOrganizationalPointModalDelete = (id) => {
        dispatch(setSpinner(true));
        axios.delete(`${SERVICE_URL}risk/company/organizational/point?Id=${id}`)
        .then(res => {
            if(res.status === 204){
                dispatch(toastUp("Organizational Point deleted successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["organizationalPoint"] = removeObjectInArrayBasedOnKey(formOptionsMetaData["organizationalPoint"],"id",id);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["organizationalPoint"] = "";
                setFormData({...updatedFormData});
                closeConfirmationModal();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // generate organizational point dropdown value based on selected company
    const generateOrganizationalPointForCompany = (companyId) => {
        var updatedFormData = formData;
        updatedFormData["organizationalPoint"] = "";
        setFormData({...updatedFormData});
        var updatedFormOptionsMetaData = formOptionsMetaData;
        updatedFormOptionsMetaData["organizationalPoint"] = [];
        setFormOptionsMetaData({...updatedFormOptionsMetaData});
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}risk/company/organizational/point/companyId?companyId=${companyId}`)
        .then(res => {
            if(res.status === 200){
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["organizationalPoint"] = res.data;
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    
    // init organizational executive modal
    const [organizationalExecutiveModal,setOrganizationalExecutiveModal] = useState({"isOpen":false,"mode":"","header":"","value":{"name":"","email":"","password":"","confirmPassword":""}});
    // open organizational executive modal
    const openOrganizationalExecutiveModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency to add organizational executive"));
        }else if(formOptionsMetaData["organizationalExecutive"].length >= 4){
            dispatch(toastUp("organizational executive user limit reached"));
        }else{
            var temp = organizationalExecutiveModal;
            temp["header"] = "Create Organizational Executive";
            temp["mode"] = "add";
            temp["isOpen"] = true;
            setOrganizationalExecutiveModal({...temp});
        }
    }
    // open organizational executive modal to edit organizational executive
    const editOrganizationalExecutiveModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["organizationalExecutive"] === ""){
            dispatch(toastUp("please select organizational executive to edit"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["organizationalExecutive"],"id",formData["organizationalExecutive"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting organizational executive to edit"));
            return false;
        }
        var temp = organizationalExecutiveModal;
        temp['value']["name"] = selectedDataDetails["name"];
        temp['value']["email"] = selectedDataDetails["email"];
        temp["mode"] = "edit";
        temp["header"] = "Edit Organizational Executive";
        temp['isOpen'] = true;
        setOrganizationalExecutiveModal({...temp});
    }
    // open confirmation modal to delete organizational executive
    const deleteOrganizationalExecutiveModal = () =>{
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["organizationalExecutive"] === ""){
            dispatch(toastUp("please select organizational executive to delete"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["organizationalExecutive"],"id",formData["organizationalExecutive"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting organizational executive to delete"));
            return false;
        }
        var temp = confirmationModal;
        temp["content"] = `Are You Sure You Want To Delete ${selectedDataDetails['name']} From Organizational Executive`;
        temp["confirmationHandler"] = ()=>{handleOrganizationalExecutiveModalDelete(selectedDataDetails["id"])};
        temp["isOpen"] = true;
        setConfirmationModal({...temp});
    }
    // handle to clean and close organizational executive modal
    const handleOrganizationalExecutiveModalClose = () => {
        var temp = organizationalExecutiveModal;
        temp["value"]["name"] = "";
        temp["value"]["email"] = "";
        temp["value"]["password"] = "";
        temp["value"]["confirmPassword"] = "";
        temp["header"] = "";
        temp["mode"] = "";
        temp["isOpen"] = false;
        setOrganizationalExecutiveModal({...temp});
    }
    // function to set form value to organizational executive modal
    const organizationalExecutiveModalValueHandler = key => event => {
        var temp = organizationalExecutiveModal;
        temp['value'][key] = event.target.value;
        setOrganizationalExecutiveModal({...temp});
    }
    // function to handle organizational executive modal submit
    const handleOrganizationalExecutiveModalSubmit = mode => event => {
        if(mode === "add"){
            handleOrganizationalExecutiveModalSave();  
        }else if(mode === "edit"){
            handleOrganizationalExecutiveModalEdit();
        }
    }
    // function to validate, save and auto select the new value of organizational executive
    const handleOrganizationalExecutiveModalSave = () => {
        if(organizationalExecutiveModal["value"]["email"] === ""){
            dispatch(toastUp("Email field cannot be empty"));
            return false;
        }
        if(organizationalExecutiveModal["value"]["name"] === ""){
            dispatch(toastUp("Name field cannot be empty"));
            return false;
        }
        if(organizationalExecutiveModal["value"]["password"] === ""){
            dispatch(toastUp("Password field cannot be empty"));
            return false;
        }
        if(organizationalExecutiveModal["value"]["password"] !== organizationalExecutiveModal["value"]["confirmPassword"]){
            dispatch(toastUp("Password and confirm password is not matching"));
            return false;
        }
        var role = getArrayOfObjectBasedOnKey(supportData["role"],"role","Organizational Executive");

        if(role === null){
            dispatch(toastUp("Issue selecting organizational executive role"));
            return false;
        }
        
        var data = {};
        data["name"] = organizationalExecutiveModal["value"]["name"];
        data["email"] = organizationalExecutiveModal["value"]["email"];
        data["roleId"] = role["id"];
        data["password"] = organizationalExecutiveModal["value"]["password"];
        data["companyId"] = formData["company"];
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}user`,data)
        .then(res => {
            if(res.status === 200){
                console.log(res)
                dispatch(toastUp("Organizational Executive created successfully"));
                var data = res["data"];
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData['organizationalExecutive'].push(wrapEmailOfObject(data["userInfo"],'displayName'));
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["organizationalExecutive"]=data["userId"];
                setFormData({...updatedFormData});
                handleOrganizationalExecutiveModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to validate, edit the existing value of organizational executive
    const handleOrganizationalExecutiveModalEdit = () => {
        if(organizationalExecutiveModal["value"]["email"] === ""){
            dispatch(toastUp("Email field cannot be empty"));
            return false;
        }
        if(organizationalExecutiveModal["value"]["name"] === ""){
            dispatch(toastUp("Name field cannot be empty"));
            return false;
        }
        var data = {};
        data["name"] = organizationalExecutiveModal["value"]["name"];
        data["email"] = organizationalExecutiveModal["value"]["email"];
        var organizationalExecutiveId = formData["organizationalExecutive"];
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}user?id=${organizationalExecutiveId}`,data)
        .then(res => {
            if(res.status === 200){
                dispatch(toastUp("Organizational Executive edited successfully"));
                var data = res["data"];
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["organizationalExecutive"] = replaceObjectInArrayBasedOnKey(formOptionsMetaData["organizationalExecutive"],"id",organizationalExecutiveId,wrapEmailOfObject(data["userInfo"],'displayName'));
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                handleOrganizationalExecutiveModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function delete the existing organizational executive value
    const handleOrganizationalExecutiveModalDelete = (id) => {
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}user/changeStatus?userId=${id}&status=deactive`)
        .then(res => {
            if(res.status === 200){
                dispatch(toastUp("Organizational Executive deleted successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["organizationalExecutive"] = removeObjectInArrayBasedOnKey(formOptionsMetaData["organizationalExecutive"],"id",id);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["organizationalExecutive"] = "";
                setFormData({...updatedFormData});
                closeConfirmationModal();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // generate organizational executive dropdown value based on selected company
    const generateOrganizationalExecutiveForcompany = (companyId) => {
        var updatedFormData = formData;
        updatedFormData["organizationalExecutive"] = "";
        setFormData({...updatedFormData});
        var updatedFormOptionsMetaData = formOptionsMetaData;
        updatedFormOptionsMetaData["organizationalExecutive"] = [];
        setFormOptionsMetaData({...updatedFormOptionsMetaData});
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}user?companyId=${companyId}&roles=Organizational Executive`)
        .then(res => {
            if(res.status === 200){
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["organizationalExecutive"] = wrapEmailInArrayOfObject(res["data"]["data"],'displayName');
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }

    // init business executive modal
    const [businessExecutiveModal,setBusinessExecutiveModal] = useState({"isOpen":false,"mode":"","header":"","value":{"name":"","email":"","password":"","confirmPassword":""}});
    // open business executive modal
    const openBusinessExecutiveModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency to add business executive"));
        }else{
            var temp = businessExecutiveModal;
            temp["header"] = "Create Business Executive";
            temp["mode"] = "add";
            temp["isOpen"] = true;
            setBusinessExecutiveModal({...temp});
        }
    }
    // open business executive modal to edit business executive
    const editBusinessExecutiveModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["businessExecutive"] === ""){
            dispatch(toastUp("please select business executive to edit"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["businessExecutive"],"id",formData["businessExecutive"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting business executive to edit"));
            return false;
        }
        var temp = businessExecutiveModal;
        temp['value']["name"] = selectedDataDetails["name"];
        temp['value']["email"] = selectedDataDetails["email"];
        temp["mode"] = "edit";
        temp["header"] = "Edit Business Executive";
        temp['isOpen'] = true;
        setBusinessExecutiveModal({...temp});
    }
    // open confirmation modal to delete business executive
    const deleteBusinessExecutiveModal = () =>{
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["businessExecutive"] === ""){
            dispatch(toastUp("please select business executive to delete"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["businessExecutive"],"id",formData["businessExecutive"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting business executive to delete"));
            return false;
        }
        var temp = confirmationModal;
        temp["content"] = `Are You Sure You Want To Delete ${selectedDataDetails['name']} From Business Executive`;
        temp["confirmationHandler"] = ()=>{handleBusinessExecutiveModalDelete(selectedDataDetails["id"])};
        temp["isOpen"] = true;
        setConfirmationModal({...temp});
    }
    // handle to clean and close business executive modal
    const handleBusinessExecutiveModalClose = () => {
        var temp = businessExecutiveModal;
        temp["value"]["name"] = "";
        temp["value"]["email"] = "";
        temp["value"]["password"] = "";
        temp["value"]["confirmPassword"] = "";
        temp["header"] = "";
        temp["mode"] = "";
        temp["isOpen"] = false;
        setBusinessExecutiveModal({...temp});
    }
    // function to set form value to business executive modal
    const businessExecutiveModalValueHandler = key => event => {
        var temp = businessExecutiveModal;
        temp['value'][key] = event.target.value;
        setBusinessExecutiveModal({...temp});
    }
    // function to handle organizational executive modal submit
    const handleBusinessExecutiveModalSubmit = mode => event => {
        if(mode === "add"){
            handleBusinessExecutiveModalSave();  
        }else if(mode === "edit"){
            handleBusinessExecutiveModalEdit();
        }
    }
    // function to validate, save and auto select the new value of business executive
    const handleBusinessExecutiveModalSave = () => {
        if(businessExecutiveModal["value"]["email"] === ""){
            dispatch(toastUp("Email field cannot be empty"));
            return false;
        }
        if(businessExecutiveModal["value"]["name"] === ""){
            dispatch(toastUp("Name field cannot be empty"));
            return false;
        }
        if(businessExecutiveModal["value"]["password"] === ""){
            dispatch(toastUp("Password field cannot be empty"));
            return false;
        }
        if(businessExecutiveModal["value"]["password"] !== businessExecutiveModal["value"]["confirmPassword"]){
            dispatch(toastUp("Password and confirm password is not matching"));
            return false;
        }
        var role = getArrayOfObjectBasedOnKey(supportData["role"],"role","Business Executive");

        if(role === null){
            dispatch(toastUp("Issue selecting business executive role"));
            return false;
        }
        
        var data = {};
        data["name"] = businessExecutiveModal["value"]["name"];
        data["email"] = businessExecutiveModal["value"]["email"];
        data["roleId"] = role["id"];
        data["password"] = businessExecutiveModal["value"]["password"];
        data["companyId"] = formData["company"];
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}user`,data)
        .then(res => {
            if(res.status === 200){
                dispatch(toastUp("Business Executive created successfully"));
                var data = res["data"];
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData['businessExecutive'].push(wrapEmailOfObject(data["userInfo"],'displayName'));
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["businessExecutive"]=data["userId"];
                setFormData({...updatedFormData});
                handleBusinessExecutiveModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to validate, edit the existing value of business executive
    const handleBusinessExecutiveModalEdit = () => {
        if(businessExecutiveModal["value"]["email"] === ""){
            dispatch(toastUp("Email field cannot be empty"));
            return false;
        }
        if(businessExecutiveModal["value"]["name"] === ""){
            dispatch(toastUp("Name field cannot be empty"));
            return false;
        }
        var data = {};
        data["name"] = businessExecutiveModal["value"]["name"];
        data["email"] = businessExecutiveModal["value"]["email"];
        var businessExecutiveId = formData["businessExecutive"];
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}user?id=${businessExecutiveId}`,data)
        .then(res => {
            if(res.status === 200){
                dispatch(toastUp("Business Executive edited successfully"));
                var data=res["data"];
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["businessExecutive"] = replaceObjectInArrayBasedOnKey(formOptionsMetaData["businessExecutive"],"id",businessExecutiveId,wrapEmailOfObject(data["userInfo"],'displayName'));
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                handleBusinessExecutiveModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function delete the existing business executive value
    const handleBusinessExecutiveModalDelete = (id) => {
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}user/changeStatus?userId=${id}&status=deactive`)
        .then(res => {
            if(res.status === 200){
                dispatch(toastUp("Business Executive deleted successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["businessExecutive"] = removeObjectInArrayBasedOnKey(formOptionsMetaData["businessExecutive"],"id",id);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["businessExecutive"] = "";
                setFormData({...updatedFormData});
                closeConfirmationModal();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // generate business executive dropdown value based on selected company
    const generateBusinessExecutiveForcompany = (companyId) => {
        var updatedFormData = formData;
        updatedFormData["businessExecutive"] = "";
        setFormData({...updatedFormData});
        var updatedFormOptionsMetaData = formOptionsMetaData;
        updatedFormOptionsMetaData["businessExecutive"] = [];
        setFormOptionsMetaData({...updatedFormOptionsMetaData});
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}user?companyId=${companyId}&roles=Business Executive`)
        .then(res => {
            if(res.status === 200){
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["businessExecutive"] = wrapEmailInArrayOfObject(res["data"]["data"],'displayName');
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }

    // init line manager modal
    const [lineManagerModal,setLineManagerModal] = useState({"isOpen":false,"mode":"","header":"","value":{"email":"","name":"","password":"","confirmPassword":""}});
    // open line manager modal
    const openLineManagerModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency to add line manager"));
        }else if(formOptionsMetaData["lineManager"].length >= 1){
            dispatch(toastUp("line manager user limit reached"));
        }else{
            var temp = lineManagerModal;
            temp["header"] = "Create Line Manager";
            temp["mode"] = "add";
            temp["isOpen"] = true;
            setLineManagerModal({...temp});
        }
    }
    // open line executive modal to edit line manager
    const editLineManagerModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["lineManager"] === ""){
            dispatch(toastUp("please select line manager to edit"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["lineManager"],"id",formData["lineManager"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting line manager to edit"));
            return false;
        }
        var temp = lineManagerModal;
        temp['value']["name"] = selectedDataDetails["name"];
        temp['value']["email"] = selectedDataDetails["email"];
        temp["mode"] = "edit";
        temp["header"] = "Edit Line Manager";
        temp['isOpen'] = true;
        setLineManagerModal({...temp});
    }
    // open confirmation modal to delete line manager
    const deleteLineManagerModal = () =>{
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["lineManager"] === ""){
            dispatch(toastUp("please select line manager to delete"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["lineManager"],"id",formData["lineManager"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting line manager to delete"));
            return false;
        }
        var temp = confirmationModal;
        temp["content"] = `Are You Sure You Want To Delete ${selectedDataDetails['name']} From Line Manager`;
        temp["confirmationHandler"] = ()=>{handleLineManagerModalDelete(selectedDataDetails["id"])};
        temp["isOpen"] = true;
        setConfirmationModal({...temp});
    }
    // handle to clean and close line manager modal
    const handleLineManagerModalClose = () => {
        var temp = lineManagerModal;
        temp["value"]["name"] = "";
        temp["value"]["email"] = "";
        temp["value"]["password"] = "";
        temp["value"]["confirmPassword"] = "";
        temp["header"] = "";
        temp["mode"] = "";
        temp["isOpen"] = false;
        setLineManagerModal({...temp});
    }
    // function to set form value to line manager modal
    const lineManagerModalValueHandler = key => event => {
        var temp = lineManagerModal;
        temp['value'][key] = event.target.value;
        setLineManagerModal({...temp});
    }
    // function to handle line manager modal submit
    const handleLineManagerModalSubmit = mode => event => {
        if(mode === "add"){
            handleLineManagerModalSave();  
        }else if(mode === "edit"){
            handleLineManagerModalEdit();
        }
    }
    // function to validate, save and auto select the new value of line manager
    const handleLineManagerModalSave = () => {
        if(lineManagerModal["value"]["email"] === ""){
            dispatch(toastUp("Email field cannot be empty"));
            return false;
        }
        if(lineManagerModal["value"]["name"] === ""){
            dispatch(toastUp("Name field cannot be empty"));
            return false;
        }
        if(lineManagerModal["value"]["password"] === ""){
            dispatch(toastUp("Password field cannot be empty"));
            return false;
        }
        if(lineManagerModal["value"]["password"] !== lineManagerModal["value"]["confirmPassword"]){
            dispatch(toastUp("Password and confirm password is not matching"));
            return false;
        }
        var role = getArrayOfObjectBasedOnKey(supportData["role"],"role","Line Manager");

        if(role === null){
            dispatch(toastUp("Issue selecting line manager role"));
            return false;
        }
        
        var data = {};
        data["name"] = lineManagerModal["value"]["name"];
        data["email"] = lineManagerModal["value"]["email"];
        data["roleId"] = role["id"];
        data["password"] = lineManagerModal["value"]["password"];
        data["companyId"] = formData["company"];
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}user`,data)
        .then(res => {
            if(res.status === 200){
                var data = res["data"];
                dispatch(toastUp("Line Manager created successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData['lineManager'].push(wrapEmailOfObject(data["userInfo"],'displayName'));
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["lineManager"]=data["userId"];
                setFormData({...updatedFormData});
                handleLineManagerModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to validate, edit the existing value of line manager
    const handleLineManagerModalEdit = () => {
        if(lineManagerModal["value"]["email"] === ""){
            dispatch(toastUp("Email field cannot be empty"));
            return false;
        }
        if(lineManagerModal["value"]["name"] === ""){
            dispatch(toastUp("Name field cannot be empty"));
            return false;
        }
        var data = {};
        data["name"] = lineManagerModal["value"]["name"];
        data["email"] = lineManagerModal["value"]["email"];
        var lineManagerId = formData["lineManager"];
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}user?id=${lineManagerId}`,data)
        .then(res => {
            if(res.status === 200){
                dispatch(toastUp("Line Manager edited successfully"));
                var data = res["data"]
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["lineManager"] = replaceObjectInArrayBasedOnKey(formOptionsMetaData["lineManager"],"id",lineManagerId,wrapEmailOfObject(data["userInfo"],'displayName'));
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                handleLineManagerModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function delete the existing line manager value
    const handleLineManagerModalDelete = (id) => {
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}user/changeStatus?userId=${id}&status=deactive`)
        .then(res => {
            if(res.status === 200){
                dispatch(toastUp("Line Manager deleted successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["lineManager"] = removeObjectInArrayBasedOnKey(formOptionsMetaData["lineManager"],"id",id);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["lineManager"] = "";
                setFormData({...updatedFormData});
                closeConfirmationModal();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // generate line manager dropdown value based on selected company
    const generateLineManagerForcompany = (companyId) => {
        var updatedFormData = formData;
        updatedFormData["lineManager"] = "";
        setFormData({...updatedFormData});
        var updatedFormOptionsMetaData = formOptionsMetaData;
        updatedFormOptionsMetaData["lineManager"] = [];
        setFormOptionsMetaData({...updatedFormOptionsMetaData});
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}user?companyId=${companyId}&roles=Line Manager`)
        .then(res => {
            if(res.status === 200){
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["lineManager"] = wrapEmailInArrayOfObject(res["data"]["data"],'displayName');
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }

    // init risk category modal
    const [riskCategoryModal,setRiskCategoryModal] = useState({"isOpen":false,"mode":"","header":"","value":{"name":""}});
    // open risk category modal
    const openRiskCategoryModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency to add risk category"));
        }else{
            var temp = riskCategoryModal;
            temp["header"] = "Create Risk Category";
            temp["mode"] = "add";
            temp["isOpen"] = true;
            setRiskCategoryModal({...temp});
        }
    }
    // open risk category modal to edit risk category
    const editRiskCategoryModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["riskCategory"] === ""){
            dispatch(toastUp("please select risk category to edit"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["riskCategory"],"id",formData["riskCategory"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting risk category to edit"));
            return false;
        }
        var temp = riskCategoryModal;
        temp['value']["name"] = selectedDataDetails["name"];
        temp["mode"] = "edit";
        temp["header"] = "Edit Risk Category";
        temp['isOpen'] = true;
        setRiskCategoryModal({...temp});
    }
    // open confirmation modal to delete risk category
    const deleteRiskCategoryModal = () =>{
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["riskCategory"] === ""){
            dispatch(toastUp("please select risk category to delete"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["riskCategory"],"id",formData["riskCategory"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting risk category to delete"));
            return false;
        }
        var temp = confirmationModal;
        temp["content"] = `Are You Sure You Want To Delete ${selectedDataDetails['name']} From Risk Category`;
        temp["confirmationHandler"] = ()=>{handleRiskCategoryModalDelete(selectedDataDetails["id"])};
        temp["isOpen"] = true;
        setConfirmationModal({...temp});
    }
    // handle to clean and close risk category modal
    const handleRiskCategoryModalClose = () => {
        var temp = riskCategoryModal;
        temp["value"]["name"] = "";
        temp["header"] = "";
        temp["mode"] = "";
        temp["isOpen"] = false;
        setRiskCategoryModal({...temp});
    }
    // function to set form value to risk category modal
    const riskCategoryModalValueHandler = key => event => {
        var temp = riskCategoryModal;
        temp['value'][key] = event.target.value;
        setRiskCategoryModal({...temp});
    }
    // function to handle risk category modal submit
    const handleRiskCategoryModalSubmit = mode => event => {
        if(mode === "add"){
            handleRiskCategoryModalSave();  
        }else if(mode === "edit"){
            handleRiskCategoryModalEdit();
        }
    }
    // function to validate, save and auto select the new value of risk category
    const handleRiskCategoryModalSave = () => {
        if(riskCategoryModal["value"]["name"] === ""){
            dispatch(toastUp("risk category field cannot be empty"));
            return false;
        }
        var data = {};
        data["name"] = riskCategoryModal["value"]["name"];
        data["companyId"] = formData['company'];
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}risk/riskCategory`,data)
        .then(res => {
            if(res.status === 201){
                dispatch(toastUp("Risk Category created successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData['riskCategory'].push(res.data);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["riskCategory"] = res.data.id;
                setFormData({...updatedFormData});
                handleRiskCategoryModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to validate, edit the existing value of risk category
    const handleRiskCategoryModalEdit = () => {
        if(riskCategoryModal["value"]["name"] === ""){
            dispatch(toastUp("risk category field cannot be empty"));
            return false;
        }
        var data = {};
        data["name"] = riskCategoryModal["value"]["name"];
        data["companyId"] = formData['company'];
        var riskCategoryId = formData["riskCategory"]
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}risk/riskCategory?Id=${riskCategoryId}`,data)
        .then(res => {
            if(res.status === 200){
                dispatch(toastUp("Risk Category edited successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["riskCategory"] = replaceObjectInArrayBasedOnKey(formOptionsMetaData["riskCategory"],"id",riskCategoryId,res.data);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                handleRiskCategoryModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function delete the existing risk category value
    const handleRiskCategoryModalDelete = (id) => {
        dispatch(setSpinner(true));
        axios.delete(`${SERVICE_URL}risk/riskCategory?Id=${id}`)
        .then(res => {
            if(res.status === 204){
                dispatch(toastUp("Risk Category deleted successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["riskCategory"] = removeObjectInArrayBasedOnKey(formOptionsMetaData["riskCategory"],"id",id);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["riskCategory"] = "";
                setFormData({...updatedFormData});
                closeConfirmationModal();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // generate risk category dropdown value based on selected company here
    const generateRiskCategoryForCompany = (companyId) => {
        var updatedFormData = formData;
        updatedFormData["riskCategory"] = "";
        setFormData({...updatedFormData});
        var updatedFormOptionsMetaData = formOptionsMetaData;
        updatedFormOptionsMetaData["riskCategory"] = [];
        setFormOptionsMetaData({...updatedFormOptionsMetaData});
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}risk/riskCategory/companyId?companyId=${companyId}`)
        .then(res => {
            if(res.status === 200){
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["riskCategory"] = res.data;
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }

    // init risk index modal
    const [riskIndexModal,setRiskIndexModal] = useState({"isOpen":false,"mode":"","header":"","value":{"name":""}});
    // open risk index modal
    const openRiskIndexModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency to add risk index"));
        }else{
            var temp = riskIndexModal;
            temp["header"] = "Create Risk Index";
            temp["mode"] = "add";
            temp["isOpen"] = true;
            setRiskIndexModal({...temp});
        }
    }
    // open risk index modal to edit risk index
    const editRiskIndexModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["riskIndex"] === ""){
            dispatch(toastUp("please select risk index to edit"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["riskIndex"],"id",formData["riskIndex"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting risk index to edit"));
            return false;
        }
        var temp = riskIndexModal;
        temp['value']["name"] = selectedDataDetails["value"];
        temp["mode"] = "edit";
        temp["header"] = "Edit Risk Index";
        temp['isOpen'] = true;
        setRiskIndexModal({...temp});
    }
    // open confirmation modal to delete risk Index
    const deleteRiskIndexModal = () =>{
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["riskIndex"] === ""){
            dispatch(toastUp("please select risk index to delete"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["riskIndex"],"id",formData["riskIndex"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting risk index to delete"));
            return false;
        }
        var temp = confirmationModal;
        temp["content"] = `Are You Sure You Want To Delete ${selectedDataDetails['value']} From Risk Index`;
        temp["confirmationHandler"] = ()=>{handleRiskIndexModalDelete(selectedDataDetails["id"])};
        temp["isOpen"] = true;
        setConfirmationModal({...temp});
    }
    // handle to clean and close risk index modal
    const handleRiskIndexModalClose = () => {
        var temp = riskIndexModal;
        temp["value"]["name"] = "";
        temp["header"] = "";
        temp["mode"] = "";
        temp["isOpen"] = false;
        setRiskIndexModal({...temp});
    }
    // function to set form value to risk index modal
    const riskIndexModalValueHandler = key => event => {
        var temp = riskIndexModal;
        temp['value'][key] = event.target.value;
        setRiskIndexModal({...temp});
    }
    // function to handle risk index modal submit
    const handleRiskIndexModalSubmit = mode => event => {
        if(mode === "add"){
            handleRiskIndexModalSave();  
        }else if(mode === "edit"){
            handleRiskIndexModalEdit();
        }
    }
    // function to validate, save and auto select the new value of risk index
    const handleRiskIndexModalSave = () => {
        if(riskIndexModal["value"]["name"] === ""){
            dispatch(toastUp("risk index field cannot be empty"));
            return false;
        }
        var data = {};
        data["value"] = riskIndexModal["value"]["name"];
        data["companyId"] = formData['company'];
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}risk/company/riskIndex`,data)
        .then(res => {
            if(res.status === 201){
                dispatch(toastUp("Risk Index created successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData['riskIndex'].push(res.data);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["riskIndex"] = res.data.id;
                setFormData({...updatedFormData});
                handleRiskIndexModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to validate, edit the existing value of risk index
    const handleRiskIndexModalEdit = () => {
        if(riskIndexModal["value"]["name"] === ""){
            dispatch(toastUp("risk index field cannot be empty"));
            return false;
        }
        var data = {};
        data["value"] = riskIndexModal["value"]["name"];
        data["companyId"] = formData['company'];
        var riskIndexId = formData["riskIndex"];
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}risk/company/riskIndex?Id=${riskIndexId}`,data)
        .then(res => {
            if(res.status === 200){
                dispatch(toastUp("Risk Index edited successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["riskIndex"] = replaceObjectInArrayBasedOnKey(formOptionsMetaData["riskIndex"],"id",riskIndexId,res.data);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                handleRiskIndexModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function delete the existing risk index value
    const handleRiskIndexModalDelete = (id) => {
        dispatch(setSpinner(true));
        axios.delete(`${SERVICE_URL}risk/company/riskIndex?Id=${id}`)
        .then(res => {
            if(res.status === 204){
                dispatch(toastUp("Risk Index deleted successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["riskIndex"] = removeObjectInArrayBasedOnKey(formOptionsMetaData["riskIndex"],"id",id);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["riskIndex"] = "";
                setFormData({...updatedFormData});
                closeConfirmationModal();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // generate risk index dropdown value based on selected company
    const generateRiskIndexForCompany = (companyId) => {
        var updatedFormData = formData;
        updatedFormData["riskIndex"] = "";
        setFormData({...updatedFormData});
        var updatedFormOptionsMetaData = formOptionsMetaData;
        updatedFormOptionsMetaData["riskIndex"] = [];
        setFormOptionsMetaData({...updatedFormOptionsMetaData});
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}risk/company/riskIndex/companyId?companyId=${companyId}`)
        .then(res => {
            if(res.status === 200){
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["riskIndex"] = res.data;
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }

    // init risk source modal
    const [riskSourceModal,setRiskSourceModal] = useState({"isOpen":false,"mode":"","header":"","value":{"name":""}});
    // open risk source modal
    const openRiskSourceModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency to add risk source"));
        }else{
            var temp = riskSourceModal;
            temp["header"] = "Create Risk Source";
            temp["mode"] = "add";
            temp["isOpen"] = true;
            setRiskSourceModal({...temp});
        }
    }
    // open risk source modal to edit risk source
    const editRiskSourceModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["riskSource"] === ""){
            dispatch(toastUp("please select risk source to edit"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["riskSource"],"id",formData["riskSource"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting risk source to edit"));
            return false;
        }
        var temp = riskSourceModal;
        temp['value']["name"] = selectedDataDetails["value"];
        temp["mode"] = "edit";
        temp["header"] = "Edit Risk Source";
        temp['isOpen'] = true;
        setRiskSourceModal({...temp});
    }
    // open confirmation modal to delete risk Source
    const deleteRiskSourceModal = () =>{
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["riskSource"] === ""){
            dispatch(toastUp("please select risk source to delete"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["riskSource"],"id",formData["riskSource"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting risk source to delete"));
            return false;
        }
        var temp = confirmationModal;
        temp["content"] = `Are You Sure You Want To Delete ${selectedDataDetails['value']} From Risk Source`;
        temp["confirmationHandler"] = ()=>{handleRiskSourceModalDelete(selectedDataDetails["id"])};
        temp["isOpen"] = true;
        setConfirmationModal({...temp});
    }
    // handle to clean and close risk source modal
    const handleRiskSourceModalClose = () => {
        var temp = riskSourceModal;
        temp["value"]["name"] = "";
        temp["header"] = "";
        temp["mode"] = "";
        temp["isOpen"] = false;
        setRiskSourceModal({...temp});
    }
    // function to set form value to risk source modal
    const riskSourceModalValueHandler = key => event => {
        var temp = riskSourceModal;
        temp['value'][key] = event.target.value;
        setRiskSourceModal({...temp});
    }
    // function to handle risk source modal submit
    const handleRiskSourceModalSubmit = mode => event => {
        if(mode === "add"){
            handleRiskSourceModalSave();  
        }else if(mode === "edit"){
            handleRiskSourceModalEdit();
        }
    }
    // function to validate, save and auto select the new value of risk source
    const handleRiskSourceModalSave = () => {
        if(riskSourceModal["value"]["name"] === ""){
            dispatch(toastUp("risk source field cannot be empty"));
            return false;
        }
        var data = {};
        data["value"] = riskSourceModal["value"]["name"];
        data["companyId"] = formData['company'];
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}risk/company/riskSource`,data)
        .then(res => {
            if(res.status === 201){
                dispatch(toastUp("Risk Source created successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData['riskSource'].push(res.data);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["riskSource"] = res.data.id;
                setFormData({...updatedFormData});
                handleRiskSourceModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to validate, edit the existing value of risk source
    const handleRiskSourceModalEdit = () => {
        if(riskSourceModal["value"]["name"] === ""){
            dispatch(toastUp("risk source field cannot be empty"));
            return false;
        }
        var data = {};
        data["value"] = riskSourceModal["value"]["name"];
        data["companyId"] = formData['company'];
        var riskSourceId = formData["riskSource"];
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}risk/company/riskSource?Id=${riskSourceId}`,data)
        .then(res => {
            if(res.status === 200){
                dispatch(toastUp("Risk Source edited successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["riskSource"] = replaceObjectInArrayBasedOnKey(formOptionsMetaData["riskSource"],"id",riskSourceId,res.data);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                handleRiskSourceModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function delete the existing risk source value
    const handleRiskSourceModalDelete = (id) => {
        dispatch(setSpinner(true));
        axios.delete(`${SERVICE_URL}risk/company/riskSource?Id=${id}`)
        .then(res => {
            if(res.status === 204){
                dispatch(toastUp("Risk Source deleted successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["riskSource"] = removeObjectInArrayBasedOnKey(formOptionsMetaData["riskSource"],"id",id);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["riskSource"] = "";
                setFormData({...updatedFormData});
                closeConfirmationModal();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // generate risk source dropdown value based on selected company
    const generateRiskSourceForCompany = (companyId) => {
        var updatedFormData = formData;
        updatedFormData["riskSource"] = "";
        setFormData({...updatedFormData});
        var updatedFormOptionsMetaData = formOptionsMetaData;
        updatedFormOptionsMetaData["riskSource"] = [];
        setFormOptionsMetaData({...updatedFormOptionsMetaData});
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}risk/company/riskSource/companyId?companyId=${companyId}`)
        .then(res => {
            if(res.status === 200){
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["riskSource"] = res.data;
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }

    // init risk Hierarchy modal
    const [riskHierarchyModal,setRiskHierarchyModal] = useState({"isOpen":false,"mode":"","header":"","value":{"name":""}});
    // open risk Hierarchy modal
    const openRiskHierarchyModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency to add risk hierarchy"));
        }else{
            var temp = riskHierarchyModal;
            temp["header"] = "Create Risk Hierarchy";
            temp["mode"] = "add";
            temp["isOpen"] = true;
            setRiskHierarchyModal({...temp});
        }
    }
    // open risk hierarchy modal to edit risk hierarchy
    const editRiskHierarchyModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["riskHierarchy"] === ""){
            dispatch(toastUp("please select risk hierarchy to edit"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["riskHierarchy"],"id",formData["riskHierarchy"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting risk hierarchy to edit"));
            return false;
        }
        var temp = riskHierarchyModal;
        temp['value']["name"] = selectedDataDetails["value"];
        temp["mode"] = "edit";
        temp["header"] = "Edit Risk Hierarchy";
        temp['isOpen'] = true;
        setRiskHierarchyModal({...temp});
    }
    // open confirmation modal to delete risk Index
    const deleteRiskHierarchyModal = () =>{
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["riskHierarchy"] === ""){
            dispatch(toastUp("please select risk hierarchy to delete"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["riskHierarchy"],"id",formData["riskHierarchy"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting risk hierarchy to delete"));
            return false;
        }
        var temp = confirmationModal;
        temp["content"] = `Are You Sure You Want To Delete ${selectedDataDetails['value']} From Risk Hierarchy`;
        temp["confirmationHandler"] = ()=>{handleRiskHierarchyModalDelete(selectedDataDetails["id"])};
        temp["isOpen"] = true;
        setConfirmationModal({...temp});
    }
    // handle to clean and close risk hierarchy modal
    const handleRiskHierarchyModalClose = () => {
        var temp = riskHierarchyModal;
        temp["value"]["name"] = "";
        temp["header"] = "";
        temp["mode"] = "";
        temp["isOpen"] = false;
        setRiskHierarchyModal({...temp});
    }
    // function to set form value to risk hierarchy modal
    const riskHierarchyModalValueHandler = key => event => {
        var temp = riskHierarchyModal;
        temp['value'][key] = event.target.value;
        setRiskHierarchyModal({...temp});
    }
    // function to handle risk hierarchy modal submit
    const handleRiskHierarchyModalSubmit = mode => event => {
        if(mode === "add"){
            handleRiskHierarchyModalSave();  
        }else if(mode === "edit"){
            handleRiskHierarchyModalEdit();
        }
    }
    // function to validate, save and auto select the new value of risk hierarchy
    const handleRiskHierarchyModalSave = () => {
        if(riskHierarchyModal["value"]["name"] === ""){
            dispatch(toastUp("risk hierarchy field cannot be empty"));
            return false;
        }
        var data = {};
        data["value"] = riskHierarchyModal["value"]["name"];
        data["companyId"] = formData['company'];
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}risk/company/riskHierarchy`,data)
        .then(res => {
            if(res.status === 201){
                dispatch(toastUp("Risk Hierarchy created successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData['riskHierarchy'].push(res.data);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["riskHierarchy"] = res.data.id;
                setFormData({...updatedFormData});
                handleRiskHierarchyModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to validate, edit the existing value of risk hierarchy
    const handleRiskHierarchyModalEdit = () => {
        if(riskHierarchyModal["value"]["name"] === ""){
            dispatch(toastUp("risk hierarchy field cannot be empty"));
            return false;
        }
        var data = {};
        data["value"] = riskHierarchyModal["value"]["name"];
        data["companyId"] = formData['company'];
        var riskHierarchyId = formData["riskHierarchy"]
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}risk/company/riskHierarchy?Id=${riskHierarchyId}`,data)
        .then(res => {
            if(res.status === 200){
                dispatch(toastUp("Risk Hierarchy edited successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["riskHierarchy"] = replaceObjectInArrayBasedOnKey(formOptionsMetaData["riskHierarchy"],"id",riskHierarchyId,res.data);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                handleRiskHierarchyModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function delete the existing risk index value
    const handleRiskHierarchyModalDelete = (id) => {
        dispatch(setSpinner(true));
        axios.delete(`${SERVICE_URL}risk/company/riskHierarchy?Id=${id}`)
        .then(res => {
            if(res.status === 204){
                dispatch(toastUp("Risk Hierarchy deleted successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["riskHierarchy"] = removeObjectInArrayBasedOnKey(formOptionsMetaData["riskHierarchy"],"id",id);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["riskHierarchy"] = "";
                setFormData({...updatedFormData});
                closeConfirmationModal();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // generate risk hierarchy dropdown value based on selected company
    const generateRiskHierarchyForCompany = (companyId) => {
        var updatedFormData = formData;
        updatedFormData["riskHierarchy"] = "";
        setFormData({...updatedFormData});
        var updatedFormOptionsMetaData = formOptionsMetaData;
        updatedFormOptionsMetaData["riskHierarchy"] = [];
        setFormOptionsMetaData({...updatedFormOptionsMetaData});
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}risk/company/riskHierarchy/companyId?companyId=${companyId}`)
        .then(res => {
            if(res.status === 200){
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["riskHierarchy"] = res.data;
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }

    // init risk name modal
    const [riskNameModal,setRiskNameModal] = useState({"isOpen":false,"mode":"","header":"","value":{"name":""}});
    // open risk name modal
    const openRiskNameModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency to add risk name"));
        }else{
            var temp = riskNameModal;
            temp["header"] = "Create Risk Name";
            temp["mode"] = "add";
            temp["isOpen"] = true;
            setRiskNameModal({...temp});
        }
    }
    // open risk name modal to edit risk name
    const editRiskNameModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["riskName"] === ""){
            dispatch(toastUp("please select risk name to edit"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["riskName"],"id",formData["riskName"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting risk name to edit"));
            return false;
        }
        var temp = riskNameModal;
        temp['value']["name"] = selectedDataDetails["name"];
        temp["mode"] = "edit";
        temp["header"] = "Edit Risk Name";
        temp['isOpen'] = true;
        setRiskNameModal({...temp});
    }
    // open confirmation modal to delete risk name
    const deleteRiskNameModal = () =>{
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["riskName"] === ""){
            dispatch(toastUp("please select risk name to delete"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["riskName"],"id",formData["riskName"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting risk name to delete"));
            return false;
        }
        var temp = confirmationModal;
        temp["content"] = `Are You Sure You Want To Delete ${selectedDataDetails['name']} From Risk Name`;
        temp["confirmationHandler"] = ()=>{handleRiskNameModalDelete(selectedDataDetails["id"])};
        temp["isOpen"] = true;
        setConfirmationModal({...temp});
    }
    // handle to clean and close risk name modal
    const handleRiskNameModalClose = () => {
        var temp = riskNameModal;
        temp["value"]["name"] = "";
        temp["header"] = "";
        temp['mode'] = "";
        temp["isOpen"] = false;
        setRiskNameModal({...temp});
    }
    // function to set form value to risk name modal
    const riskNameModalValueHandler = key => event => {
        var temp = riskNameModal;
        temp['value'][key] = event.target.value;
        setRiskNameModal({...temp});
    }
    // function to handle risk name modal submit
    const handleRiskNameModalSubmit = mode => event => {
        if(mode === "add"){
            handleRiskNameModalSave();  
        }else if(mode === "edit"){
            handleRiskNameModalEdit();
        }
    }
    // function to validate, save and auto select the new value of risk name
    const handleRiskNameModalSave = () => {
        if(riskNameModal["value"]["name"] === ""){
            dispatch(toastUp("risk name field cannot be empty"));
            return false;
        }
        var data = {};
        data["name"] = riskNameModal["value"]["name"];
        data["companyId"] = formData['company'];
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}risk/company/riskName`,data)
        .then(res => {
            if(res.status === 201){
                dispatch(toastUp("Risk Name created successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData['riskName'].push(res.data);
                updatedFormOptionsMetaData["riskDescription"] = []
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["riskName"] = res.data.id;
                updatedFormData["riskDescription"] = "";
                setFormData({...updatedFormData});
                handleRiskNameModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to validate, edit the existing risk name value
    const handleRiskNameModalEdit = () => {
        if(riskNameModal["value"]["name"] === ""){
            dispatch(toastUp("risk name field cannot be empty"));
            return false;
        }
        var data = {};
        data["name"] = riskNameModal["value"]["name"];
        data["companyid"] = formData['company'];
        var riskNameId = formData["riskName"];
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}risk/company/riskName?Id=${riskNameId}`,data)
        .then(res => {
            if(res.status === 200){
                dispatch(toastUp("Risk Name edited successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["riskName"] = replaceObjectInArrayBasedOnKey(formOptionsMetaData["riskName"],"id",riskNameId,res.data);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                handleRiskNameModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function delete the existing risk name value
    const handleRiskNameModalDelete = (id) => {
        dispatch(setSpinner(true));
        axios.delete(`${SERVICE_URL}risk/company/riskName?Id=${id}`)
        .then(res => {
            if(res.status === 204){
                dispatch(toastUp("Risk Name deleted successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["riskName"] = removeObjectInArrayBasedOnKey(formOptionsMetaData["riskName"],"id",id);
                updatedFormOptionsMetaData["riskDescription"] = [];
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["riskName"] = "";
                updatedFormData["riskDescription"] = "";
                setFormData({...updatedFormData});
                closeConfirmationModal();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // generate risk name dropdown value based on selected company
    const generateRiskNameForCompany = (companyId) => {
        var updatedFormData = formData;
        updatedFormData["riskName"] = "";
        updatedFormData["riskDescription"] = "";
        setFormData({...updatedFormData});
        var updatedFormOptionsMetaData = formOptionsMetaData;
        updatedFormOptionsMetaData["riskName"] = [];
        updatedFormOptionsMetaData["riskDescription"] = [];
        setFormOptionsMetaData({...updatedFormOptionsMetaData});
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}risk/company/riskName?companyId=${companyId}`)
        .then(res => {
            if(res.status === 200){
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["riskName"] = res.data;
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }

    // risk description riskDescription
    // init risk name modal
    const [riskDescriptionModal,setRiskDescriptionModal] = useState({"isOpen":false,"mode":"","header":"","value":{"name":""}});
    // open risk name modal
    const openRiskDescriptionModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency to add risk description"));
        }else if(formData["riskName"] === ""){
            dispatch(toastUp("please select risk name to add risk description"));
        }else{
            var temp = riskDescriptionModal;
            temp["header"] = "Create Risk Description";
            temp["mode"] = "add";
            temp["isOpen"] = true;
            setRiskDescriptionModal({...temp});
        }
    }
    // open risk name modal to edit risk name
    const editRiskDescriptionModal = () => {
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["riskName"] === ""){
            dispatch(toastUp("please select risk name"));
            return false;
        }
        if(formData["riskDescription"] === ""){
            dispatch(toastUp("please select risk description to edit"));
            return false;
        }

        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["riskDescription"],"id",formData["riskDescription"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting risk description to edit"));
            return false;
        }
        var temp = riskDescriptionModal;
        temp['value']["name"] = selectedDataDetails["description"];
        temp["mode"] = "edit";
        temp["header"] = "Edit Risk Description";
        temp['isOpen'] = true;
        setRiskDescriptionModal({...temp});
    }
    // open confirmation modal to delete risk name
    const deleteRiskDescriptionModal = () =>{
        if(formData["company"] === ""){
            dispatch(toastUp("please select company/agency"));
            return false;
        }
        if(formData["riskName"] === ""){
            dispatch(toastUp("please select risk name"));
            return false;
        }
        if(formData["riskDescription"] === ""){
            dispatch(toastUp("please select risk description to delete"));
            return false;
        }
        var selectedDataDetails = getArrayOfObjectBasedOnKey(formOptionsMetaData["riskDescription"],"id",formData["riskDescription"]);
        if(selectedDataDetails === null){
            dispatch(toastUp("issue selecting risk description to delete"));
            return false;
        }
        var temp = confirmationModal;
        temp["content"] = `Are You Sure You Want To Delete ${selectedDataDetails['description']} From Risk Description`;
        temp["confirmationHandler"] = ()=>{handleRiskDescriptionModalDelete(selectedDataDetails["id"])};
        temp["isOpen"] = true;
        setConfirmationModal({...temp});
    }
    // handle to clean and close risk name modal
    const handleRiskDescriptionModalClose = () => {
        var temp = riskDescriptionModal;
        temp["value"]["name"] = "";
        temp["header"] = "";
        temp['mode'] = "";
        temp["isOpen"] = false;
        setRiskDescriptionModal({...temp});
    }
    // function to set form value to risk name modal
    const riskDescriptionModalValueHandler = key => event => {
        var temp = riskDescriptionModal;
        temp['value'][key] = event.target.value;
        setRiskDescriptionModal({...temp});
    }
    // function to handle risk name modal submit
    const handleRiskDescriptionModalSubmit = mode => event => {
        if(mode === "add"){
            handleRiskDescriptionModalSave();  
        }else if(mode === "edit"){
            handleRiskDescriptionModalEdit();
        }
    }
    // function to validate, save and auto select the new value of risk name
    const handleRiskDescriptionModalSave = () => {
        if(riskDescriptionModal["value"]["name"] === ""){
            dispatch(toastUp("risk description field cannot be empty"));
            return false;
        }
        var data = {};
        data["description"] = riskDescriptionModal["value"]["name"];
        data["companyRiskNameId"] = formData['riskName'];
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}risk/company/riskDescription`,data)
        .then(res => {
            if(res.status === 201){
                dispatch(toastUp("Risk Description created successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData['riskDescription'].push(res.data);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["riskDescription"] = res.data.id;
                setFormData({...updatedFormData});
                handleRiskDescriptionModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to validate, edit the existing risk name value
    const handleRiskDescriptionModalEdit = () => {
        if(riskDescriptionModal["value"]["name"] === ""){
            dispatch(toastUp("risk description field cannot be empty"));
            return false;
        }
        var data = {};
        data["description"] = riskDescriptionModal["value"]["name"];
        data["companyRiskNameId"] = formData['riskName'];
        var riskDescriptionId = formData["riskDescription"];
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}risk/company/riskDescription?Id=${riskDescriptionId}`,data)
        .then(res => {
            if(res.status === 200){
                dispatch(toastUp("Risk Description edited successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["riskDescription"] = replaceObjectInArrayBasedOnKey(formOptionsMetaData["riskDescription"],"id",riskDescriptionId,res.data);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                handleRiskDescriptionModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function delete the existing risk name value
    const handleRiskDescriptionModalDelete = (id) => {
        dispatch(setSpinner(true));
        axios.delete(`${SERVICE_URL}risk/company/riskDescription?Id=${id}`)
        .then(res => {
            if(res.status === 204){
                dispatch(toastUp("Risk Description deleted successfully"));
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["riskDescription"] = removeObjectInArrayBasedOnKey(formOptionsMetaData["riskDescription"],"id",id);
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
                var updatedFormData = formData;
                updatedFormData["riskDescription"] = "";
                setFormData({...updatedFormData});
                closeConfirmationModal();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // generate risk description dropdown value based on selected risk name
    const generateRiskDescriptionForRiskName = (riskNameId) => {
        var updatedFormData = formData;
        updatedFormData["riskDescription"] = "";
        setFormData({...updatedFormData});
        var updatedFormOptionsMetaData = formOptionsMetaData;
        updatedFormOptionsMetaData["riskDescription"] = [];
        setFormOptionsMetaData({...updatedFormOptionsMetaData});
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}risk/company/riskDescription/companyRiskNameId?companyRiskNameId=${riskNameId}`)
        .then(res => {
            if(res.status === 200){
                var updatedFormOptionsMetaData = formOptionsMetaData;
                updatedFormOptionsMetaData["riskDescription"] = res.data;
                setFormOptionsMetaData({...updatedFormOptionsMetaData});
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }

    // function to submit the risk identification and move to next tab
    const assignRiskIdentification = async () => {
        var data = validateAndGetFormData();
        if(data["status"] === "valid"){
            var resp = await saveRiskIdentification(data["data"]);
            if(resp["promiseStatus"] === "success"){
                if(resp["status"] === 201){
                    dispatch(toastUp("Risk Identification created successfully"));
                    var updatedRiskRegisterDetails = props.riskRegisterDetails;
                    updatedRiskRegisterDetails["id"] = resp["data"]["id"].toString();
                    updatedRiskRegisterDetails["companyId"] = formData["company"].toString();
                    props.setRiskRegisterDetails({...updatedRiskRegisterDetails});
                    setTimeout(()=>{props.riskRegisterUIHandler("riskResponse");}, 2000);
                }else{
                    dispatch(toastUp("unable to connect server"));
                }
            }else{
                dispatch(toastUp(getErrorMsg(resp)));
            }
        }else{
            dispatch(toastUp(data["msg"]));
        }        
    }
    // function to submit the risk identification and refresh the page
    const submitRiskIdentification = async () => {
        var data = validateAndGetFormData();
        if(data["status"] === "valid"){
            var resp = await saveRiskIdentification(data["data"]);
            if(resp["promiseStatus"] === "success"){
                if(resp["status"] === 201){
                    dispatch(toastUp("Risk Identification created successfully"));
                    setTimeout(resetForm, 2000);
                }else{
                    dispatch(toastUp("unable to connect server"));
                }
            }else{
                dispatch(toastUp(getErrorMsg(resp)));
            }
        }else{
            dispatch(toastUp(data["msg"]));
        }
    }
    // funcion to reset the form data
    const resetForm = () => {
        var updatedFormOptionsMetaData = formOptionsMetaData;
        updatedFormOptionsMetaData["organizationalDomain"] = [];
        updatedFormOptionsMetaData["businessUnit"] = [];
        updatedFormOptionsMetaData["organizationalPoint"] = [];
        // updatedFormOptionsMetaData["businessObjective"] = [];
        // updatedFormOptionsMetaData["businessObjectiveTrigger"] = [];
        updatedFormOptionsMetaData["riskCategory"] = [];
        updatedFormOptionsMetaData["organizationalExecutive"] = [];
        updatedFormOptionsMetaData["businessExecutive"] = [];
        updatedFormOptionsMetaData["lineManager"] = [];
        updatedFormOptionsMetaData["riskIndex"] = [];
        updatedFormOptionsMetaData["riskSource"] = [];
        updatedFormOptionsMetaData["riskHierarchy"] = [];
        updatedFormOptionsMetaData["riskName"] = [];
        updatedFormOptionsMetaData["riskDescription"] = [];
        setFormOptionsMetaData({...updatedFormOptionsMetaData});
        var updatedFormData = formData;
        updatedFormData["company"] = "";
        updatedFormData["organizationalDomain"] = "";
        updatedFormData["businessUnit"] = "";
        updatedFormData["organizationalPoint"] = "";
        // updatedFormData["businessObjective"] = "";
        // updatedFormData["businessObjectiveTrigger"] = "";
        updatedFormData["organizationalExecutive"] = "";
        updatedFormData["businessExecutive"] = "";
        updatedFormData["lineManager"] = "";
        // updatedFormData["objectiveImpact"] = "";
        updatedFormData["riskCategory"] = "";
        updatedFormData["riskOwner"] = "";
        updatedFormData["assignedResource"] = "";
        updatedFormData["riskIndex"] = "";
        updatedFormData["riskSource"] = "";
        updatedFormData["riskHierarchy"] = "";
        updatedFormData["riskName"] = "";
        updatedFormData["riskDescription"] = "";
        // updatedFormData["businessCriticality"] = "";
        updatedFormData["riskIdentifier"] = "";
        setFormData({...updatedFormData});
    }
    // function to submit risk identification
    const saveRiskIdentification = (data) => new Promise((resolve, reject) => {
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}risk/company/riskIdentification`,data)
        .then(res => {
            dispatch(setSpinner(false));
            res["promiseStatus"] = "success";
            resolve(res);
        })
        .catch(err => {
            dispatch(setSpinner(false));
            err["promiseStatus"] = "error";
            reject(err);
        })
    })
    // function to get form data
    const validateAndGetFormData = () => {
        var data = {};
        data["status"] = "invalid";
        if(formData["company"] === ""){
            data["msg"] = "company field cannot be empty";
            return data;
        }
        if(formData["businessUnit"] === ""){
            data["msg"] = "business unit field cannot be empty";
            return data;
        }
        if(formData["organizationalDomain"] === ""){
            data["msg"] = "organizational domain field cannot be empty";
            return data;
        }
        if(formData["organizationalPoint"] === ""){
            data["msg"] = "organizational point field cannot be empty";
            return data;
        }
        if(formData["organizationalExecutive"] === ""){
            data["msg"] = "organizational executive field cannot be empty";
            return data;
        }
        if(formData["businessExecutive"] === ""){
            data["msg"] = "business executive field cannot be empty";
            return data;
        }
        if(formData["lineManager"] === ""){
            data["msg"] = "line manager field cannot be empty";
            return data;
        }
        // if(formData["businessObjective"] === ""){
        //     data["msg"] = "business objective field cannot be empty";
        //     return data;
        // }
        // if(formData["businessObjectiveTrigger"] === ""){
        //     data["msg"] = "business objective trigger field cannot be empty";
        //     return data;
        // }
        // if(formData["objectiveImpact"] === ""){
        //     data["msg"] = "objective impact field cannot be empty";
        //     return data;
        // }
        // if(formData["businessCriticality"] === ""){
        //     data["msg"] = "business criticality field cannot be empty";
        //     return data;
        // }
        if(formData["riskIndex"] === ""){
            data["msg"] = "risk index field cannot be empty";
            return data;
        }
        if(formData["riskCategory"] === ""){
            data["msg"] = "risk category field cannot be empty";
            return data;
        }
        if(formData["riskName"] === ""){
            data["msg"] = "risk name field cannot be empty";
            return data;
        }
        if(formData["riskDescription"] === ""){
            data["msg"] = "risk description field cannot be empty";
            return data;
        }
        if(formData["riskOwner"] === ""){
            data["msg"] = "risk owner field cannot be empty";
            return data;
        }
        if(formData["assignedResource"] === ""){
            data["msg"] = "assigned resource field cannot be empty";
            return data;
        }
        if(formData["riskHierarchy"] === ""){
            data["msg"] = "risk hierarchy field cannot be empty";
            return data;
        }
        if(formData["riskSource"] === ""){
            data["msg"] = "risk source field cannot be empty";
            return data;
        }
        if(formData["riskIdentifier"] === ""){
            data["msg"] = "risk identifier field cannot be empty";
            return data;
        }
        data["status"] = "valid";
        var formValue = {};
        formValue["riskSourceId"] = formData["riskSource"];
        formValue["riskOwnerId"] = formData["riskOwner"];
        formValue["assignedResourceId"] = formData['assignedResource'];
        formValue["organizationalExecutiveUserId"] = formData["organizationalExecutive"];
        formValue["organizationalDomainId"] = formData["organizationalDomain"];
        formValue["companyRiskDescId"] = formData["riskDescription"];
        formValue["companyId"] = formData["company"];
        formValue["businessUnitId"] = formData["businessUnit"];
        formValue["organizationalPointId"] = formData["organizationalPoint"];
        formValue["riskIndexId"] = formData["riskIndex"];
        formValue["riskCategoryId"] = formData["riskCategory"];
        formValue["riskHierarchyId"] = formData["riskHierarchy"];
        // formValue["businessObjectiveTriggerId"] = formData["businessObjectiveTrigger"];
        formValue["businessExecutiveUserId"] = formData["businessExecutive"];
        formValue["lineManagerUserId"] = formData["lineManager"];
        // formValue["businessObjectiveImpactType"] = formData["objectiveImpact"];
        // formValue["businessCriticalityType"] = formData["businessCriticality"];
        formValue["riskIdentifier"] = formData["riskIdentifier"];
        
        data["data"] = formValue;
        return data;
    }
    // init useEffect to get dropdown values when the element mounted
    useEffect(() => {
        dispatch(setSpinner(true));
        // get company dropdown
        axios.get(`${SERVICE_URL}risk/company`)
        .then(res => {
            if(res.status === 200){
                setFormOptionsMetaData(state => ({ ...state, "company": res.data }));
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });

      

      

        // get risk identifier dropdown
        axios.get(`${SERVICE_URL}risk/riskIdentifier`)
        .then(res => {
            if(res.status === 201){
                setFormOptionsMetaData(state => ({ ...state, "riskIdentifier": res.data }));
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        
        // get available role for rbsu group
        axios.get(`${SERVICE_URL}user/group/role?group=rbsu`)
        .then((res)=>{
            var data = res["data"];
            var temp = supportData;
            temp["role"] = data["data"];
            setSupportData({...temp});
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
        // eslint-disable-next-line
      },[]);

    const [checked, setChecked] = React.useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    return (
        <div className='p15 bgDark'>
            {/* risk identification form */}
            <div style={{textAlign:"right"}}>
            <span style={{fontSize:"12px"}}>COMPANY DETAILS HIDE/SHOW</span> <Switch
                size='small'
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
            </div>
            <div style={{display:(checked) ? "block":"none"}}>
            <FieldsetCustom legend={'COMPANY DETAILS'}>
                <Grid container spacing={2}>
                    <Grid className='selectWithAEDicons' item sm={12} md={6}>
                        <span className='lable'>Company/Agency Name <span className='required'>*</span></span><br/>
                        <SelectWithMoreAED onChangeHandler={handleFormValueChange("company")} value={formData["company"]} options={generateDropDown(formOptionsMetaData["company"],"name")} addHandler={openCompanyModal} editHandler={editCompanyModal} deleteHandler={()=>{}}/>
                    </Grid>
                    <Grid className='selectWithAEDicons' item sm={12} md={6}>
                        <span className='lable'>Business Unit <span className='required'>*</span></span><br/>
                        <SelectWithMoreAED onChangeHandler={handleFormValueChange("businessUnit")} value={formData["businessUnit"]} options={generateDropDown(formOptionsMetaData["businessUnit"],"name")} addHandler={openBusinessUnitModal} editHandler={editBusinessUnitModal} deleteHandler={deleteBusinessUnitModal}/>
                    </Grid>
                    <Grid className='selectWithAEDicons' item sm={12} md={6}>
                        <span className='lable'>Organizational Domain <span className='required'>*</span></span><br/>
                        <SelectWithMoreAED onChangeHandler={handleFormValueChange("organizationalDomain")} value={formData["organizationalDomain"]} options={generateDropDown(formOptionsMetaData["organizationalDomain"],"name")} addHandler={openOrganizationalDomainModal} editHandler={editOrganizationalDomainModal} deleteHandler={deleteOrganizationalDomainModal}/>
                    </Grid>
                    <Grid className='selectWithAEDicons' item sm={12} md={6}>
                        <span className='lable'>Organizational Point <span className='required'>*</span></span><br/>
                        <SelectWithMoreAED onChangeHandler={handleFormValueChange("organizationalPoint")} value={formData["organizationalPoint"]} options={generateDropDown(formOptionsMetaData["organizationalPoint"],"name")} addHandler={openOrganizationalPointModal} editHandler={editOrganizationalPointModal} deleteHandler={deleteOrganizationalPointModal}/>
                </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Organizational Executive <span className='required'>*</span></span><br/>
                        <SelectWithMoreAED onChangeHandler={handleFormValueChange("organizationalExecutive")} value={formData["organizationalExecutive"]} options={generateDropDown(formOptionsMetaData["organizationalExecutive"],"displayName")} addHandler={openOrganizationalExecutiveModal} editHandler={editOrganizationalExecutiveModal} deleteHandler={deleteOrganizationalExecutiveModal}/>
                </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Business Executive <span className='required'>*</span></span><br/>
                        <SelectWithMoreAED onChangeHandler={handleFormValueChange("businessExecutive")} value={formData["businessExecutive"]} options={generateDropDown(formOptionsMetaData["businessExecutive"],"displayName")} addHandler={openBusinessExecutiveModal} editHandler={editBusinessExecutiveModal} deleteHandler={deleteBusinessExecutiveModal}/>
                </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Line Manager <span className='required'>*</span></span><br/>
                        <SelectWithMoreAED onChangeHandler={handleFormValueChange("lineManager")} value={formData["lineManager"]} options={generateDropDown(formOptionsMetaData["lineManager"],"displayName")} addHandler={openLineManagerModal} editHandler={editLineManagerModal} deleteHandler={deleteLineManagerModal}/>
                </Grid>
                </Grid>
            </FieldsetCustom>
            </div>
            <FieldsetCustom legend={'RISK DETAILS'}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Risk Index <span className='required'>*</span></span><br/>
                        <SelectWithMoreAED onChangeHandler={handleFormValueChange("riskIndex")} value={formData["riskIndex"]} options={generateDropDown(formOptionsMetaData["riskIndex"],"value")} addHandler={openRiskIndexModal} editHandler={editRiskIndexModal} deleteHandler={deleteRiskIndexModal}/>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Risk Category <span className='required'>*</span></span><br/>
                        <SelectWithMoreAED onChangeHandler={handleFormValueChange("riskCategory")} value={formData["riskCategory"]} options={generateDropDown(formOptionsMetaData["riskCategory"],"name")} addHandler={openRiskCategoryModal} editHandler={editRiskCategoryModal} deleteHandler={deleteRiskCategoryModal}/>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Risk Name <span className='required'>*</span></span><br/>
                        <SelectWithMoreAED onChangeHandler={handleFormValueChange("riskName")} value={formData["riskName"]} options={generateDropDown(formOptionsMetaData["riskName"],"name")} addHandler={openRiskNameModal} editHandler={editRiskNameModal} deleteHandler={deleteRiskNameModal}/>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Risk Description <span className='required'>*</span></span><br/>
                        <SelectWithMoreAED onChangeHandler={handleFormValueChange("riskDescription")} value={formData["riskDescription"]} options={generateDropDown(formOptionsMetaData["riskDescription"],"description")} addHandler={openRiskDescriptionModal} editHandler={editRiskDescriptionModal} deleteHandler={deleteRiskDescriptionModal}/>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Risk Owner <span className='required'>*</span></span><br/>
                        <Select className={selectStyle.selectFieldSection} MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFormValueChange("riskOwner")} value={formData["riskOwner"]} displayEmpty>
                            <MenuItem disabled value=""><em>-- please select--</em></MenuItem>
                            {generateDropDown([...formOptionsMetaData["businessExecutive"],...formOptionsMetaData["organizationalExecutive"]],"name")}
                        </Select>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Assigned Resource <span className='required'>*</span></span><br/>
                        <Select className={selectStyle.selectFieldSection} MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFormValueChange("assignedResource")} value={formData["assignedResource"]} displayEmpty>
                            <MenuItem disabled value=""><em>-- please select--</em></MenuItem>
                            {generateDropDown([...formOptionsMetaData["businessExecutive"],...formOptionsMetaData["organizationalExecutive"],...formOptionsMetaData["lineManager"]],"name")}
                        </Select>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Risk Hierarchy <span className='required'>*</span></span><br/>
                        <SelectWithMoreAED onChangeHandler={handleFormValueChange("riskHierarchy")} value={formData["riskHierarchy"]} options={generateDropDown(formOptionsMetaData["riskHierarchy"],"value")} addHandler={openRiskHierarchyModal} editHandler={editRiskHierarchyModal} deleteHandler={deleteRiskHierarchyModal}/>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Risk Source <span className='required'>*</span></span><br/>
                        <SelectWithMoreAED onChangeHandler={handleFormValueChange("riskSource")} value={formData["riskSource"]} options={generateDropDown(formOptionsMetaData["riskSource"],"value")} addHandler={openRiskSourceModal} editHandler={editRiskSourceModal} deleteHandler={deleteRiskSourceModal}/>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Risk Identifier <span className='required'>*</span></span><br/>
                        <Select className={selectStyle.selectFieldSection} MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFormValueChange("riskIdentifier")} value={formData["riskIdentifier"]} displayEmpty>
                            <MenuItem disabled value=""><em>-- please select--</em></MenuItem>
                            {generateDropDownBasedOnKey(formOptionsMetaData["riskIdentifier"],"name","name")}
                        </Select>
                    </Grid>
                </Grid>
            </FieldsetCustom>
            <div className='mtb30' style={{textAlign:"center"}}>
                <Tooltip arrow componentsProps={tooltipStyle} disableFocusListener disableTouchListener placement="bottom-start" title={"save and create another risk identication"}>
                    <Button className='mlr5 roButton' variant="contained" onClick={submitRiskIdentification}>submit</Button>
                </Tooltip>
                <Button className='mlr5 roButton' variant="contained" onClick={assignRiskIdentification} >assign</Button>
            </div>
            {/* delete confirmation modal */}
            <ModalConfirmation open={confirmationModal.isOpen} content={confirmationModal.content} closeHandler={closeConfirmationModal} confirmationHandler={confirmationModal.confirmationHandler}/>
            {/* add company modal for form popup */}
            <ModalForm open={companyModal.isOpen} mode={companyModal.mode} closeHandler={handleCompanyModalClose} header={companyModal.header} saveHandler={handleCompanyModalSubmit(companyModal.mode)}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Type</span><br/>
                        <Select MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={companyModalValueHandler("agency")} value={companyModal.value.agency} displayEmpty>
                            <MenuItem value="No">Company</MenuItem>
                            <MenuItem value="Yes">Agency</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Name <span className='required'>*</span></span><br/>
                        <TextField sx={textFieldStyle} value={companyModal.value.name} fullWidth size="small" onChange={companyModalValueHandler("name")} variant="outlined" />
                    </Grid>
                </Grid>
            </ModalForm>
            {/* add organizational domain modal for form popup */}
            <ModalForm open={organizationalDomainModal.isOpen} mode={organizationalDomainModal.mode} closeHandler={handleOrganizationalDomainModalClose} header={organizationalDomainModal.header} saveHandler={handleOrganizationalDomainModalSubmit(organizationalDomainModal.mode)}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                        <span className='lable'>Domain Name <span className='required'>*</span></span><br/>
                        <TextField sx={textFieldStyle} value={organizationalDomainModal.value.name} fullWidth size="small" onChange={organizationalDomainModalValueHandler("name")} variant="outlined" />
                    </Grid>
                </Grid>
            </ModalForm>
            {/* add business unit modal for form popup */}
            <ModalForm open={businessUnitModal.isOpen} mode={businessUnitModal.mode} closeHandler={handleBusinessUnitModalClose} header={businessUnitModal.header} saveHandler={handleBusinessUnitModalSubmit(businessUnitModal.mode)}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                        <span className='lable'>Business Unit <span className='required'>*</span></span><br/>
                        <TextField sx={textFieldStyle} value={businessUnitModal.value.name} fullWidth size="small" onChange={businessUnitModalValueHandler("name")} variant="outlined" />
                    </Grid>
                </Grid>
            </ModalForm>
            {/* add organizational point modal for form popup */}
            <ModalForm open={organizationalPointModal.isOpen} mode={organizationalPointModal.mode} closeHandler={handleOrganizationalPointModalClose} header={organizationalPointModal.header} saveHandler={handleOrganizationalPointModalSubmit(organizationalPointModal.mode)}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                        <span className='lable'>Organizational Point <span className='required'>*</span></span><br/>
                        <TextField sx={textFieldStyle} value={organizationalPointModal.value.name} fullWidth size="small" onChange={organizationalPointModalValueHandler("name")} variant="outlined" />
                    </Grid>
                </Grid>
            </ModalForm>
            {/* add business objective modal for form popup */}
            {/* <ModalForm open={businessObjectiveModal.isOpen} mode={businessObjectiveModal.mode} closeHandler={handleBusinessObjectiveModalClose} header={businessObjectiveModal.header} saveHandler={handleBusinessObjectiveModalSubmit(businessObjectiveModal.mode)}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                        <span className='lable'>Business Objective <span className='required'>*</span></span><br/>
                        <TextField sx={textFieldStyle} value={businessObjectiveModal.value.name} fullWidth size="small" onChange={businessObjectiveModalValueHandler("name")} variant="outlined" />
                    </Grid>
                </Grid>
            </ModalForm> */}
            {/* add business objective trigger modal for form popup */}
            {/* <ModalForm open={businessObjectiveTriggerModal.isOpen} mode={businessObjectiveTriggerModal.mode} closeHandler={handleBusinessObjectiveTriggerModalClose} header={businessObjectiveTriggerModal.header} saveHandler={handleBusinessObjectiveTriggerModalSubmit(businessObjectiveTriggerModal.mode)}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                        <span className='lable'>Business Objective Trigger <span className='required'>*</span></span><br/>
                        <TextField sx={textFieldStyle} value={businessObjectiveTriggerModal.value.name} fullWidth size="small" onChange={businessObjectiveTriggerModalValueHandler("name")} variant="outlined" />
                    </Grid>
                </Grid>
            </ModalForm> */}
            {/* add organizational executive modal for form popup */}
            <ModalForm open={organizationalExecutiveModal.isOpen} mode={organizationalExecutiveModal.mode} closeHandler={handleOrganizationalExecutiveModalClose} header={organizationalExecutiveModal.header} saveHandler={handleOrganizationalExecutiveModalSubmit(organizationalExecutiveModal.mode)}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Email <span className='required'>*</span></span><br/>
                        <TextField sx={textFieldStyle} value={organizationalExecutiveModal.value.email} fullWidth size="small" onChange={organizationalExecutiveModalValueHandler("email")} variant="outlined" />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Name <span className='required'>*</span></span><br/>
                        <TextField sx={textFieldStyle} value={organizationalExecutiveModal.value.name} fullWidth size="small" onChange={organizationalExecutiveModalValueHandler("name")} variant="outlined" />
                    </Grid>
                    { 
                        (organizationalExecutiveModal.mode === "add") ?
                            <>
                                <Grid item sm={12} md={6}>
                                    <span className='lable'>Password <span className='required'>*</span></span><br/>
                                    <TextField type='password' sx={textFieldStyle} value={organizationalExecutiveModal.value.password} fullWidth size="small" onChange={organizationalExecutiveModalValueHandler("password")} variant="outlined" />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <span className='lable'>Confirm Password <span className='required'>*</span></span><br/>
                                    <TextField type='password' sx={textFieldStyle} value={organizationalExecutiveModal.value.confirmPassword} fullWidth size="small" onChange={organizationalExecutiveModalValueHandler("confirmPassword")} variant="outlined" />
                                </Grid>
                            </>
                        :
                            <></>
                    }
                </Grid>
            </ModalForm>
            {/* add business executive modal for form popup */}
            <ModalForm open={businessExecutiveModal.isOpen} mode={businessExecutiveModal.mode} closeHandler={handleBusinessExecutiveModalClose} header={businessExecutiveModal.header} saveHandler={handleBusinessExecutiveModalSubmit(businessExecutiveModal.mode)}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Email <span className='required'>*</span></span><br/>
                        <TextField sx={textFieldStyle} value={businessExecutiveModal.value.email} fullWidth size="small" onChange={businessExecutiveModalValueHandler("email")} variant="outlined" />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Name <span className='required'>*</span></span><br/>
                        <TextField sx={textFieldStyle} value={businessExecutiveModal.value.name} fullWidth size="small" onChange={businessExecutiveModalValueHandler("name")} variant="outlined" />
                    </Grid>
                    { 
                        (businessExecutiveModal.mode === "add") ?
                            <>
                                <Grid item sm={12} md={6}>
                                    <span className='lable'>Password <span className='required'>*</span></span><br/>
                                    <TextField type='password' sx={textFieldStyle} value={businessExecutiveModal.value.password} fullWidth size="small" onChange={businessExecutiveModalValueHandler("password")} variant="outlined" />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <span className='lable'>Confirm Password <span className='required'>*</span></span><br/>
                                    <TextField type='password' sx={textFieldStyle} value={businessExecutiveModal.value.confirmPassword} fullWidth size="small" onChange={businessExecutiveModalValueHandler("confirmPassword")} variant="outlined" />
                                </Grid>
                            </>
                        :
                            <></>
                    }
                </Grid>
            </ModalForm>
            {/* add line manager modal for form popup */}
            <ModalForm open={lineManagerModal.isOpen} mode={lineManagerModal.mode} closeHandler={handleLineManagerModalClose} header={lineManagerModal.header} saveHandler={handleLineManagerModalSubmit(lineManagerModal.mode)}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Email <span className='required'>*</span></span><br/>
                        <TextField sx={textFieldStyle} value={lineManagerModal.value.email} fullWidth size="small" onChange={lineManagerModalValueHandler("email")} variant="outlined" />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Name <span className='required'>*</span></span><br/>
                        <TextField sx={textFieldStyle} value={lineManagerModal.value.name} fullWidth size="small" onChange={lineManagerModalValueHandler("name")} variant="outlined" />
                    </Grid>
                    { 
                        (lineManagerModal.mode === "add") ?
                            <>
                                <Grid item sm={12} md={6}>
                                    <span className='lable'>Password <span className='required'>*</span></span><br/>
                                    <TextField type='password' sx={textFieldStyle} value={lineManagerModal.value.password} fullWidth size="small" onChange={lineManagerModalValueHandler("password")} variant="outlined" />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <span className='lable'>Confirm Password <span className='required'>*</span></span><br/>
                                    <TextField type='password' sx={textFieldStyle} value={lineManagerModal.value.confirmPassword} fullWidth size="small" onChange={lineManagerModalValueHandler("confirmPassword")} variant="outlined" />
                                </Grid>
                            </>
                        :
                            <></>
                    }
                </Grid>
            </ModalForm>
            {/* add risk category modal for form popup */}
            <ModalForm open={riskCategoryModal.isOpen} mode={riskCategoryModal.mode} closeHandler={handleRiskCategoryModalClose} header={riskCategoryModal.header} saveHandler={handleRiskCategoryModalSubmit(riskCategoryModal.mode)}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                        <span className='lable'>Risk Category <span className='required'>*</span></span><br/>
                        <TextField sx={textFieldStyle} value={riskCategoryModal.value.name} fullWidth size="small" onChange={riskCategoryModalValueHandler("name")} variant="outlined" />
                    </Grid>
                </Grid>
            </ModalForm>
            {/* add risk index modal for form popup */}
            <ModalForm open={riskIndexModal.isOpen} mode={riskIndexModal.mode} closeHandler={handleRiskIndexModalClose} header={riskIndexModal.header} saveHandler={handleRiskIndexModalSubmit(riskIndexModal.mode)}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                        <span className='lable'>Risk Index <span className='required'>*</span></span><br/>
                        <TextField sx={textFieldStyle} value={riskIndexModal.value.name} fullWidth size="small" onChange={riskIndexModalValueHandler("name")} variant="outlined" />
                    </Grid>
                </Grid>
            </ModalForm>
            {/* add risk source modal for form popup */}
            <ModalForm open={riskSourceModal.isOpen} mode={riskSourceModal.mode} closeHandler={handleRiskSourceModalClose} header={riskSourceModal.header} saveHandler={handleRiskSourceModalSubmit(riskSourceModal.mode)}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                        <span className='lable'>Risk Source <span className='required'>*</span></span><br/>
                        <TextField sx={textFieldStyle} value={riskSourceModal.value.name} fullWidth size="small" onChange={riskSourceModalValueHandler("name")} variant="outlined" />
                    </Grid>
                </Grid>
            </ModalForm>
            {/* add risk hierarchy modal for form popup */}
            <ModalForm open={riskHierarchyModal.isOpen} mode={riskHierarchyModal.mode} closeHandler={handleRiskHierarchyModalClose} header={riskHierarchyModal.header} saveHandler={handleRiskHierarchyModalSubmit(riskHierarchyModal.mode)}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                        <span className='lable'>Risk Hierarchy <span className='required'>*</span></span><br/>
                        <TextField sx={textFieldStyle} value={riskHierarchyModal.value.name} fullWidth size="small" onChange={riskHierarchyModalValueHandler("name")} variant="outlined" />
                    </Grid>
                </Grid>
            </ModalForm>
            {/* add risk name modal for form popup */}
            <ModalForm open={riskNameModal.isOpen} mode={riskNameModal.mode} closeHandler={handleRiskNameModalClose} header={riskNameModal.header} saveHandler={handleRiskNameModalSubmit(riskNameModal.mode)}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                        <span className='lable'>Risk Name <span className='required'>*</span></span><br/>
                        <TextField sx={textFieldStyle} value={riskNameModal.value.name} fullWidth size="small" onChange={riskNameModalValueHandler("name")} variant="outlined" />
                    </Grid>
                </Grid>
            </ModalForm>
            {/* add risk description modal for form popup */}
            <ModalForm open={riskDescriptionModal.isOpen} mode={riskDescriptionModal.mode} closeHandler={handleRiskDescriptionModalClose} header={riskDescriptionModal.header} saveHandler={handleRiskDescriptionModalSubmit(riskDescriptionModal.mode)}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                        <span className='lable'>Risk Description <span className='required'>*</span></span><br/>
                        <TextField sx={textFieldStyle} value={riskDescriptionModal.value.name} fullWidth size="small" onChange={riskDescriptionModalValueHandler("name")} variant="outlined" />
                    </Grid>
                </Grid>
            </ModalForm>
        </div>
    );
}

export default RiskIdentification;