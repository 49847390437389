const customPropReducer = (state={},action) => {
    switch(action.type){
        case 'setUiWithCustomProp':
            return {"page":action.payload.ui,"props":action.payload.props}
        case 'resetCustomProp':
            return {"page":"","props":{}}
        default:
            return state;
    }
}

export default customPropReducer;