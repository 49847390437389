import React from 'react';
import {Box,Modal, Typography} from '@mui/material';
import { modalViewInfoStyle } from '../../js/standardStyle';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import style from '../../css/ModalViewInfo.module.css';

function ModalMessageBox(props) {
    var overRideStyle = {};
    overRideStyle["width"] = "30%";
    overRideStyle["height"] = "50%";
    return (
        // modal wrapper element for displaying popups with pre defined style
        <Modal open={props.open} onClose={props.closeHandler}>
            <Box sx={{...modalViewInfoStyle,...overRideStyle}}>
                <div className={`${style.closeSection}`}>
                    <HighlightOffTwoToneIcon onClick={props.closeHandler} className={style.closeButton} />
                </div>
                <div>
                    <Typography className='cDark' id="modal-modal-title" variant="h6" component="h2">
                        {props.header}
                    </Typography>
                </div>
                <div className='mt10' style={{height:"100%"}}>
                    {props.children}
                </div>
            </Box>
        </Modal>
    );
}

export default ModalMessageBox;