import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { dataGridStyle } from '../../js/standardStyle';
import {Box} from '@mui/material';

function DataGridCustom(props) {
    const {combineSearch} = props;
    var style = dataGridStyle;
    if(combineSearch){
        style["&.MuiDataGrid-root"]={borderTop:"transparent"};
        style[".MuiDataGrid-columnHeaders"]["borderRadius"] = "0px";
    }
    return (
        <div>
            <Box sx={{ height: (props.rows.length === 0) ? "200px" : "500px", width: '100%' }}>
                <DataGrid
                // getRowHeight={() => 'auto'}
                    disableVirtualization
                    disableColumnMenu
                    disableRowSelectionOnClick
                    sx={style}
                    rows={props.rows}
                    rowCount={props.rowCount}
                    loading={props.isLoading}
                    pageSizeOptions={props.pageSizeOptions}
                    paginationMode="server"
                    paginationModel={props.paginationModel}
                    onPaginationModelChange={props.onPaginationModelChange}
                    columns={props.columns}
                />
            </Box>
        </div>
    );
}

export default DataGridCustom;