import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { dataGridStyle } from '../../js/standardStyle';

function DataGridCustomFull(props) {
    return (
        <div>
            <DataGrid
                autoHeight
                disableVirtualization
                disableColumnMenu
                disableRowSelectionOnClick
                sx={dataGridStyle}
                rows={props.rows}
                rowCount={props.rowCount}
                loading={props.isLoading}
                pageSizeOptions={props.pageSizeOptions}
                paginationMode="server"
                paginationModel={props.paginationModel}
                onPaginationModelChange={props.onPaginationModelChange}
                columns={props.columns}
            />
        </div>
    );
}

export default DataGridCustomFull;