import { Grid, Paper, Typography, TableCell, TableRow, Table, TableContainer, TableHead, TableBody } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { setSpinner } from '../../redux/actions/spinner';
import { useDispatch } from 'react-redux';
import { SERVICE_URL } from '../../config';
import axios from 'axios';
import { toastUp } from '../../redux/actions/toast';
import { getErrorMsg } from '../../js/util';


function ProgressStatusChartReport(props) {

    const {projectId} = props;
    // get dispatch
    const dispatch = useDispatch();
    // init use state for chart data
    const [chartData, setChartData] = useState({"basic":{"name":"","manager":"","startDate":"","endDate":""},"projectStatus":[],"projectComponent":[]});
    useEffect(()=>{
        dispatch(setSpinner(true))
        axios.get(`${SERVICE_URL}/issue/project/report/chart/progressStatus?projectId=${projectId}`)
        .then(res=>{
            var temp = chartData;
            if(res["data"]["basic"] !== null){
                temp["basic"] = res["data"]["basic"];
            }
            temp["projectStatus"] = res["data"]["projectStatus"];
            temp["projectComponent"] = res["data"]["projectComponent"]
            setChartData({...temp});
        })
        .catch(err=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
        // eslint-disable-next-line
    },[])
    return (
        <div>
            <Paper style={{backgroundColor:"#cee7d6"}} className='p15' elevation={2}>
                <Grid container spacing={2}>
                    <Grid item sm={6} md={3}>
                        <Typography className='fs14'><strong>Project Name</strong></Typography>
                    </Grid>
                    <Grid item sm={6} md={3}>
                        <Typography className='fs14'>{chartData["basic"]["name"]}</Typography>
                    </Grid>
                    <Grid item sm={6} md={3}>
                        <Typography className='fs14'><strong>Date of Status Entry</strong></Typography>
                    </Grid>
                    <Grid item sm={6} md={3}>
                        <Typography className='fs14'>{chartData["basic"]["startDate"]}</Typography>
                    </Grid>
                    <Grid item sm={6} md={3}>
                        <Typography className='fs14'><strong>Project Manager</strong></Typography>
                    </Grid>
                    <Grid item sm={6} md={3}>
                        <Typography className='fs14'>{chartData["basic"]["manager"]}</Typography>
                    </Grid>
                    <Grid item sm={6} md={3}>
                        <Typography className='fs14'><strong>Expected Date of Completion</strong></Typography>
                    </Grid>
                    <Grid item sm={6} md={3}>
                        <Typography className='fs14'>{chartData["basic"]["endDate"]}</Typography>
                    </Grid>
                </Grid>
            </Paper>
            <Paper className='p10 mtb30' elevation={2}>
                <Typography className='p10 taCenter'>Project Status</Typography>
                <div className='mtb10'>
                    <TableContainer>
                        <Table style={{backgroundColor:"#82dbcc"}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Project Status</strong></TableCell>
                                    <TableCell><strong>On Track</strong></TableCell>
                                    <TableCell><strong>Comments</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {
                                    (chartData["projectStatus"].length > 0) ?
                                        chartData["projectStatus"].map((row,i) => {
                                            return (
                                                <TableRow  key={i}>
                                                    {(i===0) ? <TableCell rowSpan={chartData["projectStatus"].length}>Project Milestones</TableCell> : ''}
                                                    <TableCell>{row.onTrack}</TableCell>
                                                    <TableCell>{row.comment}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    :
                                        <TableRow>
                                            <TableCell align='center' colSpan={3}>
                                                No Record Found
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Paper>
            <Paper className='p10 mtb30' elevation={2}>
                <Typography className='p10 taCenter'>Project Component Status</Typography>
                <div className='mtb10'>
                    <TableContainer>
                        <Table style={{backgroundColor:"#82dbcc"}}>
                            <TableHead>
                            <TableRow>
                                <TableCell><strong>Component</strong></TableCell>
                                <TableCell><strong>Status</strong></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody >
                                {
                                    (chartData["projectComponent"].length > 0) ?
                                        chartData["projectComponent"].map((row) => {
                                            return (
                                                <TableRow  key={row.key}>
                                                    <TableCell>{row.key}</TableCell>
                                                    <TableCell><div style={{backgroundColor:row.color,height:"8px",width:"8px",display:"inline-block",borderRadius:"8px",marginRight:"10px"}}></div>{row.value}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    :
                                        <TableRow>
                                            <TableCell align='center' colSpan={2}>
                                                No Record Found
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Paper>
        </div>
    );
}

export default ProgressStatusChartReport;