import { Grid, Typography, Button } from '@mui/material';
import React, { useState } from 'react';
import style from '../../css/ViewProjectDetailsForModal.module.css';
import FieldsetCustom from '../wrapper/FieldsetCustom';
import { getKeyBasedOnValue, getNameBasedOnValue, getProjectFinancialFormulatedValue, getScoreForInherentRisk } from '../../js/util';
import { getStyleForInherentRisk } from '../../js/standardStyle'
import { projectDropDown } from '../../js/constants'

function ViewProjectDetailsForModal(props) {
    const { projectDetails } = props;
    const [hideElement, setHideElement] = useState({ "content": true });
    // function to toggle visibility for the hidden elements
    const toggleVisibility = () => {
        var updatedHideElement = hideElement;
        updatedHideElement["content"] = !hideElement["content"];
        setHideElement({ ...updatedHideElement });
    }
    var formulatedValue = getProjectFinancialFormulatedValue(projectDetails.financial);
    return (
        <div style={{ height: "100%" }}>
            <div>
                <Grid container direction="row">
                    <Grid item>
                        <Typography variant="h6" component="h2">View Project</Typography>
                    </Grid>
                </Grid>
            </div>
            <div className={style.content}>
                <div className={`${style.fieldsetSection}`}>
                    <FieldsetCustom legend={"PROJECT DETAILS"} >
                        <Grid container spacing={2}>
                            <Grid item sm={6} md={3} lg={2}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Name</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={4}>
                                <Typography className={style.fieldContent}>{projectDetails.name}</Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={2}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Manager</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={4}>
                                <Typography className={style.fieldContent}>{projectDetails.manager}</Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={2}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Status</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={4}>
                                <Typography className={style.fieldContent}>{getNameBasedOnValue(projectDropDown["status"],projectDetails.status)}</Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={2}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>PMO Office</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={4}>
                                <Typography className={style.fieldContent}>{projectDetails.pmoOffice}</Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={2}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Business Unit Area</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={4}>
                                <Typography className={style.fieldContent}>{projectDetails.area}</Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={2}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Sponsor</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={4}>
                                <Typography className={style.fieldContent}>{projectDetails.sponsor}</Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={2}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Sponsor Group</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={4}>
                                <Typography className={style.fieldContent}>{projectDetails.sponsorGroup}</Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={2}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Scope Creep</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={4}>
                                <Typography className={style.fieldContent}>{projectDetails.scopeCreep}</Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={2}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Start Date</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={4}>
                                <Typography className={style.fieldContent}>{projectDetails.startDate}</Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={2}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>End Date</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={4}>
                                <Typography className={style.fieldContent}>{projectDetails.endDate}</Typography>
                            </Grid>
                        </Grid>
                    </FieldsetCustom>
                </div>

                {
                    (Object.keys(projectDetails.financial).length === 0 && Object.keys(projectDetails.security).length === 0) ?
                        <></>
                        :
                        <div>
                            <div className='mtb5' style={{ textAlign: "right" }}>
                                <Button className='mlr5 roButton' variant="text" onClick={toggleVisibility} >{hideElement["content"] ? 'show more' : 'show less'}</Button>
                            </div>
                            <div style={{ display: (hideElement["content"]) ? "none" : "block" }} className={`${style.fieldsetSection}`}>
                            {
                                (Object.keys(projectDetails.financial).length === 0) ?
                                    <></>
                                :
                                    <FieldsetCustom legend={"PROJECT FINANCIAL"} >
                                        <Grid container spacing={2}>
                                            <Grid item sm={6} md={3} lg={2}>
                                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Budget</strong><strong>:</strong></Typography>
                                            </Grid>
                                            <Grid item sm={6} md={3} lg={4}>
                                                <Typography className={style.fieldContent}>{projectDetails.financial.budget}</Typography>
                                            </Grid>
                                            <Grid item sm={6} md={3} lg={2}>
                                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Planned Budget (BAC)</strong><strong>:</strong></Typography>
                                            </Grid>
                                            <Grid item sm={6} md={3} lg={4}>
                                                <Typography className={style.fieldContent}>{projectDetails.financial.plannedBudget}</Typography>
                                            </Grid>
                                            <Grid item sm={6} md={3} lg={2}>
                                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>% of Poject Completed (Planned)</strong><strong>:</strong></Typography>
                                            </Grid>
                                            <Grid item sm={6} md={3} lg={4}>
                                                <Typography className={style.fieldContent}>{projectDetails.financial.plannedCompletionPercentage}</Typography>
                                            </Grid>
                                            <Grid item sm={6} md={3} lg={2}>
                                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Planned Value</strong><strong>:</strong></Typography>
                                            </Grid>
                                            <Grid item sm={6} md={3} lg={4}>
                                                <Typography className={style.fieldContent}>{formulatedValue.plannedValue}</Typography>
                                            </Grid>
                                            <Grid item sm={6} md={3} lg={2}>
                                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>% of Completed Work</strong><strong>:</strong></Typography>
                                            </Grid>
                                            <Grid item sm={6} md={3} lg={4}>
                                                <Typography className={style.fieldContent}>{projectDetails.financial.workCompletionPercentage}</Typography>
                                            </Grid>
                                            <Grid item sm={6} md={3} lg={2}>
                                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Earned Value</strong><strong>:</strong></Typography>
                                            </Grid>
                                            <Grid item sm={6} md={3} lg={4}>
                                                <Typography className={style.fieldContent}>{formulatedValue.earnedValue}</Typography>
                                            </Grid>
                                            <Grid item sm={6} md={3} lg={2}>
                                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Actual Cost</strong><strong>:</strong></Typography>
                                            </Grid>
                                            <Grid item sm={6} md={3} lg={4}>
                                                <Typography className={style.fieldContent}>{projectDetails.financial.actualCost}</Typography>
                                            </Grid>
                                            <Grid item sm={6} md={3} lg={2}>
                                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Schedule Variance</strong><strong>:</strong></Typography>
                                            </Grid>
                                            <Grid item sm={6} md={3} lg={4}>
                                                <Typography className={style.fieldContent}>{formulatedValue.scheduleVariance}</Typography>
                                            </Grid>
                                            <Grid item sm={6} md={3} lg={2}>
                                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Schedule Performance Index (SPI)</strong><strong>:</strong></Typography>
                                            </Grid>
                                            <Grid item sm={6} md={3} lg={4}>
                                                <Typography className={style.fieldContent}>{formulatedValue.schedulePerformanceIndex}</Typography>
                                            </Grid>
                                            <Grid item sm={6} md={3} lg={2}>
                                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Cost Variance</strong><strong>:</strong></Typography>
                                            </Grid>
                                            <Grid item sm={6} md={3} lg={4}>
                                                <Typography className={style.fieldContent}>{formulatedValue.costVariance}</Typography>
                                            </Grid>
                                            <Grid item sm={6} md={3} lg={2}>
                                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Cost Performance Index (CPI)</strong><strong>:</strong></Typography>
                                            </Grid>
                                            <Grid item sm={6} md={3} lg={4}>
                                                <Typography className={style.fieldContent}>{formulatedValue.costPerformanceIndex}</Typography>
                                            </Grid>
                                        </Grid>
                                    </FieldsetCustom>
                                }
                                {
                                    (Object.keys(projectDetails.security).length === 0) ?
                                        <></>
                                        :
                                        <div>
                                            <FieldsetCustom legend={"PROJECT SECURITY"} >
                                                <Grid container spacing={2}>
                                                    <Grid item sm={6} md={3} lg={2}>
                                                        <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Objective</strong><strong>:</strong></Typography>
                                                    </Grid>
                                                    <Grid item sm={6} md={3} lg={4}>
                                                        <Typography className={style.fieldContent}>{projectDetails.security.objective_name}</Typography>
                                                    </Grid>
                                                    <Grid item sm={6} md={3} lg={2}>
                                                        <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Impact</strong><strong>:</strong></Typography>
                                                    </Grid>
                                                    <Grid item sm={6} md={3} lg={4}>
                                                        <Typography className={style.fieldContent}>{projectDetails.security.impact_name}</Typography>
                                                    </Grid>
                                                </Grid>
                                                {
                                                    projectDetails.security.answersandquestions.map((item, index) => (
                                                        <div key={index} className='mt20'>
                                                            <FieldsetCustom legend={""} >
                                                                <Grid container spacing={2}>
                                                                    <Grid item sm={6} md={3} lg={2}>
                                                                        <Typography className={`${style.fieldContent} ${style.fieldHeading}`}>
                                                                            <strong>Q</strong>
                                                                            <strong>:</strong>
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item sm={6} md={6} lg={10}>
                                                                            <Typography key={index} className={style.fieldContent}>
                                                                            {`${item.question}`}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item sm={6} md={3} lg={2}>
                                                                        <Typography className={`${style.fieldContent} ${style.fieldHeading}`}>
                                                                            <strong>Answer</strong>
                                                                            <strong>:</strong>
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item sm={6} md={6} lg={10}>
                                                                        <Typography key={index} className={style.fieldContent}>
                                                                            {`${item.answer}`}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item sm={6} md={6} lg={5} style={{ textAlign: 'center' }}></Grid>
                                                                    <Grid item sm={6} md={6} lg={5} style={{ textAlign: 'center' }}>
                                                                        <Typography className={`${style.fieldContent} ${style.fieldHeading}`} >
                                                                            <strong>Inherent Risk </strong>
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item sm={6} md={6} lg={1} style={{ textAlign: 'center' }}></Grid>
                                                                    <Grid item sm={6} md={3} lg={2}>
                                                                        <Typography className={`${style.fieldContent} ${style.fieldHeading}`}>
                                                                            <strong>Likelihood </strong>
                                                                            <strong>:</strong>
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item sm={6} md={3} lg={2}>
                                                                        <Typography key={index} className={style.fieldContent}>
                                                                            <span style={{...getStyleForInherentRisk(item.likelihoodSeverity),padding:"6px"}}>
                                                                                {getNameBasedOnValue(projectDropDown["scale"], item.likelihoodSeverity)}
                                                                            </span>
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item sm={6} md={3} lg={2}>
                                                                        <Typography className={`${style.fieldContent} ${style.fieldHeading}`}>
                                                                            <strong>Impact </strong>
                                                                            <strong>:</strong>
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item sm={6} md={3} lg={2}>
                                                                        <Typography key={index} className={style.fieldContent}>
                                                                            <span style={{...getStyleForInherentRisk(item.impactSeverity),padding:"6px"}}>
                                                                                {getNameBasedOnValue(projectDropDown["scale"], item.impactSeverity)}
                                                                            </span>
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item sm={6} md={3} lg={2}>
                                                                        <Typography className={`${style.fieldContent} ${style.fieldHeading}`}>
                                                                            <strong>Score</strong>
                                                                            <strong>:</strong>
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item sm={6} md={3} lg={2}>
                                                                        <Typography key={index} className={style.fieldContent}>
                                                                            <span style={{...getStyleForInherentRisk(getScoreForInherentRisk(getKeyBasedOnValue(projectDropDown["scale"], "value", item.impactSeverity, "score"), getKeyBasedOnValue(projectDropDown["scale"], "value", item.likelihoodSeverity, "score"))),padding:"6px"}}>
                                                                                {getScoreForInherentRisk(getKeyBasedOnValue(projectDropDown["scale"], "value", item.impactSeverity, "score"), getKeyBasedOnValue(projectDropDown["scale"], "value", item.likelihoodSeverity, "score"))}
                                                                            </span>
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </FieldsetCustom>
                                                        </div>
                                                    ))
                                                }
                                            </FieldsetCustom>
                                        </div>
                                }
                            </div>
                        </div>
                }
            </div>
        </div>
    );
}

export default ViewProjectDetailsForModal;