import React, { useState } from 'react';
import HeaderSection from '../../HeaderSection';
import { Button, Menu, MenuItem } from '@mui/material';
import style from '../../../css/Issue.module.css'
import StorageIcon from '@mui/icons-material/Storage';
import HistoryIcon from '@mui/icons-material/History';
import Activity from './Activity';
import Tickler from './Tickler';
import New from './New';
import Repository from './Repository';
import InspectIssues from './InspectIssues';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function IdentifiedIssues(props) {

    // init use state for issue ui handler
    const [issueUi,setissueUi] = useState("inspectIssue");
    // function to set issue ui
    const uiHandler = () => {
        if(issueUi === "viewActivity"){
            return <Activity />
        }else if(issueUi === "new"){
            return <New />
        }else if(issueUi === "tickler"){
            return <Tickler />
        }
        else if(issueUi === "repository"){
            return <Repository />
        }
        else if(issueUi === "inspectIssue"){
            return <InspectIssues /> 
        }
    }
    // function to set ui and close anchor
    const setissueUIandCloseAnchor = (issue,uiName) => {
        setissueUi(uiName);
        closeMenuAnchor(issue);
    }
    // init use state for menu issue
    const [menuissue,setMenuissue] = useState({"activity":{"anchor":null}});
    // function to open anchor of menu issue
    const openMenuAnchor = key => event => {
        var temp = menuissue;
        temp[key]['anchor'] = event.currentTarget;
        setMenuissue({...temp});
        closeSubMenuOfAnchor(key)
    };
    // function to close anchor of menu issue
    const closeMenuAnchor = (key) => {
        var temp = menuissue;
        temp[key]['anchor'] = null;
        setMenuissue({...temp});
    };
    // function to close sub menu of anchor
    const closeSubMenuOfAnchor = (issue) => {
        var temp = menuissue;
        for(var key in temp[issue]["subMenu"]){
            temp[issue]["subMenu"][key] = false;
        }
        setMenuissue({...temp});
    }
    const getRepoButton = () => {
        return(
            <Button size='small' 
                variant='contained' 
                startIcon={<StorageIcon/>} 
                onClick={()=>setissueUi('repository')}
                className={`${style.changeissue} mlr5`}
            >
                Repository
            </Button>
        )
    }

    const getActivityButton = () =>{
        return(
            <>
                <Button size='small' 
                    variant='contained' 
                    startIcon={<HistoryIcon/>} 
                    aria-controls='addissueMenu'
                    aria-haspopup="true"
                    onClick={openMenuAnchor("activity")}
                    className={`${style.addissue} mlr5`}
                >
                    Activity
                </Button>
                <Menu
                    id="activityMenu"
                    anchorEl={menuissue["activity"]["anchor"]}
                    open={Boolean(menuissue["activity"]["anchor"])}
                    onClose={()=>{closeMenuAnchor("activity")}}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    PaperProps={{
                        style: {
                            minWidth: '260px',
                        }
                    }}
                >   
                    <MenuItem onClick={()=>setissueUIandCloseAnchor("activity","viewActivity")}>View Activity</MenuItem>
                    <MenuItem onClick={()=>setissueUIandCloseAnchor("activity","new")}>New</MenuItem>
                    <MenuItem onClick={()=>setissueUIandCloseAnchor("activity","tickler")}>Tickler</MenuItem>
                </Menu>
            </>
        );
    }

    const getAllSpecialButtons = () => {
        var allSpecialButtons = [getActivityButton(),getRepoButton()]
        return allSpecialButtons;
    }
    const elementControl = {
        "specialButtons":{"inspectIssue" : [...getAllSpecialButtons()],
       "viewActivity" : [<Button onClick={()=>{setissueUi("inspectIssue")}} style={{color: "black"}} className='mlr5 roButton' variant="text" startIcon={<ArrowBackIosIcon/>}>back</Button>],
       "repository" : [<Button onClick={()=>{setissueUi("inspectIssue")}} style={{color: "black"}} className='mlr5 roButton' variant="text" startIcon={<ArrowBackIosIcon/>}>back</Button>],
       "tickler" : [<Button onClick={()=>{setissueUi("inspectIssue")}} style={{color: "black"}} className='mlr5 roButton' variant="text" startIcon={<ArrowBackIosIcon/>}>back</Button>],
       "new" : [<Button onClick={()=>{setissueUi("inspectIssue")}} style={{color: "black"}} className='mlr5 roButton' variant="text" startIcon={<ArrowBackIosIcon/>}>back</Button>]
    }}


    return (
        <div>
            <HeaderSection header='Identified Issues' breadcrumbs={["Cyber Risk Management","Manage Risk","Identified Issues"]} specialButtons={elementControl["specialButtons"][issueUi]}/>
            <div className='m20'>
                {uiHandler()}
            </div>
        </div>
    );
}
export default IdentifiedIssues;