import React, { useState, useEffect } from 'react';
import { Autocomplete, Grid } from '@mui/material';
import DataGridCustom from '../../wrapper/DataGridCustom';
import FieldsetCustom from '../../wrapper/FieldsetCustom';
import { autocompleteStyle } from '../../../js/standardStyle';
import { useDispatch, useSelector } from 'react-redux';
import { toastUp } from '../../../redux/actions/toast';
import { renderDisplayValueForAutocomplete } from '../../../js/util';
import { SERVICE_URL } from '../../../config';
import axios from 'axios';
import { getErrorMsg, generateArrayForAutoCompleteBasedOnkey } from '../../../js/util';
import { setSpinner } from '../../../redux/actions/spinner';

function Repository(props) {

    // get use dispatch
    const dispatch = useDispatch();
    // get loggedin user from redux
    const loggedInUser = useSelector((state) => state.loggedInUser);

    // init use state for store filter details
    const [selectedFilter, setSelectedFilter] = useState("");

    // init use state handle page for table
    const [pageState, setPageState] = useState({
        page: 0,
        pageSize: 30
    })
    // eslint-disable-next-line
    const [tableContent, setTableContent] = useState({
        isLoading: false,
        data: [],
        total: 0,
    })

    // init table column details
    const columnsActivity = [
        { field: 'name', sortable: false, flex: 1, renderHeader: () => (<strong>{'Assessment Name'}</strong>) },
        { field: 'question', sortable: false, flex: 1, renderHeader: () => (<strong>{'Objective'}</strong>) },
        {
            field: 'evidenceFileName', sortable: false, flex: 1, renderHeader: () => (<strong>{'Evidence'}</strong>), renderCell: (params) => (<a href={`${SERVICE_URL}assessment/register/identifiedIssues/evidence?fileName=${params.value}`} rel='noopener noreferrer' target='_blank'>
                {params.value}
            </a>)
        }
    ]


    // init use state for search form
    const [formData, setFormData] = useState({ "assessmentName": null });
    // function to set form data for auto complete
    const handleFormDataAutoCompleteChange = key => (event, object) => {
        var temp = formData;
        temp[key] = object;
        setFormData({ ...temp });
    }

    // init use state for form options meta data
    const [formOptionsMetaData, setFormOptionsMetaData] = useState({ "assessmentName": [] });
    const applySearch = (event) => {
        var code = event.keyCode || event.which;
        if(code === 13) {
            applyFilter();
        } 
    }
    // function to apply filter
    const applyFilter = () => {

        var filter = "";

        if (formData["assessmentName"] !== null) {
            filter += `&assessmentName=${formData["assessmentName"]}`;
        }
        setSelectedFilter(filter);
        setPageState((old) => ({ ...old, page: 0 }));
    }

    // use effect to call the function when ever the page changes
    useEffect(() => {
        const fetchData = async () => {
            setTableContent(old => ({ ...old, isLoading: true }))
            axios.get(`${SERVICE_URL}assessment/register/identifiedIssues/complete/evidence?assignedTo=${loggedInUser["id"]}${selectedFilter}`)
            .then(res => {
                var data = res.data;
                var temp = tableContent;
                temp["isLoading"] = false;
                temp["data"] = data["data"];
                temp["total"] = data["total"];
                setTableContent({ ...temp });
            }).catch(err => {
                dispatch(toastUp(getErrorMsg(err)));
                setTableContent(old => ({ ...old, isLoading: false }));
            })
        }
        fetchData();
        // eslint-disable-next-line
    }, [pageState.page, pageState.pageSize, selectedFilter])

    useEffect(() => {
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}assessment/register/ui/name?assignedTo=${loggedInUser["id"]}`)
        .then(res => {
            if (res.status === 200) {
                var data = res["data"];
                var temp = formOptionsMetaData;
                temp["assessmentName"] = generateArrayForAutoCompleteBasedOnkey(data["data"], 'name');
                setFormOptionsMetaData({ ...temp });
            } else {
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <FieldsetCustom legend="REPOSITORY">
            <div className='mlr15 mtb30'>
                <Grid container spacing={2}>

                    <Grid item sm={6} md={3}>
                        <span className='lable'>Assessment Name </span><span className='required'>*</span><br />
                        <Autocomplete value={formData["assessmentName"]} freeSolo onKeyPress={applySearch} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["assessmentName"]} size="small" disablePortal onChange={handleFormDataAutoCompleteChange("assessmentName")} renderInput={renderDisplayValueForAutocomplete} />
                    </Grid>
                </Grid>

            </div>
            </FieldsetCustom>
            <div className='mtb30'>
                <DataGridCustom rows={tableContent.data} rowCount={tableContent.total} isLoading={tableContent.isLoading} pageSizeOptions={[30, 50, 100]} paginationModel={pageState} onPaginationModelChange={setPageState} columns={columnsActivity} />
            </div>
        </div>
    );
}

export default Repository;