import { Typography, Grid,Button, TextField, IconButton, Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import style from '../../css/ViewIssueDetailsForModal.module.css';
import FieldsetCustom from '../wrapper/FieldsetCustom';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { getNameBasedOnValue, getErrorMsg } from '../../js/util';
import { issueDropDown } from '../../js/constants';
import htmlParser from "html-react-parser";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import axios from "axios";
import { SERVICE_URL } from "../../config";
import { setSpinner } from '../../redux/actions/spinner';
import { toastUp } from '../../redux/actions/toast';
import { useDispatch, useSelector } from 'react-redux';
import AddCommentIcon from '@mui/icons-material/AddComment';
import ModalForm from '../wrapper/ModalForm';
import { textFieldStyle } from '../../js/standardStyle';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function ViewIssueDetailsForModal(props) {

    // get use dispatch
    const dispatch = useDispatch();
    const loggedInUser = useSelector(state=>state.loggedInUser);
    const {issueDetail,changeOnHoldForProject} = props;
    const [hideElement,setHideElement] = useState({"content":true});
    // function to toggle visibility for the hidden elements
    const toggleVisibility = () => {
        var updatedHideElement = hideElement;
        updatedHideElement["content"] = !hideElement["content"];
        setHideElement({...updatedHideElement});
    }
    // init use state for history
    const [history,setHistory] = useState({"data":{},"anchorEl":{}});
    const getContentForHistory = (data) => {
        var htmlArray = [];
        Object.keys(data).forEach(function(key) {

            if(Array.isArray(data[key])){
                htmlArray.push(<Grid item sm={12} key={key}>
                    <div className='m5'>
                    <Typography>
                        {data[key][0]["createdBy"]} {key}
                    </Typography>
                    <div>
                        {
                            data[key].map(row=>(
                                <div className='m15' key={row.id}>
                                    <li >
                                    <Typography className={`mlr5 ${style.historyParent}`} component={'span'}>{getAttrName(row.attrName)} :</Typography>
                                    <Typography className={`mlr5 ${style.historyValue}`} style={{margin:"0px 5px",fontSize:"14px"}} component={'span'}>{getAttrValue(row.attrName,row.attrOld)}</Typography>
                                    <ArrowForwardIcon className={`${style.historyArrow}`} />
                                    <Typography className={`mlr5 ${style.historyValue}`} style={{margin:"0px 5px",fontSize:"14px"}} component={'span'}>{getAttrValue(row.attrName,row.attrNew)}</Typography>
                                    </li>
                                </div>
                            ))
                        }
                    </div> 
                    </div>
                </Grid>)
            }else{
                htmlArray.push(<Grid item sm={12} key={key}>
                    <div className='m5'>
                        <div className={style.spaceBetween}>
                            <Typography>
                                {data[key]["createdBy"]} {key}
                            </Typography>
                            {
                                (loggedInUser["id"] === data[key]["createdById"]) ?
                                    <div>
                                        <IconButton aria-label="more" id="long-button" aria-controls={Boolean(history["anchorEl"][key]) ? 'long-menu' : undefined} aria-expanded={Boolean(history["anchorEl"][key]) ? 'true' : undefined} aria-haspopup="true" onClick={handleMenuAnchorOpen(key)}>
                                            <MoreVertIcon className='icon' />
                                        </IconButton>
                                        <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button'}} anchorEl={history["anchorEl"][key]} open={Boolean(history["anchorEl"][key])} onClose={()=>{handleMenuAnchorClose(key)}} PaperProps={{style: {maxHeight: 48 * 4.5,width: '10ch'}}}>
                                            <MenuItem onClick={()=>{editCommentModal(key)}}>Edit</MenuItem>
                                        </Menu>
                                    </div>
                                :
                                    <></>
                            }
                            
                        </div>
                        <div>
                            <div className='m5'>
                                <Typography className={`${style.commentText} p10`}>{data[key]["text"]}</Typography>
                            </div>
                        </div> 
                    </div>
                </Grid>)
            }
        })
        return htmlArray;
    }
    // function to handle open menu anchor
    const handleMenuAnchorOpen = key => event => {
        var updatedAnchorEl = history;
        updatedAnchorEl["anchorEl"][key] = event.currentTarget;
        setHistory({...updatedAnchorEl});
    };
    // function to handle menu anchor
    const handleMenuAnchorClose = key => {
        var updatedAnchorEl = history;
        updatedAnchorEl["anchorEl"][key] = null;
        setHistory({...updatedAnchorEl});
    };
    const getAttrName = (name) => {
        if(name === "project_id"){
            return 'PROJECT'
        }else if(name === "name"){
            return 'NAME'
        }else if(name === "assigned_to"){
            return 'ASSIGNED TO'
        }else if(name === "status"){
            return 'STATUS'
        }else if(name === "priority"){
            return 'PRIORITY'
        }else if(name === "progress"){
            return 'PROGRESS'
        }else if(name === "due_date"){
            return 'DUE DATE'
        }else if(name === "description"){
            return 'DESCRIPTION'
        }else{
            return name;
        }
    }
    const getAttrValue = (attrName,value) => {
        if(attrName === "status"){
            return getNameBasedOnValue(issueDropDown["status"],value)
        }else if(attrName === "priority"){
            return getNameBasedOnValue(issueDropDown["priority"],value)
        }else if(attrName === "description"){
            return htmlParser(value)
        }else{
            return value;
        }
    }
    const getStatus = () => {
        if(issueDetail.onHold){
            return "Paused";
        }else{
            return getNameBasedOnValue(issueDropDown["status"],issueDetail.status)
        }
    }
    const editIssue = () => {
        if(issueDetail.onHold){
            dispatch(toastUp("Cannot edit pasued issue"));
            return false;
        }
        props.editIssueHandler()
    }
    // init use state for comment modal
    const [commentModal,setCommentModal] = useState({"isOpen":false,"mode":"","currentKey":"","value":{"text":""}});
    // function to open comment modal
    const openCommentModal = () => {
        var temp = commentModal;
        temp["isOpen"] = true;
        temp["mode"] = "add";
        setCommentModal({...temp});
    }
    // function to edit comment modal
    const editCommentModal = (key) => {
        var temp = commentModal;
        temp["isOpen"] = true;
        temp["mode"] = "edit";
        temp["value"]["text"] = history["data"][key]["text"];
        temp["currentKey"] = key
        setCommentModal({...temp});
        handleMenuAnchorClose(key);
    }
    // function to close comment modal
    const closeCommentModal = () => {
        var temp = commentModal;
        temp["isOpen"] = false;
        temp["mode"] = "";
        temp["value"]["text"]="";
        temp["currentKey"] = "";
        setCommentModal({...temp});
    }
    // function to handle comment value change
    const handleCommentModalValueChange = (key) => (event) => {
        var temp = commentModal;
        temp["value"][key] = event.target.value;
        setCommentModal({...temp});
    }
    // handler to save comment
    const saveHandlerForCommentModal = (mode) => {
        if(commentModal["value"]["text"] === ""){
            dispatch(toastUp("Comment field cannot be empty"));
            return false;
        }
        if(mode === "add"){
            addComment();
        }else if(mode === "edit"){
            editComment();
        }
    }
    const addComment = () => {
        var data = {};
        data["issueTrackerId"] = issueDetail["id"];
        data["text"] = commentModal["value"]["text"];
        data["createdBy"] = loggedInUser["id"];
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}/issue/comment`,data)
        .then((res)=>{
            var temp = history;
            var commentDetail = res["data"]["commentDetail"];
            let keys = Object.keys(commentDetail);
            temp["anchorEl"][keys[0]] = null;
            temp["data"][keys[0]] = commentDetail[keys[0]];
            setHistory({...temp});
            dispatch(toastUp("Comment created successfully"));
            closeCommentModal();
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
    }
    const editComment = () => {
        var data = {};
        data["text"] = commentModal["value"]["text"];
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}/issue/comment?id=${history["data"][commentModal["currentKey"]]["id"]}`,data)
        .then((res)=>{
            var temp = history;
            temp["data"][commentModal["currentKey"]]["text"] = commentModal["value"]["text"];
            dispatch(toastUp("Comment edited successfully"));
            closeCommentModal();
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
    }
    useEffect(()=>{
        var updatedAnchorEl = {};
        Object.keys(issueDetail.history).forEach(function(key) {
            updatedAnchorEl[key] = null;
        })
        setHistory({"data":{...issueDetail.history},"anchorEl":{...updatedAnchorEl}});
        // eslint-disable-next-line
    },[]);
    return (
        <div style={{height:"100%"}}>
            <div>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6" component="h2">View Issue</Typography>
                    </Grid>
                    <Grid  item>
                        {
                            (issueDetail.onHold) ?
                                <Button className='mlr5' size='small' variant='contained' startIcon={<PlayArrowIcon/>} onClick={()=>{changeOnHoldForProject(issueDetail["id"],"resume")}} >resume</Button>
                            :
                                <Button className='mlr5' size='small' variant='contained' startIcon={<PauseIcon/>} onClick={()=>{changeOnHoldForProject(issueDetail["id"],"pause")}} >pause</Button>
                        }
                        <Button style={{backgroundColor:"#ff9e00"}} className='mlr5' size='small' variant='contained' startIcon={<ModeEditIcon/>} onClick={editIssue}>edit issue</Button>
                    </Grid>
                </Grid>
                
            </div>
            <div className={style.content}>
                <div className={style.fieldsetSection}>
                    <FieldsetCustom legend={"ISSUE DETAILS"}>
                        
                        <Grid container spacing={2}>
                                <Grid item sm={6} md={3} lg={2}>
                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>ID</strong><strong>:</strong></Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={4}>
                                    <Typography className={style.fieldContent}>{issueDetail.id}</Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={2}>
                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Name</strong><strong>:</strong></Typography>
                                </Grid>
                                <Grid item sm={6} md={9} lg={4}>
                                    <Typography className={style.fieldContent}> {issueDetail.name} </Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={2}>
                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Assigned To</strong><strong>:</strong></Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={4}>
                                    <Typography component={'span'} className={style.fieldContent}>{issueDetail.assignedTo}</Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={2}>
                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Assigned By</strong><strong>:</strong></Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={4}>
                                    <Typography component={'span'} className={style.fieldContent}>{issueDetail.assignedBy} </Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={2}>
                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Status</strong><strong>:</strong></Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={4}>
                                    <Typography component={'span'} className={style.fieldContent}>{getStatus()}</Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={2}>
                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Priority</strong><strong>:</strong></Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={4}>
                                    <Typography component={'span'} className={style.fieldContent}>{getNameBasedOnValue(issueDropDown["priority"],issueDetail.priority)}</Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={2}>
                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Progress</strong><strong>:</strong></Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={4}>
                                    <Typography component={'span'} className={style.fieldContent}>{issueDetail.progress}</Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={2}>
                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Due Date</strong><strong>:</strong></Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={4}>
                                    <Typography className={style.fieldContent}>{issueDetail.dueDate}</Typography>
                                </Grid>
                                <Grid item sm={6} md={3} lg={2}>
                                    <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Description</strong><strong>:</strong></Typography>
                                </Grid>
                                <Grid item sm={6} md={9} lg={8}>
                                    <Typography component={'span'} className={style.fieldContent}>{htmlParser(issueDetail.description)}</Typography>
                                </Grid>
                            </Grid>
                    </FieldsetCustom>
                </div>
                <div className={style.fieldsetSection}>
                    <FieldsetCustom legend={"PROJECT"}>
                        <Grid container spacing={2}>
                            <Grid item sm={6} md={3} lg={2}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Name</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={4}>
                                <Typography className={style.fieldContent}>{issueDetail.project.name}</Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={2}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Manager</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={4}>
                                <Typography className={style.fieldContent}>{issueDetail.project.manager}</Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={2}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>PMO Office</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={4}>
                                <Typography className={style.fieldContent}>{issueDetail.project.pmoOffice}</Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={2}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Business Unit Area</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={4}>
                                <Typography className={style.fieldContent}>{issueDetail.project.area}</Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={2}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Sponsor</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={4}>
                                <Typography className={style.fieldContent}>{issueDetail.project.sponsor}</Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={2}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Sponsor Group</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={6} md={3} lg={4}>
                                <Typography className={style.fieldContent}>{issueDetail.project.sponsorGroup}</Typography>
                            </Grid>
                        </Grid>
                    </FieldsetCustom>
                </div>
                <div>
                    <div className='mtb5' style={{textAlign:"right"}}>
                        <Button className='mlr5 roButton' variant="text" onClick={toggleVisibility} >{hideElement["content"] ? 'show more' : 'show less'}</Button>
                    </div>
                    <div style={{display: (hideElement["content"]) ? "none" : "block"}} className={`${style.fieldsetSection}`}>
                        <FieldsetCustom legend='HISTORY'>
                                <div style={{"textAlign":"right"}}>
                                    <Button variant='text' startIcon={<AddCommentIcon/>} onClick={openCommentModal}>add comment</Button>
                                </div>
                            {
                                (history["data"] !== undefined && Object.keys(history["data"]).length !== 0) ?
                                    <>
                                        
                                        <Grid container spacing={2}>
                                            {   
                                                getContentForHistory(history["data"])
                                            }
                                        </Grid>
                                    </>
                                :
                                    <>
                                        <div style={{"textAlign":"center"}} className='label'>No Record Found</div>
                                    </>
                                
                            }
                        </FieldsetCustom>
                    </div>
                </div>
            </div>
            <ModalForm open={commentModal.isOpen} closeHandler={closeCommentModal} header={"Add Comment"} mode={commentModal.mode} saveHandler={()=>saveHandlerForCommentModal(commentModal.mode)}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <span className='lable'>Comment </span><span className='required'>*</span><br/>
                        <TextField multiline maxRows={8} fullWidth size='small' value={commentModal["value"]["text"]} sx={textFieldStyle} onChange={handleCommentModalValueChange("text")} />
                    </Grid>
                </Grid>
            </ModalForm>
        </div>
    );
}

export default ViewIssueDetailsForModal;