import React, { useState, useEffect } from 'react';
import PolicyActionForm from './PolicyActionForm';
import PolicyDetailsFormCustomize from './PolicyDetailsFormCustomize';
import { useDispatch } from 'react-redux';
import { resetCustomProp } from '../../../redux/actions/customProp';

function CustomiseTemplate(props) {

    // init use state to store policy details
    const [policyDetails,setPolicyDetails] = useState({"policyId":""});
    // init use state to handle ui
    const [customizeUI, setCustomizeUI] = useState("policyDetails");
    // init use state to store mode details
    const [mode,setMode] = useState({"mode":"add","props":{}});
    // function to set ui handler
    const uiHandler = () => {
        if(customizeUI === "policyDetails"){
            return <PolicyDetailsFormCustomize mode={mode["mode"]} setMode={setMode} {...props} setPreConfiguredUI={setCustomizeUI} setPolicyDetails={setPolicyDetails} policyDetails={policyDetails} />
        }else if(customizeUI === "policyAction"){
            return <PolicyActionForm mode={mode["mode"]} {...mode["props"]} setPreConfiguredUI={setCustomizeUI} setPolicyDetails={setPolicyDetails} policyDetails={policyDetails} legend={"CUSTOMIZE - POLICY CONDITIONS AND ACTIONS"} />
        }
    }
    // get dispatch
    const dispatch = useDispatch();

    useEffect(()=>{
        if ('manipulate' in props){
            if(props["manipulate"]["type"]==="autoPopulatePolicy"){
                setCustomizeUI("policyDetails");
            }else if(props["manipulate"]["type"] === "setPolicyActionCustomise"){
                setCustomizeUI("policyAction");
                setPolicyDetails((old)=>({...old,"policyId":props["manipulate"]["policyId"]}));
                dispatch(resetCustomProp());
            }else if(props["manipulate"]["type"]==="editPolicyCustomise"){
                setCustomizeUI("policyDetails");
                setPolicyDetails((old)=>({...old,"policyId":props["manipulate"]["data"]["id"]}));
            }
        }
        // eslint-disable-next-line
    },[])

    return (
        <div>
            {uiHandler()}
        </div>
    );
}

export default CustomiseTemplate;