import { createTheme } from '@mui/material/styles';

// const fieldBackgroundColour = "#e4e4e4";

export const textFieldStyle = {
    '& .MuiOutlinedInput-root': {
        // backgroundColor: fieldBackgroundColour,
        '&.Mui-focused fieldset': {
            borderColor: '#0c436e'
            // borderColor: '#e8eaf6'
        }
    },
    "& label": {
        "&.Mui-focused": {
          color: '#0c436e'
        }
    }
}

export const disabledTextFieldStyle = {
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#e0e0e0',
        '&.Mui-focused fieldset': {
            borderColor: '#0c436e'
            // borderColor: '#e8eaf6'
        }
    },
    "& label": {
        "&.Mui-focused": {
          color: '#0c436e'
        }
    }

}

export const searchFieldStyle = {
    sx:textFieldStyle
}

export const selectFieldStyle = {
    'sx':{
        // backgroundColor: fieldBackgroundColour,
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#0c436e'
        }
    },
    'MenuProps':{
        "style": {
            "maxHeight": "265px",
            "whiteSpace": "normal"
        }
        // PaperProps: {
        //     style: {
        //       maxHeight: "36px",
        //     },
        // }
        // MenuListProps: {
        //     sx: {
        //         backgroundColor: '#e4e4e4'
        //     }
        // }
    }
}

export const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "Open Sans, sans-serif"
      }
    }
});

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    width: "60%",
    bgcolor: 'background.paper',
    outline:"none",
    p: 4
    // backgroundColor: "#1e1e1e"
};

export const alertModalStyle = {
    position: 'absolute',
    top: '200px',
    left: '300px',
    minWidth: 300,
    width: "calc(100% - 400px)",
    bgcolor: 'transparent',
    outline:"none",
    p: 4
    // backgroundColor: "#1e1e1e"
};

export const tooltipStyle = {
    tooltip: {
        sx: {
            bgcolor: '#616161',
            color: 'white',
            // color: rgba(0, 0, 0, 0.87)',
            boxShadow: 8,
            '& .MuiTooltip-arrow': {
                color: '#616161',
            }
        }
    }
}

export const modalViewInfoStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    width: "75%",
    height: "80%",
    bgcolor: 'background.paper',
    outline:"none",
    p: 4
    // backgroundColor: "#1e1e1e"
};

export const dataGridStyle = {
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
       outline: "none !important",
    },
    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
        outline: "none !important",
    },
    // "&.MuiDataGrid-root .MuiDataGrid-columnHeader":{
    //     bgcolor:"#EDEDED",
    // },
    ".MuiDataGrid-columnHeaders":{
        bgcolor:"#166fb8",
        color:"white"
    },
    "&.MuiDataGrid-root .MuiDataGrid-row:hover":{
        bgcolor:"#fafafa"
    }
};

export const snackbarStyle = {
    '.MuiSnackbarContent-root':{
        justifyContent:"center",fontSize:"16px"
    }
}

export const autocompleteStyle = {
    sx:{
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: '#0c436e'
            }
        }
    },
    ListboxProps:{
        "style":{ "maxHeight": "200px" } }
}

export const chartBackgroudColorSet = ["#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#b3d4ff", "#00bfa0"];

export const getStyleForInherentRisk = (value) =>{
    if(value == null){
        return {}
    }
    if(value["value"] === "low" || value === "Low" || value === "low"){
        return {"color":"white","backgroundColor":"green","borderRadius": "5px"};
    }
    if(value["value"] === "medium" || value === "Medium" || value === "medium"){
        return {"color":"black","backgroundColor":"yellow","borderRadius": "5px"};
    }
    if(value["value"] === "high" || value === "High" || value === "high"){
        return {"color":"white","backgroundColor":"red","borderRadius": "5px"};
    }
    return {}
}

export const getPriorityStyleForActionPlan = (value) =>{
    if(value == null || value === ""){
        return {}
    }
    if(value["value"] === "low" || value === "Low" || value === "low"){
        return {"color":"white","backgroundColor":"green","borderRadius": "5px"};
    }
    if(value["value"] === "medium" || value === "Medium" || value === "medium"){
        return {"color":"black","backgroundColor":"yellow","borderRadius": "5px"};
    }
    if(value["value"] === "high" || value === "High" || value === "high"){
        return {"color":"white","backgroundColor":"red","borderRadius": "5px"};
    }
    return {}
}

export const getStatusStyleForActionPlan = (value) =>{
    if(value == null || value === ""){
        return {}
    }
    if(value["value"] === "open" || value === "Open" || value === "open"){
        return {"color":"black","backgroundColor":"yellow","borderRadius": "5px"};
    }
    if(value["value"] === "active" || value === "Active" || value === "active"){
        return {"color":"white","backgroundColor":"orange","borderRadius": "5px"};
    }
    if(value["value"] === "completed" || value === "Completed" || value === "completed"){
        return {"color":"white","backgroundColor":"green","borderRadius": "5px"};
    }
    if(value["value"] === "closed" || value === "Closed" || value === "closed"){
        return {"color":"white","backgroundColor":"red","borderRadius": "5px"};
    }
    return {}
}