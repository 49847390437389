import React, { useEffect, useState } from 'react';
import PolicyDetailsForm from './PolicyDetailsForm';
import PolicyActionForm from './PolicyActionForm';
import { useDispatch } from 'react-redux';
import { resetCustomProp } from '../../../redux/actions/customProp';

function PreConfiguredTemplate(props) {

    // init use state to store policy details
    const [policyDetails,setPolicyDetails] = useState({"policyId":""});
    // init use state for pre configured ui handler
    const [preConfiguredUI, setPreConfiguredUI] = useState("policyDetails");
    // init use state to store mode details
    const [mode,setMode] = useState({"mode":"add","props":{}});
    // function to set ui handler
    const uiHandler = () => {
        if(preConfiguredUI === "policyDetails"){
            return <PolicyDetailsForm mode={mode["mode"]} setMode={setMode} {...props} setPreConfiguredUI={setPreConfiguredUI} setPolicyDetails={setPolicyDetails} policyDetails={policyDetails} />
        }else if(preConfiguredUI === "policyAction"){
            return <PolicyActionForm mode={mode["mode"]} {...mode["props"]} setPreConfiguredUI={setPreConfiguredUI} setPolicyDetails={setPolicyDetails} policyDetails={policyDetails} />
        }
    }
    // get dispatch
    const dispatch = useDispatch();

    useEffect(()=>{
        if ('manipulate' in props){
            if(props["manipulate"]["type"]==="autoPopulatePolicy"){
                setPreConfiguredUI("policyDetails");
            }else if(props["manipulate"]["type"] === "setPolicyAction"){
                setPreConfiguredUI("policyAction");
                setPolicyDetails((old)=>({...old,"policyId":props["manipulate"]["policyId"]}));
                dispatch(resetCustomProp());
            }else if(props["manipulate"]["type"]==="editPolicy"){
                setPreConfiguredUI("policyDetails");
                setPolicyDetails((old)=>({...old,"policyId":props["manipulate"]["data"]["id"]}));
            }
        }
        // eslint-disable-next-line
    },[])
    
    return (
        <div>
            {uiHandler()}
        </div>
    );
}

export default PreConfiguredTemplate;