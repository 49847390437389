import React from 'react';
import HeaderSection from '../HeaderSection';
import MyAssessmentUser from './MyAssessmentUser';
import MyAssessmentManager from './MyAssessmentManager';
import { useSelector } from 'react-redux';

function MyAssessment(props) {
    
    // get loggedin user from redux
    const loggedInUser = useSelector((state)=>state.loggedInUser);
    // set mode to display page accordingly
    var mode = "";
    if(loggedInUser["module"]["assessment_management"].includes("user_view")){
        mode = "user_view";
    }else if(loggedInUser["module"]["assessment_management"].includes("manager_view")){
        mode = "manager_view";
    }

    return (
        <div>
            {/* selected menu details */}
            <HeaderSection header='My Assessments' breadcrumbs={["Assessment Managment","My Assessments"]}/>
            {/* get page content based in the use logged it */}
            {mode === "user_view" ? <MyAssessmentUser {...props}/> : mode === "manager_view" ? <MyAssessmentManager/> : <></>}
        </div>
    );
}

export default MyAssessment;