import React, {useState,useEffect} from 'react';
import HeaderSection from '../HeaderSection';
import axios from 'axios';
import { SERVICE_URL } from '../../config';
import DataGridCustomFull from '../wrapper/DataGridCustomFull';
import { Paper } from '@mui/material';
import { assessmentRegisterStatus } from '../../js/constants';
import { getNameBasedOnValue, getErrorMsg } from '../../js/util';
import { useDispatch, useSelector } from 'react-redux';
import { toastUp } from '../../redux/actions/toast';

function HistoricalAssessment(props) {

    // get loggedin user from redux
    const loggedInUser = useSelector((state)=>state.loggedInUser);
    
    // get use dispatch rom redux
    const dispatch = useDispatch();

    // set mode to display page accordingly
    var mode = "";
    if(loggedInUser["module"]["assessment_management"].includes("user_view")){
        mode = "user_view";
    }else if(loggedInUser["module"]["assessment_management"].includes("manager_view")){
        mode = "manager_view";
    }

    // init use state handle page for table
    const [pageState, setPageState] = useState({
        page: 0,
        pageSize: 30
    })
    // init use state to store table contents
    const [tableContent,setTableContent] = useState({
        isLoading: false,
        data: [],
        total: 0,
    })
    // init table column details
    const columns = [
        {field: 'name', sortable: false, flex: 1, renderHeader: () => (<strong>{'Assessment Name'}</strong>)},
        {field: 'framework', sortable: false, flex: 1, renderHeader: () => (<strong>{'Framework Source'}</strong>)},
        {field: 'category', sortable: false, flex: 1, renderHeader: () => (<strong>{'Risk Category'}</strong>)},
        {field: 'type', sortable: false, flex: 1, renderHeader: () => (<strong>{'Assessment Type'}</strong>)},
        {field: (mode === "user_view") ? 'assigned_by' : (mode === "manager_view") ? "assigned_to" : "", sortable: false, flex: 1, renderHeader: () => (<strong>{(mode === "user_view") ? 'Assigned By' : (mode === "manager_view") ? 'Assigned To' : ""}</strong>)},
        {field: 'status', sortable: false, width: 180, renderHeader: () => (<strong>{'Status'}</strong>), renderCell: (params) => {var displayValue = getNameBasedOnValue(assessmentRegisterStatus,params.value); return <div className="MuiDataGrid-cellContent" title={displayValue}>{displayValue}</div>}}
    ]
    
    // use effect to call the function when ever the page changes 
    useEffect(() => {
        const fetchData = async () => {
            setTableContent(old => ({ ...old, isLoading: true }));
            var basedOnUser = (mode === "user_view") ? `&assignedTo=${loggedInUser["id"]}` : (mode === "manager_view") ? `&assignedBy=${loggedInUser["id"]}` : "&assignedTo=not_found"
            axios.get(`${SERVICE_URL}assessment/register/view?page=${pageState.page+1}&size=${pageState.pageSize}&groupBy=history${basedOnUser}`)
            .then(res=>{
                var data = res.data;
                var temp = tableContent;
                temp["isLoading"] = false;
                temp["data"] = data["data"];
                temp["total"] = data["total"];
                setTableContent({...temp});
            }).catch(err => {
                dispatch(toastUp(getErrorMsg(err)));
                setTableContent(old => ({ ...old, isLoading: false }));
            })
        }
        fetchData();
        // eslint-disable-next-line
    }, [pageState.page, pageState.pageSize])

    return (
        <div>
            {/* selected menu details */}
            <HeaderSection header='Historical' breadcrumbs={["Assessment Managment","Historical"]}/>
            <div className='m20'>
                <Paper elevation={16}>
                    <DataGridCustomFull rows={tableContent.data} rowCount={tableContent.total} isLoading={tableContent.isLoading} pageSizeOptions={[30,50,100]} paginationModel={pageState} onPaginationModelChange={setPageState} columns={columns}/>
                </Paper>
            </div>
        </div>
    );
}

export default HistoricalAssessment;