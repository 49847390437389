import React, { useState, useEffect } from 'react';
import { Autocomplete, Button, Grid } from '@mui/material';
import DataGridCustom from '../../wrapper/DataGridCustom';
import FieldsetCustom from '../../wrapper/FieldsetCustom';
import { autocompleteStyle } from '../../../js/standardStyle';
import { useDispatch, useSelector } from 'react-redux';
import { toastUp } from '../../../redux/actions/toast';
import { removeHtmlContent, renderDisplayValueForAutocomplete } from '../../../js/util';
import { policyDropDown } from '../../../js/constants';
import { SERVICE_URL } from '../../../config';
import axios from 'axios';
import { getErrorMsg, getNameBasedOnValue } from '../../../js/util';
import { setSpinner } from '../../../redux/actions/spinner';
import ModalViewInfo from '../../wrapper/ModalViewInfo';
import ViewPolicyDetailsForModal from '../ViewPolicyDetailsForModal';

function RegulatoryRef(props) {

    // get use dispatch
    const dispatch = useDispatch();
    // get loggedin user from redux
    const loggedInUser = useSelector((state)=>state.loggedInUser);

    // init use state for store filter details
    const [selectedFilter,setSelectedFilter] = useState("");

    // init use state handle page for table
    const [pageState, setPageState] = useState({
        page: 0,
        pageSize: 30
    })
    // init use state to store table contents
    // eslint-disable-next-line
    const [tableContent,setTableContent] = useState({
        isLoading: false,
        data: [],
        total: 0,
    }) 
    // set class for table
    const getCellClassName = (params) =>{
        if(params.row.inActiveFlag){
            return `rejectedRow`;
        }
        return "";
    }
    // coloumn details for table
    const columns = [
        {field: 'refNumber', sortable: false, width: 100, renderHeader: () => (<strong>{'Policy No'}</strong>), cellClassName: getCellClassName},
        {field: 'name', sortable: false, width: 300, renderHeader: () => (<strong>{'Policy Name'}</strong>), renderCell: (params) => (<div className={`MuiDataGrid-cellContent ${(!params.row.inActiveFlag) ? 'hyperLinkActive' : ''}`} title={params.value} onClick={()=>{openPolicyModal(params.id,params.row.inActiveFlag)}}>{params.value}</div>), cellClassName: getCellClassName},
        {field: 'statement', sortable: false, flex: 1, renderHeader: () => (<strong>{'Policy Statement'}</strong>),renderCell: (params) =>(<div className="MuiDataGrid-cellContent" title={removeHtmlContent(params.value)}>{removeHtmlContent(params.value)}</div>), cellClassName: getCellClassName},
        {field: 'status', sortable: false, width: 200, renderHeader: () => (<strong>{'Policy Status'}</strong>),renderCell: (params) =>(<div className="MuiDataGrid-cellContent" title={params.value}>{getNameBasedOnValue(policyDropDown["status"],params.value)}</div>), cellClassName: getCellClassName}
    ]
    const [policyModal, setPolicyModal] = useState({"isOpen":false,"currentId":"","policyDetails":{}});
    // function to open policy details
    const openPolicyModal = (policyId,inActiveFlag) => {
        if(inActiveFlag){
            return false;
        }
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}policy?id=${policyId}`)
        .then((res)=>{
            if(res['status'] === 200){
                var temp = policyModal;
                temp["isOpen"] = true;
                temp["currentId"] = policyId;
                temp["policyDetails"] = res["data"];
                setPolicyModal({...policyModal});
            }else{
                dispatch(toastUp("unable to connect server"));    
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to close policy modal
    const handleClosePolicyModal = () => {
        var temp = policyModal;
        temp["isOpen"] = false;
        temp["currentId"] = "";
        temp["policyDetails"] = {}
        setPolicyModal({...policyModal});
    }
    // init use state for search form
    const [formData,setFormData] = useState({"regulatory":null,"framework":null,"standard":null});
    // function to set form data for auto complete
    const handleFormDataAutoCompleteChange = key => (event,object) => {
        var temp = formData;
        temp[key] = object;
        setFormData({...temp});
    }

    // init use state for form options meta data
    const [formOptionsMetaData, setFormOptionsMetaData] = useState({"regulatoryCitation":policyDropDown["regulatoryCitation"],"frameworkCitation":[],"standard":policyDropDown["standard"]});

    // function to apply filter
    const applyFilter = () => {

        if(formData["regulatory"] === null && formData["framework"] === null && formData["standard"] === null){
            dispatch(toastUp("Please select any one of the filter"));
            return false;
        }
        var filter = "";
        if(formData["regulatory"] !== null){
            filter += `&regulatoryCitation=${formData["regulatory"]["value"]}`;
        }
        if(formData["framework"] !== null){
            filter += `&frameworkCitation=${formData["framework"]["id"]}`;
        }
        if(formData["standard"] !== null){
            filter += `&standard=${formData["standard"]["value"]}`;
        }
        setSelectedFilter(filter);
        setPageState((old)=>({...old,page:0}));
    }

    // use effect to call the function when ever the page changes
    useEffect(() => {
        const fetchData = async () => {
            if(selectedFilter !== ""){
                setTableContent(old => ({ ...old, isLoading: true }))
                axios.get(`${SERVICE_URL}policy/search?page=${pageState.page+1}&size=${pageState.pageSize}&companyId=${loggedInUser["company_id"]}&status=approved${selectedFilter}`)
                .then(res=>{
                    var data=res.data;
                    var temp = tableContent;
                    temp["isLoading"] = false;
                    temp["data"] = data["data"];
                    temp["total"] = data["totalCount"];
                    setTableContent({...temp});
                }).catch(err => {
                    dispatch(toastUp(getErrorMsg(err)));
                    setTableContent(old => ({ ...old, isLoading: false }));
                })
            }
        }
        fetchData();
        // eslint-disable-next-line
    }, [pageState.page, pageState.pageSize,selectedFilter])

    useEffect(()=>{
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}assessment/framework`)
        .then(res => {
            if(res.status === 200){
                var data = res["data"];
                var temp = formOptionsMetaData;
                temp["frameworkCitation"] = data["data"];
                setFormOptionsMetaData({...temp});
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        // eslint-disable-next-line
    },[]);

    return (
        <div>
            <FieldsetCustom legend="REGULATORY REF">
                <div className='mlr15 mtb30'>
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={4}>
                            <span className='lable'>Law/Regulatory Citation</span><br/>
                            {/* <TextField size="small" sx={textFieldStyle} fullWidth variant="outlined" /> */}
                            <Autocomplete value={formData["regulatory"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["regulatoryCitation"]} size="small" disablePortal getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.value === value.value} onChange={handleFormDataAutoCompleteChange("regulatory")} renderInput={renderDisplayValueForAutocomplete} />
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <span className='lable'>Framework Citation</span><br/>
                            <Autocomplete value={formData["framework"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["frameworkCitation"]} size="small" disablePortal getOptionLabel={(option) => option.code} isOptionEqualToValue={(option, value) => option.id === value.id} onChange={handleFormDataAutoCompleteChange("framework")} renderInput={renderDisplayValueForAutocomplete} />
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <span className='lable'>Standard</span><br/>
                            <Autocomplete value={formData["standard"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["standard"]} size="small" disablePortal getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.value === value.value} onChange={handleFormDataAutoCompleteChange("standard")} renderInput={renderDisplayValueForAutocomplete} />
                        </Grid>
                    </Grid>
                    <div className='taCenter mt30 mb15'>
                        <Button onClick={applyFilter} variant='contained'>search</Button>
                    </div>
                </div>
            </FieldsetCustom>
            {(selectedFilter === "" ?
                null 
                :
                <div className='mtb30'>
                    <DataGridCustom rows={tableContent.data} rowCount={tableContent.total} isLoading={tableContent.isLoading} pageSizeOptions={[30,50,100]} paginationModel={pageState} onPaginationModelChange={setPageState} columns={columns}/>
                </div>
            )}
            {/* policy modal */}
            <ModalViewInfo open={policyModal.isOpen} closeHandler={handleClosePolicyModal}>
                <ViewPolicyDetailsForModal policyDetails={policyModal.policyDetails} mode="reuse"/>
            </ModalViewInfo>
        </div>
    );
}

export default RegulatoryRef;