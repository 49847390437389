import React, { useEffect, useState } from 'react';
import { Grid, Typography, TableCell, TableRow, Table, Paper , TableContainer, TableBody } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setSpinner } from '../../redux/actions/spinner';
import axios from 'axios';
import { SERVICE_URL } from '../../config';
import { getErrorMsg } from '../../js/util';
import { toastUp } from '../../redux/actions/toast';
import DoughnutChart from '../wrapper/DoughnutChart';
import { chartBackgroudColorSet } from '../../js/standardStyle';
import HorizontalBarChart from '../wrapper/HorizontalBarChart';
import VerticalBarChart from '../wrapper/VerticalBarChart';
import HorizontalStackedBarChart from '../wrapper/HorizontalStackedBarChart';

function TrackProgressChartReport(props) {
    const {projectId} = props;
    // get dispatch
    const dispatch = useDispatch();
    // init use state for chart data
    const [chartData,setChartData] = useState({"healthStatus":[],"taskStatus":null,"timeStatus":null,"expenditure":null,"workloadStatus":null}) 
    useEffect(()=>{
        dispatch(setSpinner(true))
        axios.get(`${SERVICE_URL}/issue/project/report/chart/trackProgress?projectId=${projectId}`)
        .then(res=>{
            var temp = chartData;
            temp["healthStatus"] = res["data"]["healthStatus"];
            if(res["data"]["taskStatus"] != null){
                var taskStatusData = {
                    labels: res["data"]["taskStatus"].map(data=>data.name),
                    datasets: [
                        {
                            label: '# of Task',
                            data: res["data"]["taskStatus"].map(data=>data.value),
                            backgroundColor: chartBackgroudColorSet
                        }
                    ]
                }
                temp["taskStatus"] = taskStatusData;
            }
            if(res["data"]["timeStatus"] != null){
                var timeStatusData = {
                    labels: res["data"]["timeStatus"].map(data=>data.name),
                    datasets: [
                        {
                            label: '# % of',
                            data: res["data"]["timeStatus"].map(data=>data.value),
                            backgroundColor: chartBackgroudColorSet
                        }
                    ]
                }
                temp["timeStatus"] = timeStatusData;
            }
            if(res["data"]["expenditure"] != null){
                var expenditureData = {
                    labels: res["data"]["expenditure"].map(data=>data.name),
                    datasets: [
                        {
                            label: '# budget of',
                            data: res["data"]["expenditure"].map(data=>data.value),
                            backgroundColor: chartBackgroudColorSet
                        }
                    ]
                }
                temp["expenditure"] = expenditureData;
            }
            if(res["data"]["workloadStatus"] != null){
                var workloadData = {
                    labels: res["data"]["workloadStatus"].map(data=>data.userName),
                    datasets: 
                    [
                        {
                            label: 'Completed',
                            data: res["data"]["workloadStatus"].map(data=>data.completed),
                            backgroundColor: "#00bfa0"
                        },{
                            label: 'Remaining',
                            data: res["data"]["workloadStatus"].map(data=>data.remaining),
                            backgroundColor: "#ffa300"
                        },{
                            label: 'Overdue',
                            data: res["data"]["workloadStatus"].map(data=>data.overdue),
                            backgroundColor: "#e60049"
                        }
                    ]
                }
                temp["workloadStatus"] = workloadData;
            }
            setChartData({...temp});
        })
        .catch(err=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
        // eslint-disable-next-line
    },[])
    const style={}
    style["content"]={minHeight:"500px",display:"flex",flexDirection: "column",justifyContent: "center"}
    return (
        <div>
            <Grid container spacing={2} alignItems="center">
                <Grid item sm={12} md={6}>
                    <Paper style={style.content} elevation={2}>
                        <Typography className='p10 taCenter'>Project Health Status</Typography>
                        <div className='mtb10'>
                            <TableContainer>
                                <Table style={{backgroundColor:"#82dbcc"}}>
                                    <TableBody >
                                        {chartData["healthStatus"].map((row) => (
                                            <TableRow  key={row.key}>
                                                <TableCell  component="th" scope="row">{row.key}</TableCell>
                                                <TableCell >{row.value}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Paper>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Paper style={style.content} elevation={2}>
                        <Typography className='p10 taCenter'>Project Task Status</Typography>
                        <div className='mtb10 pb10' style={{width: "60%",margin:"auto"}}>
                            <DoughnutChart data={chartData["taskStatus"]}/>
                        </div>
                    </Paper>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Paper style={style.content} elevation={2}>
                        <Typography className='p10 taCenter'>Project Time Status</Typography>
                        <div className='mtb10 pb10' style={{width: "95%",margin:"auto"}}>
                            <HorizontalBarChart data={chartData["timeStatus"]}/>
                        </div>
                    </Paper>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Paper style={style.content} elevation={2}>
                        <Typography className='p10 taCenter'>Project Expenditure</Typography>
                        <div className='mtb10 pb10' style={{width: "95%",margin:"auto"}}>
                            <VerticalBarChart data={chartData["expenditure"]}/>
                        </div>
                    </Paper>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Paper style={style.content} elevation={2}>
                        <Typography className='p10 taCenter'>Workload Status</Typography>
                        <div className='mtb10 pb10' style={{width: "95%",margin:"auto"}}>
                            <HorizontalStackedBarChart data={chartData["workloadStatus"]}/>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

export default TrackProgressChartReport;