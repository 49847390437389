import React, {useState,useEffect} from 'react';
import DataGridCustomFull from '../wrapper/DataGridCustomFull';
import { SERVICE_URL } from '../../config';
import axios from 'axios';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {IconButton,Menu,MenuItem,Button,Grid,Select,Paper} from '@mui/material';
import ModalFormWithCustomButton from '../wrapper/ModalFormWithCustomButton';
import { selectFieldStyle } from '../../js/standardStyle';
import { generateDropDownBasedOnKey, getErrorMsg } from '../../js/util';
import style from '../../css/MyAssessmentUser.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { setPage } from '../../redux/actions/displayPage';
import { setSpinner } from '../../redux/actions/spinner';
import { toastUp } from '../../redux/actions/toast';
import { setUiWithCustomProp } from '../../redux/actions/customProp';
// import ModalViewInfo from '../wrapper/ModalViewInfo';
// import ViewAssessmentRegisterDetaiForModal from './ViewAssessmentRegisterDetaiForModal';

function MyAssessmentUser(props) {

    // get dispatch from redux
    const dispatch = useDispatch();
    // get loggedin user details
    const loggedInUser = useSelector(state => state.loggedInUser);

    // init use state handle page for table
    const [pageState, setPageState] = useState({
        page: 0,
        pageSize: 30
    })
    // init use state to store table contents
    const [tableContent,setTableContent] = useState({
        isLoading: false,
        data: [],
        total: 0,
    })
    // function to get cell class name for table
    const getCellClassName = (params) => {
        if(params.row.status==="rejected"){
            return `${style.rejectedRow}`;
        }
        return "";
    } 
    // function to get action cell content
    const getActionCellContent = (params) => (
        <div className={`${style.actionRow}`}>
            {
            params.row.status==="assigned"
            ?
                <Button onClick={()=>{openAssessmentExam(params.id)}} className='ptb2 plr10 riskOneGreenSmallBtn' size="small" variant="contained">start</Button>
            :
                <div>
                    <IconButton disabled={params.row.status==="rejected" ? true : false} aria-label="more" id="long-button" aria-controls={Boolean(columnState[params.id]["anchorEl"]) ? 'long-menu' : undefined} aria-expanded={Boolean(columnState[params.id]["anchorEl"]) ? 'true' : undefined} aria-haspopup="true" onClick={handleMenuAnchorOpen(params.id)}>
                        <MoreVertIcon className='icon' />
                    </IconButton>
                    <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button'}} anchorEl={columnState[params.id]["anchorEl"]} open={Boolean(columnState[params.id]["anchorEl"])} onClose={()=>{handleMenuAnchorClose(params.id)}} PaperProps={{style: {maxHeight: 48 * 4.5,width: '20ch'}}}>
                        <MenuItem onClick={()=>{acceptAssessment(params.id)}}>Accept</MenuItem>
                        <MenuItem onClick={()=>{openRejectCodeModal(params.id)}}>Reject</MenuItem>
                        <MenuItem onClick={()=>{handleMenuAnchorClose(params.id)}}>Inquire</MenuItem>
                    </Menu>
                </div>
            }
        </div>
    ) 
    // init table column details
    const columns = [
        {field: 'name', sortable: false, flex: 1, renderHeader: () => (<strong>{'Assessment Name'}</strong>), cellClassName: getCellClassName},
        {field: 'framework', sortable: false, flex: 1, renderHeader: () => (<strong>{'Framework Source'}</strong>), cellClassName: getCellClassName},
        {field: 'category', sortable: false, flex: 1, renderHeader: () => (<strong>{'Risk Category'}</strong>), cellClassName: getCellClassName},
        {field: 'type', sortable: false, flex: 1, renderHeader: () => (<strong>{'Assessment Type'}</strong>), cellClassName: getCellClassName},
        {field: 'assigned_by', sortable: false, flex: 1, renderHeader: () => (<strong>{'Assigned By'}</strong>), cellClassName: getCellClassName},
        {field: 'action', sortable: false, width: 100, renderHeader: () => (<strong>{'Action'}</strong>),renderCell: getActionCellContent, cellClassName: getCellClassName}
    ]
    // init use state for column 
    const [columnState,setColumnState] = useState({});
    // function to generate use state for table column
    const generateTableSupportingDetils = (data) => {
        var updatedColumnState = {};
        for(const row of data){
            updatedColumnState[row["id"]] = {"anchorEl":null};
        }
        setColumnState({...updatedColumnState});
    }
    // function to handle open menu anchor
    const handleMenuAnchorOpen = key => event => {
        var updatedColumnState = columnState;
        updatedColumnState[key]["anchorEl"] = event.currentTarget;
        setColumnState({...updatedColumnState});
    };
    // function to handle menu anchor
    const handleMenuAnchorClose = key => {
        var updatedColumnState = columnState;
        updatedColumnState[key]["anchorEl"] = null;
        setColumnState({...updatedColumnState});
    };
    // function to open assessment exam
    const openAssessmentExam = (id) => {
        dispatch(setUiWithCustomProp({"ui":"myAssessmentExam","props":{'manipulate':{"type":"takeExam","data":{"registerAssessmentId":id}}}}))
        dispatch(setPage(("myAssessmentExam")));
    }
    // function to accept assessment
    const acceptAssessment = frameworkId => {
        var data = {};
        data["status"] = "assigned";
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}assessment/register/changeStatus?registerAssessmentId=${frameworkId}`,data)
        .then(res => {
            if(res.status === 200){
                var temp = tableContent;
                for(var row of temp["data"]){
                    if(row["id"] === frameworkId){
                        row["status"] = "assigned";
                    }
                }
                setTableContent({...temp});
                handleMenuAnchorClose(frameworkId);
                dispatch(toastUp("Assessment accepted successfully"));
            }else{
                console.log(res)
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }

    // init use state for reject code modal
    const [rejectCodeModal,setRejectCodeModal] = useState({"isOpen":false,"header":"Reject Code For Rejection","currentId":"","value":{"rejectCode":""},"metaData":{"rejectCode":[]}});
    // function to open reject code modal
    const openRejectCodeModal = (id) => {
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}/risk/getConfig/rejectAssessmentReasonCodes`)
        .then(res => {
            handleMenuAnchorClose(id);
            var temp = rejectCodeModal;
            temp["currentId"] = id;
            temp["metaData"]["rejectCode"] = res["data"];
            temp["isOpen"] = true;
            setRejectCodeModal({...temp});
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        
    }
    // function to close reject code modal
    const closeRejectCodeModal = () => {
        var temp = rejectCodeModal;
        temp["currentId"] = "";
        temp["isOpen"] = false;
        temp["value"]["rejectCode"] = "";
        temp["metaData"]["rejectCode"] = [];
        setRejectCodeModal({...temp});
    }
    // function to reject an assessment
    const rejectAssessment = () => {
        if(rejectCodeModal["value"]["rejectCode"] === ""){
            dispatch(toastUp("Please select the reject code for rejection"));
            return false;   
        }
        var data = {};
        data["status"] = "rejected";
        data["rejectReasonCode"] = rejectCodeModal["value"]["rejectCode"];
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}assessment/register/changeStatus?registerAssessmentId=${rejectCodeModal["currentId"]}`,data)
        .then(res => {
            if(res.status === 200){
                var temp = tableContent;
                for(var row of temp["data"]){
                    if(row["id"] === rejectCodeModal["currentId"]){
                        row["status"] = "rejected";
                    }
                }
                setTableContent({...temp});
                closeRejectCodeModal();
                dispatch(toastUp("Assessment rejected successfully"));
            }else{
                console.log(res)
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to handle value change for reject code modal 
    const rejectCodeValueHandler = key => event => {
        var temp = rejectCodeModal;
        temp['value'][key] = event.target.value;
        setRejectCodeModal({...temp});
    }

    // use effect to call the function when ever the page changes 
    useEffect(() => {
        const fetchData = async () => {
            setTableContent(old => ({ ...old, isLoading: true }))
            axios.get(`${SERVICE_URL}assessment/register/view?page=${pageState.page+1}&size=${pageState.pageSize}&groupBy=navigate&assignedTo=${loggedInUser["id"]}`)
            .then(res=>{
                var data = res.data;
                generateTableSupportingDetils(data["data"]); 
                var temp = tableContent;
                temp["isLoading"] = false;
                temp["data"] = data["data"];
                temp["total"] = data["total"];
                setTableContent({...temp});
            }).catch(err=>{
                dispatch(toastUp(getErrorMsg(err)));
                setTableContent(old => ({ ...old, isLoading: false }));
            })
        }
        fetchData();
        // eslint-disable-next-line
    }, [pageState.page, pageState.pageSize])

    return (
        <div>
            <div className='m20'>
                <Paper elevation={16}>
                    <DataGridCustomFull rows={tableContent.data} rowCount={tableContent.total} isLoading={tableContent.isLoading} pageSizeOptions={[30,50,100]} paginationModel={pageState} onPaginationModelChange={setPageState} columns={columns}/>
                </Paper>
            </div>
            <ModalFormWithCustomButton open={rejectCodeModal.isOpen} closeHandler={closeRejectCodeModal} header={rejectCodeModal.header} saveName={"submit"} saveHandler={rejectAssessment}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                        <span className='lable'>Reject Code <span className='required'>*</span></span><br/>
                        <Select MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={rejectCodeValueHandler("rejectCode")} value={rejectCodeModal["value"]["rejectCode"]} displayEmpty>
                            <MenuItem disabled value=""><em>-- please select--</em></MenuItem>
                            {generateDropDownBasedOnKey(rejectCodeModal["metaData"]["rejectCode"],"name","value")}
                        </Select>
                    </Grid>
                </Grid>
            </ModalFormWithCustomButton>
            {/* temp */}
            {/* <ModalViewInfo open={false} closeHandler={()=>{}}>
                <ViewAssessmentRegisterDetaiForModal/>
            </ModalViewInfo> */}
        </div>
    );
}

export default MyAssessmentUser;