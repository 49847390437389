import React, { useEffect, useState } from 'react';
import HeaderSection from '../HeaderSection';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button,RadioGroup,FormControlLabel,Radio } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setPage } from '../../redux/actions/displayPage';
import { setSpinner } from '../../redux/actions/spinner';
import axios from 'axios';
import { SERVICE_URL } from '../../config';
import style from '../../css/MyAssessmentExam.module.css';
import { resetCustomProp } from '../../redux/actions/customProp';
import { toastUp } from '../../redux/actions/toast';
import { getErrorMsg } from '../../js/util';

function MyAssessmentExam(props) {
    const [registerAssessmentId,setRegisterAssessmentId] = useState(null);
    const dispatch = useDispatch();
    const [tableData,setTableData] = useState({});

    const changeInitialInquiryValueForTableData = (key) => (event) => {
        var temp = tableData;
        var value = event.target.value;
        temp[key]["controlDetails"]["initialInquiry"] = value;
        setTableData({...temp});
        if(value === "no"){
            resetInitialDetails(key);
        }
    };
    const resetInitialDetails = (key) => {
        var temp = tableData;
        for(var row of temp[key]["response"]){
            row["answer"] = null;
        }
        setTableData({...temp});
    }
    const changeInitialDetailsValueForTableData = (parentId,childId) => (event) => {
        var temp = tableData;
        for(var row of temp[parentId]["response"]){
            if(row["id"] === childId){
                row["answer"] = event.target.value;
            }
        }
        setTableData({...temp});
    }
    const generateTableData = (data) => {
        var markUp = [];
        if(Object.keys(data).length > 0){
            for(var key in data){
                var inquiryDetailSet = [];
                var score = 0;
                for(var row of data[key]["response"]){
                    if (row['answer'] === 'yes'){
                        score +=1
                    }

                    inquiryDetailSet.push(
                        <div key={row["id"]}>
                            <div>{row["question"]}</div>
                            <div>
                                <RadioGroup row onChange={changeInitialDetailsValueForTableData(key,row["id"])} value={row["answer"]}>
                                    <FormControlLabel value="yes" control={<Radio size="small" />} label={<span style={{fontSize:"14px"}}>Yes</span>} />
                                    <FormControlLabel value="no" control={<Radio size="small" />} label={<span style={{fontSize:"14px"}}>No</span>} />
                                </RadioGroup>
                            </div>
                            <br/>
                        </div>
                    )
                }

                var avg = (score/data[key]['response'].length)*100;
                if (avg >= 0 && avg <= 24) {
                    data[key]["controlDetails"]["cumulativeResponse"] = "Not Defined, Not Implemented";
                } else if (avg >= 25 && avg <= 49) {
                    data[key]["controlDetails"]["cumulativeResponse"] = "Controls Exist, But Need Improvement";
                } else if (avg >= 50 && avg <= 74) {
                    data[key]["controlDetails"]["cumulativeResponse"] = "Inadequate Needs Significant Development";
                } else if (avg >= 75 && avg <= 100) {
                    data[key]["controlDetails"]["cumulativeResponse"] = "Strong, Adequate and Appropriate";
                }


                markUp.push(
                    <tr key={key} className={`${style.tbodyTr}`}>
                            <td className={`${style.tbodyTd} ${style.td}`}>{data[key]["controlDetails"]["code"]}</td>
                            <td className={`${style.tbodyTd} ${style.td}`}>{data[key]["controlDetails"]["name"]}</td>
                            <td className={`${style.tbodyTd} ${style.td}`}></td>
                            <td className={`${style.tbodyTd} ${style.td}`}></td>
                            <td className={`${style.tbodyTd} ${style.td}`}>
                                <div>Does the organization have formal (documented and implemented) information security policies and procedures ?</div>
                                <div style={{display: "flex",justifyContent: "center"}}>
                                    <RadioGroup row value={data[key]["controlDetails"]["initialInquiry"]} onChange={changeInitialInquiryValueForTableData(key)}>
                                        <FormControlLabel value="yes" control={<Radio size="small" />} label={<span style={{fontSize:"14px"}}>Yes</span>} />
                                        <FormControlLabel value="no" control={<Radio size="small" />} label={<span style={{fontSize:"14px"}}>No</span>} />
                                    </RadioGroup>
                                </div>
                            </td>
                            <td className={`${style.tbodyTd} ${style.td}`}>
                                <div style={{display:(data[key]["controlDetails"]["initialInquiry"]==="no"?"none":"block")}}>
                                    {inquiryDetailSet}
                                </div>
                            </td>
                            <td className={`${style.tbodyTd} ${style.td}`}>
                                {data[key]["controlDetails"]["cumulativeResponse"]}
                            </td>
                        </tr>
                )
            }
        }else{
            markUp.push(
                <tr key={"dummy"} className={`${style.tbodyTr}`}>
                    <td className={`${style.tbodyTd} ${style.td} taCenter`} colSpan={"7"}>No Questions Found</td>
                </tr>
            )
        }
        return markUp;
    }
    const populateTableContentForRegisterAssessment = (id) => {
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}assessment/register?id=${id}`)
        .then(res => {
            var data=res["data"];
            var modifiedControlResponse = data["data"]["frameworkControlResponse"];
            for(var key in modifiedControlResponse){
                modifiedControlResponse[key]["controlDetails"]["initialInquiry"] = 'no';
                modifiedControlResponse[key]["controlDetails"]["cumulativeResponse"] = null;
            }
            setTableData({...modifiedControlResponse});
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    useEffect(()=>{
        if ('manipulate' in props){
            if(props["manipulate"]["type"]==="takeExam"){
                var data = props["manipulate"]["data"];
                setRegisterAssessmentId(data["registerAssessmentId"]);
                populateTableContentForRegisterAssessment(data["registerAssessmentId"])
                dispatch(resetCustomProp())
            }
        }
        // eslint-disable-next-line
    },[])
    const submitRegister = () => {
        var data = validateAndGetTableValue();
        if(data["status"] === "valid"){
            saveRegisterAssessment(data["data"]);
        }else{
            dispatch(toastUp(data["msg"]));
        }
    }
    const saveRegisterAssessment = (data) => {
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}assessment/register/response?id=${registerAssessmentId}`,data)
        .then(res => {
            dispatch(toastUp("Assessment response saved successfully"));
            setTimeout(()=>{dispatch(setPage("myAssessment"))}, 2000);
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    const validateAndGetTableValue = () => {
        var data = {};
        data["status"] = "invalid";
        var frameworkControlResponse = []
        for(var key in tableData){
            if(tableData[key]["controlDetails"]["initialInquiry"] === 'no'){
                data["msg"] = `Please select Initial Inquiry for ${tableData[key]["controlDetails"]["name"]}`;
                return data;
            }
            for(var row of tableData[key]["response"]){
                if(row["answer"]===null){
                    data["msg"] = `Please select Initial Detail of question '${row["question"]}' in ${tableData[key]["controlDetails"]["name"]}`;
                    return data;
                }
                frameworkControlResponse.push({"id":row["id"],"answer":row["answer"]});
            }
        }
        data["status"] = 'valid';
        data["data"]={"frameworkControlResponse":frameworkControlResponse};
        return data;
    }
    return (
        <div>
            {/* selected menu details */}
            <HeaderSection header='My Assessments' breadcrumbs={["Assessment Managment","My Assessments"]} specialButtons={[<Button onClick={()=>dispatch(setPage(("myAssessment")))} style={{color: "black"}} className='mlr5 roButton' variant="text" startIcon={<ArrowBackIosIcon/>}>back</Button>]}/>
            <div className='m20'>
                <table className={`${style.table}`}>
                    <thead>
                        <tr className={`${style.theadTr}`}>
                            <th className={`${style.th}`}>Standard/Framework</th>
                            <th className={`${style.th}`}>Section Label</th>
                            <th className={`${style.th}`}>Objective</th>
                            <th className={`${style.th}`}>Guidance</th>
                            <th className={`${style.th}`}>Initial Inquiry</th>
                            <th className={`${style.th}`}>Inquiry Detail</th>
                            <th className={`${style.th}`}>Cumulative Response</th>
                        </tr>
                    </thead>
                    <tbody style={{fontSize:"14px",verticalAlign:"top"}}>
                        {generateTableData(tableData)}
                    </tbody>
                </table>
            </div>
            <div className='mt30 mb15' style={{textAlign:"center"}}>
                <Button className='mlr5 roButton' variant="contained" onClick={submitRegister} >submit</Button>
            </div>
        </div>
    );
}

export default MyAssessmentExam;