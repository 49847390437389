import { Paper, Typography, TableCell, TableRow, Table, TableContainer, TableBody } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { setSpinner } from '../../redux/actions/spinner';
import { useDispatch } from 'react-redux';
import { SERVICE_URL } from '../../config';
import axios from 'axios';
import { toastUp } from '../../redux/actions/toast';
import { getErrorMsg } from '../../js/util';

function ProjectManagementProgressStatusChartReport(props) {
    const {projectId} = props;
    // get dispatch
    const dispatch = useDispatch();
    // init use state for chart data
    const [chartData, setChartData] = useState({"name":"","overallStatus":{"budget":"","time":"","plannedBudget":"","actualCost":""},"criticalIssues":[],"majorRisk":[]});
    useEffect(()=>{
        dispatch(setSpinner(true))
        axios.get(`${SERVICE_URL}/issue/project/report/chart/managementProgressStatusChartReport?projectId=${projectId}`)
        .then(res=>{
            var temp = chartData;
            if(res["data"]["overallStatus"] !== null){
                temp["overallStatus"] = res["data"]["overallStatus"];
            }
            temp["name"] = res["data"]["name"];
            temp["criticalIssues"] = res["data"]["criticalIssues"];
            temp["majorRisk"] = res["data"]["majorRisk"];
            setChartData({...temp});
        })
        .catch(err=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
        // eslint-disable-next-line
    },[])
    return (
        <div>
            <Paper style={{backgroundColor:"#cee7d6"}} className='p10 mtb30' elevation={2}>
                <Typography className='p10 taCenter'>Project Name</Typography>
                <div className='mtb10'>
                    <Typography className='p10'>{chartData["name"]}</Typography>
                </div>
            </Paper>
            <Paper className='p10 mtb30' elevation={2}>
                <Typography className='p10 taCenter'>Overall Status</Typography>
                <div className='mtb10'>
                <TableContainer>
                        <Table style={{backgroundColor:"#82dbcc"}}>
                            <TableBody>
                                <TableRow>
                                    <TableCell><div style={{backgroundColor:chartData["overallStatus"]["time"],height:"8px",width:"8px",display:"inline-block",borderRadius:"8px",marginRight:"10px"}}></div>Time</TableCell>
                                    <TableCell>Cost (Planned)</TableCell>
                                    <TableCell>{chartData["overallStatus"]["plannedBudget"]}</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell><div style={{backgroundColor:chartData["overallStatus"]["budget"],height:"8px",width:"8px",display:"inline-block",borderRadius:"8px",marginRight:"10px"}}></div>Budget</TableCell>
                                    <TableCell>Cost (Actual)</TableCell>
                                    <TableCell>{chartData["overallStatus"]["actualCost"]}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Paper>
            <Paper className='p10 mtb30' elevation={2}>
                <Typography className='p10 taCenter'>Critical Issues</Typography>
                <div className='mtb10'>
                <TableContainer>
                        <Table style={{backgroundColor:"#82dbcc"}}>
                            <TableBody>
                                {
                                    (chartData["criticalIssues"].length > 0) ?
                                        chartData["criticalIssues"].map((value,i) => {
                                            return (
                                                <TableRow  key={i}>
                                                    <TableCell>{value}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    :
                                    <TableRow>
                                        <TableCell align='center'>
                                            No Record Found
                                        </TableCell>
                                    </TableRow>
                                }    
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Paper>
            <Paper className='p10 mtb30' elevation={2}>
                <Typography className='p10 taCenter'>Major Risks</Typography>
                <div className='mtb10'>
                <TableContainer>
                        <Table style={{backgroundColor:"#82dbcc"}}>
                            <TableBody>
                                {
                                    (chartData["majorRisk"].length > 0) ?
                                        chartData["majorRisk"].map((value,i) => {
                                            return (
                                                <TableRow  key={i}>
                                                    <TableCell>{value}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    :
                                    <TableRow>
                                        <TableCell align='center'>
                                            No Record Found
                                        </TableCell>
                                    </TableRow>
                                }  
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Paper>
            
        </div>
    );
}

export default ProjectManagementProgressStatusChartReport;