import React from 'react';
import style from '../../css/FieldsetCustom.module.css'

function FieldsetCustom(props) {
    return (
        <fieldset className={style.fieldset}>
            <legend className={style.legend}>{props.legend}</legend>
                {props.children}
        </fieldset>
    );
}

export default FieldsetCustom;