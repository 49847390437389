import React, { useState } from 'react';
import EmailInbox from './EmailInbox';
import EmailView from './EmailView';
import ActionPlan from './ActionPlan';

function Email(props) {
    const [emailUi,setEmailUi] = useState("inbox");
    const [selectedEmailId, setSelectedEmailId] = useState("");
    const [emailInboxMenu,setEmailInboxMenu] = useState("inbox");
    const uiHandler = () => {
        if(emailUi === "inbox"){
            return <EmailInbox emailInboxMenu={emailInboxMenu} setEmailInboxMenu={setEmailInboxMenu} pageState={pageState} setPageState={setPageState} setSelectedEmailId={setSelectedEmailId} setEmailUi={setEmailUi}/>
        }else if(emailUi === "view"){
            return <EmailView selectedEmailId={selectedEmailId} setSelectedEmailId={setSelectedEmailId} setEmailUi={setEmailUi}/>
        }else if(emailUi === "actionPlan"){
            return <ActionPlan selectedEmailId={selectedEmailId} setEmailUi={setEmailUi}/>
        }
    }
    const [pageState, setPageState] = useState({
        page: 0,
        pageSize: 30
    })
    return (
        <div>
            {uiHandler()}
        </div>
    );
}

export default Email;