const alertReducer = (state={"isOpen":false,"msg":""},action) => {
    switch(action.type){
        case 'setAlert':
            return {"isOpen":true,"msg":action.payload}
        case 'closeAlert':
            return {"isOpen":false,"msg":""}
        default:
            return state;
    }
}

export default alertReducer;