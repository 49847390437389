import React, { useState, useEffect } from 'react';
import { Paper } from '@mui/material';
import style from '../../css/LogIn.module.css'
import LogInForm from './LogInForm';
import ForgotPassword from './ForgotPassword';
import axios from 'axios';
import { SERVICE_URL } from '../../config';
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/actions/loggedInUser';
import { setSpinner } from '../../redux/actions/spinner';
import { toastUp } from '../../redux/actions/toast';

function LogIn(props) {

    // get use dispactch from redux
    const dispatch = useDispatch();

    // init use state for render login page form ui
    const [loginUi,setLoginUi] = useState("logInForm"); 
    // function to hanle login page form ui
    const uiHandler = () => {
        if(loginUi === "logInForm"){
            return <LogInForm setLoginUiHandler={setLoginUi}/>
        }else if(loginUi === "forgotPasswordForm"){
            return <ForgotPassword setLoginUiHandler={setLoginUi}/>
        }
    }

    // check if the user is already logged in
    useEffect(() => {
        // check if user is logged in
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}user/login`,{ withCredentials: true })
        .then((res) => {
            if(res.status === 200){
                dispatch(setUser(res.data));
            }else{
                console.log(res)
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch((err) => {
            dispatch(setSpinner(false));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        })
        // eslint-disable-next-line
      },[]);
      
    return (
        <div>
            <div className={style.formSection}>
                <Paper elevation={16}>
                    {uiHandler()}
                </Paper>
            </div>
        </div>
    );
}

export default LogIn;