import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { MenuItem, Select, Autocomplete } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import style from '../../css/AutocompleteWithMoreAED.module.css'
import { selectFieldStyle,autocompleteStyle } from '../../js/standardStyle';
import { renderDisplayValueForAutocomplete } from '../../js/util';

function AutocompleteWithMoreAED(props) {
    const ITEM_HEIGHT = 48;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleMenuItemClick = key => event => {
        if(key === "add"){
            props.addHandler();
        }else if(key === "edit"){
            props.editHandler();
        }else if(key === "delete"){
            props.deleteHandler();
        }
        setAnchorEl(null);
    };

    return (
        <div>
            <div className={style.mainSection}>
                <Autocomplete className={style.selectFieldSection} value={props.value} sx={autocompleteStyle.sx} getOptionLabel={props.getOptionLabel} isOptionEqualToValue={props.isOptionEqualToValue} ListboxProps={autocompleteStyle.ListboxProps} options={props.options} size="small" disablePortal onChange={props.onChange} renderInput={renderDisplayValueForAutocomplete} />
                <IconButton className={style.iconSection}
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon className='icon' />
                </IconButton>
            </div>
            <Menu
                id="long-menu"
                MenuListProps={{
                'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                },
                }}
            >
                <MenuItem onClick={handleMenuItemClick("add")}>Add</MenuItem>
                <MenuItem onClick={handleMenuItemClick("edit")}>Edit</MenuItem>
                {props.ignore!==undefined && props.ignore.includes('delete') ? "" : <MenuItem onClick={handleMenuItemClick("delete")}>Delete</MenuItem>}
            </Menu>
    </div>
    );
}

export default AutocompleteWithMoreAED;