import React from 'react';
import {Typography,Box,Modal,Button,Divider} from '@mui/material';
import { modalStyle } from '../../js/standardStyle';

function ModalConfirmation(props) {
    return (
        // modal wrapper element for displaying popups with pre defined style
        <Modal open={props.open} onClose={props.closeHandler}>
            <Box sx={modalStyle} className="p20">
                <Typography className='cDark' id="modal-modal-title" variant="h6" component="h2">
                    {props.content}
                </Typography>
                <Divider className='mtb20' />
                <div style={{textAlign:"right"}}>
                    <Button className='mlr5 roTextButton' onClick={props.closeHandler} variant="text">cancel</Button>
                    <Button className='mlr5 roButton' onClick={props.confirmationHandler} variant="contained"> confirm </Button>
                </div>
            </Box>
        </Modal>
    );
}

export default ModalConfirmation;