import React, { useEffect, useState } from 'react';
import HeaderSection from '../HeaderSection';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import ModalViewInfo from '../wrapper/ModalViewInfo';
import IssueTrackerFormForModal from './IssueTrackerFormForModal';
import DataGridCustom from '../wrapper/DataGridCustom';
import { autocompleteStyle, textFieldStyle } from '../../js/standardStyle';
import { renderDisplayValueForAutocomplete, renderDisplayValueWithLableForAutocomplete, getErrorMsg, getIdOrNullOfObject, getKeyOrNullOfObject, getNameBasedOnValue } from '../../js/util';
import style from '../../css/IssueTracker.module.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { DATE_FORMAT, issueDropDown } from '../../js/constants';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { SERVICE_URL } from '../../config';
import { toastUp } from '../../redux/actions/toast';
import { setSpinner } from '../../redux/actions/spinner';
import ViewIssueDetailsForModal from './ViewIssueDetailsForModal';


function IssueTracker(props) {

    const dispatch = useDispatch();
    const loggedInUser = useSelector(state=>state.loggedInUser);
    // init use state for form data
    const [formData, setFormData] = useState({"manager":null,"pmoOffice":"","projectArea":null,"groupBy":'All Issues','project':{"id":"","name":"All Projects"},"search":null,"searchField":{"text":"","date":null,"user":null,"status":null,"priority":null}}); 
    // init use state for form meta data
    const [formOptionsMetaData, setFormOptionsMetaData] = useState({"projectArea":[],"manager":[],"groupBy":['All Issues', 'Created By Me', 'My Tasks'],'project':[],"search":["ID","Name","Created Date","Created By","Modified Date","Modified By","Assigned To","Status","Priority","Progress","Due Date"],"searchField":{"user":[],"status":[...issueDropDown["status"]],"priority":[...issueDropDown["priority"]]}});
    // function to set form data for auto complete
    const handleFormDataAutoCompleteChange = key => (event,object) => {
        var temp = formData;
        if(key === "search"){
            temp["searchField"]["date"] = null;
            temp["searchField"]["priority"] = null;
            temp["searchField"]["status"] = null;
            temp["searchField"]["user"] = null;
            temp["searchField"]["text"] = "";
        }
        if(object === null){
            if(key === 'groupBy'){
                object = "All Issues";
            }else if(key === 'project'){
                object = {"id":"","name":"All Projects"};
            }
        }
        var value = object;

        if(["pmoOffice"].includes(key)){
            value = event.target.value;
        }
        
        temp[key] = value;
        setFormData({...temp});
        if(!["pmoOffice"].includes(key)){
            applyFilter();
        }
    }
    const [selectedFilter, setSeletedFilter] = useState("");
    const applySearch = (event) => {
        var code = event.keyCode || event.which;
        if(code === 13) {
            applyFilter();
        } 
    }
    const applyFilter = () => {
        var filter = "";
        if(formData["project"] === null){
            filter+= `&projectId=${getIdOrNullOfObject(formData["project"])}`;
        }
        if(formData["groupBy"] === "Created By Me"){
            filter+= `&assignedBy=${loggedInUser["id"]}`;
        }
        if(formData["groupBy"] === "My Tasks"){
            filter+= `&assignedTo=${loggedInUser["id"]}`;
        }
        if(formData["manager"] !== null){
            filter+= `&projectManager=${getIdOrNullOfObject(formData["manager"])}`;
        } 
        if(formData["pmoOffice"] !== ""){
            filter+= `&pmoOffice=${formData["pmoOffice"]}`;
        }
        if(formData["projectArea"] !== null){
            filter+= `&projectArea=${getIdOrNullOfObject(formData["projectArea"])}`;
        }
        if(formData["search"] === "ID" && formData["searchField"]["text"] !== ""){
            filter+= `&id=${formData["searchField"]["text"]}`;
        }
        if(formData["search"] === "Name" && formData["searchField"]["text"] !== ""){
            filter+= `&name=${formData["searchField"]["text"]}`;
        }
        if(formData["search"] === "Progress" && formData["searchField"]["text"] !== ""){
            filter+= `&progress=${formData["searchField"]["text"]}`;
        }
        if(formData["search"] === "Priority" && formData["searchField"]["priority"] !== null){
            filter+= `&priority=${getKeyOrNullOfObject(formData["searchField"]["priority"],'value')}`;
        }
        if(formData["search"] === "Status" && formData["searchField"]["status"] !== null){
            filter+= `&status=${getKeyOrNullOfObject(formData["searchField"]["status"],'value')}`;
        }
        if(formData["search"] === "Created By" && formData["searchField"]["user"] !== null){
            filter+= `&assignedBy=${getIdOrNullOfObject(formData["searchField"]["user"])}`;
        } 
        if(formData["search"] === "Modified By" && formData["searchField"]["user"] !== null){
            filter+= `&modifiedBy=${getIdOrNullOfObject(formData["searchField"]["user"])}`;
        } 
        if(formData["search"] === "Assigned To" && formData["searchField"]["user"] !== null){
            filter+= `&assignedTo=${getIdOrNullOfObject(formData["searchField"]["user"])}`;
        }
        if(formData["search"] === "Due Date" && formData["searchField"]["date"] !== null){
            filter+= `&dueDate=${formData["searchField"]["date"].format(DATE_FORMAT)}`;
        }
        if(formData["search"] === "Created Date" && formData["searchField"]["date"] !== null){
            filter+= `&createdDate=${formData["searchField"]["date"].format(DATE_FORMAT)}`;
        } 
        if(formData["search"] === "Modified Date" && formData["searchField"]["date"] !== null){
            filter+= `&modifiedDate=${formData["searchField"]["date"].format(DATE_FORMAT)}`;
        }
        setSeletedFilter(filter);
        setPageState((old)=>({...old,page:0}));
    }
    // function to set search data for auto complete
    const handleSerchDataChange = key => (event,object) => {
        var temp = formData;
        var value = object;
        if(key === "date"){
            value = event;
        }else if(key === "text"){
            value = event.target.value;
        }
        temp["searchField"][key] = value;
        setFormData({...temp});
        if(key !== 'text'){
            applyFilter();
        }
    }
    // init use state handle page for table
    const [pageState, setPageState] = useState({
        page: 0,
        pageSize: 30
    })
    // init use state to store table contents
    const [tableContent,setTableContent] = useState({
        isLoading: false,
        data: [],
        total: 0,
    }) 
    const getStatus = (params) => {
        var displayValue = "";
        if(params.row.onHold){
            displayValue = "Paused";
        }else{
            displayValue = getNameBasedOnValue(issueDropDown["status"],params.value)
        }
        return <div className={`MuiDataGrid-cellContent`} title={displayValue}>{displayValue}</div>
    }
    // init table columns
    const columns = [
    { field: 'id', sortable: false, width: 100, renderHeader: () => (<strong>{'ID'}</strong>) },
    { field: 'name', sortable: false, flex: 1, renderHeader: () => (<strong>{'Name'}</strong>), renderCell: (params) => (<div className={`MuiDataGrid-cellContent hyperLinkActive`} title={params.value} onClick={()=>{openViewIssueModal(params.id)}}>{params.value}</div>) },
    { field: 'project', sortable: false, flex: 1, renderHeader: () => (<strong>{'Project'}</strong>) },
    { field: 'modifiedDate', sortable: false, flex: 1, renderHeader: () => (<strong>{'Modified Date'}</strong>) },
    { field: 'modifiedBy', sortable: false, flex: 1, renderHeader: () => (<strong>{'Modified By'}</strong>) },
    { field: 'assignedTo', sortable: false, flex: 1, renderHeader: () => (<strong>{'Assigned To'}</strong>) },
    { field: 'status', sortable: false, flex: 1, renderHeader: () => (<strong>{'Status'}</strong>), renderCell: getStatus },
    ];
    const [viewIssueModal, setViewIssueModal] = useState({"isOpen":false,"issueDetail":{}});
    const openViewIssueModal = (id) => {
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}issue?id=${id}`)
        .then((res)=>{
            var temp = viewIssueModal;
            temp["isOpen"] = true;
            temp["issueDetail"] = res["data"];
            setViewIssueModal({...temp});
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
    }
    const closeViewIssueModal = () => {
        var temp = viewIssueModal;
        temp["isOpen"] = false;
        temp["issueDetail"] = {};
        setViewIssueModal({...temp});
    }
    // use state for issue form modal
    const [issueFormModal,setIssueFormModal] = useState({"isOpen":false,"mode":"","issueDetails":{}});
    // function to open issue form modal handler
    const openIssueFormModal = () => {
        var temp = issueFormModal;
        temp["isOpen"] = true;
        temp["mode"] = "add";
        setIssueFormModal({...temp});
    }
    // function to close issue form modal handler
    const closeIssueFormModal = () => {
        var temp = issueFormModal;
        temp["isOpen"] = false;
        temp["mode"] = "";
        temp["issueDetails"] = {};
        setIssueFormModal({...temp});
        fetchData();
    }
    const editIssue = () => {
        var temp = issueFormModal;
        temp["isOpen"] = true;
        temp["mode"] = "edit";
        temp["issueDetails"] = viewIssueModal["issueDetail"];
        setIssueFormModal({...temp});
        closeViewIssueModal();
    }
    const changeOnHoldForProject = (id,value) => {
        dispatch(setSpinner(true));
        var data = {};
        data["onHold"] = value;
        axios.put(`${SERVICE_URL}/issue/onHold?id=${id}`,data)
        .then((res)=>{
            dispatch(toastUp(`Issue ${value}d successfully`));
            closeViewIssueModal();
            fetchData();
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
    }
    const fetchData = async () => {
        setTableContent(old => ({ ...old, isLoading: true }));
        axios.get(`${SERVICE_URL}issue/view?page=${pageState.page+1}&size=${pageState.pageSize}&companyId=${loggedInUser["company_id"]}${selectedFilter}`)
        .then(res=>{
            var data = res.data;
            var temp = tableContent;
            temp["isLoading"] = false;
            temp["data"] = data["data"];
            temp["total"] = data["totalCount"];
            setTableContent({...temp});
        }).catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
            setTableContent(old => ({ ...old, isLoading: false }));
        })
    }
    useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[pageState.page,pageState.pageSize,selectedFilter])
    useEffect(()=>{
        
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}user?groups=rbsu&companyId=${loggedInUser["company_id"]}`)
        .then((res)=>{
            var data = res["data"];
            var updatedFormOptionsMetaData = formOptionsMetaData;
            updatedFormOptionsMetaData["manager"] = data["data"];
            setFormOptionsMetaData({...updatedFormOptionsMetaData});
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })

        axios.get(`${SERVICE_URL}issue/project/view?companyId=${loggedInUser["company_id"]}`)
        .then((res)=>{
            var temp = formOptionsMetaData;
            var options = [{"id":"","name":"All Projects"}];
            options.push(...res["data"]["data"]);
            temp["project"] = options;
            setFormOptionsMetaData({...temp});
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })

        axios.get(`${SERVICE_URL}user?groups=rbsu,rbr,external,local_admin,lgsa_backup,global_super_admin&companyId=${loggedInUser["company_id"]}`)
        .then((res)=>{
            var data = res["data"];
            var temp = formOptionsMetaData;
            temp["searchField"]["user"] = data["data"];
            setFormOptionsMetaData({...temp});
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })

        axios.get(`${SERVICE_URL}risk/company/business/unit/companyId?companyId=${loggedInUser["company_id"]}`)
        .then((res)=>{
            var temp = formOptionsMetaData;
            temp["projectArea"] = res["data"];
            setFormOptionsMetaData({...temp});
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
        // eslint-disable-next-line
    },[])
  
    return (
        <div>
            {/* selected menu details */}
            <HeaderSection header='Project Issue' breadcrumbs={["Issue Management","Project Issue"]} specialButtons={[<Button onClick={openIssueFormModal} className='riskOneGreenBtn' size="small" variant="contained">add</Button>]} />
            <div className='m20'>
                <div className={`plr10 ptb30 ${style.filterSection}`}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item sm={6} md={6} lg={4}>
                                    <Autocomplete value={formData["groupBy"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["groupBy"]} size="small" disablePortal onChange={handleFormDataAutoCompleteChange("groupBy")} renderInput={renderDisplayValueForAutocomplete} />
                                </Grid>
                                <Grid item sm={6} md={6} lg={4}>
                                    <Autocomplete value={formData["project"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["project"]} size="small" disablePortal onChange={handleFormDataAutoCompleteChange("project")} getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.id === value.id} renderInput={renderDisplayValueForAutocomplete} />
                                </Grid>
                                <Grid item sm={6} md={6} lg={4}>
                                    <Autocomplete value={formData["manager"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["manager"]} size="small" disablePortal onChange={handleFormDataAutoCompleteChange("manager")} getOptionLabel={(option) => option.name+" ("+option.email+")"} isOptionEqualToValue={(option, value) => option.id === value.id} renderInput={renderDisplayValueWithLableForAutocomplete("Project Manager")} />
                                </Grid>
                                <Grid item sm={6} md={6} lg={6}>
                                    <TextField onKeyPress={applySearch} label='PMO Office' value={formData["pmoOffice"]} onChange={handleFormDataAutoCompleteChange("pmoOffice")} sx={textFieldStyle} fullWidth size="small" variant="outlined" />
                                </Grid>
                                <Grid item sm={12} md={12} lg={6}>
                                    <Autocomplete value={formData["projectArea"]} onChange={handleFormDataAutoCompleteChange("projectArea")} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["projectArea"]} size="small" disablePortal getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.id === value.id} renderInput={renderDisplayValueWithLableForAutocomplete("Business Unit Area")}  />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <Grid container spacing={2} justifyContent={"flex-end"}>
                                <Grid item sm={6} md={5} lg={4}>
                                    <Autocomplete value={formData["search"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["search"]} size="small" disablePortal onChange={handleFormDataAutoCompleteChange("search")} renderInput={renderDisplayValueWithLableForAutocomplete("Filter")} />
                                </Grid>
                                <Grid item sm={6} md={6} lg={5}>
                                    <TextField onKeyPress={applySearch} style={{display: (["ID","Name","Progress"].includes(formData["search"]) ? "block" : "none")}} type={(["ID","Progress"].includes(formData["search"]) ? 'number' : "text")} label='Please Enter' value={formData["searchField"]["text"]} onChange={handleSerchDataChange("text")} sx={textFieldStyle} fullWidth size="small" variant="outlined" />
                                    <div style={{display: (["Created Date","Modified Date","Due Date"].includes(formData["search"]) ? "block" : "none")}}>
                                        <LocalizationProvider size="small" dateAdapter={AdapterDayjs}>
                                            <DatePicker value={formData["searchField"]["date"]} onChange={handleSerchDataChange("date")} slotProps={{ textField: { size: 'small',fullWidth: true, sx:textFieldStyle } }} />
                                        </LocalizationProvider>
                                    </div>
                                    <Autocomplete style={{display: (["Created By","Modified By","Assigned To"].includes(formData["search"]) ? "block" : "none")}} value={formData["searchField"]["user"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["searchField"]["user"]} size="small" disablePortal onChange={handleSerchDataChange("user")} getOptionLabel={(option) => option.name+" ("+option.email+")"} isOptionEqualToValue={(option, value) => option.id === value.id} renderInput={renderDisplayValueWithLableForAutocomplete("Please Select")} />
                                    <Autocomplete style={{display: (["Priority"].includes(formData["search"]) ? "block" : "none")}} value={formData["searchField"]["priority"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["searchField"]["priority"]} size="small" getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.value === value.value} disablePortal onChange={handleSerchDataChange("priority")} renderInput={renderDisplayValueWithLableForAutocomplete("Please Select")} />
                                    <Autocomplete style={{display: (["Status"].includes(formData["search"]) ? "block" : "none")}} value={formData["searchField"]["status"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["searchField"]["status"]} size="small" getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.value === value.value} disablePortal onChange={handleSerchDataChange("status")} renderInput={renderDisplayValueWithLableForAutocomplete("Please Select")} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <DataGridCustom rows={tableContent.data} rowCount={tableContent.total} isLoading={tableContent.isLoading} pageSizeOptions={[30,50,100]} paginationModel={pageState} onPaginationModelChange={setPageState} columns={columns} combineSearch={true} />
                </div>
            </div>
            <ModalViewInfo open={issueFormModal.isOpen} closeHandler={closeIssueFormModal}>
                <IssueTrackerFormForModal closeHandler={closeIssueFormModal} mode={issueFormModal.mode} issueDetail={issueFormModal.issueDetails}/>
            </ModalViewInfo>
            <ModalViewInfo open={viewIssueModal.isOpen} closeHandler={closeViewIssueModal}>
                <ViewIssueDetailsForModal issueDetail={viewIssueModal.issueDetail} editIssueHandler={editIssue} changeOnHoldForProject={changeOnHoldForProject}/>
            </ModalViewInfo>
        </div>
    )
};
 
export default IssueTracker;