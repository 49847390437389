import React from 'react';
import {Box,Toolbar} from '@mui/material';
import Nav from './Nav';

function Layout(props) {
    // setting drawer width it will adjust the drawer with and main content with accordingly
    const drawerWidth = 250;
    return (
        <Box sx={{ display: 'flex' }}>
            {/* // calling nav component where the menu are difined */}
            <Nav drawerWidth={drawerWidth} {...props}/>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                {/* // getting main page content which passed as children prop */}
                {props.children}
            </Box>
        </Box>
    );
}

export default Layout;