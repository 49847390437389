import React from "react"
import ReactQuill from "react-quill"

function undoChange() {
    this.quill.history.undo();
}

function redoChange() {
    this.quill.history.redo();
}

const CustomToolbar = ({id = 'toolbar'}) => (
  <div id={id}>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
    </span>
    <span className="ql-formats">
      <select className="ql-align" />
      <select className="ql-color" />
      <select className="ql-background" />
      <button className="ql-blockquote" />
    </span>
    <span className="ql-formats">
      <select className="ql-size" defaultValue="medium">
        <option value="small">Small</option>
        <option value="medium">Normal</option>
        <option value="large">Large</option>
        <option value="huge">Huge</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-clean" />
    </span>
  </div>
)

/**
 * Clase principal
 * 
 * @class QuillTextEditor
 * @extends {React.Component}
 */

class QuillTextEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = { text: '' }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    this.setState({ text: value })    
    // this.props.onChange(this.state.text)
  }

  render() {
    return (
      <div>
        <CustomToolbar id={this.props.name} />
        <ReactQuill value={this.props.value}
          theme="snow"
          placeholder={this.props.placeholder}
          modules={QuillTextEditor.modules(this.props.name)}
          onChange={this.props.onChange}
        />
      </div>
    )
  }
}

QuillTextEditor.modules = (toolbarId) => ({
  toolbar: {
    container: "#" + toolbarId,
    handlers: {
        undo: undoChange,
        redo: redoChange
    }
  }
})

QuillTextEditor.formats = [
    "header",
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "script",
    "blockquote",
    "background",
    "list",
    "bullet",
    "color",
]

export default QuillTextEditor;