import React, { useState, useEffect } from 'react';
import HeaderSection from '../HeaderSection';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { toastUp } from '../../redux/actions/toast';
import DataGridEmail from '../wrapper/DataGridEmail';
import { getErrorMsg, removeHtmlContent } from '../../js/util';
import { SERVICE_URL } from '../../config';
import { Typography, Grid, Button, Divider } from '@mui/material';
import style from '../../css/EmailInbox.module.css'
import InboxIcon from '@mui/icons-material/InboxOutlined';
import SendIcon from '@mui/icons-material/SendOutlined';
import DraftsIcon from '@mui/icons-material/DraftsOutlined';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import CreateIcon from '@mui/icons-material/CreateOutlined';
import ModalViewInfo from '../wrapper/ModalViewInfo';
import ComposeForModal from './ComposeForModal';

function EmailInbox(props) {

    const dispatch = useDispatch();
    // init use state handle page for table
    const loggedInUser = useSelector(state=>state.loggedInUser)
    const {pageState,setPageState,setSelectedEmailId,setEmailUi,emailInboxMenu,setEmailInboxMenu} = props;
    // init use state to store table contents
    const [tableContent,setTableContent] = useState({
        isLoading: false,
        data: [],
        total: 0,
    })
    // init table column details
    const columns = [
        {field: 'createdBy', sortable: false, width: 250, renderCell: (params)=>(<div><Typography>{params.value}</Typography></div>)},
        {field: 'recentMail', sortable: false, flex: 1, renderCell: (params)=>(<div><Typography>{params.row.header} - {removeHtmlContent(params.value)}</Typography></div>)},
        {field: 'lastModified', sortable: false, width: 125, renderCell: (params)=>(<div style={{textAlign:"right",width:"100%"}}>{params.value}</div>)}
    ]
    const fetchData = async () => {
        if(["deleted","draft"].includes(emailInboxMenu)){
            var temp = tableContent;
            temp["data"] = [];
            temp["total"] = 0;
            setTableContent({...temp});
            return false
        }
        var filter = "";
        if(emailInboxMenu === "sent"){
            filter+="&groupBy=sent";
        }
        setTableContent(old => ({ ...old, isLoading: true }))
        axios.get(`${SERVICE_URL}email/inbox?${pageState.page+1}&userId=${loggedInUser["id"]}${filter}`)
        .then(res=>{
            var data = res.data;
            var temp = tableContent;
            temp["isLoading"] = false;
            temp["data"] = data["data"];
            temp["total"] = data["total"];
            setTableContent({...temp});
        }).catch(err=>{
            dispatch(toastUp(getErrorMsg(err)));
            setTableContent(old => ({ ...old, isLoading: false }));
        })
    }
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [pageState.page, pageState.pageSize,emailInboxMenu])
    const openEmailThread = (params) => {
        setSelectedEmailId(params.id);
        setEmailUi("view")
    }
    const menuChange = (value) => {
        setEmailInboxMenu(value);
        setPageState(old=>({...old,"page":0}));
    }
    const [compose,setCompose] = useState({"isOpen":false})
    const openCompose = () => {
        setCompose(old=>({...old,"isOpen":true}));
    }
    const closeCompose = () => {
        setCompose(old=>({...old,"isOpen":false}));
    }
    const closeComposeAndRefreshTable = () => {
        closeCompose();
        fetchData();
    }
    return (
        <div>
            <HeaderSection header='Email' breadcrumbs={["Email","Inbox"]}/>
            <Grid container>
                <Grid className='mt20 mr10' style={{width:"160px"}}>
                    <div className='mtb10'><Button onClick={openCompose} variant='contained' className={`${style.compose}`} size='small' startIcon={<CreateIcon/>}>compose</Button></div>
                    <div className='mtb20'><Divider/></div>
                    <div className='mtb10'><Button variant='text' className={`${style.mailMenu} ${(emailInboxMenu === 'inbox' ? style.selectedMenu : "")}`} size='small' startIcon={<InboxIcon/>} onClick={()=>menuChange("inbox")}>inbox</Button></div>
                    <div className='mtb10'><Button variant='text' className={`${style.mailMenu} ${(emailInboxMenu === 'sent' ? style.selectedMenu : "")}`} size='small' startIcon={<SendIcon/>} onClick={()=>menuChange("sent")}>sent</Button></div>
                    <div className='mtb10'><Button variant='text' className={`${style.mailMenu} ${(emailInboxMenu === 'draft' ? style.selectedMenu : "")}`} size='small' startIcon={<DraftsIcon/>} onClick={()=>menuChange("draft")}>draft</Button></div>
                    <div className='mtb10'><Button variant='text' className={`${style.mailMenu} ${(emailInboxMenu === 'deleted' ? style.selectedMenu : "")}`} size='small' startIcon={<DeleteIcon/>} onClick={()=>menuChange('deleted')}>deleted</Button></div>
                </Grid>
                <Grid className='m20' style={{width:"calc(100% - 220px"}}>
                    <DataGridEmail rows={tableContent.data} rowCount={tableContent.total} isLoading={tableContent.isLoading} pageSizeOptions={[30,50,100]} paginationModel={pageState} onPaginationModelChange={setPageState} columns={columns} onRowClick={openEmailThread}/>
                </Grid>
            </Grid>
            <ModalViewInfo open={compose.isOpen} closeHandler={closeCompose}>
                <ComposeForModal closeHandler={closeCompose} closeComposeAndRefreshTable={closeComposeAndRefreshTable}/>
            </ModalViewInfo>
        </div>
    );
}

export default EmailInbox;