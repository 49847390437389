import React, {useState,useEffect} from 'react';
import DataGridCustomFull from '../wrapper/DataGridCustomFull';
import { SERVICE_URL } from '../../config';
import axios from 'axios';
import { Paper } from '@mui/material';
import { getErrorMsg } from '../../js/util';
import { useDispatch, useSelector } from 'react-redux';
import { toastUp } from '../../redux/actions/toast';

function MyAssessmentManager(props) {
    
    // get use dispatch from redux
    const dispatch = useDispatch();
    // get loggedin user details
    const loggedInUser = useSelector(state => state.loggedInUser);

    // init use state handle page for table
    const [pageState, setPageState] = useState({
        page: 0,
        pageSize: 30
    })
    // init use state to store table contents
    const [tableContent,setTableContent] = useState({
        isLoading: false,
        data: [],
        total: 0,
    })  
    // init table column details
    const columns = [
        {field: 'name', sortable: false, flex: 1, renderHeader: () => (<strong>{'Assessment Name'}</strong>)},
        {field: 'framework', sortable: false, flex: 1, renderHeader: () => (<strong>{'Framework Source'}</strong>)},
        {field: 'category', sortable: false, flex: 1, renderHeader: () => (<strong>{'Risk Category'}</strong>)},
        {field: 'type', sortable: false, flex: 1, renderHeader: () => (<strong>{'Assessment Type'}</strong>)},
        {field: 'assigned_to', sortable: false, flex: 1, renderHeader: () => (<strong>{'Assigned To'}</strong>)}
    ]

    // use effect to call the function when ever the page changes 
    useEffect(() => {
        const fetchData = async () => {
            setTableContent(old => ({ ...old, isLoading: true }))
            axios.get(`${SERVICE_URL}assessment/register/view?page=${pageState.page+1}&size=${pageState.pageSize}&assignedBy=${loggedInUser['id']}`)
            .then(res=>{
                var data = res.data;
                var temp = tableContent;
                temp["isLoading"] = false;
                temp["data"] = data["data"];
                temp["total"] = data["total"];
                setTableContent({...temp});
            }).catch(err=>{
                dispatch(toastUp(getErrorMsg(err)));
                setTableContent(old => ({ ...old, isLoading: false }));
            })
        }
        fetchData();
        // eslint-disable-next-line
    }, [pageState.page, pageState.pageSize])

    return (
        <div>
            <div className='m20'>
                <Paper elevation={16}>
                    <DataGridCustomFull rows={tableContent.data} rowCount={tableContent.total} isLoading={tableContent.isLoading} pageSizeOptions={[30,50,100]} paginationModel={pageState} onPaginationModelChange={setPageState} columns={columns}/>
                </Paper>
            </div>
        </div>
    );
}

export default MyAssessmentManager;