export const assessmentRegisterStatus = [{'name':'Pending For Response','value':'pendingForResponse'}, {'name':'Accepted','value':'assigned'}, {'name':'Rejected','value':'rejected'}, {'name':'Inquired','value':'inquired'}, {'name':'Completed','value':'completed'}]

export const policyDropDown = {
    "regulatoryCitation": [{"value":"cjis","name":"CJIS"},{"value":"coso","name":"COSO"},{"value":"cobit","name":"COBIT"},{"value":"csaccm","name":"CSACCM"},{"value":"cis-cscsans-20","name":"CIS-CSCSANS-20"},{"value":"euus_privacy_shield","name":"EUUS Privacy Shield"},{"value":"fedramp_high","name":"FeDRAMP HIGH"},{"value":"fedramp_lowmod","name":"FedRAMP LOWMOD"},{"value":"gdpr","name":"GDPR"},{"value":"hipaa","name":"HIPAA"},{"value":"hitruts","name":"HiTrust"}],
    "standard": [{"value":"iso_27001_isms","name":"ISO 27001 ISMS"},{"value":"iso_27001_appa","name":"ISO 27001 AppA"},{"value":"iso_27001_appendix_a","name":"ISO 27001 Appendix A"},{"value":"iso_27017","name":"ISO 27017"},{"value":"iso_27018","name":"ISO 27018"}],
    "severity":[{"value":"highCritical","name":"High - Critical"},{"value":"severeHigh","name":"Severe - High"},{"value":"moderateMedium","name":"Moderate - Medium"},{"value":"lowNegligible","name":"Low - Negligible"}],
    "situation":[{"value":"open","name":"Open"},{"value":"pending","name":"Pending"},{"value":"approved","name":"Approved"}],
    "status":[{"value":"pendingForApprove","name":"Pending For Approval"},{"value":"approved","name":"Approved"},{"value":"rejected","name":"Rejected"},{"value":"deleted","name":"Deleted"}]
}

export const powerUserGroup = ['global_super_admin', 'lgsa_backup']

export const issueDropDown = {
    "status":[{"value":"open","name":"Open"}, {"value":"active","name":"Active"}, {"value":"completed","name":"Completed"},{"value":"closed","name":"Closed"}],
    "priority":[{"value":"critical","name":"Critical"}, {"value":"high","name":"High"}, {"value":"medium","name":"Medium"}, {"value":"low","name":"Low"}]
}

export const projectDropDown = {
    "scale":[{ score: 1, name: "Low", value: "low" },{ score: 2, name: "Medium", value: "medium" },{ score: 3, name: "High", value: "high"}],
    "status":[{"value":"open","name":"Open"},{"value":"active","name":"Active"},{"value":"completed","name":"Completed"},{"value":"closed","name":"Closed"}]
}

export const ynDropDown=[{"name":"Yes","value":"yes"},{"name":"No","value":"no"}];

export const ACTION_PLAN_DROPDOWN = {
    "priority":[{ score: 1, name: "Low", value: "low" },{ score: 2, name: "Medium", value: "medium" },{ score: 3, name: "High", value: "high"}],
    "status":[{ score: 1, name: "Open", value: "open" },{ score: 2, name: "Active", value: "active" },{ score: 3, name: "Completed", value: "completed" },{ score: 4, name: "Closed", value: "closed" }]
};

export const DATE_FORMAT = 'YYYY-MM-DD';