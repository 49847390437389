import React from 'react';
import {Snackbar} from '@mui/material';
import { snackbarStyle } from '../../js/standardStyle';

function Toast(props) {
    return (
        // snackbar wrapper element for toast up custom msg with pre defined style
        <Snackbar
            anchorOrigin={{vertical:"bottom",horizontal:"center"}}
            open={props.open}
            autoHideDuration={3000}
            onClose={props.closeHandler}
            message={props.message}
            style= {{textAlign: 'center'}}
            sx={snackbarStyle}
      />
    );
}

export default Toast;