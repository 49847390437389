import React, { useEffect, useState } from 'react';
import { Button, Typography, Grid, TextField, LinearProgress, Autocomplete } from '@mui/material';
import FieldsetCustom from '../../wrapper/FieldsetCustom';
import { textFieldStyle, autocompleteStyle } from '../../../js/standardStyle';
import dayjs from 'dayjs';
import { getArrayOfObjectBasedOnKey, getKeyBasedOnValue, getNameBasedOnValue, getValueForPriorityScore, getValueForStatusScore, renderDisplayValueWithLableForAutocomplete } from '../../../js/util';
import { ACTION_PLAN_DROPDOWN } from '../../../js/constants';
import { useDispatch } from 'react-redux';
import { toastUp } from '../../../redux/actions/toast';
import { setSpinner } from '../../../redux/actions/spinner';
import axios from 'axios';
import { getErrorMsg } from '../../../js/util';
import { SERVICE_URL } from '../../../config';
import { getPriorityStyleForActionPlan, getStatusStyleForActionPlan } from '../../../js/standardStyle';

function ActionPlanEditForModal(props) {
    const {closeHandler} = props;
    const {actionPlanData} = props;
    const dispatch = useDispatch();
    const [actionPlan,setActionPlan] = useState({"name":"","owner":"","priority":"","status":"","timeline":"","progress":0});
    const [actionStep,setActionStep] = useState([]);
    useEffect(()=>{
        let temp = actionPlan;
        let startDate = null;
        let endDate = null;
        let priorityScore = 0;
        let statusScore = 0;
        temp["name"] = actionPlanData["name"]
        let completed = 0
        for(let [i,row] of actionPlanData["steps"].entries()){
            if(i === 0){
                if(row["owner"]!== null){
                    temp["owner"] = row["owner"]
                }else{
                    temp["owner"] = ""
                }
                startDate = dayjs(new Date(row["startDate"]))
                endDate = dayjs(new Date(row["endDate"]))
            }
            if(row["status"] === "completed"){
                completed+=1;
            }
            if(startDate > dayjs(new Date(row["startDate"]))){
                startDate = dayjs(new Date(row["startDate"]))
            }
            if(endDate < dayjs(new Date(row["endDate"]))){
                endDate = dayjs(new Date(row["endDate"]))
            }
            priorityScore += getKeyBasedOnValue(ACTION_PLAN_DROPDOWN["priority"],"value",row["priority"],"score");
            row["status"] = getArrayOfObjectBasedOnKey(ACTION_PLAN_DROPDOWN["status"],"value",row["status"]);
            statusScore += row["status"]["score"];
            
        }
        if(startDate !== null && endDate !== null){
            temp["timeline"] = `${startDate.format('MM/DD/YY')} - ${endDate.format('MM/DD/YY')}`
        }else{
            temp["timeline"] = ` - `
        }
        temp["progress"] = completed/actionPlanData["steps"].length*100
        temp["priority"] = getValueForPriorityScore(priorityScore/actionPlanData["steps"].length);
        temp["status"] = getValueForStatusScore(statusScore/actionPlanData["steps"].length);
        setActionPlan({...temp});
        setActionStep([...actionPlanData["steps"]]);
        // eslint-disable-next-line
    },[])
    const handleValueChangeForActionStep = (index,key) => (event,object) => {
        var temp = actionStep;
        var statusScore = 0;
        var statusMatch = 0;
        var completed = 0;
        for(var [i,row] of temp.entries()){
            if(i === index){
                var value =object
                row[key] = value;
            }
            if(row["status"] !== null){
                statusScore += row["status"]["score"];
                statusMatch+=1
                if(row["status"]["value"] === 'completed'){
                    completed+=1;
                }
            }
        }
        setActionPlan(old=>({...old,"status":getValueForStatusScore(statusScore/statusMatch),"progress":completed/temp.length*100}));
        setActionStep([...temp]);
        // populateStats(temp);
    }
    const generateActionSteps = (data) => {
        let htmlMarkUp= []
        for(let [i,row] of data.entries()){
            htmlMarkUp.push(
                <Grid container key={i} justifyContent="space-between" style={{border:"1px solid rgba(0, 0, 0, 0.12)",borderRadius:"4px"}} className='p10 mtb10' alignItems="center">
                    <Grid style={{minWidth:"150px"}} className='mtb5'>
                        <TextField value={row["name"]} disabled sx={textFieldStyle} fullWidth size='small' label={"Name"}/>
                    </Grid>
                    <Grid style={{minWidth:"150px"}} className='mtb5'>
                        <TextField value={row["owner"]} disabled sx={textFieldStyle} fullWidth size='small' label={"Owner"}/>
                    </Grid>
                    <Grid style={{minWidth:"150px"}} className='mtb5'>
                        <TextField multiline value={row["description"]} disabled sx={textFieldStyle} fullWidth size='small' label={"Description"}/>
                    </Grid>
                    <Grid style={{minWidth:"150px"}} className='mtb5'>
                        <TextField value={getNameBasedOnValue(ACTION_PLAN_DROPDOWN["priority"],row["priority"])} disabled sx={{...textFieldStyle,'.MuiInputBase-input':{...getPriorityStyleForActionPlan(row["priority"]),"padding": "2px 6px","margin": "6.5px 8px"}}} fullWidth size='small' label={"Priority"}/>
                    </Grid>
                    <Grid style={{minWidth:"150px"}} className='mtb5'>
                        <Autocomplete value={row["status"]} onChange={handleValueChangeForActionStep(i,"status")} options={ACTION_PLAN_DROPDOWN["status"]} sx={{...autocompleteStyle.sx,".MuiAutocomplete-input":getStatusStyleForActionPlan(row["status"])}} ListboxProps={autocompleteStyle.ListboxProps} disablePortal getOptionLabel={(option)=>option.name} isOptionEqualToValue={(selected,option)=>selected.value===option.value} size='small' renderInput={renderDisplayValueWithLableForAutocomplete("Status")}/>
                    </Grid>
                    <Grid style={{minWidth:"255px"}} className='mtb5'>
                        <TextField value={`${row["startDate"]} - ${row["endDate"]}`} disabled sx={textFieldStyle} fullWidth size='small' label={"Timeline"}/>
                    </Grid>
                </Grid>
            )
        }
        return htmlMarkUp;
    }
    const updateAtionPlan = () => {
        var temp = actionStep;
        var actionPlanSteps = [];
        for(var row of temp){
            if(row["status"] === null){
                dispatch(toastUp("Status field cannot be empty"));
                return true;
            }
            actionPlanSteps.push({
                "id":row["id"],
                "status":row["status"]["value"]
            })
        }
        var data={"steps":actionPlanSteps}
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}assessment/register/identifiedIssues/actionPlan?id=${actionPlanData["id"]}`,data)
        .then(res => {
            dispatch(toastUp("Action Plan updated successfully"));
            closeHandler();
        })
        .catch(err => {
            console.log(err)
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    return (
        <div style={{"height":"100%"}}>
            <Typography variant="h6" component="h2">Action Plan</Typography>
            <div className='m10' style={{height:"calc(100% - 125px)",overflowX:"hidden"}}>
                <div style={{width:"95%",margin:"auto"}}>
                    <FieldsetCustom legend={"ACTION PLAN"}>
                        <Grid className='mtb10' container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={2}>
                                <TextField value={actionPlan["name"]} disabled sx={textFieldStyle} fullWidth size='small' label={"Goal"}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={2}>
                                <TextField value={actionPlan["owner"]} disabled sx={textFieldStyle} fullWidth size='small' label={"Owner"}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                <TextField value={actionPlan["priority"]} disabled sx={{...textFieldStyle,'.MuiInputBase-input':{...getPriorityStyleForActionPlan(actionPlan["priority"]),"padding": "2px 6px","margin": "6.5px 8px"}}} fullWidth size='small' label={"Priority"}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                <TextField value={actionPlan["status"]} disabled sx={{...textFieldStyle,'.MuiInputBase-input':{...getStatusStyleForActionPlan(actionPlan["status"]),"padding": "2px 6px","margin": "6.5px 8px"}}} fullWidth size='small' label={"Status"}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                <TextField value={actionPlan["timeline"]} disabled sx={textFieldStyle} fullWidth size='small' label={"Timeline"}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                <div style={{display:"flex",height:"100%",justifyContent:"space-between",alignItems:"center"}}>
                                    <LinearProgress variant="determinate" value={actionPlan["progress"]} style={{width:"calc(100% - 110px)"}} />
                                    <Typography variant="body2" color="text.secondary">{actionPlan["progress"]} % Progress</Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <Typography className='mt20 mb10 legendColour'>ACTION STEPS</Typography>
                        {generateActionSteps(actionStep)}
                    </FieldsetCustom>
                </div>
            </div>
            <div className='mtb30 taCenter'>
                <Button variant='contained' className='mlr5' onClick={updateAtionPlan}>update</Button>
                <Button variant='text' onClick={closeHandler} className='mlr5'>close</Button>
            </div>
        </div>
    );
}

export default ActionPlanEditForModal;