export const setUiWithCustomProp = (payload) => {
    return {
        type:"setUiWithCustomProp",
        payload:payload
    }
}

export const resetCustomProp = () => {
    return {
        type:"resetCustomProp"
    }
}