import React from 'react';
import {Typography,Box,Modal,Button} from '@mui/material';
import { alertModalStyle } from '../../js/standardStyle';
import { useDispatch } from 'react-redux';
import { closeAlert } from '../../redux/actions/alert';

function ModalAlert(props) {
    // get use dispatch from redux
    const dispatch = useDispatch();
    // function to close modal alert
    const closeModalAlert = () => {
        dispatch(closeAlert());
    }

    return (
        // modal wrapper element for displaying popups with pre defined style
        <Modal open={props.open} onClose={closeModalAlert}>
            <Box sx={alertModalStyle} className="p20">
                <Typography style={{fontSize:"19px",fontStyle:"italic",color:"white"}} className='cDark' id="modal-modal-title" variant="h6" component="h2">
                    {props.content}
                </Typography>
                <div style={{textAlign:"right"}}>
                    <Button style={{color:"white"}} className='mlr5 roTextButton' onClick={closeModalAlert} variant="text">got it</Button>
                </div>
            </Box>
        </Modal>
    );
}

export default ModalAlert;