import './App.css';
import React from 'react';
import Layout from './components/Layout';
import RiskRegister from './components/risk/RiskRegister';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './js/standardStyle';
import SearchRisk from './components/risk/SearchRisk';
import ViewRisk from './components/risk/ViewRisk';
import MyAssessment from './components/assessment/MyAssessment';
import AssignAssessment from './components/assessment/AssignAssessment';
import NavigateAssessment from './components/assessment/NavigateAssessment';
import HistoricalAssessment from './components/assessment/HistoricalAssessment';
import MyAssessmentExam from './components/assessment/MyAssessmentExam';
import AcceptRejectViewAssessment from './components/assessment/AcceptRejectViewAssessment';
import LogIn from './components/login/LogIn';
import { useSelector, useDispatch } from 'react-redux';
import CreateUser from './components/user/CreateUser';
import Spinner from './components/wrapper/Spinner';
import Toast from './components/wrapper/Toast';
import { closeToast } from './redux/actions/toast';
import Policy from './components/policy/Policy';
import ModalAlert from './components/wrapper/ModalAlert';
import 'react-quill/dist/quill.snow.css';
import IssueTracker from './components/issueTracker/IssueTracker';
import ModalConfirmation from './components/wrapper/ModalConfirmation';
import { closeConfirmationModal } from './redux/actions/confirmationModal';
import ProjectFinancial from './components/issueTracker/ProjectFinancial';
import PortfolioManagement from './components/issueTracker/PortfolioManagement';
import ProjectSecurity from './components/issueTracker/ProjectSecurity';
import ProjectReport from './components/issueTracker/ProjectReport';
import IdentifiedIssues from './components/risk/manageRisk/IdentifiedIssues';
import EmailView from './components/email/EmailView';
import Email from './components/email/Email';

function App() {

  // get dispatch from redux
  const dispatch = useDispatch();

  // get toast for redux
  const snackbar = useSelector((state)=>state.toast);
  // close and reset the custom message of toast
  const closeSnackbarHandler = (event) => {
    dispatch(closeToast());
  };
  // close and reset the confirmation modal
  const closeConfirmationModalHandler = (event) => {
    dispatch(closeConfirmationModal());
  }

  // get spinner from redux
  const spinner = useSelector((state)=>state.spinner);

  // get display page state from redux
  const displayPage = useSelector(state => (state.displayPage));

  // get alert state from redux
  const modalAlert = useSelector(state => (state.alert));

  // get alert state from redux
  const confirmationModal = useSelector(state => (state.confirmationModal));

  // get custom prop from redux
  const customPropRedux = useSelector(state=>state.customProp);

  // get logged in user redux 
  const loggedInUserDetails = useSelector(state => (state.loggedInUser));
  // function to return equivalent component based on ui state
  const uiHandler = () => {

    var custom = {};
    if(displayPage === customPropRedux.page && Object.keys(customPropRedux.props).length !== 0){
        custom = customPropRedux.props;
    }

    if(displayPage === "riskRegister"){
        return <RiskRegister {...custom}/>;
    }else if(displayPage === "searchRisk"){
        return <SearchRisk />;
    }else if(displayPage === "viewRisk"){
        return <ViewRisk />
    }else if(displayPage === "myAssessment"){
        return <MyAssessment />
    }else if(displayPage === "assignAsessment"){
        return <AssignAssessment />
    }else if(displayPage === "navigateAssessment"){
        return <NavigateAssessment />
    }else if(displayPage === "historicalAssessment"){
        return <HistoricalAssessment />
    }else if(displayPage === "myAssessmentExam"){
        return <MyAssessmentExam {...custom} />
    }else if(displayPage === "acceptRejectViewAssessment"){
        return <AcceptRejectViewAssessment />
    }else if(displayPage === "createUser"){
        return <CreateUser />
    }else if(displayPage === "policy"){
        return <Policy {...custom} />
    }else if(displayPage === "issueTracker"){
        return <IssueTracker />
    }else if(displayPage === "projectFinancial"){
        return <ProjectFinancial />
    }else if(displayPage === "portfolioManagement"){
        return <PortfolioManagement />
    }else if(displayPage === "projectSecurity"){
        return <ProjectSecurity />
    }else if(displayPage === "projectReport"){
        return <ProjectReport />
    }else if(displayPage === "identifiedIssues"){
        return <IdentifiedIssues />    
    }else if(displayPage === "emailInbox"){
        return <Email/>
    }else{
        return <></>
    }

}

  return (
    // passing main page content to layout as children prop where the nav drawer is already defined in layout
    <ThemeProvider theme={theme}>
        {
            loggedInUserDetails ?
            <Layout>
                {uiHandler()}
            </Layout>
            :
            <LogIn/>
        }
        {/* adding toast for custom toast message  */}
        <Toast open={snackbar.open} closeHandler={closeSnackbarHandler} message={snackbar.message}/>
        {/* loading screen */}
        <Spinner open={spinner} />
        {/* custom alert */}
        <ModalAlert open={modalAlert.isOpen} content={modalAlert.msg}/>
        {/* delete confirmation modal */}
        <ModalConfirmation open={confirmationModal.isOpen} content={confirmationModal.content} closeHandler={closeConfirmationModalHandler} confirmationHandler={confirmationModal.confirmationHandler}/>
    </ThemeProvider>

    
    );

}

export default App;
