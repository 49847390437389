import React, { useState, useEffect, Fragment } from 'react';
import HeaderSection from '../HeaderSection';
import DataGridCustom from '../wrapper/DataGridCustom';
import axios from 'axios';
import { MenuItem, Grid, Autocomplete, TextField, RadioGroup, Radio, IconButton, Menu, FormControlLabel } from '@mui/material';
import { SERVICE_URL } from '../../config';
import { autocompleteStyle, getStyleForInherentRisk, textFieldStyle,  } from '../../js/standardStyle';
import { generateArrayForAutoCompleteBasedOnkey, getArrayOfObjectBasedOnKey, getIdOrNullOfObject, getKeyBasedOnValue, getKeyOrNullOfObject, getScoreForInherentRisk, replaceObjectInArrayBasedOnKey } from '../../js/util';
import FieldsetCustom from '../wrapper/FieldsetCustom';
import { getErrorMsg, renderDisplayValueForAutocomplete, renderDisplayValueWithLableForAutocomplete } from '../../js/util';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from '../../redux/actions/spinner';
import { toastUp } from '../../redux/actions/toast';
import style from '../../css/ProjectSecurity.module.css';
import AutocompleteWithMoreAED from '../wrapper/AutocompleteWithMoreAED';
import AutocompleteMultiWithMoreAED from '../wrapper/AutocompleteMultiWithMoreAED';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModalForm from '../wrapper/ModalForm';
import ModalFormMax from '../wrapper/ModalFormMax';
import { projectDropDown } from '../../js/constants';
import ModalViewInfo from '../wrapper/ModalViewInfo';
import ViewProjectDetailsForModal from './ViewProjectDetailsForModal';


function ProjectSecurity(props) {


    // get use dispatch from redux
    const dispatch = useDispatch();
    // get logged in user details
    const loggedInUser = useSelector(state => state.loggedInUser);
    // init use state for form data
    const [formData, setFormData] = useState({"name":""});
    // function to handle form value change
    const handleFormDataChange = key => (event, object) => {
        var temp = formData;
        temp[key] = object;
        setFormData({ ...temp });
        applyFilter();
    }
    const [formOptionsMetaData,setFormOptionsMetaData] = useState({"name":[]});
    // init use state for store filter details
    const [selectedFilter, setSelectedFilter] = useState("");
    // function to apply filter
    const applyFilter = () => {
        var filter = "";
        if(formData["name"] !== null){
            filter+=`&name=${formData["name"]}`;
        }
        setSelectedFilter(filter);
        setPageState((old)=>({...old,page:0}));
    }

    // init use state for project modal
    const [viewProjectModal,setViewProjectModal] = useState({"isOpen":false,"projectDetails":{}});

    // function to open view project modal
    const openViewProjectModal = (id) => {
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}issue/project?id=${id}`)
        .then((res)=>{
            var temp = viewProjectModal;
            temp["isOpen"] = true;
            temp["projectDetails"] = res["data"];
            setViewProjectModal({...temp});
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
    }

    // function to close project modal
    const closeViewProjectModal = () => {
        var temp = viewProjectModal;
        temp["isOpen"] = false;
        temp["projectDetails"] = {};
        setViewProjectModal({...temp});
    }


    // init use state for security objective modal
    const [projectSecurityModal,setProjectSecurityModal] = useState({"isOpen":false,"currentId":"",'projectId':'',"header":"","mode":"","value":{"objective":null,"impact":null,"question":[],"answer":[]},"metaData":{"objective":[],"impact":[],"question":[]}});
    const openProjectSecurityModal = (id) => {
        var temp = projectSecurityModal;
        temp["isOpen"] = true;
        temp["header"] = "Add Project Security";
        temp["mode"] = "add";
        temp["projectId"] = id;
        handleMenuAnchorClose(id);
    }
    const editProjectSecurityModal = (id) => {
        var temp = projectSecurityModal;
        var selectedData = getArrayOfObjectBasedOnKey(tableContent["data"],"id",id);
        if( selectedData === null){
            dispatch(toastUp("Issue Selecting Project"));
            return false;
        }
        temp["isOpen"] = true;
        temp["mode"] = "edit";
        temp["header"] = "Edit Project Security";
        temp["parentId"] = id;
        temp["currentId"] = selectedData["security"]["id"];
        temp["value"]["impact"] = getArrayOfObjectBasedOnKey(temp["metaData"]["impact"],"id",selectedData["security"]["project_security_impact_id"]);
        temp["value"]["objective"] = getArrayOfObjectBasedOnKey(temp["metaData"]["objective"],"id",selectedData["security"]["project_security_objective_id"]);
        var answerState = {};
        var questionSet = [];
        for (var row of selectedData["security"]["answersandquestions"]){
            questionSet.push(getArrayOfObjectBasedOnKey(temp["metaData"]["question"],"id",row["questionId"]));
            answerState[row["questionId"]] = {"questionId":row["questionId"],"question":row["question"],"answer":row["answer"],"likelihood":getArrayOfObjectBasedOnKey(projectDropDown["scale"],"value",row["likelihoodSeverity"]),"impact":getArrayOfObjectBasedOnKey(projectDropDown["scale"],"value",row["impactSeverity"])};
        }
        temp["value"]["question"] = questionSet;
        temp["value"]["answer"] = answerState;
        addOrRemoveAnswerForSecurityQuestion();
        handleMenuAnchorClose(id);
    }
    const closeProjectSecurityModal = (id) => {
        var temp = projectSecurityModal;
        temp["isOpen"] = false;
        temp["header"] = "";
        temp["mode"] = "";
        temp["currentId"] = "";
        temp["value"]["objective"] = null;
        temp["value"]["impact"] = null;
        temp["value"]["question"] = [];
        temp["value"]["answer"] = [];
        temp["projectId"] = "";
        setProjectSecurityModal({...temp});
    }
    const handleValueChangeForProjectSecurity = (key) => (event,object) => {
        var temp = projectSecurityModal;
        temp["value"][key] = object;
        setProjectSecurityModal({...temp});
        if(key === "question"){
            addOrRemoveAnswerForSecurityQuestion();
        }
    }
    const addOrRemoveAnswerForSecurityQuestion = () => {
        var temp = projectSecurityModal;
        var selectedQuestions = temp["value"]["question"];
        var answerState = {};
        for (var question of selectedQuestions){
            if(! temp["value"]["answer"].hasOwnProperty(question["id"])){
                answerState[question["id"]] = {"questionId":question["id"],"question":question["question"],"answer":"no","likelihood":null,"impact":null};
            }else{
                answerState[question["id"]] = temp["value"]["answer"][question["id"]];
            }
        }
        temp["value"]["answer"] = answerState;
        setProjectSecurityModal({...temp});
    }
    const handleValueChangeForSecurityAnswer = (questionId,key) => (event,object) => {
        var temp = projectSecurityModal;
        var value = event.target.value
        if(['likelihood','impact'].includes(key)){
            value = object;
        }
        temp["value"]["answer"][questionId][key] = value;
        setProjectSecurityModal({...temp});
    }
    // function to handle company modal submit
    const handleProjectSecuritySubmit = mode => {
        var data = validateAndGetProjectSecurityFormValue();
        if(data["status"] === "valid"){
            if (mode === "add") {
                handleProjectSecurityModalSave(data["data"]);
            }else if(mode === "edit"){
                handleProjectSecurityModalEdit(data["data"]);
            }
        }else{
            dispatch(toastUp(data["msg"]));
        }
    }
    const handleProjectSecurityModalSave = (data) => {
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}issue/project/security?projectId=${projectSecurityModal["projectId"]}`,data)
        .then(res => {
            dispatch(toastUp("Project Security created successfully"));
            closeProjectSecurityModal();
            fetchData();
        })
        .catch(err => {
            console.log(err)
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    const handleProjectSecurityModalEdit = (data) => {
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}issue/project/security?id=${projectSecurityModal["currentId"]}`,data)
        .then(res => {
            dispatch(toastUp("Project Security edited successfully"));
            closeProjectSecurityModal();
            fetchData();
        })
        .catch(err => {
            console.log(err)
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    const validateAndGetProjectSecurityFormValue = () => {
        var data = {}
        data["status"] = "invalid";
        var value = projectSecurityModal["value"];
        if(value["objective"] === null){
            data["msg"] = "Security Objective field cannot be empty";
            return data;
        }
        if(value["impact"] === null){
            data["msg"] = "Security Impact field cannot be empty";
            return data;
        }
        if(value["question"].length === 0){
            data["msg"] = "Security Question field cannot be empty";
            return data;
        }
        var answer = []
        for(var key in value["answer"]){
            if(value["answer"][key]["impact"] === null){
                data["msg"] = `${value["answer"][key]["question"]}'s Impact of Inherent Risk field cannot be empty`;
                return data;
            }
            if(value["answer"][key]["likelihood"] === null){
                data["msg"] = `${value["answer"][key]["question"]}'s Likelihood of Inherent Risk field cannot be empty`;
                return data;
            }
            var temp = {}
            temp["impact"] = getKeyOrNullOfObject(value["answer"][key]["impact"],"value");
            temp["likelihood"] = getKeyOrNullOfObject(value["answer"][key]["likelihood"],"value");
            temp["questionId"] = value["answer"][key]["questionId"]
            temp["answer"] = value["answer"][key]["answer"]
            answer.push(temp);
        }
        data["status"] = "valid";
        var formValue = {};
        formValue["objective"] = getIdOrNullOfObject(value["objective"]);
        formValue["impact"] = getIdOrNullOfObject(value["impact"]);
        formValue["answer"] = answer;
        data["data"] = formValue;
        return data;
    }
    // init company modal state and values
    const [objectiveModal, setObjectiveModal] = useState({ "isOpen": false, "mode": "", "header": "", "value": { "name": "" } });
    // open company modal to create company
    const openObjectiveModal = () => {
        var temp = objectiveModal;
        temp['value']["name"] = "";
        temp['mode'] = "add";
        temp["header"] = "Create Security Objective";
        temp['isOpen'] = true;
        setObjectiveModal({ ...temp });
    }
    const editObjectiveModal = () => {
        var selectedDataDetails = projectSecurityModal["value"]["objective"];
        if(selectedDataDetails === null){
            dispatch(toastUp("Please select Security Objective to edit"));
            return false;
        }
        var temp = objectiveModal;
        if(selectedDataDetails === null){
            dispatch(toastUp("Issue selecting Security Objective to edit"));
            return false;
        }
        temp['value']["name"] = selectedDataDetails["name"];
        temp['isOpen'] = true;
        temp['mode'] = "edit";
        temp["header"] = "Edit Security Objective";
        setObjectiveModal({...temp});
    }
    // handler to clean and close company modal
    const handleObjectiveModalClose = () => {
        var temp = objectiveModal;
        temp['value']["name"] = "";
        temp['header'] = "";
        temp["mode"] = "";
        temp['isOpen'] = false;
        setObjectiveModal({ ...temp });
    }
    // function to set form value to companyModal value field
    const objectiveModalValueHandler = key => event => {
        var temp = objectiveModal;
        temp['value'][key] = event.target.value;
        setObjectiveModal({ ...temp });
    }
    // function to handle company modal submit
    const handleObjectiveModalSubmit = mode => event => {
        if (mode === "add") {
            handleObjectiveModalSave();
        }else if(mode === "edit"){
            handleObjectiveModalEdit();
        }
    }
    // function to validate company modal, send request to service and select the newly added value in the dropdown for company
    const handleObjectiveModalSave = () => {
        if (objectiveModal.value.name === "") {
            dispatch(toastUp("Security Objective field cannot be empty"));
            return false;
        }
        var data = {};
        data["name"] = objectiveModal["value"]["name"];
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}issue/project/security/objective?companyId=${loggedInUser['company_id']}`, data)
        .then(res => {
            if (res.status === 201) {
                var temp = projectSecurityModal;
                var newOption = {"id":res["data"]["projectSecurityObjectiveId"],"name":objectiveModal["value"]["name"]};
                temp["metaData"]["objective"].push(newOption);
                temp["value"]["objective"] = newOption
                setFormOptionsMetaData({...temp});
                dispatch(toastUp("Security Objective created successfully"));
                handleObjectiveModalClose();
            } else {
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to edit existing company
    const handleObjectiveModalEdit = () => {
        if (objectiveModal.value.name === "") {
            dispatch(toastUp("Name Field Cannot Be Empty"));
            return false;
        }
        var data = {};
        data["name"] = objectiveModal["value"]["name"];
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}issue/project/security/objective?id=${projectSecurityModal["value"]["objective"]["id"]}`, data)
        .then(res => {
            var temp = projectSecurityModal;
            var newOption = {"id":projectSecurityModal["value"]["objective"]["id"],"name":objectiveModal["value"]["name"]};
            temp["metaData"]["objective"] = replaceObjectInArrayBasedOnKey(projectSecurityModal["metaData"]["objective"],"id",projectSecurityModal["value"]["objective"]["id"],newOption)
            temp["value"]["objective"] = newOption
            setFormOptionsMetaData({...temp});
            dispatch(toastUp("Security Objective edited successfully"));
            handleObjectiveModalClose();
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // init company modal state and values
    const [impactModal, setImpactModal] = useState({ "isOpen": false, "mode": "", "header": "", "value": { "name": "" } });
    // open company modal to create company
    const openImpactModal = () => {
        var temp = impactModal;
        temp['value']["name"] = "";
        temp['mode'] = "add";
        temp["header"] = "Create Security Impact";
        temp['isOpen'] = true;
        setImpactModal({ ...temp });
    }
    const editImpactModal = () => {
        var selectedDataDetails = projectSecurityModal["value"]["impact"];
        if(selectedDataDetails === null){
            dispatch(toastUp("Please select Security Impact to edit"));
            return false;
        }
        var temp = impactModal;
        if(selectedDataDetails === null){
            dispatch(toastUp("Issue selecting Security Impact to edit"));
            return false;
        }
        temp['value']["name"] = selectedDataDetails["name"];
        temp['isOpen'] = true;
        temp['mode'] = "edit";
        temp["header"] = "Edit Security Impact";
        setImpactModal({...temp});
    }
    // handler to clean and close impact modal
    const handleImpactModalClose = () => {
        var temp = impactModal;
        temp['value']["name"] = "";
        temp['header'] = "";
        temp['isOpen'] = false;
        temp["mode"] = "";
        setImpactModal({ ...temp });
    }
    // function to set form value to companyModal value field
    const impactModalValueHandler = key => event => {
        var temp = impactModal;
        temp['value'][key] = event.target.value;
        setImpactModal({ ...temp });
    }
    // function to handle company modal submit
    const handleImpactModalSubmit = mode => event => {
        if (mode === "add") {
            handleImpactModalSave();
        }else if(mode === "edit"){
            handleImpactModalEdit();
        }
    }
    // function to validate company modal, send request to service and select the newly added value in the dropdown for company
    const handleImpactModalSave = () => {
        if (impactModal.value.name === "") {
            dispatch(toastUp("Security Impact field cannot be empty"));
            return false;
        }
        var data = {};
        data["name"] = impactModal["value"]["name"];
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}issue/project/security/impact?companyId=${loggedInUser['company_id']}`, data)
        .then(res => {
            var temp = projectSecurityModal;
            var newOption = {"id":res["data"]["projectSecurityImpactId"],"name":impactModal["value"]["name"]};
            temp["metaData"]["impact"].push(newOption);
            temp["value"]["impact"] = newOption
            setFormOptionsMetaData({...temp});
            dispatch(toastUp("Security Impact created successfully"));
            handleImpactModalClose();
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    const handleImpactModalEdit = () => {
        if (impactModal.value.name === "") {
            dispatch(toastUp("Security Impact field cannot be empty"));
            return false;
        }
        var data = {};
        data["name"] = impactModal["value"]["name"];
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}issue/project/security/impact?id=${projectSecurityModal["value"]["impact"]["id"]}`, data)
        .then(res => {
            var temp = projectSecurityModal;
            var newOption = {"id":projectSecurityModal["value"]["impact"]["id"],"name":impactModal["value"]["name"]};
            temp["metaData"]["impact"] = replaceObjectInArrayBasedOnKey(projectSecurityModal["metaData"]["impact"],"id",projectSecurityModal["value"]["impact"]["id"],newOption)
            temp["value"]["impact"] = newOption
            setFormOptionsMetaData({...temp});
            dispatch(toastUp("Security Impact edited successfully"));
            handleImpactModalClose();
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }

    // init company modal state and values
    const [questionModal, setQuestionModal] = useState({ "isOpen": false, "mode": "", "header": "", "value": { "question": "" } });
    // open company modal to create company
    const openQuestionModal = () => {
        var temp = questionModal;
        temp['value']["question"] = "";
        temp['mode'] = "add";
        temp["header"] = "Create Security Question";
        temp['isOpen'] = true;
        setQuestionModal({ ...temp });
    }
    // const editQuestionModal = () => {
    //     var selectedDataDetails = projectSecurityModal["value"]["question"];
    //     if(selectedDataDetails === null){
    //         dispatch(toastUp("Please select Security Question to edit"));
    //         return false;
    //     }
    //     var temp = questionModal;
    //     if(selectedDataDetails === null){
    //         dispatch(toastUp("Issue selecting Security Question to edit"));
    //         return false;
    //     }
    //     temp['value']["name"] = selectedDataDetails["name"];
    //     temp['isOpen'] = true;
    //     temp['mode'] = "edit";
    //     temp["header"] = "Edit Security Question";
    //     setQuestionModal({...temp});
    // }
    // handler to clean and close question modal
    const handleQuestionModalClose = () => {
        var temp = questionModal;
        temp['value']["name"] = "";
        temp['header'] = "";
        temp['isOpen'] = false;
        temp["mode"] = "";
        setQuestionModal({ ...temp });
    }
    // function to set form value to companyModal value field
    const questionModalValueHandler = key => event => {
        var temp = questionModal;
        temp['value'][key] = event.target.value;
        setQuestionModal({ ...temp });
    }
    // function to handle company modal submit
    const handleQuestionModalSubmit = mode => event => {
        if (mode === "add") {
            handleQuestionModalSave();
        }
        // else if(mode === "edit"){
        //     handleQuestionModalEdit();
        // }
    }
    // function to validate company modal, send request to service and select the newly added value in the dropdown for company
    const handleQuestionModalSave = () => {
        if (questionModal.value.question === "") {
            dispatch(toastUp("Question Field Cannot Be Empty"));
            return false;
        }
        var data = {};
        data["question"] = questionModal["value"]["question"];
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}issue/project/security/question?companyId=${loggedInUser['company_id']}`, data)
            .then(res => {
                if (res.status === 201) {
                    var temp = projectSecurityModal;
                    var newOption = {"id":res["data"]["projectSecurityQuestionId"],"question":questionModal["value"]["question"]};
                    temp["metaData"]["question"].push(newOption);
                    temp["value"]["question"].push(newOption);
                    setFormOptionsMetaData({...temp});
                    addOrRemoveAnswerForSecurityQuestion();
                    dispatch(toastUp("Security question created successfully"));
                    handleQuestionModalClose();
                } else {
                    dispatch(toastUp("unable to connect server"));
                }
            })
            .catch(err => {
                dispatch(toastUp(getErrorMsg(err)));
            })
            .finally(() => {
                dispatch(setSpinner(false));
            });
    }
    // const handleQuestionModalEdit = () => {
    //     if (questionModal.value.name === "") {
    //         dispatch(toastUp("Security Question field cannot be empty"));
    //         return false;
    //     }
    //     var data = {};
    //     data["name"] = questionModal["value"]["name"];
    //     dispatch(setSpinner(true));
    //     axios.put(`${SERVICE_URL}issue/project/security/question?id=${projectSecurityModal["value"]["question"]["id"]}`, data)
    //     .then(res => {
    //         var temp = projectSecurityModal;
    //         var newOption = {"id":projectSecurityModal["value"]["question"]["id"],"question":questionModal["value"]["name"]};
    //         temp["metaData"]["question"] = replaceObjectInArrayBasedOnKey(projectSecurityModal["metaData"]["question"],"id",projectSecurityModal["value"]["question"]["id"],newOption)
    //         temp["value"]["question"] = newOption
    //         setFormOptionsMetaData({...temp});
    //         dispatch(toastUp("Security Question edited successfully"));
    //         handleQuestionModalClose();
    //     })
    //     .catch(err => {
    //         dispatch(toastUp(getErrorMsg(err)));
    //     })
    //     .finally(() => {
    //         dispatch(setSpinner(false));
    //     });
    // }
    const getActionCellContent = (params) => (
        <div className={`${style.actionRow}`}>
            <IconButton aria-label="more" id="long-button" aria-controls={Boolean(columnState[params.id]["anchorEl"]) ? 'long-menu' : undefined} aria-expanded={Boolean(columnState[params.id]["anchorEl"]) ? 'true' : undefined} aria-haspopup="true" onClick={handleMenuAnchorOpen(params.id)}>
                <MoreVertIcon className='icon' />
            </IconButton>
            <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button'}} anchorEl={columnState[params.id]["anchorEl"]} open={Boolean(columnState[params.id]["anchorEl"])} onClose={()=>{handleMenuAnchorClose(params.id)}}>
                {
                    (params.row.security !== undefined && Object.keys(params.row.security).length === 0) ?
                        <MenuItem onClick={()=>openProjectSecurityModal(params.id)} >Add Security</MenuItem>
                    :
                        <MenuItem onClick={()=>editProjectSecurityModal(params.id)}>Edit Security</MenuItem>
                }
            </Menu>
        </div>
    ) 
    // init table column details
    const columns = [
        { field: 'name', sortable: false, flex: 1, renderHeader: () => (<strong>{'Project Name'}</strong>), renderCell: (params) => (<div className={`MuiDataGrid-cellContent ${(!params.row.inActiveFlag) ? 'hyperLinkActive' : ''}`} onClick={()=>{openViewProjectModal(params.id)}} title={params.value} >{params.value}</div>) },
        { field: 'manager', sortable: false, flex: 1, renderHeader: () => (<strong>{'Project Manager'}</strong>) },
        { field: 'pmoOffice', sortable: false, flex: 1, renderHeader: () => (<strong>{'PMO Office'}</strong>) },
        {field: 'action', sortable: false, width: 100, renderHeader: () => (<strong>{'Action'}</strong>),renderCell: getActionCellContent}
    ]
    // init use state handle page for table
    const [pageState, setPageState] = useState({
        page: 0,
        pageSize: 30
    })

    // init use state to store table contents
    const [tableContent, setTableContent] = useState({
        isLoading: false,
        data: [],
        total: 0,
    })
    // init use state for column 
    const [columnState,setColumnState] = useState({});
    // function to generate use state for table column
    const generateTableSupportingDetils = (data) => {
        var updatedColumnState = {};
        for(const row of data){
            updatedColumnState[row["id"]] = {"anchorEl":null};
        }
        setColumnState({...updatedColumnState});
    }
    // function to handle open menu anchor
    const handleMenuAnchorOpen = key => event => {
        var updatedColumnState = columnState;
        updatedColumnState[key]["anchorEl"] = event.currentTarget;
        setColumnState({...updatedColumnState});
    };
    // function to handle menu anchor
    const handleMenuAnchorClose = key => {
        var updatedColumnState = columnState;
        updatedColumnState[key]["anchorEl"] = null;
        setColumnState({...updatedColumnState});
    };
    // use effect to call the function when ever the page changes
    const fetchData = async () => {
        setTableContent(old => ({ ...old, isLoading: true }))
        axios.get(`${SERVICE_URL}issue/project/view?page=${pageState.page+1}&size=${pageState.pageSize}&companyId=${loggedInUser["company_id"]}${selectedFilter}`)
        .then(res=>{
            var data=res.data;
            generateTableSupportingDetils(data["data"]); 
            var temp = tableContent;
            temp["isLoading"] = false;
            temp["data"] = data["data"];
            temp["total"] = data["totalCount"];
            setTableContent({...temp});
        }).catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
            setTableContent(old => ({ ...old, isLoading: false }));
        })
    }
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [pageState.page, pageState.pageSize,selectedFilter]);
    useEffect(()=>{
        dispatch(setSpinner(false));
        axios.get(`${SERVICE_URL}issue/project/view?companyId=${loggedInUser["company_id"]}`)
        .then(res => {
            var temp = formOptionsMetaData;
            temp["name"] = generateArrayForAutoCompleteBasedOnkey(res["data"]["data"],'name');
            setFormOptionsMetaData({...temp});
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        axios.get(`${SERVICE_URL}issue/project/security/objective/ui?companyId=${loggedInUser['company_id']}`)
        .then(res => {
            var temp = projectSecurityModal;
            temp["metaData"]["objective"] = res["data"];
            setProjectSecurityModal({...temp});
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        axios.get(`${SERVICE_URL}issue/project/security/impact/ui?companyId=${loggedInUser['company_id']}`)
        .then(res => {
            var temp = projectSecurityModal;
            temp["metaData"]["impact"] = res["data"];
            setProjectSecurityModal({...temp});
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        axios.get(`${SERVICE_URL}issue/project/security/question/ui?companyId=${loggedInUser['company_id']}`)
        .then(res => {
            var temp = projectSecurityModal;
            temp["metaData"]["question"] = res["data"];
            setProjectSecurityModal({...temp});
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        // eslint-disable-next-line
    },[]);
    return (
        <div>
            <HeaderSection header='Project Security' breadcrumbs={["Issues Management", "Portfolio Management", "Project Security"]} />
            <div className='m20'>
                <div className={`plr10 ptb30 ${style.filterSection}`}>
                    <Grid container spacing={2}>
                        <Grid item sm={6} md={3}>
                            <Autocomplete value={formData["name"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["name"]} freeSolo size="small" disablePortal onChange={handleFormDataChange("name")} renderInput={renderDisplayValueWithLableForAutocomplete('Project Name')} />
                        </Grid>
                    </Grid>
                </div>
                <DataGridCustom rows={tableContent.data} rowCount={tableContent.total} isLoading={tableContent.isLoading} pageSizeOptions={[30, 50, 100]} paginationModel={pageState} onPaginationModelChange={setPageState} columns={columns} combineSearch={true} />
            </div>
            <ModalFormMax open={projectSecurityModal.isOpen} mode={projectSecurityModal.mode} closeHandler={closeProjectSecurityModal} header={projectSecurityModal.header} saveHandler={()=>handleProjectSecuritySubmit(projectSecurityModal.mode)}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Security Objective </span><span className='required'>*</span><br />
                        <AutocompleteWithMoreAED ignore={['delete']} size='small' value={projectSecurityModal["value"]["objective"]} options={projectSecurityModal["metaData"]["objective"]} renderInput={renderDisplayValueForAutocomplete} addHandler={openObjectiveModal} editHandler={editObjectiveModal} onChange={handleValueChangeForProjectSecurity("objective")} getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.id === value.id} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Security Impact </span><span className='required'>*</span><br />
                        <AutocompleteWithMoreAED ignore={['delete']} size='small' value={projectSecurityModal["value"]["impact"]} options={projectSecurityModal["metaData"]["impact"]} renderInput={renderDisplayValueForAutocomplete} addHandler={openImpactModal} editHandler={editImpactModal} onChange={handleValueChangeForProjectSecurity("impact")} getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.id === value.id}/>
                    </Grid>
                    <Grid item sm={12} md={12}>
                        <span className='lable'>Security Questions<span className='required'>*</span></span><br />
                        <AutocompleteMultiWithMoreAED ignore={['delete','edit']} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={projectSecurityModal["metaData"]["question"]} size="small" getOptionLabel={(option) => option.question} isOptionEqualToValue={(option, value) => option.id === value.id} disablePortal renderInput={renderDisplayValueForAutocomplete} onChange={handleValueChangeForProjectSecurity("question")} value={projectSecurityModal["value"]["question"]} addHandler={openQuestionModal} />
                    </Grid>
                    <Grid style={{display:(projectSecurityModal.value.question.length === 0) ? "none" : "block"}} item sm={12} md={12}>
                        <FieldsetCustom legend={""}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} sm={12} md={3} lg={3} sx={{ ...style.gridItemStyle, color: '#0A6EBD', marginBottom: '0px', fontSize: '17px', marginTop: '30px' }}>
                                    Questions
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} sx={{ ...style.gridItemStyle, color: '#0A6EBD', marginBottom: '0px', fontSize: '17px', marginTop: '30px' }}>
                                    Answer
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ ...style.gridItemStyle, color: '#0A6EBD', marginBottom: '0px', fontSize: '17px', textAlign: 'center' }}>
                                    Inherent Risk
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ ...style.gridItemStyle, color: '#0A6EBD', marginBottom: '10px', fontSize: '17px' }}>

                                </Grid>
                                <Grid item xs={12} sm={6} md={2} lg={2} sx={{ ...style.gridItemStyle, color: '#0A6EBD', marginBottom: '10px', fontSize: '17px' }}>
                                    Impact
                                </Grid>
                                <Grid item xs={12} sm={6} md={2} lg={2} sx={{ ...style.gridItemStyle, color: '#0A6EBD', marginBottom: '10px', fontSize: '17px' }}>
                                    Likelihood
                                </Grid>
                                <Grid item xs={12} sm={6} md={2} lg={2} sx={{ ...style.gridItemStyle, color: '#0A6EBD', marginBottom: '10px', fontSize: '17px' }}>
                                    Score
                                </Grid>
                                {Object.keys(projectSecurityModal["value"]["answer"]).map((key) => (
                                    <Fragment key={projectSecurityModal["value"]["answer"][key]["questionId"]}>
                                            <Grid item xs={12} sm={6} md={3} lg={3} >
                                                {getKeyBasedOnValue(projectSecurityModal["value"]["question"],"id",projectSecurityModal["value"]["answer"][key]["questionId"],"question")}
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={3} lg={3} >
                                                <RadioGroup value={projectSecurityModal["value"]["answer"][key]["answer"]} onChange={handleValueChangeForSecurityAnswer(projectSecurityModal["value"]["answer"][key]["questionId"],"answer")} row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                                    <FormControlLabel value="yes" control={<Radio size="small" />} label={<span style={{ fontSize: "14px" }}>Yes</span>} />
                                                    <FormControlLabel value="no" control={<Radio size="small" />} label={<span style={{ fontSize: "14px" }}>No</span>} />
                                                </RadioGroup>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={2} lg={2} >
                                            {/* autocompleteStyleOverRide["sx"]['.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]'] */}
                                                <Autocomplete value={projectSecurityModal["value"]["answer"][key]["impact"]}  sx={{...autocompleteStyle.sx,'.MuiAutocomplete-input':getStyleForInherentRisk(projectSecurityModal["value"]["answer"][key]["impact"])}} ListboxProps={autocompleteStyle.ListboxProps} options={projectDropDown["scale"]} size="small" getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.value === value.value} disablePortal renderInput={renderDisplayValueWithLableForAutocomplete("Scale")} onChange={handleValueChangeForSecurityAnswer(projectSecurityModal["value"]["answer"][key]["questionId"],"impact")}/>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={2} lg={2} >
                                                <Autocomplete value={projectSecurityModal["value"]["answer"][key]["likelihood"]}  sx={{...autocompleteStyle.sx,'.MuiAutocomplete-input':getStyleForInherentRisk(projectSecurityModal["value"]["answer"][key]["likelihood"])}} ListboxProps={autocompleteStyle.ListboxProps} options={projectDropDown["scale"]} size="small" getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.value === value.value} disablePortal renderInput={renderDisplayValueWithLableForAutocomplete("Scale")}   onChange={handleValueChangeForSecurityAnswer(projectSecurityModal["value"]["answer"][key]["questionId"],"likelihood")} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={2} lg={2} >
                                                <div style={{ border: '1px solid #c4c4c4', padding: '5px', borderRadius: '5px' }}>
                                                    <div style={{padding: "3px",width:"65%",...getStyleForInherentRisk(getScoreForInherentRisk(getKeyOrNullOfObject(projectSecurityModal["value"]["answer"][key]["impact"],"score"),getKeyOrNullOfObject(projectSecurityModal["value"]["answer"][key]["likelihood"],"score")))}}>
                                                        {getScoreForInherentRisk(getKeyOrNullOfObject(projectSecurityModal["value"]["answer"][key]["impact"],"score"),getKeyOrNullOfObject(projectSecurityModal["value"]["answer"][key]["likelihood"],"score"))}
                                                    </div>
                                                </div>
                                            </Grid>
                                    </Fragment>
                                ))}
                            </Grid>
                        </FieldsetCustom>
                    </Grid>
                </Grid>
            </ModalFormMax>
            <ModalForm open={objectiveModal.isOpen} mode={objectiveModal.mode} closeHandler={handleObjectiveModalClose} header={objectiveModal.header} saveHandler={handleObjectiveModalSubmit(objectiveModal.mode)} >
                <Grid container spacing={1}>
                    <Grid item sm={12} md={12}>
                        <span className='lable'>Security Objective <span className='required'>*</span></span><br />
                        <TextField sx={textFieldStyle} value={objectiveModal.value.name} fullWidth size="small" onChange={objectiveModalValueHandler("name")} variant="outlined" />
                    </Grid>
                </Grid>
            </ModalForm>
            <ModalForm open={impactModal.isOpen} mode={impactModal.mode} closeHandler={handleImpactModalClose} header={impactModal.header} saveHandler={handleImpactModalSubmit(impactModal.mode)} >
                <Grid container spacing={1}>
                    <Grid item sm={12} md={12}>
                        <span className='lable'>Security Impact <span className='required'>*</span></span><br />
                        <TextField sx={textFieldStyle} value={impactModal.value.name} fullWidth size="small" onChange={impactModalValueHandler("name")} variant="outlined" />
                    </Grid>
                </Grid>
            </ModalForm>
            <ModalForm open={questionModal.isOpen} mode={questionModal.mode} closeHandler={handleQuestionModalClose} header={questionModal.header} saveHandler={handleQuestionModalSubmit(questionModal.mode)} >
                <Grid container spacing={1}>
                    <Grid item sm={12} md={12}>
                        <span className='lable'>Security Question <span className='required'>*</span></span><br />
                        <TextField sx={textFieldStyle} value={questionModal.value.question} fullWidth size="small" onChange={questionModalValueHandler("question")} variant="outlined" />
                    </Grid>
                </Grid>
            </ModalForm>
            <ModalViewInfo open={viewProjectModal.isOpen} closeHandler={closeViewProjectModal}>
                <ViewProjectDetailsForModal projectDetails={viewProjectModal.projectDetails}/>
            </ModalViewInfo>
        </div>
    );
}

export default ProjectSecurity;