import React, {useState,useEffect,useRef} from 'react';
import {Button,Paper,Grid,TextField,Tooltip} from '@mui/material';
import HeaderSection from '../HeaderSection';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import style from '../../css/ViewRisk.module.css';
import DataGridCustom from '../wrapper/DataGridCustom';
import ModalViewInfo from '../wrapper/ModalViewInfo';
import { searchFieldStyle } from '../../js/standardStyle';
import ViewRiskRegisterDetailForModal from './ViewRiskRegisterDetailForModal';
import axios from 'axios';
import { SERVICE_URL } from '../../config';
import {getIdIndexOfArrayObject} from '../../js/util';
import { tooltipStyle } from '../../js/standardStyle';
import { getErrorMsg } from '../../js/util';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from '../../redux/actions/displayPage';
import { setSpinner } from '../../redux/actions/spinner';
import { toastUp } from '../../redux/actions/toast';


function ViewRisk(props) {

    // get use dispatch from redux
    const dispatch = useDispatch();
    // get logged in user
    const loggedInUser = useSelector(state => state.loggedInUser);
    // init ref for search field
    const searchField = useRef()
    // function to apply search
    const applySearch = (event) => {
        var code = event.keyCode || event.which;
        if(code === 13) {
            applyFilter();
        } 
    }
    // function to apply filter
    const applyFilter = () => {
        var filter = "";
        if(viewRiskUiHandler==="unassigned"){
            filter+="&groupBy=new";
        }
        var searchQuery = searchField.current.value;
        if(searchQuery !== ""){
            filter+=`&search=${searchQuery}`;
        }
        setSelectedFilter(filter);
        setPageState((old)=>({...old,page:0}));
    }
    // function to reset filter
    const resetFilter = (key) => {
        var filter = "";
        if(key==="unassigned"){
            filter+="&groupBy=new"
        }
        searchField.current.value="";
        setSelectedFilter(filter);
        setPageState((old)=>({...old,page:0}));
    }
    // init use state for column 
    const [columnState,setColumnState] = useState({});
    // coloumn details for table
    const columns = [
        {field: 'riskIndex', headerName: 'ID Ref', sortable: false, flex: 1, renderHeader: () => (<strong>{'Risk ID Index'}</strong>),renderCell:(params) => (<div style={{cursor:"default"}}> <Tooltip onMouseLeave={()=>{handleColumnIndexTooltipClose(params.id)}} onContextMenu={(event)=>{handleColumnIndexTooltipOpen(params["id"]);event.preventDefault()}} componentsProps={tooltipStyle} open={columnState[params.id]["indexTooltip"]} disableFocusListener disableHoverListener disableTouchListener placement="right" arrow title={<span style={{ whiteSpace:'pre-line',fontSize:"14px"}}>{`Risk Impact Classification: ${params.row.riskImpactClassification}\nAssigned Resource: ${params.row.assignedResource}\nRisk Treatment: ${params.row.responseAction}`}</span>}><span >{params.value}</span></Tooltip></div>)},
        {field: 'riskName', headerName: 'Risk Name', sortable: false, flex: 1, renderHeader: () => (<strong>{'Risk Name'}</strong>),renderCell: (params) => (<div className="MuiDataGrid-cellContent hyperLinkActive" title={params.value} onClick={()=>{openRiskRegisterModal(params.id)}}>{params.value}</div>)},
        {field: 'responseAction', headerName: 'Risk Treatment', sortable: false, flex: 1, renderHeader: () => (<strong>{'Risk Treatment'}</strong>)},
        {field: 'treatmentStatus', headerName: 'Treatment Status', sortable: false, flex: 1, renderHeader: () => (<strong>{'Treatment Status'}</strong>)},
        {field: 'riskOwner', headerName: 'Risk Owner', sortable: false, flex: 1, renderHeader: () => (<strong>{'Risk Owner'}</strong>)},
        {field: 'createdBy', headerName: 'Created By', sortable: false, flex: 1, renderHeader: () => (<strong>{'Created By'}</strong>)},
        {field: 'riskRating', headerName: 'Risk Rating', sortable: false, flex: 1, renderHeader: () => (<strong>{'Risk Rating'}</strong>)}
    ]
    // function to open index column tool tip
    const handleColumnIndexTooltipOpen = key => {
        var updatedColumnState = columnState;
        for (const key of Object.keys(updatedColumnState)) {
            updatedColumnState[key]["indexTooltip"] = false;
        }
        updatedColumnState[key]["indexTooltip"] = true;
        setColumnState({...updatedColumnState});
    }
    // function to close index column tool tip
    const handleColumnIndexTooltipClose = (key) => {
        var updatedColumnState = columnState;
        updatedColumnState[key]["indexTooltip"] = false;
        setColumnState({...updatedColumnState});
    }
    // init use state handle page for table
    const [pageState, setPageState] = useState({
        page: 0,
        pageSize: 30
    })
    // init use state to store table contents
    const [tableContent,setTableContent] = useState({
        isLoading: false,
        data: [],
        total: 0,
    })    

    // init use state for handle screen
    const [viewRiskUiHandler,setViewRiskUiHandler] = useState("all")
    // function to change view risk ui
    const changeViewRiskUi = (key) => {
        setViewRiskUiHandler(key);
        resetFilter(key);
    }

    // init use state for total count
    const [formDataCount,setFormDataCount] = useState({"all":"0","triage":"0","assess":"0","active":"0","review":"0","closed":"0","unassigned":"0"})
    
    // init use state for store filter details
    const [selectedFilter,setSelectedFilter] = useState("");
    
    // init use state for risk register modal
    const [riskRegisterModal,setRiskRegisterModal] = useState({"isOpen":false,"content":undefined,"currentId":"","index":"","buttonDisable":{"next":false,"prev":false}});
    // function to open risk register modal
    const openRiskRegisterModal = (id) => {
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}risk/register?Id=${id}`)
        .then(res => {
            var next = false;
            var prev = false;
            var index = getIdIndexOfArrayObject(id,tableContent["data"]);
            var currentPage = pageState.page+1;
            if(index !== null){
                if(index+1 === tableContent["data"].length){
                    var totalPageNumbers = Math.ceil(tableContent["total"]/pageState["pageSize"]);
                    if(currentPage === totalPageNumbers){
                        next = true;
                    }
                }if(index === 0){
                    if(currentPage === 1){
                        prev = true;
                    }
                }
            }
            setRiskRegisterModal({"isOpen":true,"content":res.data,"currentId":id,"buttonDisable":{"next":next,"prev":prev}});
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to close risk register modal
    const closeRiskRegisterModal = () => {
        setRiskRegisterModal({"isOpen":false,"content":undefined,"currentId":"","index":"","buttonDisable":{"next":false,"prev":false}});
    }
    // function to switch next and previous risk register
    const nextPrevPageRiskRegisterModalHandler = (mode) => {
        var currentId = riskRegisterModal["currentId"];
        var index = getIdIndexOfArrayObject(currentId,tableContent["data"]);
        var currentPage = pageState.page+1;
        if(index === null){
            dispatch(toastUp(`issues switching ${mode} page`));
            return false;
        }
        if(mode === "next"){
            if(index+1 === tableContent["data"].length){
                var totalPageNumbers = Math.ceil(tableContent["total"]/pageState["pageSize"]);
                if(currentPage === totalPageNumbers){
                    dispatch(toastUp("end of table content"));
                    return false;
                }else{
                    setRiskRegisterModal((old)=>({...old,index:"0"}));
                    setPageState((old)=>({...old,page:old["page"]+1}));
                }
            }else{
                var nextData = tableContent["data"][index+1];
                openRiskRegisterModal(nextData["id"]);
            }
        }else if((mode === "previous")){
            if(index === 0){
                if(currentPage === 1){
                    dispatch(toastUp("end of table content"));
                    return false;
                }else{
                    setRiskRegisterModal((old)=>({...old,index:"-1"}));
                    setPageState((old)=>({...old,page:old["page"]-1}))
                }
            }else{
                var prevData = tableContent["data"][index-1];
                openRiskRegisterModal(prevData["id"]);
            }
        }
    }
    // function to generate use state for table content
    const generateTableSupportingDetils = (data) => {
        var updatedColumnState = {};
        for(const row of data){
            updatedColumnState[row["id"]] = {"indexTooltip":false};
        }
        setColumnState({...updatedColumnState});
    }
    
    // use effect to call the function when ever the page changes
    useEffect(() => {
        const fetchData = async () => {
            setTableContent(old => ({ ...old, isLoading: true }))
            axios.get(`${SERVICE_URL}risk/register/view?page=${pageState.page+1}&size=${pageState.pageSize}${selectedFilter}&companyId=${loggedInUser["company_id"]}`)
            .then(res=>{
                var data=res.data;
                generateTableSupportingDetils(data["data"]); 
                var temp = tableContent;
                temp["isLoading"] = false;
                temp["data"] = data["data"];
                temp["total"] = data["filterTotal"];
                setTableContent({...temp});
                setFormDataCount((old)=>({...old,"all":data["count"]["all"],"unassigned":data["count"]["unAssigned"]}));
                if(riskRegisterModal["isOpen"]===true){
                    if(data.riskdata.length !== 0){
                        if(riskRegisterModal["index"] !== ""){
                            openRiskRegisterModal(data["riskdata"].at(riskRegisterModal["index"])["id"]);
                            setRiskRegisterModal((old)=>({...old,index:""}));
                        }
                    }
                }
            }).catch(err => {
                dispatch(toastUp(getErrorMsg(err)));
                setTableContent(old => ({ ...old, isLoading: false }));
            })
        }
        fetchData();
        // eslint-disable-next-line
    }, [pageState.page, pageState.pageSize,selectedFilter])
    return (
        <div>
            {/* selected menu details */}
            <HeaderSection header='View Risk' breadcrumbs={["Cyber Risk Management","Risk Register"]} specialButtons={[<Button onClick={()=>{dispatch(setPage(("riskRegister")))}} style={{color: "black"}} className='mlr5 roButton' variant="text" startIcon={<ArrowBackIosIcon/>}>back</Button>]}/>
            {/* form header */}
            <div className='m20'>
                <Paper elevation={16}>
                    <div className={`formHeaderSection ptlr15`}>
                        <Grid container spacing={1} direction="row" justifyContent="space-between" alignItems="flex-end">
                            <Grid item zeroMinWidth>
                                <div className={`p10 ${viewRiskUiHandler === "all" ? "selectedFormHeader" : "buttonProperty" } ${style.formHeader}`} onClick={()=>{changeViewRiskUi("all")}}>{`All (${formDataCount["all"]})`}</div>
                            </Grid>
                            <Grid item zeroMinWidth>
                                <div className={`buttonProperty p10 ${style.formHeader}`}>{`Triage (${formDataCount["triage"]})`}</div>
                            </Grid>
                            <Grid item zeroMinWidth>
                                <div className={`buttonProperty p10 ${style.formHeader}`}>{`Assess (${formDataCount["triage"]})`}</div>
                            </Grid>
                            <Grid item zeroMinWidth>
                                <div className={`buttonProperty p10 ${style.formHeader}`}>{`Active (${formDataCount["active"]})`}</div>
                            </Grid>
                            <Grid item zeroMinWidth>
                                <div className={`buttonProperty p10 ${style.formHeader}`}>{`Review (${formDataCount["review"]})`}</div>
                            </Grid>
                            <Grid item zeroMinWidth>
                                <div className={`buttonProperty p10 ${style.formHeader}`}>{`Closed (${formDataCount["closed"]})`}</div>
                            </Grid>
                            <Grid item zeroMinWidth>
                                <div className={`p10 ${viewRiskUiHandler === "unassigned" ? "selectedFormHeader" : "buttonProperty" } ${style.formHeader}`} onClick={()=>{changeViewRiskUi("unassigned")}}>{`Unassigned (${formDataCount["unassigned"]})`}</div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className='m15 mt30'>
                        <Grid container spacing={2}>
                            <Grid item sm={6} md={3}>
                                <TextField label="Search" type="search" sx={searchFieldStyle.sx} InputLabelProps={searchFieldStyle.label} size="small" inputRef={searchField} onKeyPress={applySearch} fullWidth variant="outlined" />
                            </Grid>
                        </Grid>
                    </div>
                    <div className='p15'>
                        <DataGridCustom rows={tableContent.data} rowCount={tableContent.total} isLoading={tableContent.isLoading} pageSizeOptions={[30,50,100]} paginationModel={pageState} onPaginationModelChange={setPageState} columns={columns}/>
                    </div>
                </Paper>
            </div>
            {/* modal to view and slide between risk registers */}
            <ModalViewInfo open={riskRegisterModal.isOpen} closeHandler={closeRiskRegisterModal}>
                <ViewRiskRegisterDetailForModal buttonDisable={riskRegisterModal.buttonDisable} pageModal={pageState} nextPrevPageHandler={nextPrevPageRiskRegisterModalHandler} riskRegister={riskRegisterModal.content} currentId={riskRegisterModal.currentId}/>
            </ModalViewInfo>
        </div>
    );
}

export default ViewRisk;