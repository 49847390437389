import React from 'react';
import {Box,Modal} from '@mui/material';
import { modalViewInfoStyle } from '../../js/standardStyle';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import style from '../../css/ModalViewInfo.module.css';

function ModalViewInfo(props) {
    const {max,ignoreClose} = props;
    var overRideStyle = {};
    if(max){
        overRideStyle["width"] = "85%";
    }
    return (
        // modal wrapper element for displaying popups with pre defined style
        <Modal open={props.open} onClose={props.closeHandler}>
            <Box sx={{...modalViewInfoStyle,...overRideStyle}}>
                {
                    (ignoreClose) ?
                        <></>
                    :
                        <div className={style.closeSection}>
                            <HighlightOffTwoToneIcon onClick={props.closeHandler} className={style.closeButton} />
                        </div>
                }
                {props.children}
            </Box>
        </Modal>
    );
}

export default ModalViewInfo;