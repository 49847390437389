import HeaderSection from '../HeaderSection';
import React, { useState, useEffect } from 'react';
import DataGridCustom from '../wrapper/DataGridCustom';
import axios from 'axios';
import { SERVICE_URL } from '../../config';
import { useDispatch, useSelector } from 'react-redux';
import style from '../../css/IssueTracker.module.css';
import { generateArrayForAutoCompleteBasedOnkey, getArrayOfObjectBasedOnKey, getBudgetPercent, getErrorMsg, getProjectFinancialFormulatedValue } from '../../js/util';
import { toastUp } from '../../redux/actions/toast';
import ModalViewInfo from '../wrapper/ModalViewInfo';
import { setSpinner } from '../../redux/actions/spinner';
import { autocompleteStyle, textFieldStyle, disabledTextFieldStyle } from '../../js/standardStyle';
import { renderDisplayValueWithLableForAutocomplete } from '../../js/util';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {IconButton,Menu,MenuItem,Grid,Autocomplete, TextField, Button} from '@mui/material';
import ModalForm from '../wrapper/ModalForm';
import ViewProjectDetailsForModal from './ViewProjectDetailsForModal';
import ModalFormDisplay from '../wrapper/ModalFormDisplay';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PROJECT_COST_MANAGEMENT from '../../pdf/PROJECT_COST_MANAGEMENT.pdf'

// import ViewIssueDetailsForModal from './ViewIssueDetailsForModal';

function ProjectFinancial(props) {
    
    // get use dispatch
    const dispatch = useDispatch();
    // get loggedin user from redux
    const loggedInUser = useSelector((state)=>state.loggedInUser);
    // init use state for store filter details
    const [selectedFilter,setSelectedFilter] = useState("");
    // init use state for form data
    const [formData, setFormData] = useState({"name":null}); 
    // init use state for form meta data
    const [formOptionsMetaData, setFormOptionsMetaData] = useState({"name":[]});
    // function to set form data for auto complete
    const handleFormDataAutoCompleteChange = key => (event,object) => {
        var temp = formData;
        temp[key] = object;
        setFormData({...temp});
        applyFilter();
    }
    // init use state handle page for table
    const [pageState, setPageState] = useState({
        page: 0,
        pageSize: 30
    })
     // init use state to store table contents
     const [tableContent,setTableContent] = useState({
        isLoading: false,
        data: [],
        total: 0,
    }) 
    const getActionCellContent = (params) => (
        <div className={`${style.actionRow}`}>
            <IconButton aria-label="more" id="long-button" aria-controls={Boolean(columnState[params.id]["anchorEl"]) ? 'long-menu' : undefined} aria-expanded={Boolean(columnState[params.id]["anchorEl"]) ? 'true' : undefined} aria-haspopup="true" onClick={handleMenuAnchorOpen(params.id)}>
                <MoreVertIcon className='icon' />
            </IconButton>
            <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button'}} anchorEl={columnState[params.id]["anchorEl"]} open={Boolean(columnState[params.id]["anchorEl"])} onClose={()=>{handleMenuAnchorClose(params.id)}}>
                {
                    (params.row.financial !== undefined && Object.keys(params.row.financial).length === 0) ?
                        <MenuItem onClick={()=>openFinancialModal(params.id)} >Add Financial</MenuItem>
                    :
                        <MenuItem onClick={()=>editFinancialModal(params.id)}>Edit Financial</MenuItem>
                }
                <MenuItem onClick={()=>openBudgetPercentCalculatorModal(params.id)}>Budget Percent Calculator</MenuItem>
            </Menu>
        </div>
    ) 


    // init table column details
    const columns = [
        {field: 'name', sortable: false, flex: 1, renderHeader: () => (<strong>{'Project Name'}</strong>), renderCell: (params)=> (<div className={`MuiDataGrid-cellContent ${(!params.row.inActiveFlag) ? 'hyperLinkActive' : ''}`} title={params.value} onClick={()=>{openViewProjectModal(params.id)}}>{params.value}</div>)},
        {field: 'manager', sortable: false, flex: 1, renderHeader: () => (<strong>{'Project Manager'}</strong>)},
        {field: 'pmoOffice', sortable: false, flex: 1, renderHeader: () => (<strong>{'PMO Office'}</strong>)},
        {field: 'action', sortable: false, width: 100, renderHeader: () => (<strong>{'Action'}</strong>),renderCell: getActionCellContent}
    ]

    // init use state for column 
    const [columnState,setColumnState] = useState({});
    // function to generate use state for table column
    const generateTableSupportingDetils = (data) => {
        var updatedColumnState = {};
        for(const row of data){
            updatedColumnState[row["id"]] = {"anchorEl":null};
        }
        setColumnState({...updatedColumnState});
    }
    // function to handle open menu anchor
    const handleMenuAnchorOpen = key => event => {
        var updatedColumnState = columnState;
        updatedColumnState[key]["anchorEl"] = event.currentTarget;
        setColumnState({...updatedColumnState});
    };
    // function to handle menu anchor
    const handleMenuAnchorClose = key => {
        var updatedColumnState = columnState;
        updatedColumnState[key]["anchorEl"] = null;
        setColumnState({...updatedColumnState});
    };
    // init budget percent calculator modal
    const [budgetPercentCalculatorModal,setBudgetPercentCalculator] = useState({"isOpen":false,"value":{"plannedBudget":"","actualCost":"","result":""}});
    // function to open budget percent calculator modal
    const openBudgetPercentCalculatorModal = (id) => {
        var temp = budgetPercentCalculatorModal;
        var selectedData = getArrayOfObjectBasedOnKey(tableContent["data"],"id",id);
        if( selectedData === null){
            dispatch(toastUp("Issue Selecting Project"));
            return false;
        }
        temp["isOpen"] = true;
        if(Object.keys(selectedData.financial).length !== 0){
            temp["value"]["actualCost"] = selectedData["financial"]["actualCost"];
            temp["value"]["plannedBudget"] = selectedData["financial"]["plannedBudget"];
        }
        setBudgetPercentCalculator({...budgetPercentCalculatorModal});
        handleMenuAnchorClose(id);
    }
    // function to close budget percent calculator modal
    const closeBudgetPercentCalculatorModal = () => {
        var temp = budgetPercentCalculatorModal;
        temp["isOpen"] = false;
        temp["value"]["plannedBudget"] = "";
        temp["value"]["actualCost"] = "";
        temp["value"]["result"] = "";
        setBudgetPercentCalculator({...budgetPercentCalculatorModal});
    }
    // function to handle vaue change for budget percent calculator
    const handleBudgetPercentCalculatorValueChange = key => (event) => {
        var temp = budgetPercentCalculatorModal;
        temp["value"][key] = event.target.value;
        setBudgetPercentCalculator({...temp});
    }
    // function to calculate budget financial
    const calculateBudgetFinancial = () => {
        var temp = budgetPercentCalculatorModal
        var value = temp["value"];
        if(value["plannedBudget"] === ""){
            dispatch(toastUp("Planned Budget field cannot be empty"))
            return false;
        }
        if(value["actualCost"] === ""){
            dispatch(toastUp("Actual Cost field cannot be empty"))
            return false;
        }
        var budgetPercent = getBudgetPercent(value["plannedBudget"],value["actualCost"]);
        temp["value"]["result"] = budgetPercent;
        setBudgetPercentCalculator({...temp});
        dispatch(toastUp("Budget percent calculated"));
    }
    // init use state for project modal
    const [viewProjectModal,setViewProjectModal] = useState({"isOpen":false,"projectDetails":{}});
    // function to open view project modal
    const openViewProjectModal = (id) => {
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}issue/project?id=${id}`)
        .then((res)=>{
            var temp = viewProjectModal;
            temp["isOpen"] = true;
            temp["projectDetails"] = res["data"];
            setViewProjectModal({...temp});
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
    }
    // function to close project modal
    const closeViewProjectModal = () => {
        var temp = viewProjectModal;
        temp["isOpen"] = false;
        temp["projectDetails"] = {};
        setViewProjectModal({...temp});
    }
    // init use state for financial modal
    const [financialModal,setFinancialModal] = useState({"isOpen":false,"mode":"","header":"","currentId":"","parentId":"","value":{"budget":"","plannedBudget":"","actualCost":"","plannedCompletionPercentage":"","workCompletionPercentage":"","plannedValue":"","earnedValue":"","scheduleVariance":"","schedulePerformanceIndex":"","costVariance":"","costPerformanceIndex":""}})
    // function to open financial modal
    const openFinancialModal = (id) => {
        var temp = financialModal;
        temp["isOpen"] = true;
        temp["mode"] = "add";
        temp["header"] = "Add Project Financial";
        temp["parentId"] = id;
        setFinancialModal({...temp});
        handleMenuAnchorClose(id);
    }
    // function to edit financial modal
    const editFinancialModal = (id) => {
        var temp = financialModal;
        var selectedData = getArrayOfObjectBasedOnKey(tableContent["data"],"id",id);
        if( selectedData === null){
            dispatch(toastUp("Issue Selecting Project"));
            return false;
        }
        temp["isOpen"] = true;
        temp["mode"] = "edit";
        temp["header"] = "Edit Project Financial";
        temp["parentId"] = id;
        temp["currentId"] = selectedData["financial"]["id"]
        temp["value"]["actualCost"] = selectedData["financial"]["actualCost"];
        temp["value"]["plannedBudget"] = selectedData["financial"]["plannedBudget"];
        temp["value"]["plannedCompletionPercentage"] = selectedData["financial"]["plannedCompletionPercentage"];
        temp["value"]["workCompletionPercentage"] = selectedData["financial"]["workCompletionPercentage"];
        temp["value"]["budget"] = selectedData["financial"]["budget"];
        setFinancialModal({...temp});
        setFormulatedValue();
        handleMenuAnchorClose(id);
    }
    // function to close finaicial modal
    const closeFinancialModal = () => {
        var temp = financialModal;
        temp["isOpen"] = false;
        temp["mode"] = "";
        temp["header"] = "";
        temp["currentId"] = "";
        temp["parentId"] = "";
        temp["value"]["actualCost"] = "";
        temp["value"]["plannedBudget"] = "";
        temp["value"]["plannedCompletionPercentage"] = "";
        temp["value"]["budget"] = "";
        temp["value"]["workCompletionPercentage"] = "";
        temp["value"]["plannedValue"] = "";
        temp["value"]["earnedValue"] = "";
        temp["value"]["scheduleVariance"] = "";
        temp["value"]["schedulePerformanceIndex"] = "";
        temp["value"]["costVariance"] = "";
        temp["value"]["costPerformanceIndex"] = "";
        setFinancialModal({...temp});
    }
    // function to handle save handler for financial modal
    const saveHandlerForFinaicialModal = (mode) => {
        var data = validateAndGetFinancialModalValue();
        if(data["status"] === "valid"){
            if(mode === "add"){
                setProjectFinancial(data["data"]);
            }else if(mode === "edit"){
                editProjectFinancial(data["data"])
            }
        }else{
            dispatch(toastUp(data["mg"]));
        }
    }
    // add project finaicial
    const setProjectFinancial = (data) => {
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}issue/project/financial?projectId=${financialModal["parentId"]}`,data)
        .then((res)=>{
            fetchData();
            closeFinancialModal();
            dispatch(toastUp("Project Financial created successfully"));
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
    }
    // edit project finaicial
    const editProjectFinancial = (data) => {
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}issue/project/financial?id=${financialModal["currentId"]}`,data)
        .then((res)=>{
            fetchData();
            closeFinancialModal();
            dispatch(toastUp("Project Financial edit successfully"));
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
    }
    // validate and get fianicail data
    const validateAndGetFinancialModalValue = () => {
        var data = {}
        data["status"] = "inValid";
        var value = financialModal["value"];
        if(value["budget"] === ""){
            data["msg"] = "Budget field cannot be empty";
            return data;
        }
        if(value["plannedBudget"] === ""){
            data["msg"] = "Planned Budget field cannot be empty";
            return data;
        }
        if(value["plannedCompletionPercentage"] === ""){
            data["msg"] = "Planned Completion Percentage field cannot be empty";
            return data;
        }
        if(value["actualCost"] === ""){
            data["msg"] = "Actual Cost field cannot be empty";
            return data;
        }
        if(value["workCompletionPercentage"] === ""){
            data["msg"] = "Work Completion Percentage field cannot be empty";
            return data;
        }
        data["status"] = "valid";
        var formValue  = {}
        formValue["plannedBudget"] = value["plannedBudget"]
        formValue["actualCost"] = value["actualCost"];
        formValue["plannedCompletionPercentage"] = value["plannedCompletionPercentage"];
        formValue["workCompletionPercentage"] = value["workCompletionPercentage"];
        formValue["budget"] = value["budget"];
        data["data"] = formValue;
        return data;
    }
    // function change value for financial modal
    const handleFinancialValueChange = (key) => (event) => {
        // const re = /^[0-9\b]+$/;
        var temp = financialModal;
        var value = event.target.value;
        if(["plannedCompletionPercentage","workCompletionPercentage"].includes(key)){
            if(value.length > 3){
                return false;
            }
        }
        temp["value"][key] = value;
        setFinancialModal({...temp});
        setFormulatedValue();            
    }
    // function to set formulated value
    const setFormulatedValue = () => {
        var temp = financialModal;
        if(
            financialModal["value"]["actualCost"] !== "" &&
            financialModal["value"]["workCompletionPercentage"] !== "" &&
            financialModal["value"]["plannedBudget"] !== "" &&
            financialModal["value"]["plannedCompletionPercentage"] !== ""
        ){
            var formulatedValue = getProjectFinancialFormulatedValue(financialModal["value"]);
            temp["value"]["plannedValue"] = formulatedValue["plannedValue"];
            temp["value"]["earnedValue"] = formulatedValue["earnedValue"];
            temp["value"]["scheduleVariance"] = formulatedValue["scheduleVariance"];
            temp["value"]["schedulePerformanceIndex"] = formulatedValue["schedulePerformanceIndex"];
            temp["value"]["costVariance"] = formulatedValue["costVariance"];
            temp["value"]["costPerformanceIndex"] = formulatedValue["costPerformanceIndex"];
        }else{
            temp["value"]["plannedValue"] = "";
            temp["value"]["earnedValue"] = "";
            temp["value"]["scheduleVariance"] = "";
            temp["value"]["schedulePerformanceIndex"] = "";
            temp["value"]["costVariance"] = "";
            temp["value"]["costPerformanceIndex"] = "";
        }
        setFinancialModal({...temp})
    }
    // use effect to call the function when ever the page changes
    const fetchData = async () => {
        setTableContent(old => ({ ...old, isLoading: true }))
        axios.get(`${SERVICE_URL}issue/project/view?page=${pageState.page+1}&size=${pageState.pageSize}&companyId=${loggedInUser["company_id"]}${selectedFilter}`)
        .then(res=>{
            var data=res.data;
            generateTableSupportingDetils(data["data"]); 
            var temp = tableContent;
            temp["isLoading"] = false;
            temp["data"] = data["data"];
            temp["total"] = data["totalCount"];
            setTableContent({...temp});
        }).catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
            setTableContent(old => ({ ...old, isLoading: false }));
        })
    }
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [pageState.page, pageState.pageSize,selectedFilter])
    useEffect(()=>{
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}issue/project/view?companyId=${loggedInUser["company_id"]}`)
        .then(res => {
            var temp = formOptionsMetaData;
            temp["name"] = generateArrayForAutoCompleteBasedOnkey(res["data"]["data"],'name');
            setFormOptionsMetaData({...temp});
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        // eslint-disable-next-line
    },[]);
    
    // function to apply filter
    const applyFilter = () => {
        var filter = "";
        if(formData["name"] !== null){
            filter+=`&name=${formData["name"]}`;
        }
        setSelectedFilter(filter);
        setPageState((old)=>({...old,page:0}));
    }

    return (
        <div>
            <HeaderSection header='Project Financials' breadcrumbs={["Issues Management","Project Financials"]} />
            <div className='m20'>
                <div className={`plr10 ptb30 ${style.filterSection}`}>
                    <Grid container spacing={2}>
                        <Grid item sm={6} md={3}>
                            <Autocomplete value={formData["name"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["name"]} freeSolo size="small" disablePortal onChange={handleFormDataAutoCompleteChange("name")} renderInput={renderDisplayValueWithLableForAutocomplete('Project Name')}/>
                        </Grid>
                    </Grid>
                </div>
                <DataGridCustom rows={tableContent.data} rowCount={tableContent.total} isLoading={tableContent.isLoading} pageSizeOptions={[30,50,100]} paginationModel={pageState} onPaginationModelChange={setPageState} columns={columns}  combineSearch={true}/>
            </div>
            <ModalForm open={financialModal.isOpen} mode={financialModal.mode} closeHandler={closeFinancialModal} header={financialModal.header} saveHandler={()=>saveHandlerForFinaicialModal(financialModal.mode)}>
                <Grid container spacing={2}>
                <Grid item sm={12} md={6}>
                        <span className='lable'>Budget </span><span className='required'>*</span><br/>
                        <TextField type='number' step={1} fullWidth size='small' value={financialModal["value"]["budget"]} sx={textFieldStyle} onChange={handleFinancialValueChange("budget")} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Planned Budget (BAC) </span><span className='required'>*</span><br/>
                        <TextField type='number' step={1} fullWidth size='small' value={financialModal["value"]["plannedBudget"]} sx={textFieldStyle} onChange={handleFinancialValueChange("plannedBudget")} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>% of Project Completed (Planned) </span><span className='required'>*</span><br/>
                        <TextField type='number' step={1} fullWidth size='small' value={financialModal["value"]["plannedCompletionPercentage"]} sx={textFieldStyle} onChange={handleFinancialValueChange("plannedCompletionPercentage")} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Planned Value </span><br/>
                        <TextField type='number' disabled step={1} fullWidth size='small' value={financialModal["value"]["plannedValue"]} sx={disabledTextFieldStyle} onChange={handleFinancialValueChange("plannedCompletionPercentage")} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>% of Completed (Work) </span><span className='required'>*</span><br/>
                        <TextField type='number' step={1} fullWidth size='small' value={financialModal["value"]["workCompletionPercentage"]} sx={textFieldStyle} onChange={handleFinancialValueChange("workCompletionPercentage")} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Earned Value </span><br/>
                        <TextField type='number' disabled step={1} fullWidth size='small' value={financialModal["value"]["earnedValue"]} sx={disabledTextFieldStyle} onChange={handleFinancialValueChange("workCompletionPercentage")} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Actual Cost </span><span className='required'>*</span><br/>
                        <TextField type='number' step={1} fullWidth size='small' value={financialModal["value"]["actualCost"]} sx={textFieldStyle} onChange={handleFinancialValueChange("actualCost")} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Schedule Variance </span><br/>
                        <TextField type='number' disabled step={1} fullWidth size='small' value={financialModal["value"]["scheduleVariance"]} sx={disabledTextFieldStyle} onChange={handleFinancialValueChange("actualCost")} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Schedule Performance Index (SPI) </span><br/>
                        <TextField type='number' disabled step={1} fullWidth size='small' value={financialModal["value"]["schedulePerformanceIndex"]} sx={disabledTextFieldStyle} onChange={handleFinancialValueChange("actualCost")} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Cost Variance </span><br/>
                        <TextField type='number' disabled step={1} fullWidth size='small' value={financialModal["value"]["costVariance"]} sx={disabledTextFieldStyle} onChange={handleFinancialValueChange("actualCost")} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Cost Performance Index (CPI) </span><br/>
                        <TextField type='number' disabled step={1} fullWidth size='small' value={financialModal["value"]["costPerformanceIndex"]} sx={disabledTextFieldStyle} onChange={handleFinancialValueChange("actualCost")} />
                    </Grid>
                </Grid>
                <div style={{textAlign:"right"}}>
                    <a target="_blank" rel="noreferrer" href={PROJECT_COST_MANAGEMENT}><Button size='small' variant='text' endIcon={<HelpOutlineIcon/>}>help</Button></a>
                </div>
            </ModalForm>
            <ModalFormDisplay open={budgetPercentCalculatorModal.isOpen} closeHandler={closeBudgetPercentCalculatorModal} header={"Budget Percent Calculator"} saveHandler={()=>calculateBudgetFinancial()} saveName={"calculate"}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Planned Budget (BAC) </span><span className='required'>*</span><br/>
                        <TextField type='number' step={1} fullWidth size='small' value={budgetPercentCalculatorModal["value"]["plannedBudget"]} sx={textFieldStyle} onChange={handleBudgetPercentCalculatorValueChange("plannedBudget")} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Actual Cost </span><span className='required'>*</span><br/>
                        <TextField type='number' step={1} fullWidth size='small' value={budgetPercentCalculatorModal["value"]["actualCost"]} sx={textFieldStyle} onChange={handleBudgetPercentCalculatorValueChange("actualCost")} />
                    </Grid>
                    <Grid item sm={12}>
                        <div className='mtb10 taCenter'>
                            {
                                (budgetPercentCalculatorModal["value"]["result"] !== "" && parseFloat(budgetPercentCalculatorModal["value"]["result"])) ?
                                    <span>Budget percent is {parseFloat(budgetPercentCalculatorModal["value"]["result"]).toFixed(2)}% and This project {budgetPercentCalculatorModal["value"]["result"] > 0 ? "was under" : "went over"} budget</span>
                                :
                                    (parseInt(budgetPercentCalculatorModal["value"]["result"]) === 0 ) ?
                                        <span>Budget percent is {parseFloat(budgetPercentCalculatorModal["value"]["result"]).toFixed(2)}% and This project equal to budget</span>
                                    :
                                        <></>
                            }
                        </div>    
                    </Grid>
                </Grid>
            </ModalFormDisplay>
            <ModalViewInfo open={viewProjectModal.isOpen} closeHandler={closeViewProjectModal}>
                <ViewProjectDetailsForModal projectDetails={viewProjectModal.projectDetails}/>
            </ModalViewInfo>
            {/* <ModalForm open={false} header={"Add Project Security"}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Security Questionnaire </span><span className='required'>*</span><br/>
                        <AutocompleteWithMoreAED value={null} options={[]} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Security Objective </span><span className='required'>*</span><br/>
                        <Autocomplete size='small' value={null} options={[]} renderInput={renderDisplayValueForAutocomplete} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Security Impact </span><span className='required'>*</span><br/>
                        <Autocomplete size='small' value={null} options={[]} renderInput={renderDisplayValueForAutocomplete} />
                    </Grid>
                </Grid>
            </ModalForm> */}
        </div>
    );
}

export default ProjectFinancial;