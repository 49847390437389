import React from 'react';
import {Typography,Box,Modal,Button} from '@mui/material';
import { modalStyle } from '../../js/standardStyle';

function ModalForm(props) {

    return (
        // modal wrapper element for displaying popups with pre defined style
        <Modal open={props.open} onClose={props.closeHandler}>
            <Box sx={modalStyle}>
                <Typography className='cDark' id="modal-modal-title" variant="h6" component="h2">
                    {props.header}
                </Typography>
                <br/>
                <div id="modal-modal-description" sx={{ mt: 2 }}>
                    {props.children}
                </div>
                <div className='mt25' style={{textAlign:"center"}}>
                    <Button className='mlr5 roButton' onClick={props.saveHandler} variant="contained"> { props.mode === "edit" ? "edit" : "create" } </Button>
                    <Button className='mlr5 roTextButton' onClick={props.closeHandler} variant="text">cancel</Button>
                </div>
            </Box>
        </Modal>
    );
}

export default ModalForm;