export const setAlert = (payload) => {
    return {
        type:"setAlert",
        payload:payload
    }
}

export const closeAlert = () => {
    return {
        type:"closeAlert"
    }
}