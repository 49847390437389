import React, {useState, useEffect} from 'react';
import HeaderSection from '../HeaderSection';
import DataGridCustomFull from '../wrapper/DataGridCustomFull';
import axios from 'axios';
import {MenuItem, Grid, Select,Button} from '@mui/material';
import ModalFormWithCustomButton from '../wrapper/ModalFormWithCustomButton';
import { SERVICE_URL } from '../../config';
import { selectFieldStyle } from '../../js/standardStyle';
import { generateDropDown, getNameBasedOnValue, getErrorMsg, wrapEmailInArrayOfObject } from '../../js/util';
import { assessmentRegisterStatus } from '../../js/constants';
import { useSelector, useDispatch } from 'react-redux';
import { setSpinner } from '../../redux/actions/spinner';
import { toastUp } from '../../redux/actions/toast';

function AcceptRejectViewAssessment(props) {

    var companyId = "2";

    // get dispatch from redux
    const dispatch = useDispatch();
    
    // get loggedin user details
    const loggedInUser = useSelector(state => state.loggedInUser);

    // init use state handle page for table
    const [pageState, setPageState] = useState({
        page: 0,
        pageSize: 30
    })
    // init use state to store table contents
    const [tableContent,setTableContent] = useState({
        isLoading: false,
        data: [],
        total: 0,
    })    
    // init table column details
    const columns = [
        {field: 'name', sortable: false, flex: 1, renderHeader: () => (<strong>{'Assessment Name'}</strong>)},
        {field: 'framework', sortable: false, flex: 1, renderHeader: () => (<strong>{'Framework Source'}</strong>)},
        {field: 'category', sortable: false, flex: 1, renderHeader: () => (<strong>{'Risk Category'}</strong>)},
        {field: 'type', sortable: false, flex: 1, renderHeader: () => (<strong>{'Assessment Type'}</strong>)},
        {field: 'assigned_to', sortable: false, flex: 1, renderHeader: () => (<strong>{'Assigned To'}</strong>)},
        {field: 'status', sortable: false, width: 200, renderHeader: () => (<strong>{'Status'}</strong>), renderCell: (params) => {var displayValue = getNameBasedOnValue(assessmentRegisterStatus,params.value); return <><div className="MuiDataGrid-cellContent" title={displayValue}>{displayValue}</div>{params.value === 'rejected' ? <Button onClick={()=>{openReAssignModal(params.id)}} className='ptb2 plr10 ml15 riskOneGreenSmallBtn' size="small" variant="contained">re-assign</Button> : <></>}</>}}
    ]
    
    // init use state for assign modal
    const [reAssignModal,setReAssignModal] = useState({"isOpen":false,"header":"Re-Assign Assessment To User","value":{"user":"","register":""},"metaData":{"user":[]}});
    // function to open assign modal
    const openReAssignModal = registerId => {
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}user?company_id=${companyId}&groups=rbr`)
        .then(res => {
            if(res.status === 200){
                var data = res["data"];
                var temp = reAssignModal;
                temp["metaData"]["user"] = wrapEmailInArrayOfObject(data["data"],'displayName');
                temp["value"]["register"] = registerId
                temp["isOpen"] = true;
                setReAssignModal({ ...temp});
            }else{
                console.log(res)
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to close assign modal
    const handleReAssignModalClose = () => {
        var temp = reAssignModal;
        temp["metaData"]["user"] = [];
        temp["value"]["user"] = "";
        temp["value"]["register"] = "";
        temp["isOpen"] = false;
        setReAssignModal({ ...temp});
    }
    // function to set form value to assign modal value field
    const reAssignModalValueHandler = key => event => {
        var temp = reAssignModal;
        temp['value'][key] = event.target.value;
        setReAssignModal({...temp});
    }
    // function to assign the framework to user
    const handleReAssignModalSubmit = () => {
        if(reAssignModal["value"]["user"] === ""){
            dispatch(toastUp("Please select user to re-assign"));
            return false;
        }
        var registerId = reAssignModal["value"]["register"];
        var data = {};
        data["status"] = "re-assign";
        data["assignedTo"] = reAssignModal["value"]["user"];
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}assessment/register/changeStatus?registerAssessmentId=${registerId}`,data)
        .then(res => {
            if(res.status === 200){
                var totalPageNumbers = Math.ceil(tableContent["total"]/pageState["pageSize"]);
                if(totalPageNumbers !== 1 && totalPageNumbers === pageState["page"]+1 && tableContent["data"].length === 1){
                    var temp = pageState;
                    temp["page"]= pageState["page"]-1;
                    setPageState({...temp});
                }else{
                    fetchData();
                }
                dispatch(toastUp("Assessment re-assigned successfully"));
                handleReAssignModalClose();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }

    // fetch table data
    const fetchData = async () => {
        setTableContent(old => ({ ...old, isLoading: true }))
        axios.get(`${SERVICE_URL}assessment/register/view?page=${pageState.page+1}&size=${pageState.pageSize}&assignedBy=${loggedInUser["id"]}&groupBy=acceptReject`)
        .then(res=>{
            var data = res.data;
            var temp = tableContent;
            temp["isLoading"] = false;
            temp["data"] = data["data"];
            temp["total"] = data["total"];
            setTableContent({...temp});
        }).catch(err=>{
            dispatch(toastUp(getErrorMsg(err)));
            setTableContent(old => ({ ...old, isLoading: false }));
        })
    }

    // use effect to call the function when ever the page changes 
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [pageState.page, pageState.pageSize])
    return (
        <div>
            {/* selected menu details */}
            <HeaderSection header='Accept/Reject View' breadcrumbs={["Assessment Managment","Accept/Reject View"]}/>
            <div className='m20'>
                <DataGridCustomFull rows={tableContent.data} rowCount={tableContent.total} isLoading={tableContent.isLoading} pageSizeOptions={[30,50,100]} paginationModel={pageState} onPaginationModelChange={setPageState} columns={columns}/>
            </div>
            {/* assign risk framework to a user */}
            <ModalFormWithCustomButton open={reAssignModal.isOpen} closeHandler={handleReAssignModalClose} header={reAssignModal.header} saveName={"re-assign"} saveHandler={handleReAssignModalSubmit}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                        <span className='lable'>Users <span className='required'>*</span></span><br/>
                        <Select MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={reAssignModalValueHandler("user")} value={reAssignModal["value"]["user"]} displayEmpty>
                            <MenuItem value=""><em>-- please select--</em></MenuItem>
                            {generateDropDown(reAssignModal["metaData"]["user"],"displayName")}
                        </Select>
                    </Grid>
                </Grid>
            </ModalFormWithCustomButton>
        </div>
    );
}

export default AcceptRejectViewAssessment;