import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { MenuItem, Select } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import style from '../../css/SelectWithMoreAED.module.css'
import { selectFieldStyle } from '../../js/standardStyle';

function SelectWithMoreAED(props) {

    const ITEM_HEIGHT = 48;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleMenuItemClick = key => event => {
        if(key === "add"){
            props.addHandler();
        }else if(key === "edit"){
            props.editHandler();
        }else if(key === "delete"){
            props.deleteHandler();
        }
        setAnchorEl(null);
    };

    return (
        <div>
            <Select className={style.selectFieldSection} MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} size="small" onChange={props.onChangeHandler} value={props.value} displayEmpty>
                <MenuItem disabled value=""><em>-- please select--</em></MenuItem>
                {props.options}
            </Select>
            <IconButton className={style.iconSection}
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon className='icon' />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                },
                }}
            >
                <MenuItem onClick={handleMenuItemClick("add")}>Add</MenuItem>
                <MenuItem onClick={handleMenuItemClick("edit")}>Edit</MenuItem>
                <MenuItem onClick={handleMenuItemClick("delete")}>Delete</MenuItem>
            </Menu>
    </div>
    );
}

export default SelectWithMoreAED;