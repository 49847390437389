import React, { useEffect, useState } from 'react';
import HeaderSection from '../HeaderSection';
import { Button, Collapse, Menu, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import style from '../../css/Policy.module.css'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CreateIcon from '@mui/icons-material/Create';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../redux/actions/alert';
import Existing from './addPolicy/Existing';
import PolicyNumber from './addPolicy/PolicyNumber';
import ChangePolicy from './ChangePolicy';
import RegulatoryRef from './addPolicy/RegulatoryRef';
import PreConfiguredTemplate from './addPolicy/PreConfiguredTemplate';
import DeletePolicy from './DeletePolicy';
import MovePolicy from './MovePolicy';
import CustomiseTemplate from './addPolicy/CustomiseTemplate';

function Policy(props) {

    // get use dispatch form redux
    const dispatch = useDispatch();
    // init use state for policy ui handler
    const [policyUi,setPolicyUi] = useState("");
    // function to set policy ui
    const uiHandler = () => {
        if(policyUi === "existing"){
            return <Existing />
        }else if(policyUi === "policyNumber"){
            return <PolicyNumber />
        }else if(policyUi === "changePolicy"){
            return <ChangePolicy {...props} />
        }else if(policyUi === "regulatoryRef"){
            return <RegulatoryRef />
        }else if(policyUi === "preConfiguredTemplate"){
            return <PreConfiguredTemplate {...props} />
        }else if(policyUi === "deletePolicy"){
            return <DeletePolicy />
        }else if(policyUi === "movePolicy"){
            return <MovePolicy />
        }else if(policyUi === "customizeTemplate"){
            return <CustomiseTemplate {...props}/>
        }
    }
    // function to set ui and close anchor
    const setPolicyUIandCloseAnchor = (policy,uiName) => {
        setPolicyUi(uiName);
        closeMenuAnchor(policy);
    }
    // init use state for menu policy
    const [menuPolicy,setMenuPolicy] = useState({"addPolicy":{"anchor":null,"subMenu":{"policy":false,"new":false}},"movePolicy":{"anchor":null},"changePolicy":{"anchor":null},"deletePolicy":{"anchor":null}});
    // function to open anchor of menu policy
    const openMenuAnchor = key => event => {
        var temp = menuPolicy;
        temp[key]['anchor'] = event.currentTarget;
        setMenuPolicy({...temp});
        closeSubMenuOfAnchor(key)
    };
    // function to close anchor of menu policy
    const closeMenuAnchor = (key) => {
        var temp = menuPolicy;
        temp[key]['anchor'] = null;
        setMenuPolicy({...temp});
    };
    // function to toggle between sub menu of anchor
    const toggleSubMenuOfAnchor = (policy,key) => {
        var temp = menuPolicy;
        temp[policy]["subMenu"][key] = !menuPolicy[policy]["subMenu"][key];
        setMenuPolicy({...temp})
    }
    // function to close sub menu of anchor
    const closeSubMenuOfAnchor = (policy) => {
        var temp = menuPolicy;
        for(var key in temp[policy]["subMenu"]){
            temp[policy]["subMenu"][key] = false;
        }
        setMenuPolicy({...temp});
    }
    
    // function to generate add policy button
    const getAddPolicyButton = () =>{
        return(
            <>
                <Button size='small' 
                    variant='contained' 
                    startIcon={<AddIcon/>} 
                    aria-controls='addPolicyMenu'
                    aria-haspopup="true"
                    onClick={openMenuAnchor("addPolicy")}
                    className={`${style.addPolicy} mlr5`}
                >
                    add policy
                </Button>
                <Menu
                    id="addPolicyMenu"
                    anchorEl={menuPolicy["addPolicy"]["anchor"]}
                    open={Boolean(menuPolicy["addPolicy"]["anchor"])}
                    onClose={()=>{closeMenuAnchor("addPolicy")}}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    PaperProps={{
                        style: {
                            minWidth: '260px',
                        }
                    }}
                >   
                    <MenuItem className={`${style.spaceBetween}`} onClick={()=>toggleSubMenuOfAnchor('addPolicy','policy')}>Policy {menuPolicy["addPolicy"]["subMenu"]["policy"] ? <ExpandLessIcon className='ml10' /> : <ExpandMoreIcon className='ml10' />}</MenuItem>
                    <Collapse in={menuPolicy["addPolicy"]["subMenu"]["policy"]} timeout="auto" unmountOnExit>
                        <MenuItem className={`${style.secondLevelSubMenu} ${style.spaceBetween}`} onClick={()=>toggleSubMenuOfAnchor('addPolicy','new')}>New {menuPolicy["addPolicy"]["subMenu"]["new"] ? <ExpandLessIcon className='ml10' /> : <ExpandMoreIcon className='ml10' />}</MenuItem>
                            <Collapse in={menuPolicy["addPolicy"]["subMenu"]["new"]} timeout="auto" unmountOnExit>
                                <MenuItem onClick={()=>setPolicyUIandCloseAnchor("addPolicy","preConfiguredTemplate")} className={`${style.thirdLevelSubMenu}`}>Pre-Configured Template</MenuItem>
                                <MenuItem onClick={()=>setPolicyUIandCloseAnchor("addPolicy","customizeTemplate")} className={`${style.thirdLevelSubMenu}`}>Customized Template</MenuItem>
                                <MenuItem className={`${style.thirdLevelSubMenu}`}>Import Policy</MenuItem>
                            </Collapse>
                        <MenuItem onClick={()=>setPolicyUIandCloseAnchor("addPolicy",'existing')} className={`${style.secondLevelSubMenu}`}>Existing</MenuItem>
                        <MenuItem onClick={()=>setPolicyUIandCloseAnchor("addPolicy",'policyNumber')} className={`${style.secondLevelSubMenu}`}>Policy Number</MenuItem>
                    </Collapse>
                    <MenuItem onClick={()=>setPolicyUIandCloseAnchor("addPolicy","regulatoryRef")}>Regulatory  Ref. #</MenuItem>
                </Menu>
            </>
        );
    }

    // function to generate move policy button
    const getMovePolicyButton = () => {
        return(
            <Button size='small' 
                variant='contained' 
                startIcon={<ContentCopyIcon/>} 
                onClick={()=>setPolicyUi("movePolicy")}
                className={`${style.movePolicy} mlr5`}
            >
                move policy
            </Button>
        )
    }

    // function to generate change policy button
    const getChangePolicyButton = () => {
        return(
            <Button size='small' 
                variant='contained' 
                startIcon={<CreateIcon/>} 
                onClick={()=>setPolicyUi('changePolicy')}
                className={`${style.changePolicy} mlr5`}
            >
                change policy
            </Button>
        )
    }
    // function to generate delete policy button
    const getDeletePolicyButton = () => {
        return(
                <Button size='small' 
                    variant='contained' 
                    startIcon={<CloseIcon/>}
                    onClick={()=>setPolicyUi('deletePolicy')}
                    className={`${style.deletePolicy} mlr5`}
                >
                    delete policy
                </Button>
        )
    }

    // function to get all policy buttons
    const getAllSpecialButtons = () => {
        var allSpecialButtons = [getMovePolicyButton(),getAddPolicyButton(),getChangePolicyButton(),getDeletePolicyButton()]
        return allSpecialButtons;
    }

    // use effect to trigger when ever the page loads for the first time
    useEffect(() => {
        dispatch(setAlert("This module helps to Add and/or Update policies that informs and governs your organizational accepted behavioral practices and the identification, classification, management and protection of assets"));
        // eslint-disable-next-line
    },[]);

    useEffect(()=>{
        if ('manipulate' in props){
            if(props["manipulate"]["type"] === "autoPopulatePolicy" || props["manipulate"]["type"] === "setPolicyAction" || props["manipulate"]["type"] === "editPolicy"){
                setPolicyUi("preConfiguredTemplate");
            }else if(props["manipulate"]["type"] === "redirectToChangePolicy"){
                setPolicyUi("changePolicy");
            }else if(props["manipulate"]["type"] === "autoPopulatePolicyCustomise" || props["manipulate"]["type"] === "setPolicyActionCustomise" || props["manipulate"]["type"] === "editPolicyCustomise"){
                setPolicyUi("customizeTemplate");
            }
        }
    },[props]);

    return (
        <div>
            {/* selected menu details */}
            <HeaderSection header='Policy Management' breadcrumbs={[]} specialButtons={[...getAllSpecialButtons()]}/>
            <div className='m20'>
                {uiHandler()}
            </div>
        </div>
    );
}

export default Policy;