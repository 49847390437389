import { Typography, TextField, Grid, Autocomplete, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import style from '../../css/IssueTrackerFormForModal.module.css';
import { textFieldStyle, autocompleteStyle } from '../../js/standardStyle';
import { renderDisplayValueForAutocomplete,getErrorMsg, getIdOrNullOfObject, getArrayOfObjectBasedOnKey, removeHtmlContent, getKeyOrNullOfObject } from '../../js/util';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import QuillTextEditor from '../wrapper/QuillTextEditor';
import FieldsetCustom from '../wrapper/FieldsetCustom';
import axios from 'axios';
import { SERVICE_URL } from '../../config';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from '../../redux/actions/spinner';
import { toastUp } from '../../redux/actions/toast';
import { DATE_FORMAT, issueDropDown } from '../../js/constants';
import dayjs from 'dayjs';

function IssueTrackerFormForModal(props) {

    const {mode,issueDetail} = props;
    const [formData, setFormData] = useState({"project":null,"status":null,"priority":null,"assignedTo":null,"name":"","description":"","progress":"","dueDate":null});
    const HandleFormDataValueChange = key => (event,object) => {
        var temp = formData;
        var value;
        if(['project','status','priority','assignedTo'].includes(key)){
            value = object;
        }else if(['dueDate','description'].includes(key)){
            value = event;
        }else{
            value = event.target.value;
        }
        temp[key]=value;
        setFormData({...temp});
    }
    const [formOptionsMetaData, setFormOptionsMetaData] = useState({"project":[],"status":[...issueDropDown["status"]],"priority":[...issueDropDown["priority"]],"assignedTo":[]});
    const loggedInUser = useSelector(state=>state.loggedInUser);
    const dispatch = useDispatch();
    const saveIssue = () => {
        var data = validateAndGetFormValue();
        if(data["status"] === "valid"){
            dispatch(setSpinner(true));
            axios.post(`${SERVICE_URL}issue`,data["data"])
            .then((res)=>{
                if(res.status === 201){
                    dispatch(toastUp("Issue created successfully"));
                    props.closeHandler();
                }else{
                    dispatch(toastUp("Unable connect to server"));
                }
            })
            .catch((err)=>{
                console.log(err)
                dispatch(toastUp(getErrorMsg(err)));
            })
            .finally(()=>{
                dispatch(setSpinner(false));
            })
        }else{
            dispatch(toastUp(data["msg"]));
        }
    }
    const editIssue = ()=> {
        var data = validateAndGetFormValue();
        if(data["status"] === "valid"){
            dispatch(setSpinner(true));
            axios.put(`${SERVICE_URL}issue?id=${issueDetail["id"]}`,data["data"])
            .then((res)=>{
                if(res.status === 201){
                    dispatch(toastUp("Issue edited successfully"));
                    props.closeHandler();
                }else{
                    dispatch(toastUp("Unable connect to server"));
                }
            })
            .catch((err)=>{
                console.log(err)
                dispatch(toastUp(getErrorMsg(err)));
            })
            .finally(()=>{
                dispatch(setSpinner(false));
            })
        }else{
            dispatch(toastUp(data["msg"]));
        }
    }
    const validateAndGetFormValue = () => {
        var data={}
        data["status"] = "invalid";
        if(formData["name"] === ""){
            data["msg"] = "Name field cannot be empty";
            return data;
        }
        if(formData["project"] === null){
            data["msg"] = "Project field cannot be empty";
            return data;
        }
        if(formData["assignedTo"] === null){
            data["msg"] = "Assign To field cannot be empty";
            return data;
        }
        if(formData["status"] === null){
            data["msg"] = "Status field cannot be empty";
            return data;
        }
        if(formData["priority"] === null){
            data["msg"] = "Priority field cannot be empty";
            return data;
        }
        if(formData["progress"] === ""){
            data["msg"] = "Progress field cannot be empty";
            return data;
        }
        if(formData["dueDate"] === null){
            data["msg"] = "Due Date field cannot be empty";
            return data;
        }
        if(removeHtmlContent(formData["description"]) === ""){
            data["msg"] = "Description field cannot be empty";
            return data;
        }
        data["status"] = "valid";
        var formValue = {}
        formValue["name"] = formData["name"];
        formValue["projectId"] = getIdOrNullOfObject(formData["project"]);
        formValue["assignedTo"] = getIdOrNullOfObject(formData["assignedTo"]);
        formValue["status"] = getKeyOrNullOfObject(formData["status"],"value");
        formValue["priority"] = getKeyOrNullOfObject(formData["priority"],"value");
        formValue["progress"] = formData["progress"];
        formValue["dueDate"] = formData["dueDate"].format(DATE_FORMAT);
        formValue["description"] = formData["description"];
        formValue["companyId"] = loggedInUser["company_id"];
        if(mode === "add"){
            formValue["assignedBy"] = loggedInUser["id"];
        }else if(mode === "edit"){
            formValue["modifiedBy"] = loggedInUser["id"];
        }
        data["data"] = formValue;
        return data;
    }
    useEffect(()=>{
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}issue/project/view?companyId=${loggedInUser["company_id"]}`)
        .then((res)=>{
            var temp = formOptionsMetaData
            temp["project"] = res["data"]["data"];
            setFormOptionsMetaData({...temp});
            if(mode === "edit"){
                if(issueDetail !== undefined && Object.keys(issueDetail).length !== 0){
                    var updatedFormData = formData;
                    updatedFormData["project"] = getArrayOfObjectBasedOnKey(formOptionsMetaData["project"],"id",issueDetail["project"]["id"]);
                    setFormData({...updatedFormData});
                }
            }
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })

        axios.get(`${SERVICE_URL}user?groups=rbsu,rbr,external,local_admin,lgsa_backup,global_super_admin&companyId=${loggedInUser["company_id"]}`)
        .then((res)=>{
            var data = res["data"];
            var temp = formOptionsMetaData;
            temp["assignedTo"] = data["data"];
            setFormOptionsMetaData({...temp});
            if(mode === "edit"){
                if(issueDetail !== undefined && Object.keys(issueDetail).length !== 0){
                    var updatedFormData = formData;
                    updatedFormData["assignedTo"] = getArrayOfObjectBasedOnKey(formOptionsMetaData["assignedTo"],"id",issueDetail["assignedToId"]);
                    setFormData({...updatedFormData});
                }
            }
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
        if(mode === "edit"){
            if(issueDetail !== undefined && Object.keys(issueDetail).length !== 0){
                var temp = formData;
                temp["name"] = issueDetail["name"];
                temp["description"] = issueDetail["description"];
                temp["progress"] = issueDetail["progress"]
                temp["priority"] = getArrayOfObjectBasedOnKey(issueDropDown["priority"],"value",issueDetail["priority"]);
                temp["status"] = getArrayOfObjectBasedOnKey(issueDropDown["status"],"value",issueDetail["status"]);
                temp["dueDate"] = dayjs(new Date(issueDetail["dueDate"]));
                setFormData({...temp});
            }
        }
        // eslint-disable-next-line
    },[])
    return (
        <div style={{height:"100%"}}>
            <div>
                <Typography variant="h6" component="h2">ADD ISSUE</Typography>
            </div>
            <div className={style.content}>
                <div className={style.fieldsetSection}>
                    <FieldsetCustom legend={"FORM"}>
                        <Grid container spacing={2}>
                            <Grid item sm={12} md={12}>
                                <span className='lable'>Name </span><span className='required'>*</span><br/>
                                <TextField value={formData["name"]} onChange={HandleFormDataValueChange("name")} sx={textFieldStyle} fullWidth size="small" variant="outlined" />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <span className='lable'>Project </span><span className='required'>*</span><br/>
                                <Autocomplete value={formData["project"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["project"]} getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.id === value.id} onChange={HandleFormDataValueChange("project")} size="small" disablePortal renderInput={renderDisplayValueForAutocomplete}/>
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <span className='lable'>Assign To </span><span className='required'>*</span><br/>
                                <Autocomplete value={formData["assignedTo"]} onChange={HandleFormDataValueChange("assignedTo")} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["assignedTo"]} size="small" disablePortal getOptionLabel={(option) => option.name+" ("+option.email+")"} isOptionEqualToValue={(option, value) => option.id === value.id} renderInput={renderDisplayValueForAutocomplete} />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <span className='lable'>Status </span><span className='required'>*</span><br/>
                                <Autocomplete value={formData["status"]} onChange={HandleFormDataValueChange("status")} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["status"]} size="small" disablePortal getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.value === value.value} renderInput={renderDisplayValueForAutocomplete} />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <span className='lable'>Priority </span><span className='required'>*</span><br/>
                                <Autocomplete value={formData["priority"]} onChange={HandleFormDataValueChange("priority")} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["priority"]} size="small" disablePortal getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.value === value.value} renderInput={renderDisplayValueForAutocomplete} />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <span className='lable'>Progress </span><span className='required'>*</span><br/>
                                <TextField value={formData["progress"]} onChange={HandleFormDataValueChange("progress")} sx={textFieldStyle} type='number' fullWidth size="small" variant="outlined" />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <span className='lable'>Due Date </span><span className='required'>*</span><br/>
                                <LocalizationProvider  size="small" dateAdapter={AdapterDayjs}>
                                    <DatePicker value={formData["dueDate"]} onChange={HandleFormDataValueChange("dueDate")} slotProps={{ textField: { size: 'small',fullWidth: true, sx:textFieldStyle } }} />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item sm={12} md={12}>
                                <span className='lable'>Description </span><span className='required'>*</span><br/>
                                <QuillTextEditor name={"description"} value={formData["description"]} onChange={HandleFormDataValueChange("description")} />
                            </Grid>
                        </Grid>
                    </FieldsetCustom>
                </div>
            </div>
            <div className='mtb30' style={{textAlign:"center"}}>
                {
                    (mode === "add") ?
                        <Button className='mlr5 roButton' variant="contained" onClick={saveIssue}> create</Button>
                    :
                        
                        (mode === "edit") ?
                            <Button className='mlr5 roButton' variant="contained" onClick={editIssue}> edit</Button>
                        :
                            <></>
                        
                }
                
                <Button className='mlr5 roButton' variant="text" onClick={props.closeHandler}>cancel</Button>
            </div>
        </div>
    );
}

export default IssueTrackerFormForModal;