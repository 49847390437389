import React from 'react';
import { Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend} from 'chart.js';
import { Bar } from 'react-chartjs-2';

function VerticalBarChart(props) {
    ChartJS.register(CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend);
    const options = {
        responsive: true,
        plugins: {
          legend: {
            display: false
          }
        },
    };
    var {data} = props;
    if (data === null){
        data = {
            labels: [],
            datasets: [
              {
                label: '',
                data: []
              }
            ],
        };
    }
    return (
        <div>
            <Bar options={options} data={data} />
        </div>
    );
}

export default VerticalBarChart;