export const toastUp = (payload) => {
    return {
        type:"toastUp",
        payload:payload
    }
}
export const closeToast = () => {
    return {
        type:"closeToast"
    }
}
