import React, { useEffect, useState } from 'react';
import HeaderSection from '../HeaderSection';
import FieldsetCustom from '../wrapper/FieldsetCustom';
import {Grid,MenuItem,Select,Button} from '@mui/material';
import {selectFieldStyle} from '../../js/standardStyle.js';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import { SERVICE_URL } from '../../config';
import { generateDropDown, generateDropDownWithToolTipBasedOnKey,generateDropDownBasedOnKey, getIdIndexOfArrayObject, getErrorMsg, wrapEmailInArrayOfObject } from '../../js/util';
import { riskResponseRiskType } from '../../js/tiptoolDescription.js';
import dayjs from 'dayjs';
import DataGridCustom from '../wrapper/DataGridCustom';
import ModalViewInfo from '../wrapper/ModalViewInfo';
import ViewRiskRegisterDetailForModal from './ViewRiskRegisterDetailForModal';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from '../../redux/actions/spinner';
import { toastUp } from '../../redux/actions/toast';

function SearchRisk(props) {

    // get use dispatch from redux
    const dispatch = useDispatch();
    // get logged in user details
    const loggedInUser = useSelector(state => state.loggedInUser);
    // fetch company id
    const companyId = loggedInUser["company_id"];
    // init use state to show/hide filter element conditionally
    const [hideElement,setHideElement] = useState({"filter":true,"specificDate":true,"dateRange":true,"searchTable":true});
    // function to toggle between show/hide filter elements
    const toggleFilterVisibility = () => {
        var updatedHideElement = hideElement;
        if(updatedHideElement["filter"]===false){
            var updatedFilterData = filterData;
            updatedFilterData["riskType"] = "";
            updatedFilterData["riskDescription"] = "";
            updatedFilterData["riskCategory"] = "";
            updatedFilterData["riskStatus"] = "";
            updatedFilterData["riskSource"] = "";
            updatedFilterData["riskHierarchy"] = "";
            setFilterData({...updatedFilterData});
        }
        updatedHideElement["filter"] = !hideElement["filter"];
        setHideElement({...updatedHideElement});
    }

    // init use state for filter data
    const [filterData,setFilterData] = useState({"riskIndex":"","riskName":"","riskOwner":"","riskDateType":"","riskSpecificDate":null,"riskStartDate":null,"riskEndDate":null,"riskType":"","riskDescription":"","riskCategory":"","riskStatus":"","riskSource":"","riskHierarchy":""});
    // function to handle filter data
    const handleFilterValueChange = key => event => {
        var temp = filterData;
        var value = event.target.value;
        temp[key] = value;
        setFilterData({...temp});

        if(key === "riskName"){
            if(value !== ""){
                generateRiskDescriptionForRiskName(filterData["riskName"]);
            }
        }
        if(key === "riskDateType"){
            var updatedHideElement = hideElement;
            var updatedFilterData = filterData;
            updatedFilterData["riskStartDate"] = null;
            updatedFilterData["riskEndDate"] = null;
            updatedFilterData["riskSpecificDate"] = null;
            setFilterData({...updatedFilterData});
            if(value === "specificDate"){
                updatedHideElement["specificDate"] = false;
                updatedHideElement["dateRange"] = true;
            }else if(value === "dateRange"){
                updatedHideElement["specificDate"] = true;
                updatedHideElement["dateRange"] = false;
            }else{
                updatedHideElement["specificDate"] = true;
                updatedHideElement["dateRange"] = true;
            }
            setHideElement({...updatedHideElement});
        }
    }
    // function to handle date change for filter
    const handleFilterDateChange = (key,date) => {
        if(key === "riskEndDate"){
            if(filterData["riskStartDate"] === null){
                dispatch(toastUp("please select risk start date"));
                return false;
            }
        }
        var temp = filterData;
        temp[key] = date;
        setFilterData({...temp});
        // console.log(date.format('MM/DD/YYYY'));
    }

    // init use state for filter meta data
    const [filterOptionsMetaData,setFilterOptionsMetaData] = useState({"riskIndex":[],"riskName":[],"riskOwner":[],"riskType":[],"riskDescription":[],"riskCategory":[],"riskStatus":[],"riskSource":[],"riskHierarchy":[]});
    
    // function to generate risk description based on risk name
    const generateRiskDescriptionForRiskName = (riskNameId) => {
        var updatedFilterData = filterData;
        updatedFilterData["riskDescription"] = "";
        setFilterData({...updatedFilterData});
        var updatedFilterOptionsMetaData = filterOptionsMetaData;
        updatedFilterOptionsMetaData["riskDescription"] = [];
        setFilterOptionsMetaData({...updatedFilterOptionsMetaData});
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}risk/company/riskDescription/companyRiskNameId?companyRiskNameId=${riskNameId}`)
        .then(res => {
            if(res.status === 200){
                setFilterOptionsMetaData(state => ({ ...state, "riskDescription": res.data }));
            }else{
                console.log(res)
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        }).finally(() => {
            dispatch(setSpinner(false));
        });
    }

    // init use state for selectedFilter
    const [selectedFilter,setSelectedFilter] = useState("");
    
    // init search table column
    const columns = [
        {field: 'riskIndex', sortable: false, flex: 1, renderHeader: () => (<strong>{'Risk ID Index'}</strong>)},
        {field: 'name', sortable: false, flex: 1, renderHeader: () => (<strong>{'Risk Name'}</strong>),renderCell: (params) => (<div className="MuiDataGrid-cellContent hyperLinkActive" title={params.value} onClick={()=>{openRiskRegisterModal(params.id)}}>{params.value}</div>)},
        {field: 'category', sortable: false, flex: 1, renderHeader: () => (<strong>{'Risk Category'}</strong>)},
        {field: 'ownerName', sortable: false, flex: 1, renderHeader: () => (<strong>{'Risk Owner'}</strong>)},
        {field: 'source', sortable: false, flex: 1, renderHeader: () => (<strong>{'Risk Source'}</strong>)}
    ]
    // init use state to handle pagination
    const [pageState, setPageState] = useState({
        page: 0,
        pageSize: 30
    })
    // init use state to handle data content
    const [tableContent,setTableContent] = useState({
        isLoading: false,
        data: [],
        total: 0,
    })

    // init use state for risk register modal
    const [riskRegisterModal,setRiskRegisterModal] = useState({"isOpen":false,"content":undefined,"currentId":"","index":"","buttonDisable":{"next":false,"prev":false}});
    // function to open risk register modal
    const openRiskRegisterModal = (id) => {
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}risk/register?Id=${id}`)
        .then(res => {
            var next = false;
            var prev = false;
            var index = getIdIndexOfArrayObject(id,tableContent["data"]);
            var currentPage = pageState.page+1;
            if(index !== null){
                if(index+1 === tableContent["data"].length){
                    var totalPageNumbers = Math.ceil(tableContent["total"]/pageState["pageSize"]);
                    if(currentPage === totalPageNumbers){
                        next = true;
                    }
                }if(index === 0){
                    if(currentPage === 1){
                        prev = true;
                    }
                }
            }
            setRiskRegisterModal({"isOpen":true,"content":res.data,"currentId":id,"buttonDisable":{"next":next,"prev":prev}});
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // function to close risk register modal
    const closeRiskRegisterModal = () => {
        setRiskRegisterModal({"isOpen":false,"content":undefined,"currentId":"","index":"","buttonDisable":{"next":false,"prev":false}});
    }
    // function to switch between next/prev page
    const nextPrevPageRiskRegisterModalHandler = (mode) => {
        var currentId = riskRegisterModal["currentId"];
        var index = getIdIndexOfArrayObject(currentId,tableContent["data"]);
        var currentPage = pageState.page+1;
        if(index === null){
            dispatch(toastUp(`issues switching ${mode} page`));
            return false;
        }
        if(mode === "next"){
            if(index+1 === tableContent["data"].length){
                var totalPageNumbers = Math.ceil(tableContent["total"]/pageState["pageSize"]);
                if(currentPage === totalPageNumbers){
                    dispatch(toastUp("end of table content"));
                    return false;
                }else{
                    setRiskRegisterModal((old)=>({...old,index:"0"}));
                    setPageState((old)=>({...old,page:old["page"]+1}));
                }
            }else{
                var nextData = tableContent["data"][index+1];
                openRiskRegisterModal(nextData["id"]);
            }
        }else if((mode === "previous")){
            if(index === 0){
                if(currentPage === 1){
                    dispatch(toastUp("end of table content"));
                    return false;
                }else{
                    setRiskRegisterModal((old)=>({...old,index:"-1"}));
                    setPageState((old)=>({...old,page:old["page"]-1}))
                }
            }else{
                var prevData = tableContent["data"][index-1];
                openRiskRegisterModal(prevData["id"]);
            }
        }
    }
    // init date format using for date
    var dateFormat = 'YYYY-MM-DD';
    // function to get data based on filter
    const getDataBasedOnFilter = () => {
        var data = validateAndGetFilterData();
        if(data["status"] === "valid"){
            setSelectedFilter(data["data"]);
            setPageState((prev)=>({...prev,page:0}))
            if(hideElement["searchTable"] === true){
                var temp = hideElement;
                temp["searchTable"] = false;
                setHideElement({...temp});
            }
        }else{
            dispatch(toastUp(data["msg"]));
        }
    }
    // funnction to validate and get filter data
    const validateAndGetFilterData = () => {
        var data = {};
        data["status"] = "inValid";

        if(
            filterData["riskCategory"] === "" && 
            filterData["riskDateType"] === "" &&
            filterData["riskDescription"] === "" &&
            filterData["riskHierarchy"] === "" &&
            filterData["riskIndex"] === "" &&
            filterData["riskName"] === "" &&
            filterData["riskOwner"] === "" &&
            filterData["riskSource"] === "" &&
            filterData["riskStatus"] === "" &&
            filterData["riskType"] === ""
        ){
            data["msg"]="please select any one of the filter";
            return data
        }

        if(filterData["riskDateType"] === "specificDate"){
            if(filterData["riskSpecificDate"] === null){
                data["msg"]="please select risk date";
                return data
            }
        }
        if(filterData["riskDateType"] === "dateRange"){
            if(filterData["riskStartDate"] === null){
                data["msg"]="please select risk start date";
                return data
            }
            if(filterData["riskEndDate"] === null){
                data["msg"]="please select risk end date";
                return data
            }
        }
        
        var parameter = "";
        parameter += filterData["riskCategory"] !== "" ? `&riskCategory=${filterData["riskCategory"]}` : '';
        parameter += filterData["riskDateType"] !== "" ? `&riskDateType=${filterData["riskDateType"]}` : '';
        parameter += filterData["riskDescription"] !== "" ? `&riskDescription=${filterData["riskDescription"]}` : '';
        parameter += filterData["riskHierarchy"] !== "" ? `&riskHierarchy=${filterData["riskHierarchy"]}` : '';
        parameter += filterData["riskIndex"] !== "" ? `&riskIndex=${filterData["riskIndex"]}` : '';
        parameter += filterData["riskName"] !== "" ? `&riskName=${filterData["riskName"]}` : '';
        parameter += filterData["riskOwner"] !== "" ? `&riskOwner=${filterData["riskOwner"]}` : '';
        parameter += filterData["riskSource"] !== "" ? `&riskSource=${filterData["riskSource"]}` : '';
        parameter += filterData["riskStatus"] !== "" ? `&riskStatus=${filterData["riskStatus"]}` : '';
        parameter += filterData["riskType"] !== "" ? `&riskType=${filterData["riskType"]}` : '';

        if(filterData["riskDateType"] === "specificDate"){
            parameter += filterData["riskSpecificDate"] !== "" ? `&riskSpecificDate=${filterData["riskSpecificDate"].format(dateFormat)}` : '';
        }else if(filterData["riskDateType"] === "dateRange"){
            parameter += filterData["riskStartDate"] !== "" ? `&riskStartDate=${filterData["riskStartDate"].format(dateFormat)}` : '';
            parameter += filterData["riskEndDate"] !== "" ? `&riskEndDate=${filterData["riskEndDate"].format(dateFormat)}` : '';
        }
        if(parameter.length !== 0){
            parameter += `&companyId=${companyId}`;
        }
        data["status"] = "valid";
        data["data"]=parameter;
        return data;
    }

    // generate search table
    useEffect(() => {
        const fetchData = async () => {
            if(selectedFilter.length !== 0){
                setTableContent(old => ({ ...old, isLoading: true }))
                axios.get(`${SERVICE_URL}risk/riskRegister/search?page=${pageState.page+1}&size=${pageState.pageSize}${selectedFilter}`)
                .then(res=>{
                    var data=res.data;
                    var temp = tableContent;
                    temp["isLoading"] = false;
                    temp["data"] = data["riskdata"];
                    temp["total"] = data["total_risks"];
                    setTableContent({...temp});
                    if(riskRegisterModal["isOpen"]===true){
                        if(data.riskdata.length !== 0){
                            if(riskRegisterModal["index"] !== ""){
                                openRiskRegisterModal(data["riskdata"].at(riskRegisterModal["index"])["id"]);
                                setRiskRegisterModal((old)=>({...old,index:""}));
                            }
                        }
                    }
                }).catch(err => {
                    dispatch(toastUp(getErrorMsg(err)));
                    setTableContent(old => ({ ...old, isLoading: false }));
                })
            }
        }
        fetchData();
        // eslint-disable-next-line
    }, [pageState.page, pageState.pageSize,selectedFilter])

    // use effect to init dropdowns when component mount
    useEffect(()=>{
        // populate risk index
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}risk/company/riskIndex/companyId?companyId=${companyId}`)
        .then(res => {
            if(res.status === 200){
                setFilterOptionsMetaData(state => ({ ...state, "riskIndex": res.data }));
            }else{
                console.log(res)
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        // populate risk name
        axios.get(`${SERVICE_URL}risk/company/riskName?companyId=${companyId}`)
        .then(res => {
            if(res.status === 200){
                setFilterOptionsMetaData(state => ({ ...state, "riskName": res.data }));
            }else{
                console.log(res)
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        // populate risk type
        axios.get(`${SERVICE_URL}risk/getConfig/RiskType`)
        .then(res => {
            if(res.status === 201){
                setFilterOptionsMetaData(state => ({ ...state, "riskType": res.data }));
            }else{
                console.log(res)
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        // populate risk status
        axios.get(`${SERVICE_URL}risk/getConfig/riskStatus`)
        .then(res => {
            if(res.status === 201){
                setFilterOptionsMetaData(state => ({ ...state, "riskStatus": res.data }));
            }else{
                console.log(res)
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        // populate risk category
        axios.get(`${SERVICE_URL}risk/riskCategory/companyId?companyId=${companyId}`)
        .then(res => {
            if(res.status === 200){
                setFilterOptionsMetaData(state => ({ ...state, "riskCategory": res.data }));
            }else{
                console.log(res)
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        // populate risk source
        axios.get(`${SERVICE_URL}risk/company/riskSource/companyId?companyId=${companyId}`)
        .then(res => {
            if(res.status === 200){
                setFilterOptionsMetaData(state => ({ ...state, "riskSource": res.data }));
            }else{
                console.log(res)
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        // populate risk owner
        axios.get(`${SERVICE_URL}user?company_id=${companyId}&roles=Business Executive,Organizational Executive`)
        .then(res => {
            if(res.status === 200){
                var data = res.data;
                setFilterOptionsMetaData(state => ({ ...state, "riskOwner": wrapEmailInArrayOfObject(data["data"],"displayName") }));
            }else{
                console.log(res)
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        // populate risk hierarchy
        axios.get(`${SERVICE_URL}risk/company/riskHierarchy/companyId?companyId=${companyId}`)
        .then(res => {
            if(res.status === 200){
                setFilterOptionsMetaData(state => ({ ...state, "riskHierarchy": res.data }));
            }else{
                console.log(res)
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        // eslint-disable-next-line
    },[])

    return (
        <div>
            {/* selected menu details */}
            <HeaderSection header='Search Risk' breadcrumbs={["Cyber Risk Management","Search Risk"]} specialButtons={[<Button className='mlr5 roButton' variant="text">action plan</Button>]}/>
            <div className='m20'>
                <FieldsetCustom legend="search filters">
                    <div className='mtb5' style={{textAlign:"right"}}>
                        <Button className='mlr5 roButton' variant="text" onClick={toggleFilterVisibility} >{hideElement["filter"] ? 'more filters' : 'less filters'}</Button>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item sm={3} md={3}>
                            <span className='lable'>Risk ID-Index</span><br/>
                            <Select MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFilterValueChange("riskIndex")} value={filterData["riskIndex"]} displayEmpty>
                                <MenuItem value=""><em>-- please select--</em></MenuItem>
                                {generateDropDown(filterOptionsMetaData["riskIndex"],"value")}
                            </Select>
                        </Grid>
                        <Grid item sm={3} md={3}>
                            <span className='lable'>Risk Name</span><br/>
                            <Select MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFilterValueChange("riskName")} value={filterData["riskName"]} displayEmpty>
                                <MenuItem value=""><em>-- please select--</em></MenuItem>
                                {generateDropDown(filterOptionsMetaData["riskName"],"name")}
                            </Select>
                        </Grid>
                        <Grid item sm={3} md={3}>
                            <span className='lable'>Risk Owner</span><br/>
                            <Select MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFilterValueChange("riskOwner")} value={filterData["riskOwner"]} displayEmpty>
                                <MenuItem value=""><em>-- please select--</em></MenuItem>
                                {generateDropDown(filterOptionsMetaData["riskOwner"],"displayName")}
                            </Select>
                        </Grid>
                        <Grid item sm={3} md={3}>
                            <span className='lable'>Risk Date Type</span><br/>
                            <Select MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFilterValueChange("riskDateType")} value={filterData["riskDateType"]} displayEmpty>
                                <MenuItem value=""><em>-- please select--</em></MenuItem>
                                <MenuItem value="specificDate">Specific Date</MenuItem>
                                <MenuItem value="dateRange">Date Range</MenuItem>
                            </Select>
                        </Grid>
                        <Grid style={{ display: (hideElement["specificDate"] ? 'none' : 'block') }} item sm={3} md={3}>
                            <span className='lable'>Risk Date</span><br/>
                            <LocalizationProvider  size="small" dateAdapter={AdapterDayjs}>
                                <DatePicker value={filterData["riskSpecificDate"]} onChange={(newDate)=>{handleFilterDateChange("riskSpecificDate",newDate)}} maxDate={dayjs(new Date())} slotProps={{ textField: { size: 'small',fullWidth: true } }} />
                            </LocalizationProvider>
                        </Grid>
                        <Grid style={{ display: (hideElement["dateRange"] ? 'none' : 'block') }} item sm={3} md={3}>
                            <span className='lable'>Risk Start Date</span><br/>
                            <LocalizationProvider  size="small" dateAdapter={AdapterDayjs}>
                                <DatePicker value={filterData["riskStartDate"]} onChange={(newDate)=>{handleFilterDateChange("riskStartDate",newDate)}} maxDate={dayjs(new Date())} slotProps={{ textField: { size: 'small',fullWidth: true } }} />
                            </LocalizationProvider>
                        </Grid>
                        <Grid style={{ display: (hideElement["dateRange"] ? 'none' : 'block') }} item sm={3} md={3}>
                            <span className='lable'>Risk End Date</span><br/>
                            <LocalizationProvider  size="small" dateAdapter={AdapterDayjs}>
                                <DatePicker value={filterData["riskEndDate"]} onChange={(newDate)=>{handleFilterDateChange("riskEndDate",newDate)}} maxDate={dayjs(new Date())} minDate={filterData["riskStartDate"]} slotProps={{ textField: { size: 'small',fullWidth: true } }} />
                            </LocalizationProvider>
                        </Grid>
                        <Grid style={{ display: (hideElement["filter"] ? 'none' : 'block') }} item sm={3} md={3}>
                            <span className='lable'>Risk Type</span><br/>
                            <Select MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFilterValueChange("riskType")} value={filterData["riskType"]} renderValue={(selected) => selected === "" ? <em>-- please select--</em> : selected} displayEmpty>
                                <MenuItem value=""><em>-- please select--</em></MenuItem>
                                {generateDropDownWithToolTipBasedOnKey(filterOptionsMetaData["riskType"],"name","name",riskResponseRiskType)}
                            </Select>
                        </Grid>
                        <Grid style={{ display: (hideElement["filter"] ? 'none' : 'block') }} item sm={3} md={3}>
                            <span className='lable'>Risk Description</span><br/>
                            <Select MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFilterValueChange("riskDescription")} value={filterData["riskDescription"]} displayEmpty>
                                <MenuItem value=""><em>-- please select--</em></MenuItem>
                                {generateDropDown(filterOptionsMetaData["riskDescription"],"description")}
                            </Select>
                        </Grid>
                        <Grid style={{ display: (hideElement["filter"] ? 'none' : 'block') }} item sm={3} md={3}>
                            <span className='lable'>Risk Category</span><br/>
                            <Select MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFilterValueChange("riskCategory")} value={filterData["riskCategory"]} displayEmpty>
                                <MenuItem value=""><em>-- please select--</em></MenuItem>
                                {generateDropDown(filterOptionsMetaData["riskCategory"],"name")}
                            </Select>
                        </Grid>
                        <Grid style={{ display: (hideElement["filter"] ? 'none' : 'block') }} item sm={3} md={3}>
                            <span className='lable'>Risk Status</span><br/>
                            <Select MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFilterValueChange("riskStatus")} value={filterData["riskStatus"]} displayEmpty>
                                <MenuItem value=""><em>-- please select--</em></MenuItem>
                                {generateDropDownBasedOnKey(filterOptionsMetaData["riskStatus"],"name","value")}
                            </Select>
                        </Grid>
                        <Grid style={{ display: (hideElement["filter"] ? 'none' : 'block') }} item sm={3} md={3}>
                            <span className='lable'>Risk Source</span><br/>
                            <Select MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFilterValueChange("riskSource")} value={filterData["riskSource"]} displayEmpty>
                                <MenuItem value=""><em>-- please select--</em></MenuItem>
                                {generateDropDown(filterOptionsMetaData["riskSource"],"value")}
                            </Select>
                        </Grid>
                        
                        <Grid style={{ display: (hideElement["filter"] ? 'none' : 'block') }} item sm={3} md={3}>
                            <span className='lable'>Risk Hierarchy</span><br/>
                            <Select MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFilterValueChange("riskHierarchy")} value={filterData["riskHierarchy"]} displayEmpty>
                                <MenuItem value=""><em>-- please select--</em></MenuItem>
                                {generateDropDown(filterOptionsMetaData["riskHierarchy"],"value")}
                            </Select>
                        </Grid>
                    </Grid>
                    <div className='mt30 mb15' style={{textAlign:"center"}}>
                        <Button className='mlr5 roButton' variant="contained" onClick={getDataBasedOnFilter} >search</Button>
                    </div>
                </FieldsetCustom>
                {(hideElement["searchTable"] ?
                    null :
                    <div className='mt50 mb30'>
                        <DataGridCustom rows={tableContent.data} rowCount={tableContent.total} isLoading={tableContent.isLoading} pageSizeOptions={[30,50,100]} paginationModel={pageState} onPaginationModelChange={setPageState} columns={columns}/>
                    </div>
                )}
            </div>
            {/* modal to view and slide between risk registers */}
            <ModalViewInfo open={riskRegisterModal.isOpen} closeHandler={closeRiskRegisterModal}>
                <ViewRiskRegisterDetailForModal buttonDisable={riskRegisterModal.buttonDisable} pageModal={pageState} nextPrevPageHandler={nextPrevPageRiskRegisterModalHandler} riskRegister={riskRegisterModal.content} currentId={riskRegisterModal.currentId}/>
            </ModalViewInfo>
        </div>
    );
}

export default SearchRisk;