const confirmationModalReducer = (state={"isOpen":false,"content":"","confirmationHandler":()=>{}},action) => {
    switch(action.type){
        case 'setConfirmationModal':
            return {"isOpen":true,"content":action.payload.content,"confirmationHandler":action.payload.confirmationHandler}
        case 'closeConfirmationModal':
            return {"isOpen":false,"content":"","confirmationHandler":()=>{}}
        default:
            return state;
    }
}

export default confirmationModalReducer;