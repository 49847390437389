import { Grid, Paper, Typography, TableCell, TableRow, Table, TableContainer, TableHead, TableBody } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { setSpinner } from '../../redux/actions/spinner';
import { useDispatch } from 'react-redux';
import { SERVICE_URL } from '../../config';
import axios from 'axios';
import { toastUp } from '../../redux/actions/toast';
import { getErrorMsg } from '../../js/util';
import PieChart from '../wrapper/PieChart';
import { chartBackgroudColorSet } from '../../js/standardStyle';
import HorizontalBarChart from '../wrapper/HorizontalBarChart';

function ExhibitingTaskChartProgress(props) {
    const {projectId} = props;
    // get dispatch
    const dispatch = useDispatch();
    // init use state for chart data
    const [chartData, setChartData] = useState({"task":[],"taskStatus":null,"taskPriority":null,"budget":null});
    useEffect(()=>{
        dispatch(setSpinner(true))
        axios.get(`${SERVICE_URL}/issue/project/report/chart/exhibitingTaskChartProgress?projectId=${projectId}`)
        .then(res=>{
            var temp = chartData;
            if(res["data"]["taskStatus"] !== null){
                var taskStatusData = {
                    labels: res["data"]["taskStatus"].map(data=>data.name),
                    datasets: [
                        {
                            label: '# of Task',
                            data: res["data"]["taskStatus"].map(data=>data.value),
                            backgroundColor: chartBackgroudColorSet
                        }
                    ]
                }
                temp["taskStatus"] = taskStatusData;
            }
            if(res["data"]["taskPriority"] !== null){
                var taskPriorityData = {
                    labels: res["data"]["taskPriority"].map(data=>data.name),
                    datasets: [
                        {
                            label: '# of Task',
                            data: res["data"]["taskPriority"].map(data=>data.value),
                            backgroundColor: chartBackgroudColorSet
                        }
                    ]
                }
                temp["taskPriority"] = taskPriorityData;
            }
            if(res["data"]["budget"] != null){
                var expenditureData = {
                    labels: res["data"]["budget"].map(data=>data.name),
                    datasets: [
                        {
                            label: '# budget of',
                            data: res["data"]["budget"].map(data=>data.value),
                            backgroundColor: chartBackgroudColorSet
                        }
                    ]
                }
                temp["budget"] = expenditureData;
            }
            if(res["data"]["task"] != null){
                temp["task"] = res["data"]["task"];
            }
            setChartData({...temp});
        })
        .catch(err=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
        // eslint-disable-next-line
    },[])
    const style={}
    style["content"]={minHeight:"500px",display:"flex",flexDirection: "column",justifyContent: "center"}
    return (
        <div>
            <Grid container spacing={2} alignItems="center">
                <Grid item sm={12} md={6}>
                    <Paper style={style.content} elevation={2}>
                        <Typography className='p10 taCenter'>Task</Typography>
                        <div className='mtb10' style={{width: "95%",margin:"auto"}}>
                        <TableContainer>
                            <Table style={{backgroundColor:"#82dbcc"}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><strong>Task</strong></TableCell>
                                        <TableCell><strong>Start Date</strong></TableCell>
                                        <TableCell><strong>End Date</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        (chartData["task"].length > 0) ?
                                            chartData["task"].map((row,i) => {
                                                return (
                                                    <TableRow  key={i}>
                                                        <TableCell>{row.name}</TableCell>
                                                        <TableCell>{row.startDate}</TableCell>
                                                        <TableCell>{row.endDate}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        :
                                            <TableRow>
                                                <TableCell align='center' colSpan={3}>
                                                    No Record Found
                                                </TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </div>
                    </Paper>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Paper style={style.content} elevation={2}>
                        <Typography className='p10 taCenter'>Task Status</Typography>
                        <div className='mtb10' style={{width: "60%",margin:"auto"}}>
                            <PieChart data={chartData["taskStatus"]}/>
                        </div>
                    </Paper>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Paper style={style.content} elevation={2}>
                        <Typography className='p10 taCenter'>Task Priority</Typography>
                        <div className='mtb10' style={{width: "60%",margin:"auto"}}>
                            <PieChart data={chartData["taskPriority"]}/>
                        </div>
                    </Paper>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Paper style={style.content} elevation={2}>
                        <Typography className='p10 taCenter'>Budget</Typography>
                        <div className='mtb10' style={{width: "95%",margin:"auto"}}>
                            <HorizontalBarChart data={chartData["budget"]}/>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

export default ExhibitingTaskChartProgress;