import React, {useState,Fragment} from 'react';
import {AppBar,Box,CssBaseline,Divider,Drawer,IconButton,List,ListItem,ListItemButton} from '@mui/material';
import {ListItemText,Typography,Toolbar,Collapse,Badge, Menu, MenuItem} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import style from "../css/Nav.module.css"
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from '../redux/actions/displayPage';
import axios from 'axios';
import { SERVICE_URL } from '../config';
import { setSpinner } from '../redux/actions/spinner';
import { toastUp } from '../redux/actions/toast';
import { setUser } from '../redux/actions/loggedInUser';
import { getErrorMsg } from '../js/util';

function Nav(props) {

    const [notificationCountBadge,setNotificationBadgeCount] = useState({"email":0});
    // get dispatch from redux
    const dispatch = useDispatch();
    // get logged in user from redux
    const loggedInUser = useSelector((state)=>state.loggedInUser);
    // init app bar state
    const [appBarState, setAppBarState] = useState({"profile":{"menuAnchor":false}});
    // use state for handle menu
    const [subMenuExpansion, changeSubmenuExpansion] = useState({"riskRegister":true,"assessmentManagement":false,"manageRisk":false,"user":false,"issueManagement":false,"portfolioManagement":false});
    // function to handle menu expansion
    const submenuHandleClick = (key) => {
        var temp = subMenuExpansion;
        temp[key] = !temp[key];
        changeSubmenuExpansion({...temp});
    };
    // storing menu content including submenu
    var menu = [{"id":"1","name":"Dashboard","module":"dashboard"},
                {"id":"2","name":"Cyber Risk Management","module":"cyber_risk_management",
                "subMenu":[{"id":"2.1","name":"Search Risk","componentKey":"searchRisk"},{"id":"2.2","name":"Risk Register","componentKey":"riskRegister"},{"id":"2.3","name":"Import Risk"},{"id":"2.4","name":"Manage Risk","subMenu":[{"id":"2.4.1","name":"Identified Issues","componentKey":"identifiedIssues"}],"expansionKey":"manageRisk"},{"id":"2.5","name":"Risk Planning"},{"id":"2.6","name":"Risk Monitoring"},{"id":"2.7","name":"Risk Assign"}],
                "expansionKey":"riskRegister"},
                {"id":"3","name":"Assessment Management","module":"assessment_management",
                "subMenu":[{"id":"3.1","name":"My Assessments","componentKey":"myAssessment"},{"id":"3.2","name":"Regulatory/Framework"},{"id":"3.3","name":"Assign Assessments","componentKey":"assignAsessment"},{"id":"3.4","name":"Navigate Assessments","componentKey":"navigateAssessment"},{"id":"3.5","name":"Historical","componentKey":"historicalAssessment"},{"id":"3.6","name":"Accept/Reject View","componentKey":"acceptRejectViewAssessment"}],
                "expansionKey":"assessmentManagement"},
                {"id":"4","name":"Standalone Assessment","module":"standalone_management"},
                {"id":"5","name":"Control Management","module":"control_management"},
                {"id":"6","name":"Policy Management","module":"policy_management","componentKey":"policy"},
                {"id":"10","name":"Issues Management","module":"issue_management",
                "subMenu":[{"id":"10.1","name":"Project Issue","componentKey":"issueTracker"},{"id":"10.2","name":"Portfolio Management","expansionKey":"portfolioManagement","subMenu":[{"id":"10.2.1","name":"Project Risk","componentKey":"portfolioManagement"},{"id":"10.2.2","name":"Project Security","componentKey":"projectSecurity"}]},{"id":"10.3","name":"Project Financials","componentKey":"projectFinancial"},{"id":"10.4","name":"Project Reporting","componentKey":"projectReport"}],
                // "subMenu":[{"id":"10.2","name":"Project Risk","subMenu":[{"id":"10.2.1","name":"Project Issue","id":"10.2.2"},{"id":"10.2.2","name":"Project Security"},{"id":"10.2.3","name":"Inherent Risk"}]},{"id":"10.1","name":"IT Portfolio Management"},{"id":"10.3","name":"Project Financial","componentKey":"projectFinancial"},{"id":"10.4","name":"Project Reporting"}],
                "expansionKey":"issueManagement"},
                {"id":"7","name":"GRC Threat Management","module":"grc_threat_management"},
                {"id":"8","name":"Compliance Management","module":"compliance_management"},
                {"id":"9","name":"User Management","module":"user_management",
                "subMenu":[{"id":"9.1","name":"Create User","componentKey":"createUser"}],
                "expansionKey":"user"
                }
            ]
    
    // getting drawer width from prop
    const drawerWidth = props.drawerWidth;
    // handle menu for mobile screens
    const [mobileOpen, setMobileOpen] = useState(false);
    // function to toggle handleDrawer for mobile screens
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };

    const logoutUser = () => {
        dispatch(setSpinner(true));
        axios.delete(`${SERVICE_URL}user/logout`,{ withCredentials: true })
        .then((res) => {
            if(res.status === 200){
                dispatch(setUser(null));
                dispatch(setPage("riskRegister"))
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch((err) => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        })
    }

    const checkIfModlueExists = (moduleName) =>{
        if(moduleName in loggedInUser["module"]){
            return true;
        }
        return false;
    }
    const checkIfFeatureVisible = (subMenuId,moduleName) => {
        if(moduleName === "assessment_management"){
            var userIgnoreMenuId = ["3.3","3.6"];
            var managerIgnoreMenuId = [];
            if(loggedInUser["module"][moduleName].includes("manager_view") && managerIgnoreMenuId.includes(subMenuId)){
                return false;
            }else if(loggedInUser["module"][moduleName].includes("user_view") && userIgnoreMenuId.includes(subMenuId)){
                return false;
            }
        }
        return true;
    }
    const displayPage = useSelector(state=>state.displayPage);
    const getSelectedMenus = () => {
        var selectedMenu = {};
        if(displayPage === "riskRegister"){
            selectedMenu["menu"] = "2";
            selectedMenu["subMenu"] = "2";
        }else if(displayPage === "searchRisk"){
            selectedMenu["menu"] = "2";
            selectedMenu["subMenu"] = "1";
        }else if(displayPage === "viewRisk"){
            selectedMenu["menu"] = "2";
            selectedMenu["subMenu"] = "2";
        }else if(displayPage === "myAssessment"){
            selectedMenu["menu"] = "3";
            selectedMenu["subMenu"] = "1";
        }else if(displayPage === "assignAsessment"){
            selectedMenu["menu"] = "3";
            selectedMenu["subMenu"] = "3";
        }else if(displayPage === "navigateAssessment"){
            selectedMenu["menu"] = "3";
            selectedMenu["subMenu"] = "4";
        }else if(displayPage === "historicalAssessment"){
            selectedMenu["menu"] = "3";
            selectedMenu["subMenu"] = "5";
        }else if(displayPage === "myAssessmentExam"){
            selectedMenu["menu"] = "3";
            selectedMenu["subMenu"] = "1";
        }else if(displayPage === "acceptRejectViewAssessment"){
            selectedMenu["menu"] = "3";
            selectedMenu["subMenu"] = "6";
        }else if(displayPage === "createUser"){
            selectedMenu["menu"] = "9";
            selectedMenu["subMenu"] = "1";
        }else if(displayPage === "policy"){
            selectedMenu["menu"] = "6";
        }else if(displayPage === "issueTracker"){
            selectedMenu["menu"] = "10";
            selectedMenu["subMenu"] = "1";
        }else if(displayPage === "projectFinancial"){
            selectedMenu["menu"] = "10";
            selectedMenu["subMenu"] = "3";
        }else if(displayPage === "portfolioManagement"){
            selectedMenu["menu"] = "10";
            selectedMenu["subMenu"] = "2";
            selectedMenu["secondSubMenu"] = "1";
        }else if(displayPage === "projectSecurity"){
            selectedMenu["menu"] = "10";
            selectedMenu["subMenu"] = "2";
            selectedMenu["secondSubMenu"] = "2";
        }else if(displayPage === "projectReport"){
            selectedMenu["menu"] = "10";
            selectedMenu["subMenu"] = "4";
        }else if(displayPage === "identifiedIssues"){
            selectedMenu["menu"] = "2";
            selectedMenu["subMenu"] = "4";
            selectedMenu["secondSubMenu"] = "1";    
        }else{
            return <></>
        }
        return selectedMenu;
    }
    var displayMenu = getSelectedMenus();
    // setting drawer content
    const drawer = (
      <div>
        <Toolbar />
        <Divider />
        <List>
          {menu.map((value) => {
            if(checkIfModlueExists(value["module"])){
                return(
                <Fragment key={value.id} >
                    <ListItem disablePadding className={value.id === displayMenu["menu"] ? "selectedMenu" : ""} onClick={value.hasOwnProperty("componentKey") ? ()=>{dispatch(setPage(value.componentKey))}:()=>{}}>
                        <ListItemButton onClick={value.hasOwnProperty("subMenu") ? ()=>{submenuHandleClick(value.expansionKey)} : ()=>{}}>
                            <ListItemText primaryTypographyProps={{fontSize:"14px"}} primary={value.name} />
                            {value.hasOwnProperty("subMenu") ? subMenuExpansion[value.expansionKey]  ? <ExpandLessIcon /> : <ExpandMoreIcon /> : <></>}
                        </ListItemButton>
                    </ListItem>
                    {value.hasOwnProperty("subMenu") ?
                        <Collapse in={subMenuExpansion[value.expansionKey]} timeout="auto" unmountOnExit>
                        <List className='submenuColourTheme' component="div" disablePadding>
                        {value.subMenu.map((subValue) => {
                            if(!checkIfFeatureVisible(subValue.id,value["module"])){
                                return <Fragment key={subValue.id}></Fragment>;
                            }
                            return (
                            <Fragment key={subValue.id}>
                            <ListItem className={subValue.id === `${displayMenu["menu"]}.${displayMenu["subMenu"]}` ? "selectedMenu" : "" } onClick={subValue.hasOwnProperty("componentKey") ? ()=>{dispatch(setPage(subValue.componentKey))}:()=>{}} disablePadding>
                                <ListItemButton className={style.subMenu} onClick={subValue.hasOwnProperty("subMenu") ? ()=>{submenuHandleClick(subValue.expansionKey)} : ()=>{}}>
                                    <ListItemText primaryTypographyProps={{fontSize:"14px"}} primary={subValue.name} />
                                    {subValue.hasOwnProperty("subMenu") ? subMenuExpansion[subValue.expansionKey]  ? <ExpandLessIcon /> : <ExpandMoreIcon /> : <></>}
                                </ListItemButton>
                            </ListItem>
                            {subValue.hasOwnProperty("subMenu") ?
                                <Collapse in={subMenuExpansion[subValue.expansionKey]} timeout="auto" unmountOnExit>
                                <List className='submenuColourTheme' component="div" disablePadding>
                                {subValue.subMenu.map((secondSubValue) => (
                                    <ListItem key={secondSubValue.id} className={secondSubValue.id === `${displayMenu["menu"]}.${displayMenu["subMenu"]}.${displayMenu["secondSubMenu"]}` ? "selectedMenu" : "" } onClick={secondSubValue.hasOwnProperty("componentKey") ? ()=>{dispatch(setPage(secondSubValue.componentKey))}:()=>{}} disablePadding>
                                        <ListItemButton className={style.secondSubMenu}>
                                            <ListItemText primaryTypographyProps={{fontSize:"14px"}} primary={secondSubValue.name} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                                </List>
                                </Collapse>
                                :<></>
                            }
                            </Fragment>
                            )
                        })}
                        </List>
                        </Collapse>
                        :
                        <></>
                    }
                </Fragment>)
            }else{
                return <Fragment key={value.id} ></Fragment>
            }
        })}
        </List>
      </div>
    );
    
    return (
        <>
            <CssBaseline />
            {/* app bar content */}
            <AppBar
                position="fixed"
                sx={{
                width: { md: `100%` },
                ml: { md: `${drawerWidth}px` },
                }}
                style={{"zIndex":1300}}
                className={"appBarColourTheme"}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography style={{ flex: 1, fontSize:"25px",fontWeight: "bold"}} variant="h6" noWrap component="div">
                        RISK ONE
                    </Typography>
                    <IconButton color="inherit">
                        <Badge badgeContent={notificationCountBadge["email"]} sx={{"& .MuiBadge-badge": {backgroundColor: "#5c8de1"}}}>
                            <MailIcon onClick={()=>dispatch(setPage("emailInbox"))} color="white" />
                        </Badge>
                    </IconButton>
                    <IconButton color="inherit">
                        <Badge badgeContent={0} sx={{"& .MuiBadge-badge": {backgroundColor: "#00b2a8"}}}>
                            <NotificationsIcon color="white" />
                        </Badge>
                    </IconButton>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={(event)=>{var temp = appBarState;temp["profile"]["menuAnchor"]=event.currentTarget;setAppBarState({...temp})}}
                        color="inherit"
                    >
                        <AccountCircleIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={appBarState["profile"]["menuAnchor"]}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                        }}
                        open={Boolean(appBarState["profile"]["menuAnchor"])}
                        onClose={(event)=>{var temp = appBarState;temp["profile"]["menuAnchor"]=null;setAppBarState({...temp})}}
                    >
                        <MenuItem onClick={logoutUser}>Logout</MenuItem>
                    </Menu>
                    <Typography style={{fontWeight:"bold"}}>Welcome {loggedInUser["name"]}!</Typography>
                    <IconButton color="inherit">
                        <SettingsIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            {/* drawer content */}
            <Box
                component="nav"
                sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 }}}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    PaperProps={{
                        sx: {
                        backgroundColor: "#166fb8",
                        color: "white"
                        // backgroundColor: "#1e1e1e",
                        // color: "#e4e4e4"
                        }
                    }}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
                    }}
                >
                    {/* calling defined drawer content for mobile screens */}
                    {drawer}
                </Drawer>
                <Drawer
                    PaperProps={{
                        sx: {
                        backgroundColor: "#166fb8",
                        color: "white"
                        // backgroundColor: "#1e1e1e",
                        // color: "#e4e4e4"
                        }
                    }}
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'none', md: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
                    }}
                    open
                >
                    {/* calling defined drawer content for bigger screens */}
                    {drawer}
                </Drawer>
            </Box>
        </>
    );
}

export default Nav;