import React, { useState } from 'react';
import style from '../../css/LogIn.module.css'
import { Typography, TextField, Button } from '@mui/material';
import { textFieldStyle } from '../../js/standardStyle';
import LoginIcon from '@mui/icons-material/Login';
import axios from 'axios';
import { getErrorMsg } from '../../js/util';
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/actions/loggedInUser';
import { SERVICE_URL } from '../../config';
import { setSpinner } from '../../redux/actions/spinner';
import { toastUp } from '../../redux/actions/toast';

function LogInForm(props) {

    // init use state for form data
    const [formData,setFormData] = useState({"email":"","password":""});
    // function to handle form value change
    const handleFormValueChange = key => event => {
        var temp = formData;
        var value = event.target.value;
        temp[key] = value;
        setFormData({...temp});
    }

    // get use dispactch from redux
    const dispatch = useDispatch();

    // function to login the user
    const loginUser = () => {
        var email = formData["email"];
        var password = formData["password"];
        if(email === ""){
            dispatch(toastUp("Please enter email-id"));
            return false;
        }
        if(password === ""){
            dispatch(toastUp("Please enter password"));
            return false;
        }
        var data={};
        data["email"]=email;
        data["password"]=password
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}user/login`,data,{ withCredentials: true })
        .then((res) => {
            if(res.status === 200){
                dispatch(setUser(res.data));
            }else{
                console.log(res)
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch((err) => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        })
    }
    return (
        <div className={style.form}>
            {/* <Typography className={style.companyName}>RISK ONE</Typography> */}
            <Typography className={style.Formheader}>LOGIN</Typography>
            <br/>
            <div style={{width:"100%"}}>
                <div className='mb15'>
                    <span className='lable'>Email <span className='required'>*</span></span><br/>
                    <TextField sx={textFieldStyle} value={formData["email"]} onChange={handleFormValueChange("email")} fullWidth size="small" variant="outlined" />
                </div>
                <div>
                    <span className='lable'>Password <span className='required'>*</span></span><br/>
                    <TextField sx={textFieldStyle} value={formData["password"]} onChange={handleFormValueChange("password")} fullWidth size="small" type="password" variant="outlined" />
                </div>
            </div>
            <div className='mtb30 taCenter'>
                <Button className={`mlr5 roButton plr40`} variant="contained" endIcon={<LoginIcon/>} onClick={loginUser}>login</Button>
                <div id={style.forgetPassword} className='mt5' onClick={()=>props.setLoginUiHandler("forgotPasswordForm")}>Forgot Password</div>
            </div>
        </div>
    );
}

export default LogInForm;