const toastReducer = (state={"open":false,"message":""},action) => {
    switch(action.type){
        case 'toastUp':
            return {"open":true,"message":action.payload};
        case 'closeToast':
            return {"open":false,"message":""};
        default:
            return state;
    }
}

export default toastReducer;