import React, { Fragment } from 'react';
import {Typography,Breadcrumbs,Divider,Box,Toolbar} from '@mui/material';

function HeaderSection(props) {
    const generateBreadcrumbs = () => {
        if(props.breadcrumbs === undefined || props.breadcrumbs.length === 0){
            return <br/>;
        }
        return props.breadcrumbs.map(breakcrumb => <Typography key={breakcrumb} className='breadcrumbs'>{breakcrumb}</Typography>);
    }
    const generateButtons = () => {
        if(props.specialButtons === undefined){
            return [];
        }
        return props.specialButtons.map((button, index) => <Fragment key={index}>{button}</Fragment>)
    }
    return (
        <div>
            <Toolbar>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h5">
                        {props.header}
                    </Typography>
                    <div className='mt10 mb20'>
                    <Breadcrumbs className='cDark' aria-label="breadcrumb">
                        {generateBreadcrumbs()}
                    </Breadcrumbs>
                    </div>
                </Box>
                <div>
                    {generateButtons()}
                </div>
            </Toolbar>
            <Divider className='divider' />
        </div>
    );
}

export default HeaderSection;