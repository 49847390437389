import React, { useEffect, useState } from 'react';
import tableStyle from '../../../css/TableStyleCustom.module.module.css'
import { fileToBase64, fileToBlob, generateArrayForAutoCompleteBasedOnkey, getNameBasedOnValue, renderDisplayValueForAutocomplete } from '../../../js/util';
import { ynDropDown } from '../../../js/constants';
import { Radio, RadioGroup, FormControlLabel, Button, Autocomplete, TextField, Typography, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from '../../../redux/actions/spinner';
import { toastUp } from '../../../redux/actions/toast';
import axios from 'axios';
import { SERVICE_URL } from '../../../config';
import { getErrorMsg } from '../../../js/util';
import { autocompleteStyle, textFieldStyle } from '../../../js/standardStyle';
import ModalMessageBox from "../../wrapper/ModalMessageBox";
import ModalFormDisplay from '../../wrapper/ModalFormDisplay';
import ModalViewInfo from '../../wrapper/ModalViewInfo'
import ActionPlanEditForModal from './ActionPlanEditForModal';


function IdentifiedIssueResponseForModal(props) {
    const loggedInUser = useSelector(state=>state.loggedInUser);
    const dispatch = useDispatch();
    const changeAnswerType = ["Reason","Evidence"];
    const onHoldType = ["Pin","Investigate"]
    const [tableData, setTableData] = useState({});
    const handleChangeAnswerValueForTableData = (parentId,childId,key) => async (event,object) => {
        var temp = tableData;
        for(var row of temp[parentId]["response"]){
            if(row["id"] === childId){
                var value = event.target.value;
                if(["changeAnswerType","onHoldType"].includes(key)){
                    value = object;
                }
                if(["evidence"].includes(key)){
                    value = event.target.files[0];
                    var blob = await fileToBlob(value);
                    row["evidenceUri"] = blob;
                }
                if(key === "changeAnswerType"){
                    if(value === null){
                        value = "Reason";
                        row["reason"] = "";
                    }else if(value === "Reason"){
                        row["evidence"] = null;
                        row["evidenceUri"] = null;
                    }else if(value === "Evidence"){
                        row["reason"] = "";
                    }
                }
                row[key] = value;
            }
        }
        setTableData({...temp});
    }
    const [displayModal, setDisplayModal] = useState({"isOpen":false,"response":""});
    const openDisplayModal = (text) => {
        var temp = displayModal;
        temp["isOpen"] = true;
        temp["response"] = text;
        setDisplayModal({...temp});
    }
    const closeDisplayModal = () => {
        var temp = displayModal;
        temp["isOpen"] = false;
        temp["response"] = "";
        setDisplayModal({...temp});
    }
    const [actionPlanModal,setActionPlanModal] = useState({"isOpen":false,"data":{}});
    const openActionPlanModal = (actionPlanId) => {
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}assessment/register/identifiedIssues/actionPlan?id=${actionPlanId}`)
        .then(res=>{
            var temp = actionPlanModal;
            temp["isOpen"] = true;
            temp["data"] = res["data"]["data"];
            setActionPlanModal({...temp});
        })
        .catch(err=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
    }
    const closeActionPlanModal = () => {
        var temp = actionPlanModal;
        temp["isOpen"] = false;
        temp["data"] = {}
        setActionPlanModal({...temp});
    }
    const generateTableContent = data => {
        let markUp = [];
        if(Object.keys(data).length > 0){
            for(let key in data){
                for(let row of data[key]["response"]){
                    markUp.push(
                        <tr style={{verticalAlign:"middle"}} key={row["id"]} className={`${tableStyle.tbodyTr}`}>
                            <td className={`${tableStyle.tbodyTd} ${tableStyle.td}`}>{data[key]["controlDetails"]["code"]}</td>
                            <td className={`${tableStyle.tbodyTd} ${tableStyle.td}`}>{data[key]["controlDetails"]["name"]}</td>
                            <td className={`${tableStyle.tbodyTd} ${tableStyle.td}`}>{row["question"]}</td>
                            <td className={`${tableStyle.tbodyTd} ${tableStyle.td}`}>{getNameBasedOnValue(ynDropDown,row["answer"])}</td>
                            <td className={`${tableStyle.tbodyTd} ${tableStyle.td}`}>
                                <RadioGroup key={row["id"]} row value={row["changeAnswer"]} onChange={handleChangeAnswerValueForTableData(key,row["id"],"changeAnswer")}>
                                    <FormControlLabel value="yes" control={<Radio size="small" />} label={<span className='fs14'>Yes</span>} />
                                    <FormControlLabel value="no" control={<Radio size="small" />} label={<span className='fs14'>No</span>} />
                                </RadioGroup>
                            </td>
                            <td className={`${tableStyle.tbodyTd} ${tableStyle.td}`}>
                                <Autocomplete style={{display:(row["changeAnswer"]==='yes')?"block":"none"}} size='small' value={row["changeAnswerType"]} onChange={handleChangeAnswerValueForTableData(key,row["id"],"changeAnswerType")} options={changeAnswerType} renderInput={renderDisplayValueForAutocomplete} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps}/>
                            </td>
                            <td className={`${tableStyle.tbodyTd} ${tableStyle.td}`}>
                                <div style={{display:(row["changeAnswer"]==='yes')?"block":"none"}}>
                                    {
                                        (row["changeAnswerType"]==='Reason') ?
                                            <div> 
                                                <TextField fullWidth size='small' multiline maxRows={4} value={row["reason"]} onChange={handleChangeAnswerValueForTableData(key,row["id"],"reason")} sx={textFieldStyle} />
                                            </div>
                                        :   
                                            (row["changeAnswerType"]==='Evidence') ?
                                                <div>
                                                    <label htmlFor={`${row["id"]}-evidenceFilePicker`} style={{ padding:"8px 12px", border:"1px solid rgba(0, 0, 0, 0.4)",borderRadius:"5px",display:"inline-block" }}>
                                                        Browes
                                                    </label>
                                                    <input
                                                    onChange={handleChangeAnswerValueForTableData(key,row["id"],"evidence")}
                                                    accept=".pdf"
                                                    id={`${row["id"]}-evidenceFilePicker`}
                                                    style={{display:"none"}}
                                                    type="file"
                                                    />
                                                    <Typography style={{width:"calc(100% - 100px)",display:"inline-block",verticalAlign:"middle"}} noWrap className='mlr5'>{(row["evidence"]!==null) ? row["evidence"]["name"] : "No file chosen"}</Typography>
                                                    {
                                                        (row["evidence"]!==null) ?  
                                                            <div className='taCenter'>
                                                                <a href={(row["evidence"]["alreadyExists"]) ? `${SERVICE_URL}assessment/register/identifiedIssues/evidence?fileName=${row["evidence"]["name"]}` : row["evidenceUri"]} rel='noopener noreferrer' target='_blank'>preview</a> 
                                                            </div>
                                                        :
                                                            <></>
                                                    }
                                                    
                                                </div>
                                            :
                                                <></>
                                    }
                                </div>
                            </td>
                            <td className={`${tableStyle.tbodyTd} ${tableStyle.td}`}>
                                <Autocomplete size='small' value={row["onHoldType"]} onChange={handleChangeAnswerValueForTableData(key,row["id"],"onHoldType")} options={onHoldType} renderInput={renderDisplayValueForAutocomplete} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps}/>
                                {
                                    (row["onHoldType"]==="Investigate") ?
                                        (row["issue"] === null) ?
                                            // eslint-disable-next-line
                                            <div onClick={()=>{
                                                openMessageBoxModal(key,row["id"]);
                                            }} className='mt10 taCenter hyperLinkActive'>
                                                compose mail box
                                            </div>
                                        :   
                                            (row["issue"]["issueInvestigate"] === null) ?
                                                <></>
                                            :
                                                (row["issue"]["issueInvestigate"]["responseType"] === null) ?
                                                    <div className='mt10 taCenter'>Waiting For Response</div>
                                                :
                                                    (["known_issue","take_no_action"].includes(row["issue"]["issueInvestigate"]["responseType"])) ?
                                                        <div className='mt10 taCenter'>
                                                            {(row["issue"]["issueInvestigate"]["responseType"] === "known_issue") ? "Known Issue" : "Take No Action"} / <span onClick={()=>{openDisplayModal(row["issue"]["issueInvestigate"]["response"])}} className='hyperLinkActive'>open response</span>
                                                        </div>
                                                    :
                                                        (["action_plan"].includes(row["issue"]["issueInvestigate"]["responseType"])) ?
                                                            <div className='mt10 taCenter'>
                                                            <span onClick={()=>{openActionPlanModal(row["issue"]["issueInvestigate"]["actionPlanId"])}} className='hyperLinkActive'>Open Action Plan</span>
                                                            </div>
                                                        :
                                                            <></>
                                    :
                                        <></>
                                }
                            </td>
                        </tr>
                    )
                }
            }
        }else{
            markUp.push(
                <tr style={{verticalAlign:"middle"}} key={"tableElse"} className={`${tableStyle.tbodyTr}`}>
                    <td className={`${tableStyle.tbodyTd} ${tableStyle.td} taCenter`} colSpan={"7"}>No Issues Found</td>
                </tr>
            )
        }
        return markUp;
    }
    useEffect(()=>{
        const {data} = props;
        var modifiedControlResponse = data["frameworkControlResponse"];
        for(var key in modifiedControlResponse){
            for(var row of modifiedControlResponse[key]["response"]){
                row["changeAnswer"] = "no";
                row["changeAnswerType"] = 'Reason';
                row["reason"] = "";
                row["evidence"] = null;
                row["evidenceUri"] = null;
                if(row["issue"]!==null){
                    if(row["issue"]["onHoldType"] === "pin"){
                        row["onHoldType"] = "Pin";
                    }else if(row["issue"]["onHoldType"] === "investigate"){
                        row["onHoldType"] = "Investigate";
                    }
                    if(row["issue"]["changeAnswer"]){
                        row["changeAnswer"] = "yes";
                        if(row["issue"]["changeAnswerType"] === "reason"){
                            row["reason"] = row["issue"]["reason"];
                        }else if(row["issue"]["changeAnswerType"] === "evidence"){
                            row["changeAnswerType"] = "Evidence";
                            row["evidence"] = {"name":row["issue"]["evidenceFileName"],"alreadyExists":true};
                        }
                    }
                }else{
                    row["onHoldType"] = null;
                    row["mailBox"] = {"message":"","receiver":""};
                }
            }
        }
        setTableData({...modifiedControlResponse});
        // eslint-disable-next-line
    },[])
    const submitRegister = async () => {
        var data = {};
        data["issueResponse"] = [];
        for(var key in tableData){
            for(var row of tableData[key]["response"]){
                var response = {}
                if(row["changeAnswer"] === "no"){
                    response = {
                        "id" : row["id"],
                        "changeAnswer" : false
                    }
                }else if(row["changeAnswer"] === "yes"){
                    if(row["changeAnswerType"]==="Reason"){
                        if(row["reason"] === ""){
                            dispatch(toastUp(`${row["question"]} reason cannot be empty`));
                            return false;
                        }
                        response = {
                            "id" : row["id"],
                            "changeAnswer" : true,
                            "reason" : row["reason"],
                            "changeAnswerType" : "reason"
                        }
                    }else if(row["changeAnswerType"]==="Evidence"){
                        if(row["evidence"] === null){
                            dispatch(toastUp(`${row["question"]} evidence cannot be empty`));
                            return false;
                        }
                        response = {
                            "id" : row["id"],
                            "changeAnswer" : true,
                            "changeAnswerType" : "evidence"
                        }
                        if(row["evidence"]["alreadyExists"]){
                            response["evidence"] = null;
                            response["ignoreEvidence"] = true;
                        }else{
                            response["evidence"] = {"base64": await fileToBase64(row["evidence"]),"fileName":row["evidence"].name};
                        }
                    }
                }else{
                    dispatch(toastUp("Unable To Handle Request"));
                    return false;
                }

                if(row["onHoldType"] === "Investigate"){
                    response["onHoldType"]="investigate";
                    if(row["issue"] === null){
                        response["mailBox"] = {}
                        response["mailBox"]["message"] = row["mailBox"]["message"]
                        response["mailBox"]["receiver"] = row["mailBox"]["receiver"]
                        response["mailBox"]["sender"] = loggedInUser["email"]
                        response["mailBox"]["companyId"] = loggedInUser["company_id"]
                    }
                }else if(row["onHoldType"] === "Pin"){
                    response["onHoldType"]="pin";
                }

                data["issueResponse"].push(response);
            }
        }
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}assessment/register/identifiedIssues`,data)
        .then(res => {
            dispatch(toastUp("Assessment issue response saved successfully"));
            props.refreshAndCloseHandler();
        })
        .catch(err => {
            console.log(err)
            dispatch(toastUp(getErrorMsg(err)));
            if(err.hasOwnProperty("response") && err.response.status === 401){
                props.refreshAndCloseHandler();
            }
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    const [messageBox,setMessageBox]=useState({"isOpen":false,"parentId":"","childId":"","value":{"message":"","receiver":null,"question":""},"metaData":{"user":[]}})
    const openMessageBoxModal = (parentId,childId) => {
        var message = null;
        var question = null;
        var receiver = null;
        for(var row of tableData[parentId]["response"]){
            if(row["id"] === childId){
                var mailBox = row["mailBox"]
                message = mailBox["message"]
                receiver = mailBox["receiver"]
                question = row["question"]
            }
        }
        if(message === null || question === null){
            dispatch(toastUp("Issue getting mail box"));
            return false;
        }
        var temp = messageBox;
        temp["isOpen"] = true;
        temp["childId"] = childId;
        temp["parentId"] = parentId;
        temp["value"]["message"] = message;
        temp["value"]["question"] = question;
        temp["value"]["receiver"] = receiver;
        setMessageBox({...temp});
    }
    const closeMessageBoxModal = () => {
        var temp = messageBox;
        temp["isOpen"] = false;
        temp["value"]["message"] = "";
        temp["value"]["receiver"] = null;
        temp["value"]["question"] = "";
        temp["childId"] = "";
        temp["parentId"] = "";
        setMessageBox({...temp});
    }
    const changeMessageBoxValue = (key) => (event,object) => {
        var temp = messageBox;
        var value = event.target.value;
        if(key === "receiver"){
            value = object;
        }
        temp["value"][key] = value;
        setMessageBox({...temp});
    }
    const populateMailContent = () => {
        var temp = messageBox;
        if(temp["value"]["receiver"] === null){
            return false;
        }
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}email/getUserName?email=${temp["value"]["receiver"]}&companyId=${loggedInUser["company_id"]}`)
        .then((res)=>{
            if(loggedInUser["id"] === res["data"]["id"]){
                temp["value"]["receiver"] = "";
                temp["value"]["message"] = "";
                dispatch(toastUp("Can't send mail to yourself"));
                return false;
            }
            var userName = res["data"]["userName"];
            temp["value"]["message"] = `Hi ${userName}, for the ${props.data.framework} assessment, This question scored a No. Do you have an update for this question.`
        })
        .catch(err=>{
            temp["value"]["message"] = "";
            temp["value"]["receiver"] = "";
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            setMessageBox({...temp})
            dispatch(setSpinner(false));
        })

    }
    const saveAndResetMessageBox = () => {
        var temp = messageBox;
        if(temp["value"]["receiver"] === null){
            dispatch(toastUp("Mail To field cannot be empty"));
            return false;
        }
        if(temp["value"]["message"] === null){
            dispatch(toastUp("Content field cannot be empty"));
            return false;
        }
        var updatedTableData = tableData;
        for(var row of updatedTableData[temp["parentId"]]["response"]){
            if(row["id"] === temp["childId"]){
                row["mailBox"]["message"] = temp["value"]["message"];
                row["mailBox"]["receiver"] = temp["value"]["receiver"];
                setTableData({...updatedTableData});
            }
        }
        setMessageBox({...temp});
        closeMessageBoxModal();
        dispatch(toastUp("Email set successfully, Submit inspect issue to send email"))
    }
    useEffect(()=>{
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}user?companyId=${loggedInUser["company_id"]}&roles=Line Manager`)
        .then(res => {
            var temp = messageBox;
            temp["metaData"]["user"] = generateArrayForAutoCompleteBasedOnkey(res["data"]["data"],"email")
            setMessageBox({...temp});
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        // eslint-disable-next-line
    },[]);
    return (
        <div style={{height:"100%"}}>
            <Typography className='cDark' id="modal-modal-title" variant="h6" component="h2">
                Inspect Issue
            </Typography>
            <div className='m20' style={{overflowX:"hidden",height:"calc(100% - 130px)"}}>
                <table style={{wordBreak:"break-word"}} className={`${tableStyle.table}`}>
                    <thead>
                        <tr className={`${tableStyle.theadTr}`}>
                            <th className={`${tableStyle.th}`}>Standard/Framework</th>
                            <th className={`${tableStyle.th}`}>Section Label</th>
                            <th className={`${tableStyle.th}`}>Inquiry Detail</th>
                            <th className={`${tableStyle.th}`}>Initial Response</th>
                            <th className={`${tableStyle.th}`}>Change Response</th>
                            <th className={`${tableStyle.th}`}>Support Type</th>
                            <th className={`${tableStyle.th}`}>Reason/Evidence</th>
                            <th className={`${tableStyle.th}`}>Outcome</th>
                        </tr>
                    </thead>
                    <tbody style={{fontSize:"14px",verticalAlign:"top"}}>
                        {generateTableContent(tableData)}
                    </tbody>
                </table>
            </div>
            <div className='mt30 mb15' style={{textAlign:"center"}}>
                <Button className='mlr5 roButton' variant="contained" onClick={submitRegister} >submit</Button>
            </div>
            <ModalMessageBox open={messageBox.isOpen} closeHandler={closeMessageBoxModal} header={"Compose Email"}>
                <div style={{height:"calc(100% - 80px)",overflowX:"hidden"}}>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <span className='lable'>Mail To </span><span className='required'>*</span><br/>
                            <Autocomplete value={messageBox.value.receiver} autoSelect onBlur={populateMailContent} onChange={changeMessageBoxValue("receiver")} options={messageBox.metaData.user} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} size='small' freeSolo disablePortal renderInput={renderDisplayValueForAutocomplete} />
                        </Grid>
                        <Grid item sm={12}>
                            <span className='lable'>Objective </span><span className='required'>*</span><br/>
                            <TextField disabled size='small' value={messageBox.value.question} sx={textFieldStyle} fullWidth multiline/>
                        </Grid>
                        <Grid item sm={12}>
                            <span className='lable'>Content </span><span className='required'>*</span><br/>
                            <TextField size='small' value={messageBox.value.message} onChange={changeMessageBoxValue("message")} sx={textFieldStyle} fullWidth multiline rows={4} />
                        </Grid>
                    </Grid>
                </div>
                <div className='mt5' style={{textAlign:"center"}}>
                    <Button onClick={saveAndResetMessageBox} variant='contained'>save</Button>
                </div>
            </ModalMessageBox>
            <ModalFormDisplay open={displayModal.isOpen} closeHandler={closeDisplayModal} ignoreSave header={"Response"}>
                <Typography className='m10' style={{"whiteSpace":"pre-line"}}>
                    {displayModal.response}
                </Typography>
            </ModalFormDisplay>
            <ModalViewInfo open={actionPlanModal.isOpen} closeHandler={closeActionPlanModal} max ignoreClose>
                <ActionPlanEditForModal actionPlanData={actionPlanModal.data} closeHandler={closeActionPlanModal} />
            </ModalViewInfo>
        </div>
    );
}

export default IdentifiedIssueResponseForModal;