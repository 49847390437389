export const setConfirmationModal = (payload) => {
    return {
        type:"setConfirmationModal",
        payload:payload
    }
}

export const closeConfirmationModal = () => {
    return {
        type:"closeConfirmationModal"
    }
}