import React, {useState, useEffect} from 'react';
import HeaderSection from '../HeaderSection';
import axios from 'axios';
import {Checkbox,MenuItem,Grid,Select,Button,Typography,Accordion,AccordionSummary,AccordionDetails, Autocomplete} from '@mui/material';
import {FormControlLabel,FormControl} from '@mui/material';
import { SERVICE_URL } from '../../config';
import { selectFieldStyle, autocompleteStyle } from '../../js/standardStyle';
import { concatTwoKeyInArrayOfObject, generateDropDown, wrapEmailInArrayOfObject } from '../../js/util';
import FieldsetCustom from '../wrapper/FieldsetCustom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getArrayOfObjectBasedOnKey, getErrorMsg, renderDisplayValueForAutocomplete } from '../../js/util';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from '../../redux/actions/spinner';
import { toastUp } from '../../redux/actions/toast';

function AssignAssessment(props) {

    // get dispatch from redux
    const dispatch = useDispatch();

    // get logged in user details
    const loggedInUser = useSelector(state => state.loggedInUser);
    // get company id
    const companyId = loggedInUser["company_id"];
    // get user id
    const userId = loggedInUser["id"]
    // init use state for form data
    const [formData, setFormData] = useState({"frameWork":"","controlFamilies":[],"controlDescription":{},"riskCategory":"","name":"","type":"","assignTo":""});
    // function to handle form value change
    const handleFormValueChange = key => (event,object) => {
        var temp = formData;
        var value = event.target.value;

        if(key === "controlFamilies"){
            value = object;
        }

        temp[key] = value;
        setFormData({...temp});

        if(key === "frameWork"){
            if(value !== ""){
                populateFrameworkBasedOnFrameworkId(value);
            }
        }
        if(key === "controlFamilies"){
            populateQuestionsForControlFamilies();
        }
        showOrHideSection(key,value);
    }

    const populateQuestionsForControlFamilies = async () => {
        var temp = formData;
        var controlDescriptionState = {};
        for(var row of formData["controlFamilies"]){
            if(! formData["controlDescription"].hasOwnProperty(row["id"])){
                var displayName = row["code"]+" -- "+row["name"];
                var res = await getControlFamilyQuestions(row["id"]);
                if(res["promiseStatus"] === "success"){
                    var data = res["data"]["data"].map((data)=>({...data,"isSelected":false}));
                    controlDescriptionState[row["id"]] = {"basic":{"id":row["id"],"displayName":displayName},"data":data};
                }else{
                    dispatch(toastUp("Issue Getting Control Description For "+displayName));
                }
            }else{
                controlDescriptionState[row["id"]] = formData["controlDescription"][row["id"]];
            }
        }
        temp['controlDescription'] = controlDescriptionState;
        setFormData({...temp});
    }

    const getControlFamilyQuestions = (controlId) => new Promise((resolve, reject) => {
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}assessment/framework/control/question?controlId=${controlId}`)
        .then(res => {
            dispatch(setSpinner(false));
            res["promiseStatus"] = "success";
            resolve(res);
        })
        .catch(err => {
            dispatch(setSpinner(false));
            err["promiseStatus"] = "error";
            reject(err);
        })
    })

    const handleValueChangeForControlDescription = (controlId,questionId) => (event) => {
        var temp = formData
        for (var row of temp["controlDescription"][controlId]["data"]){
            if(row["id"] === questionId){
                row["isSelected"] = event.target.checked;
                break;
            }
        }
        setFormData({...temp});
    }

    const generateControlDescriptionMarkUp = (data) => {
        var markUp = [];
        for(var key in data){
            if(data[key]["data"].length > 0){
                var questionSet = [];
                for(var row of data[key]["data"]){
                    questionSet.push(
                        <FormControlLabel key={row["id"]}
                            control={<Checkbox checked={row["isSelected"]} onChange={handleValueChangeForControlDescription(key,row["id"])}/>}
                            label={<span style={{userSelect:"none",fontSize:"14px"}}>{row["question"]}</span>}
                        />
                    )
                }
                markUp.push(
                    <Accordion key={key}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        >
                        <Typography>{data[key]["basic"]["displayName"]}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <FormControl>
                                {questionSet}
                            </FormControl>
                        </AccordionDetails>
                    </Accordion>
                )
            }else{
                markUp.push(
                    <Accordion key={key}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        >
                        <Typography>{data[key]["basic"]["displayName"]}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='taCenter'>No Data Found</div>
                        </AccordionDetails>
                    </Accordion>
                )
            }
        }
        return markUp
    }

    const showOrHideSection = (key,value) => {

        var updatedHideElement = hideElement;

        if(key === "frameWork"){
            updatedHideElement["controlFamilies"] = false;
        }

        if(key === "controlFamilies"){
            updatedHideElement["controlDescription"] = true;
            if(value.length !== 0){
                updatedHideElement["name"] = false;
                updatedHideElement["controlDescription"] = false;
            }
        }

        if(key === "name"){
            updatedHideElement["riskCategory"] = false;
        }

        if(key === "riskCategory"){
            updatedHideElement["type"] = false;
        }

        if(key === "type"){
            updatedHideElement["assignTo"] = false;
        }

        setHideElement({...updatedHideElement});

    }

    // init use state for form meta data
    const [formOptionsMetaData,setFormOptionsMetaData] = useState({"assignTo":[],"riskCategory":[],"frameWork":[],"controlFamilies":[]})

    // init use state for hide element
    const [hideElement,setHideElement] = useState({"controlDescription":true,"controlFamilies":true,"riskCategory":true,"name":true,"type":true,"assignTo":true});

    // function to assign the assessment to a user
    const AssignAssessmentToUser = () => {
        var data = validateAndGetFormData();
        if(data["status"] === "valid"){
            dispatch(setSpinner(true));
            axios.post(`${SERVICE_URL}assessment/register`,data["data"])
            .then(res => {
                if(res.status === 200){
                    var selectedUser = getArrayOfObjectBasedOnKey(formOptionsMetaData["assignTo"],"id",formData["assignTo"]);
                    dispatch(toastUp(`Assessment assigned to ${selectedUser["name"]}`));
                    resetForm();
                }else{
                    dispatch(toastUp("unable to connect server"));
                }
            })
            .catch(err => {
                dispatch(toastUp(getErrorMsg(err)));
            })
            .finally(() => {
                dispatch(setSpinner(false));
            });
        }else{
            dispatch(toastUp(data["msg"]));
        }
    }

    // function to validate and get form data
    const validateAndGetFormData = () => {
        var data = {};
        data["status"] = "invalid";

        if(formData["frameWork"] === ""){
            data["msg"] = "Please select framework";
            return data;
        }
        if(formData["controlFamilies"].length === 0){
            data["msg"] = "Please select control families";
            return data;
        }
        if(formData["name"] === ""){
            data["msg"] = "Please select name";
            return data;
        }
        if(formData["riskCategory"] === ""){
            data["msg"] = "Please select risk category";
            return data;
        }
        if(formData["type"] === ""){
            data["msg"] = "Please select type";
            return data;
        }
        if(formData["assignTo"] === ""){
            data["msg"] = "Please select assign to";
            return data;
        }

        var selectedQuestions = []
        for(var key in formData["controlDescription"]){
            var controlFamilyFlag = true;
            for(var row of formData["controlDescription"][key]["data"]){
                if(row["isSelected"]){
                    controlFamilyFlag = false;
                    selectedQuestions.push(row["id"])
                }
            }
            if(controlFamilyFlag){
                data["msg"] = `Please select atleast one Control Description for ${formData["controlDescription"][key]["basic"]["displayName"]}`;
                return data;
            }
        }
        if(selectedQuestions.length === 0){
            data["msg"] = "Please select atleast one control description";
            return data;
        }

        data["status"] = "valid";

        var formValue = {}
        formValue["companyId"] = companyId;
        formValue["frameworkId"] = formData["frameWork"];
        formValue["categoryId"] = formData["riskCategory"];
        formValue["name"] = formData["name"];
        formValue["type"] = formData["type"];
        formValue["assignBy"] = userId;
        formValue["assignTo"] = formData["assignTo"];
        formValue["frameworkQuestionList"] = selectedQuestions;

        data["data"] = formValue;
        
        return data;
    }

    // function to reset the form
    const resetForm = () => {

        var updatedFormData = formData;
        updatedFormData["frameWork"] = "";
        updatedFormData["controlFamilies"] = [];
        updatedFormData["riskCategory"] = "";
        updatedFormData["name"] = "";
        updatedFormData["type"] = "";
        updatedFormData["assignTo"] = "";
        updatedFormData["controlDescription"] = {};
        setFormData({...updatedFormData});

        var updatedHideElement = hideElement;
        updatedHideElement["controlDescription"] = true;
        updatedHideElement["controlFamilies"] = true;
        updatedHideElement["name"] = true;
        updatedHideElement["riskCategory"] = true;
        updatedHideElement["type"] = true;
        updatedHideElement["assignTo"] = true;
        setHideElement({...updatedHideElement});
    }
    
    // function to get framework control
    const populateFrameworkBasedOnFrameworkId = (frameworkId) => {
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}assessment/framework/control?frameworkId=${frameworkId}`)
        .then(res => {
            if(res.status === 200){
                var data = res["data"];
                var temp = formOptionsMetaData;
                var updatedData = concatTwoKeyInArrayOfObject(data["data"],"code","name","displayName"," -- ")
                temp["controlFamilies"] = updatedData;
                setFormOptionsMetaData({...temp});
                var updatededFormData = formData;
                updatededFormData["controlFamilies"] = [];
                setFormData({...updatededFormData});
                populateQuestionsForControlFamilies();
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
    }
    // use effect to call the function when ever the page changes 
    useEffect(() => {
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}user?company_id=${companyId}&groups=rbr`)
        .then(res => {
            if(res.status === 200){
                var data = res["data"];
                var temp = formOptionsMetaData;
                temp["assignTo"] = wrapEmailInArrayOfObject(data["data"],'displayName');
                setFormOptionsMetaData({...temp});
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });

        axios.get(`${SERVICE_URL}risk/riskCategory/companyId?companyId=${companyId}`)
        .then(res => {
            if(res.status === 200){
                var temp = formOptionsMetaData;
                temp["riskCategory"] = res.data;
                setFormOptionsMetaData({...temp});
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });

        axios.get(`${SERVICE_URL}assessment/framework`)
        .then(res => {
            if(res.status === 200){
                var data = res["data"];
                var temp = formOptionsMetaData;
                temp["frameWork"] = data["data"];
                setFormOptionsMetaData({...temp});
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        // eslint-disable-next-line
    }, [])
    return (
        <div>
            {/* selected menu details */}
            <HeaderSection header='Assign Assessments' breadcrumbs={["Assessment Managment","Assign Assessments"]}/>
            <div className='m20'>
                <FieldsetCustom legend={"ASSESSMENT"}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={6}>
                            <span className='lable'>Framework <span className='required'>*</span></span><br/>
                            <Select MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFormValueChange("frameWork")} value={formData["frameWork"]} displayEmpty>
                                <MenuItem disabled value=""><em>-- please select--</em></MenuItem>
                                {generateDropDown(formOptionsMetaData["frameWork"],"code")}
                            </Select>
                        </Grid>
                        <Grid style={{ display: (hideElement["controlFamilies"] ? 'none' : 'block')}} item sm={12} md={12}>
                            <span className='lable'>Control Families <span className='required'>*</span></span><br/>
                            <Autocomplete multiple filterSelectedOptions disableCloseOnSelect value={formData["controlFamilies"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["controlFamilies"]} size="small" getOptionLabel={(option) => option.code+" -- "+option.name} isOptionEqualToValue={(option, value) => option.id === value.id} disablePortal onChange={handleFormValueChange("controlFamilies")} renderInput={renderDisplayValueForAutocomplete} />
                        </Grid>
                        <Grid style={{ display: (hideElement["controlDescription"] ? 'none' : 'block'),marginTop:"25px" }} item sm={12} md={12}>
                            <FieldsetCustom legend={"CONTROL DESCRIPTION"}>
                                {generateControlDescriptionMarkUp(formData["controlDescription"])}
                            </FieldsetCustom>
                        </Grid>
                        <Grid style={{ display: (hideElement["name"] ? 'none' : 'block')}} item sm={12} md={6}>
                            <span className='lable'>Assessment Name <span className='required'>*</span></span><br/>
                            <Select MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFormValueChange("name")} value={formData["name"]} displayEmpty>
                                <MenuItem disabled value=""><em>-- please select--</em></MenuItem>
                                <MenuItem value="Data Protection">Data Protection</MenuItem>
                            </Select>
                        </Grid>
                        <Grid style={{ display: (hideElement["riskCategory"] ? 'none' : 'block')}} item sm={12} md={6}>
                            <span className='lable'>Risk Category <span className='required'>*</span></span><br/>
                            <Select MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFormValueChange("riskCategory")} value={formData["riskCategory"]} displayEmpty>
                                <MenuItem disabled value=""><em>-- please select--</em></MenuItem>
                                {generateDropDown(formOptionsMetaData["riskCategory"],"name")}
                            </Select>
                        </Grid>
                        <Grid style={{ display: (hideElement["type"] ? 'none' : 'block')}} item sm={12} md={6}>
                            <span className='lable'>Assessment Type <span className='required'>*</span></span><br/>
                            <Select MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFormValueChange("type")} value={formData["type"]} displayEmpty>
                                <MenuItem disabled value=""><em>-- please select--</em></MenuItem>
                                <MenuItem value="Privacy">Privacy</MenuItem>
                                <MenuItem value="Security">Security</MenuItem>
                            </Select>
                        </Grid>
                        <Grid style={{ display: (hideElement["assignTo"] ? 'none' : 'block')}} item sm={12} md={6}>
                            <span className='lable'>Assign To <span className='required'>*</span></span><br/>
                            <Select MenuProps={selectFieldStyle.MenuProps} sx={selectFieldStyle.sx} fullWidth size="small" onChange={handleFormValueChange("assignTo")} value={formData["assignTo"]} displayEmpty>
                                <MenuItem disabled value=""><em>-- please select--</em></MenuItem>
                                {generateDropDown(formOptionsMetaData["assignTo"],"displayName")}
                            </Select>
                        </Grid>
                    </Grid>
                    <div className='mtb30' style={{textAlign:"center"}}>
                        <Button className='mlr5 roButton' variant="contained" onClick={AssignAssessmentToUser} >assign</Button>
                    </div>
                </FieldsetCustom>
            </div>
        </div>
    );
}

export default AssignAssessment;