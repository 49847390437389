import React, { useState, useEffect } from 'react';
import HeaderSection from '../HeaderSection';
import { useDispatch, useSelector } from 'react-redux';
import style from '../../css/IssueTracker.module.css';
import { generateArrayForAutoCompleteBasedOnkey, getIdOrNullOfObject, renderDisplayValueWithLableForAutocomplete, getErrorMsg, getArrayOfObjectBasedOnKey, renderDisplayValueForAutocomplete,getKeyOrNullOfObject } from '../../js/util';
import { Grid, TextField, Autocomplete, Button, RadioGroup, Radio, FormControlLabel, IconButton, Menu, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { textFieldStyle, autocompleteStyle } from '../../js/standardStyle';
import DataGridCustom from '../wrapper/DataGridCustom';
import { setSpinner } from '../../redux/actions/spinner';
import { SERVICE_URL } from '../../config';
import axios from 'axios';
import { toastUp } from '../../redux/actions/toast';
import ModalForm from '../wrapper/ModalForm';
import { setPage } from '../../redux/actions/displayPage';
import ModalViewInfo from '../wrapper/ModalViewInfo';
import ViewProjectDetailsForModal from './ViewProjectDetailsForModal';
import dayjs from 'dayjs';
import { DATE_FORMAT, projectDropDown } from '../../js/constants';

function PortfolioManagement(props) {
    
    // get loggedin user details
    const loggedInUser = useSelector(state=>state.loggedInUser);
    // get dispatch
    const dispatch = useDispatch();
    // init use state for form data
    const [formData, setFormData] = useState({"project":null,"manager":null,"pmoOffice":"","area":null});
    // function to change form data
    const handleFormValueChange = (key) => (event,object) => {
        var temp = formData;
        var value;
        if(['project','manager','area','status'].includes(key)){
            value = object;
        }else{
            value = event.target.value;
        }
        temp[key]=value;
        setFormData({...temp})
        if(['project','manager','area'].includes(key)){
            applyFilter();
        }
    }
    // init use state for form meta data
    const [formOptionsMetaData, setFormOptionsMetaData] = useState({"project":[],"manager":[],"area":[],"sponsor":[],"sponsorGroup":[],"status":[...projectDropDown["status"]]});
    // init use state for filter
    const [selectedFilter, setSeletedFilter] = useState("");
    // function to trigger search
    const applySearch = (event) => {
        var code = event.keyCode || event.which;
        if(code === 13) {
            applyFilter();
        } 
    }
    // function to apply filter
    const applyFilter = () => {
        var filter = "";
        if(formData["project"] !== null){
            filter+=`&name=${formData['project']}`;
        }
        if(formData["manager"] !== null){
            filter+=`&manager=${getIdOrNullOfObject(formData['manager'])}`;
        }
        if(formData["pmoOffice"] !== ""){
            filter+=`&pmoOffice=${formData['pmoOffice']}`;
        }
        if(formData["area"] !== null){
            filter+=`&area=${getIdOrNullOfObject(formData['area'])}`;
        }
        setSeletedFilter(filter);
        setPageState((old)=>({...old,page:0}));
    }
    // init use state for project modal
    const [projectModal, setProjectModal] = useState({"isOpen":false,'currentId':"","mode":"add","header":"","value":{"name":"","area":null,"pmoOffice":"","manager":null,"sponsor":null,"sponsorGroup":null,"scopeCreep":"","scopeCreepDisplay":"false","startDate":null,"endDate":null,"status":null}});
    // function to open project modal
    const openProjectModal = () => {
        var temp = projectModal;
        temp["isOpen"] = true;
        temp["header"] = "Add Project";
        temp["mode"] = "add";
        temp["currentId"] = "";
        setProjectModal({...temp});
    }
    // function to edit project modal
    const editProjectModal = (id) => {
        var selectedData = getArrayOfObjectBasedOnKey(tableContent["data"],"id",id);
        if(selectedData === null){
            dispatch(toastUp("Issue selecting project"))
            return false
        }
        var temp = projectModal;
        temp["isOpen"] = true;
        temp["header"] = "Edit Project";
        temp["mode"] = "edit";
        temp["value"]["area"] = getArrayOfObjectBasedOnKey(formOptionsMetaData["area"],"id",selectedData["areaId"]);
        temp["value"]["manager"] = getArrayOfObjectBasedOnKey(formOptionsMetaData["manager"],"id",selectedData["managerId"]);
        temp["value"]["status"] = getArrayOfObjectBasedOnKey(projectDropDown["status"],"value",selectedData["status"]);
        temp["value"]["name"] = selectedData["name"];
        temp["value"]["pmoOffice"] = selectedData["pmoOffice"];
        temp["value"]["sponsor"] = getArrayOfObjectBasedOnKey(formOptionsMetaData["sponsor"],"id",selectedData["sponsorId"]);
        temp["value"]["sponsorGroup"] = getArrayOfObjectBasedOnKey(formOptionsMetaData["sponsorGroup"],"id",selectedData["sponsorGroupId"]);
        temp["value"]["startDate"] = dayjs(new Date(selectedData["startDate"]));
        temp["value"]["endDate"] = dayjs(new Date(selectedData["endDate"]));
        temp["value"]["status"] = getArrayOfObjectBasedOnKey(projectDropDown["status"],'value',selectedData["status"]);
        temp["currentId"] = selectedData["id"]
        if(selectedData["scopeCreep"] === ""){
            temp["value"]["scopeCreepDisplay"] = "false";
            temp["value"]["scopeCreep"] = "";
        }else{
            temp["value"]["scopeCreepDisplay"] = "true";
            temp["value"]["scopeCreep"] = selectedData["scopeCreep"];
        }
        setProjectModal({...temp});
        handleMenuAnchorClose(id);
    }
    // function to close project modal
    const closeProjectModal = () => {
        var temp = projectModal;
        temp["isOpen"] = false;
        temp["header"] = "";
        temp["mode"] = "";
        temp["value"]["area"] = null;
        temp["value"]["manager"] = null;
        temp["value"]["status"] = null;
        temp["value"]["name"] = "";
        temp["value"]["pmoOffice"] = "";
        temp["value"]["sponsor"] = null;
        temp["value"]["sponsorGroup"] = null;
        temp["value"]["scopeCreepDisplay"] = "false";
        temp["value"]["scopeCreep"] = "";
        temp["value"]["startDate"] = null;
        temp["value"]["endDate"] = null;
        temp["currentId"] = "";
        setProjectModal({...temp});
    }
    // function to handle value change for project modal
    const handleProjectModalValueChange = key => (event,object) => {
        var temp = projectModal;
        var value;
        if(['manager','area','sponsor','sponsorGroup','status'].includes(key)){
            value = object;
        }else if(['startDate','endDate'].includes(key)){
            value = event;
        }else{
            value = event.target.value;
        }
        if(key === "scopeCreepDisplay" && value === "false"){
            temp["value"]["scopeCreep"] = "";
        }
        temp["value"][key]=value;
        setProjectModal({...temp});
    }
    // fuction to handle submit for project modal
    const saveHandlerForProjecModal = (mode) => {
        var data = validateAndGetPolicyModalValue();
        if(data["status"] !== "valid"){
            dispatch(toastUp(data["msg"]))
            return false;
        }
        if(mode === "add"){
            addProject(data["data"]);
        }else if(mode === "edit"){
            editProject(data["data"]);
        }
    }
    // function to validate policy and det form value
    const validateAndGetPolicyModalValue = () => {
        var data ={};
        data["status"] = "invalid";
        var value = projectModal["value"];
        if(value["name"] === ""){
            data["msg"] = "Name field cannot be empty";
            return data;
        }
        if(value["manager"] === null){
            data["msg"] = "Manager field cannot be empty";
            return data;
        }
        if(value["status"] === null){
            data["msg"] = "Status field cannot be empty";
            return data;
        }
        if(value["pmoOffice"] === ""){
            data["msg"] = "PMO Office field cannot be empty";
            return data;
        }
        if(value["area"] === null){
            data["msg"] = "Business Unit Area field cannot be empty";
            return data;
        }
        if(value["sponsor"] === null){
            data["msg"] = "Sponsor field cannotformOptionsMetaData be empty";
            return data;
        }
        if(value["sponsorGroup"] === null){
            data["msg"] = "Sponsor Group field cannot be empty";
            return data;
        }
        if(value["startDate"] === null){
            data["msg"] = "start date field cannot be empty";
            return data;
        }
        if(value["endDate"] === null){
            data["msg"] = "End date field cannot be empty";
            return data;
        }
        data["status"] = "valid";
        var formValue = {};
        formValue["name"] = value["name"];
        formValue["manager"] = getIdOrNullOfObject(value["manager"]);
        formValue["status"] = getKeyOrNullOfObject(value["status"],"value");
        formValue["pmoOffice"] = value["pmoOffice"];
        formValue["area"] = getIdOrNullOfObject(value["area"]);
        formValue["sponsor"] = getIdOrNullOfObject(value["sponsor"]);
        formValue["sponsorGroup"] = getIdOrNullOfObject(value["sponsorGroup"]);
        formValue["companyId"] = loggedInUser["company_id"];
        formValue["scopeCreep"] = value["scopeCreep"];
        formValue["startDate"] = value["startDate"].format(DATE_FORMAT);
        formValue["endDate"] = value["endDate"].format(DATE_FORMAT);
        data["data"] = formValue;
        return data;
    }
    const addProject = (data) => {
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}issue/project`,data)
        .then((res)=>{
            if(res.status === 201){
                closeProjectModal();
                fetchData();
                dispatch(toastUp("Project created successfully"));
            }else{
                dispatch(toastUp("Unable connect to server"));
            }
        })
        .catch((err)=>{
            console.log(err)
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
    }
    const editProject = (data) => {
        dispatch(setSpinner(true));
        axios.put(`${SERVICE_URL}issue/project?id=${projectModal["currentId"]}`,data)
        .then((res)=>{
            if(res.status === 201){
                fetchData();
                closeProjectModal();
                dispatch(toastUp("Project edited successfully"));
            }else{
                dispatch(toastUp("Unable connect to server"));
            }
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
    }
    // init use state handle page for table
    const [pageState, setPageState] = useState({
        page: 0,
        pageSize: 30
    })
    // init use state to store table contents
    const [tableContent,setTableContent] = useState({
        isLoading: false,
        data: [],
        total: 0,
    }) 
    // function to get action cell content
    const getActionCellContent = (params) => (
        <div className={`${style.actionRow}`}>
            <div>
                <IconButton aria-label="more" id="long-button" aria-controls={Boolean(columnState[params.id]["anchorEl"]) ? 'long-menu' : undefined} aria-expanded={Boolean(columnState[params.id]["anchorEl"]) ? 'true' : undefined} aria-haspopup="true" onClick={handleMenuAnchorOpen(params.id)}>
                    <MoreVertIcon className='icon' />
                </IconButton>
                <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button'}} anchorEl={columnState[params.id]["anchorEl"]} open={Boolean(columnState[params.id]["anchorEl"])} onClose={()=>{handleMenuAnchorClose(params.id)}} PaperProps={{style: {maxHeight: 48 * 4.5,width: '20ch'}}}>
                    <MenuItem onClick={()=>{editProjectModal(params.id)}}>Edit</MenuItem>
                </Menu>
            </div>
        </div>
    ) 
    // init table columns
    const columns = [
        {field: 'name', sortable: false, flex: 1, renderHeader: () => (<strong>{'Project Name'}</strong>), renderCell: (params)=> (<div className={`MuiDataGrid-cellContent ${(!params.row.inActiveFlag) ? 'hyperLinkActive' : ''}`} title={params.value} onClick={()=>{openViewProjectModal(params.id)}}>{params.value}</div>)},
        { field: 'manager', sortable: false, flex: 1, renderHeader: () => (<strong>{'Project Manager'}</strong>) },
        { field: 'pmoOffice', sortable: false, flex: 1, renderHeader: () => (<strong>{'PMO Office'}</strong>) },
        { field: 'area', sortable: false, flex: 1, renderHeader: () => (<strong>{'Business Unit Area'}</strong>) },
        { field: 'sponsor', sortable: false, flex: 1, renderHeader: () => (<strong>{'Sponsor'}</strong>) },
        { field: 'sponsorGroup', sortable: false, flex: 1, renderHeader: () => (<strong>{'Sponsor Group'}</strong>) },
        { field: "action", sortable: false, renderHeader: () => (<strong>{'Action'}</strong>), renderCell: getActionCellContent},
    ];
    // function to handle open menu anchor
    const handleMenuAnchorOpen = key => event => {
        var updatedColumnState = columnState;
        updatedColumnState[key]["anchorEl"] = event.currentTarget;
        setColumnState({...updatedColumnState});
    };
    // function to handle menu anchor
    const handleMenuAnchorClose = key => {
        var updatedColumnState = columnState;
        updatedColumnState[key]["anchorEl"] = null;
        setColumnState({...updatedColumnState});
    };
    // init use state for project modal
    const [viewProjectModal,setViewProjectModal] = useState({"isOpen":false,"projectDetails":{}});
    // function to open view project modal
    const openViewProjectModal = (id) => {
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}issue/project?id=${id}`)
        .then((res)=>{
            var temp = viewProjectModal;
            temp["isOpen"] = true;
            temp["projectDetails"] = res["data"];
            setViewProjectModal({...temp});
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
    }
    // function to close project modal
    const closeViewProjectModal = () => {
        var temp = viewProjectModal;
        temp["isOpen"] = false;
        temp["projectDetails"] = {};
        setViewProjectModal({...temp});
    }
    // init use state for column 
    const [columnState,setColumnState] = useState({});
    // function to generate use state for table column
    const generateTableSupportingDetils = (data) => {
        var updatedColumnState = {};
        for(const row of data){
            updatedColumnState[row["id"]] = {"anchorEl":null};
        }
        setColumnState({...updatedColumnState});
    }
    useEffect(()=>{
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}user?groups=rbsu&companyId=${loggedInUser["company_id"]}`)
        .then((res)=>{
            var data = res["data"];
            var updatedFormOptionsMetaData = formOptionsMetaData;
            updatedFormOptionsMetaData["manager"] = data["data"];
            setFormOptionsMetaData({...updatedFormOptionsMetaData});
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })

        axios.get(`${SERVICE_URL}issue/project/view?companyId=${loggedInUser["company_id"]}`)
        .then((res)=>{
            var temp = formOptionsMetaData;
            temp["project"] = generateArrayForAutoCompleteBasedOnkey(res["data"]["data"],"name");
            setFormOptionsMetaData({...temp});
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
        
        axios.get(`${SERVICE_URL}risk/company/business/unit/companyId?companyId=${loggedInUser["company_id"]}`)
        .then((res)=>{
            var temp = formOptionsMetaData;
            temp["area"] = res["data"];
            setFormOptionsMetaData({...temp});
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })

        axios.get(`${SERVICE_URL}risk/company/organizational/domain/companyId?companyId=${loggedInUser["company_id"]}`)
        .then((res)=>{
            var temp = formOptionsMetaData;
            temp["sponsorGroup"] = res["data"];
            setFormOptionsMetaData({...temp});
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })

        axios.get(`${SERVICE_URL}user?roles=Business Executive&companyId=${loggedInUser["company_id"]}`)
        .then((res)=>{
            var data = res["data"];
            var updatedFormOptionsMetaData = formOptionsMetaData;
            updatedFormOptionsMetaData["sponsor"] = data["data"];
            setFormOptionsMetaData({...updatedFormOptionsMetaData});
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
        // eslint-disable-next-line
    },[])
    const fetchData = async () => {
        setTableContent(old => ({ ...old, isLoading: true }));
        // console.log(`${SERVICE_URL}issue/view?page=${pageState.page+1}&size=${pageState.pageSize}&companyId=${loggedInUser["company_id"]}${selectedFilter}`)
        axios.get(`${SERVICE_URL}issue/project/view?page=${pageState.page+1}&size=${pageState.pageSize}&companyId=${loggedInUser["company_id"]}${selectedFilter}`)
        .then(res=>{
            var data = res.data;
            var temp = tableContent;
            temp["isLoading"] = false;
            temp["data"] = data["data"];
            generateTableSupportingDetils(data["data"]); 
            temp["total"] = data["totalCount"];
            setTableContent({...temp});
        }).catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
            setTableContent(old => ({ ...old, isLoading: false }));
        })
    }
    useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[pageState.page,pageState.pageSize,selectedFilter])
    return (
        <div>
            <HeaderSection header='Portfolio Management' breadcrumbs={["Issues Management","Portfolio Management","Project Risk"]} specialButtons={[<Button onClick={openProjectModal} className='riskOneGreenBtn' size="small" variant="contained">add</Button>]} />
            <div className='m20'>
                <div className={`plr10 ptb30 ${style.filterSection}`}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <Autocomplete value={formData["project"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["project"]} size="small" disablePortal onChange={handleFormValueChange("project")} freeSolo renderInput={renderDisplayValueWithLableForAutocomplete("Project Name")} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <Autocomplete value={formData["manager"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["manager"]} size="small" disablePortal onChange={handleFormValueChange("manager")} getOptionLabel={(option) => option.name+" ("+option.email+")"} isOptionEqualToValue={(option, value) => option.id === value.id} renderInput={renderDisplayValueWithLableForAutocomplete("Project Manager")} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <TextField onKeyPress={applySearch} label='PMO Office' value={formData["pmoOffice"]} onChange={handleFormValueChange("pmoOffice")} sx={textFieldStyle} fullWidth size="small" variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <Autocomplete value={formData["area"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} options={formOptionsMetaData["area"]} size="small" getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.id === value.id} disablePortal onChange={handleFormValueChange("area")} renderInput={renderDisplayValueWithLableForAutocomplete("Business Unit Area")} />
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <DataGridCustom rows={tableContent.data} rowCount={tableContent.total} isLoading={tableContent.isLoading} pageSizeOptions={[30,50,100]} paginationModel={pageState} onPaginationModelChange={setPageState} columns={columns} combineSearch={true} />
                </div>
                <div style={{textAlign:"right"}} className='mtb5'>
                    <Button variant='text' size='small' onClick={()=>dispatch(setPage('projectFinancial'))}>Project Financial</Button>
                </div>
            </div>
            <ModalForm open={projectModal.isOpen} closeHandler={closeProjectModal} header={projectModal.header} mode={projectModal.mode} saveHandler={()=>saveHandlerForProjecModal(projectModal.mode)}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Name </span><span className='required'>*</span><br/>
                        <TextField fullWidth size='small' value={projectModal["value"]["name"]} sx={textFieldStyle} onChange={handleProjectModalValueChange("name")} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Manager </span><span className='required'>*</span><br/>
                        <Autocomplete value={projectModal["value"]["manager"]} options={formOptionsMetaData["manager"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} size="small" getOptionLabel={(option) => option.name+" ("+option.email+")"} isOptionEqualToValue={(option, value) => option.id === value.id} disablePortal onChange={handleProjectModalValueChange("manager")} renderInput={renderDisplayValueForAutocomplete} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Status </span><span className='required'>*</span><br/>
                        <Autocomplete value={projectModal["value"]["status"]} options={formOptionsMetaData["status"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} size="small" getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.value === value.value} disablePortal onChange={handleProjectModalValueChange("status")} renderInput={renderDisplayValueForAutocomplete} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>PMO Office </span><span className='required'>*</span><br/>
                        <TextField fullWidth size='small' value={projectModal["value"]["pmoOffice"]} sx={textFieldStyle} onChange={handleProjectModalValueChange("pmoOffice")} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Business Unit Area </span><span className='required'>*</span><br/>
                        <Autocomplete value={projectModal["value"]["area"]} options={formOptionsMetaData["area"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} size="small" getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.id === value.id} disablePortal onChange={handleProjectModalValueChange("area")} renderInput={renderDisplayValueForAutocomplete} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Sponsor </span><span className='required'>*</span><br/>
                        <Autocomplete value={projectModal["value"]["sponsor"]} options={formOptionsMetaData["sponsor"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} size="small" getOptionLabel={(option) => option.name+" ("+option.email+")"} isOptionEqualToValue={(option, value) => option.id === value.id} disablePortal onChange={handleProjectModalValueChange("sponsor")} renderInput={renderDisplayValueForAutocomplete} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Sponsor Group </span><span className='required'>*</span><br/>
                        <Autocomplete value={projectModal["value"]["sponsorGroup"]} options={formOptionsMetaData["sponsorGroup"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} size="small" getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.id === value.id} disablePortal onChange={handleProjectModalValueChange("sponsorGroup")} renderInput={renderDisplayValueForAutocomplete} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Start Date </span><span className='required'>*</span><br/>
                        <LocalizationProvider  size="small" dateAdapter={AdapterDayjs}>
                                    <DatePicker value={projectModal["value"]["startDate"]} onChange={handleProjectModalValueChange("startDate")} slotProps={{ textField: { size: 'small',fullWidth: true, sx:textFieldStyle } }} />
                                </LocalizationProvider>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>End Date </span><span className='required'>*</span><br/>
                        <LocalizationProvider  size="small" dateAdapter={AdapterDayjs}>
                                    <DatePicker value={projectModal["value"]["endDate"]} onChange={handleProjectModalValueChange("endDate")} slotProps={{ textField: { size: 'small',fullWidth: true, sx:textFieldStyle } }} />
                                </LocalizationProvider>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Add Scope Creep ? </span><br/>
                        <RadioGroup value={projectModal["value"]["scopeCreepDisplay"]} onChange={handleProjectModalValueChange("scopeCreepDisplay")} row>
                            <FormControlLabel value={"true"} control={<Radio size="small" />} label={<span style={{fontSize:"14px"}}>Yes</span>} />
                            <FormControlLabel value={"false"} control={<Radio size="small" />} label={<span style={{fontSize:"14px"}}>No</span>} />
                        </RadioGroup>
                    </Grid>
                    <Grid style={{display:(projectModal["value"]["scopeCreepDisplay"] === "true") ? "block" : "none"}} item sm={12} md={6}>
                        <span className='lable'>Scope Creep </span><br/>
                        <TextField fullWidth size='small' value={projectModal["value"]["scopeCreep"]} sx={textFieldStyle} onChange={handleProjectModalValueChange("scopeCreep")} />
                    </Grid>
                </Grid>
            </ModalForm>
            <ModalViewInfo open={viewProjectModal.isOpen} closeHandler={closeViewProjectModal}>
                <ViewProjectDetailsForModal projectDetails={viewProjectModal.projectDetails}/>
            </ModalViewInfo>
        </div>
    );
}

export default PortfolioManagement;