import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { dataGridStyle } from '../../js/standardStyle';

function DataGridEmail(props) {
    var overrideStyle = {
        ".MuiDataGrid-columnHeaders":{
            display:"none"
        },
        "&.MuiDataGrid-root .MuiDataGrid-row":{
            cursor:"pointer",
            userSelect:"none"
        }
    }
    return (
        <div>
            <DataGrid
                style={{"display":"flex","flexDirection":"column-reverse"}}
                onRowClick={(props.onRowClick ? props.onRowClick : ()=>{})}
                autoHeight
                disableVirtualization
                disableColumnMenu
                disableRowSelectionOnClick
                sx={{...dataGridStyle,...overrideStyle}}
                rows={props.rows}
                rowCount={props.rowCount}
                loading={props.isLoading}
                pageSizeOptions={props.pageSizeOptions}
                paginationMode="server"
                paginationModel={props.paginationModel}
                onPaginationModelChange={props.onPaginationModelChange}
                columns={props.columns}
            />
        </div>
    );
}

export default DataGridEmail;