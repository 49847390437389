import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

function PieChart(props) {
    ChartJS.register(ArcElement, Tooltip, Legend);
    var {data} = props;
    if (data === null){
        data = {
            labels: [],
            datasets: [
              {
                label: '',
                data: []
              }
            ],
        };
    }
    return (
        <div>
            <Pie data={data} />
        </div>
    );
}

export default PieChart;