import React from 'react';
import style from '../../css/Spinner.module.css'
import loader from '../../image/loader.gif'

function Spinner(props) {
    return (
        <div className={`${style.overlay} ${props.open ? style.fadeIn : style.fadeOut}`}>
            <div className={style.loaderSection}>
                <img src={loader} height={150} width={150} alt=""/>
                <div>This Will Take Few Secs. Please Don't Hit The Back Button</div>
            </div>
        </div>
    );
}

export default Spinner;