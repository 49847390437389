import { Autocomplete, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getIdOrNullOfArrayOfObject, removeHtmlContent, removeObjectInArrayBasedOnKey, renderDisplayValueForAutocomplete } from '../../js/util';
import { autocompleteStyle, textFieldStyle } from '../../js/standardStyle';
import QuillTextEditor from '../wrapper/QuillTextEditor';
import SendIcon from '@mui/icons-material/Send';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from '../../redux/actions/spinner';
import axios from 'axios';
import { SERVICE_URL } from '../../config';
import { toastUp } from '../../redux/actions/toast';
import { getErrorMsg } from '../../js/util';

function ComposeForModal(props) {
    const {closeComposeAndRefreshTable} = props;
    const dispatch = useDispatch();
    const loggedInUser = useSelector(state=>state.loggedInUser);
    const [formData,setFormData] = useState({"mailTo":[],"subject":"","content":""});
    const [formMetaData,setFormMetaData] = useState({"mailTo":[]});
    const handleFormValue = (key) => (event,object) => {
        var temp = formData;
        var value;
        if(["mailTo"].includes(key)){
            value = object;
        }else if(["content"].includes(key)){
            value = event;
        }else{
            value = event.target.value;
        }
        temp[key] = value;
        setFormData({...formData});
    }
    useEffect(()=>{
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}user?groups=rbsu,rbr,external,local_admin,lgsa_backup,global_super_admin&companyId=${loggedInUser["company_id"]}`)
        .then((res)=>{
            var data = res["data"];
            var temp = formMetaData;
            temp["mailTo"] = removeObjectInArrayBasedOnKey(data["data"],"id",loggedInUser["id"]);
            setFormMetaData({...temp});
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
        // eslint-disable-next-line
    },[]);
    const composeEmail = () => {
        var data={};
        if(formData["mailTo"].length === 0){
            dispatch(toastUp("Mail To field cannot be empty"))
            return false
        }
        if(formData["subject"] === ""){
            dispatch(toastUp("Subject field cannot be empty"))
            return false
        }
        if(removeHtmlContent(formData["content"]) === ""){
            dispatch(toastUp("Content field cannot be empty"))
            return false
        }
        data["mailTo"] = getIdOrNullOfArrayOfObject(formData["mailTo"]);
        data["header"] = formData["subject"];
        data["content"] = formData["content"];
        data["createdBy"] = loggedInUser["id"];
        data["companyId"] = loggedInUser["company_id"];
        axios.post(`${SERVICE_URL}email/compose`,data)
        .then((res)=>{
            dispatch(toastUp("Email sent successfully"));
            closeComposeAndRefreshTable();
        })
        .catch((err)=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
    }
    return (
        <div style={{height:"100%"}}>
            <div>
                <Typography variant="h6" component="h2">
                    COMPOSE
                </Typography>
            </div>
            <div className='m20' style={{overflowX:"hidden",height:"calc(100% - 125px)"}}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <span className='lable'>Mail To </span><span className='required'>*</span>
                        <Autocomplete multiple filterSelectedOptions disableCloseOnSelect onChange={handleFormValue("mailTo")} size='small' value={formData["mailTo"]} options={formMetaData["mailTo"]} sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} disablePortal getOptionLabel={options=>options.email} isOptionEqualToValue={(selected,option)=>selected.id===option.id} renderInput={renderDisplayValueForAutocomplete} />
                    </Grid>
                    <Grid item sm={12}>
                        <span className='lable'>Subject </span><span className='required'>*</span>
                        <TextField value={formData["subject"]} onChange={handleFormValue("subject")} size='small' sx={textFieldStyle} fullWidth />
                    </Grid>
                    <Grid item sm={12}>
                        <span className='lable'>Content </span><span className='required'>*</span>
                        <QuillTextEditor name={"content"} value={formData["content"]} onChange={handleFormValue("content")}/>
                    </Grid>
                </Grid>
            </div>
            <div className='mtb30 taCenter'>
                <Button variant='contained' endIcon={<SendIcon/>} onClick={composeEmail}>COMPOSE</Button>
            </div>
        </div>
    );
}

export default ComposeForModal;