import loggedInUserReducer from "./loggedInUser";
import displayPageReducer from "./displayPage";
import spinnerReducer from "./spinner";
import {combineReducers} from 'redux';
import toastReducer from "./toast";
import alertReducer from "./alert";
import customPropReducer from "./customProp";
import confirmationModalReducer from "./confirmationModal";

const allReducer = combineReducers({
    loggedInUser: loggedInUserReducer,
    displayPage: displayPageReducer,
    spinner: spinnerReducer,
    toast: toastReducer,
    alert: alertReducer,
    customProp: customPropReducer,
    confirmationModal: confirmationModalReducer
})

export default allReducer;