import React, { useEffect, useState } from 'react';
import HeaderSection from '../HeaderSection';
import { Autocomplete, Grid } from '@mui/material';
import { autocompleteStyle } from '../../js/standardStyle';
import { getErrorMsg, renderDisplayValueWithLableForAutocomplete } from '../../js/util';
import FieldsetCustom from '../wrapper/FieldsetCustom';
import axios from 'axios';
import { SERVICE_URL } from '../../config';
import { useDispatch, useSelector } from 'react-redux';
import { toastUp } from '../../redux/actions/toast';
import { setSpinner } from '../../redux/actions/spinner';
import TrackProgressChartReport from './TrackProgressChartReport';
import ProgressStatusChartReport from './ProgressStatusChartReport';
import ProjectManagementProgressStatusChartReport from './ProjectManagementProgressStatusChartReport';
import ExhibitingTaskChartProgress from './ExhibitingTaskChartProgress';

function ProjectReport(props) {

    // init use state for form data
    const [formData,setFormData] = useState({"name":null,"chartGroup":null});

    // function to handle value change for form data
    const handleFormValueChange = key => (event,object) => {
        var temp = formData;
        if(key === "chartGroup"){
            if(temp["name"] === null){
                dispatch(toastUp("Please select Project Name"));
                return false;
            }
        }
        temp[key] = object;
        setFormData({...temp});

    }
    // function to handle ui
    const uiHandler = () => {
        if(formData["name"] !== null){
            if(formData["chartGroup"] === "Track Progress Report"){
                return <TrackProgressChartReport key={formData["name"]["id"]} projectId={formData["name"]["id"]}/>
            }else if(formData["chartGroup"] === "Progress Status Report"){
                return <ProgressStatusChartReport key={formData["name"]["id"]} projectId={formData["name"]["id"]}/>
            }else if(formData["chartGroup"] === "Project Management Progress Status Report"){
                return <ProjectManagementProgressStatusChartReport key={formData["name"]["id"]} projectId={formData["name"]["id"]}/>
            }else if(formData["chartGroup"] === "Exhibiting Task Progress"){
                return <ExhibitingTaskChartProgress key={formData["name"]["id"]} projectId={formData["name"]["id"]}/>
            }else{
                return <></>
            }
        }else{
            return <></>
        }
    }
    // init use state for form meta data
    const [formOptionsMetaData,setFormOptionsMetaData] = useState({"name":[],"chartGroup":["Track Progress Report","Progress Status Report","Project Management Progress Status Report","Exhibiting Task Progress"]})
    // get loggedin user details
    const loggedInUser = useSelector(state=>state.loggedInUser);
    // get dispatch
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}issue/project/ui?companyId=${loggedInUser["company_id"]}`)
        .then(res=>{
            var temp = formOptionsMetaData;
            temp["name"] = res["data"]["data"];
            setFormOptionsMetaData({...temp});
        })
        .catch(err=>{
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(()=>{
            dispatch(setSpinner(false));
        })
        // eslint-disable-next-line
    },[])
    return (
        <div>
            <HeaderSection header='Project Reporting' breadcrumbs={["Issues Management", "Project Reporting"]} />
            <div className='m20'>
                <FieldsetCustom legend={""}>
                    <div className='mlr15 mtb30'>
                        <Grid container spacing={2}>
                            <Grid item sm={6} md={4}>
                                <Autocomplete value={formData["name"]} options={formOptionsMetaData["name"]} onChange={handleFormValueChange("name")} size='small' sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.id === value.id} disablePortal renderInput={renderDisplayValueWithLableForAutocomplete("Project Name")} /> 
                            </Grid>
                            <Grid item sm={6} md={8}>
                                <Autocomplete value={formData["chartGroup"]} options={formOptionsMetaData["chartGroup"]} onChange={handleFormValueChange("chartGroup")} size='small' sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} disablePortal renderInput={renderDisplayValueWithLableForAutocomplete("Chart Group")} /> 
                            </Grid>
                        </Grid>
                    </div>
                </FieldsetCustom>
                <div className='mtb10'>
                    {uiHandler()}
                </div>
            </div>
        </div>
    );
}

export default ProjectReport;