import React from 'react';
import { Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

function HorizontalStackedBarChart(props) {
    ChartJS.register(CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend);
    const options = {
        indexAxis: 'y',
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
    };
    var {data} = props;
    if (data === null){
        data = {
            labels: [],
            datasets: [
              {
                label: '',
                data: []
              }
            ],
        };
    }
    return (
        <div>
             <Bar options={options} data={data} />
        </div>
    );
}

export default HorizontalStackedBarChart;