import React, { useEffect, useState } from 'react';
import {Paper,Grid,Button} from '@mui/material';
import classes from '../../css/RiskRegister.module.css'
import RiskIdentification from './RiskIdentification';
import HeaderSection from '../HeaderSection';
import UploadIcon from '@mui/icons-material/Upload';
import RiskResponse from './RiskResponse';
import RiskImpactClassification from './RiskImpactClassification.js';
import { useDispatch } from 'react-redux';
import { setPage } from '../../redux/actions/displayPage';
import { resetCustomProp } from '../../redux/actions/customProp';

function RiskRegister(props) {
    // get use dispatch from redux
    const dispatch = useDispatch();
    // dict to store control page based on selected form option
    const elementControl = {
        "specialButtons":{
            "riskIdentification":[<Button className='mlr5 roButton' variant="text" endIcon={<UploadIcon/>}>import</Button>],
            "riskResponse":[],
            "riskImpactClassification":[]
        }
    }
    // init use state to store risk register details
    const [riskRegisterDetails,setRiskRegisterDetails] = useState({"id":"","companyId":""})
    // init use state to track current form
    const [riskRegisterUI,setRiskRegisterUI] = useState("riskIdentification");
    // function to return equivalent component based on risk register ui state
    const uiHandler = () => {
        if(riskRegisterUI === "riskIdentification"){
            return <RiskIdentification setRiskRegisterDetails={setRiskRegisterDetails} riskRegisterDetails={riskRegisterDetails} riskRegisterUIHandler={setRiskRegisterUI}/>;
        }else if(riskRegisterUI === "riskResponse"){
            return <RiskResponse riskRegisterDetails={riskRegisterDetails} riskRegisterUIHandler={setRiskRegisterUI}/>;
        }else if(riskRegisterUI === "riskImpactClassification"){
            return <RiskImpactClassification riskRegisterDetails={riskRegisterDetails} riskRegisterUIHandler={setRiskRegisterUI}/>;
        }
    }
    // use effect to manitulate the form data
    useEffect(()=>{
        if ('manipulate' in props){
            if(props["manipulate"]["type"]==="setRiskReponse"){
                var data = props["manipulate"]["data"];
                setRiskRegisterDetails({"id":data["riskRegisterId"].toString(),"companyId":data["companyId"].toString()});
                setRiskRegisterUI("riskResponse");
            }
            dispatch(resetCustomProp());
        }
        // eslint-disable-next-line
    },[])
    return (
        <>  
            {/* selected menu details */}
            <HeaderSection header='Risk Register' breadcrumbs={["Cyber Risk Management","Risk Register"]} specialButtons={elementControl['specialButtons'][riskRegisterUI]} />
            {/* form header */}
            <div className='m20'>
                <Paper elevation={16}>
                    <div className={`${classes.riskFormHeaderSection} ptlr15`}>
                        <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-end">
                            <Grid item md={2} lg={2} zeroMinWidth>
                                <div onClick={()=>{setRiskRegisterUI("riskIdentification")}} className={`${riskRegisterUI === "riskIdentification" ? classes.selectedForm : "buttonProperty"} p10 ${classes.formHeader}`}>Risk<br/>Identification</div>
                            </Grid>
                            <Grid item md={2} lg={2} zeroMinWidth>
                                <div onClick={()=>{setRiskRegisterUI("riskResponse")}} className={`${riskRegisterUI === "riskResponse" ? classes.selectedForm : "buttonProperty"} p10 ${classes.formHeader}`}>Risk<br/>Response</div>
                            </Grid>
                            <Grid item md={2} lg={2} zeroMinWidth>
                                <div onClick={()=>{setRiskRegisterUI("riskImpactClassification")}} className={`${riskRegisterUI === "riskImpactClassification" ? classes.selectedForm : "buttonProperty"} p10 ${classes.formHeader}`}>Risk<br/>Impact Classification</div>
                            </Grid>
                            <Grid item md={2} lg={2} zeroMinWidth>
                                <div className={`p10 buttonProperty ${classes.formHeader}`}>Control<br/>Mapping</div>
                            </Grid>
                            <Grid className={classes.formHeaderButtonSegment} item md={4} lg={4} zeroMinWidth>
                                <Button id={classes.viewAllButton} className='mlr5 roButton' variant="contained" onClick={()=>dispatch(setPage(("viewRisk")))}>view all</Button>
                                <Button id={classes.searchButton} className='mlr5 roButton' variant="contained" onClick={()=>dispatch(setPage(("searchRisk")))}>search</Button>
                            </Grid>
                        </Grid>
                    </div>
                    {uiHandler()}
                </Paper>
            </div>
        </>
    );
}

export default RiskRegister;