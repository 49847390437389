import React, { useEffect, useState } from 'react';
import { TextField, Autocomplete, Button, Grid, Tooltip } from '@mui/material';
import FieldsetCustom from '../../wrapper/FieldsetCustom';
import { textFieldStyle, autocompleteStyle, tooltipStyle } from '../../../js/standardStyle';
import { getIdOrNullOfObject, renderDisplayValueForAutocomplete, getKeyOrNullOfObject, getErrorMsg, getArrayOfObjectBasedOnKey } from '../../../js/util';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinner } from '../../../redux/actions/spinner';
import axios from 'axios';
import { SERVICE_URL } from '../../../config';
import { toastUp } from '../../../redux/actions/toast';
import { policyDropDown } from '../../../js/constants';
import { resetCustomProp } from '../../../redux/actions/customProp';

function PolicyDetailsForm(props) {

    // get dispatch
    const dispatch = useDispatch();
    // get loggedin user from redux
    const loggedInUser = useSelector((state)=>state.loggedInUser);
    // destructure props
    const {mode,setMode} = props;
    // init use state for form data
    const [formData, setFormData] = useState({"name":"","number":"","statement":"","generalRequirements":"","audience":"","scope":"","policy":"","regulatoryCitation":null,"frameworkCitation":null,"standard":null,"consequence":"","supportingPolicy":null})
    // function to handle form value change
    const handleFormValueChange = key => event => {
        var temp = formData;
        var value = event.target.value;
        temp[key] = value;
        setFormData({...temp});
    }
    // init use state for form options meta data
    const [formOptionsMetaData, setFormOptionsMetaData] = useState({"regulatoryCitation":policyDropDown["regulatoryCitation"],"frameworkCitation":[],"standard":policyDropDown["standard"],"supportingPolicy":[]});
    // function to set form data for auto complete
    const handleFormDataAutoCompleteChange = key => (event,object) => {
        var temp = formData;
        temp[key] = object;
        setFormData({...temp});
    }
    // function to save and contiue the policy
    const saveAndContiuePolicy = async () => {
        var data = validateAndGetFormData();
        if(data["status"] === "valid"){
            var resp = await savePolicy(data["data"]);
            if(resp["promiseStatus"] === "success"){
                if(resp["status"] === 200){
                    var respData=resp["data"];
                    dispatch(toastUp("Policy Details created successfully"));
                    setTimeout(()=>{
                        props.setPolicyDetails((old)=>({...old,"policyId":respData["policyId"]}))
                        props.setPreConfiguredUI("policyAction");
                    }, 2000);
                }else{
                    dispatch(toastUp("unable to connect server"));
                }
            }else{
                dispatch(toastUp(getErrorMsg(resp)));
            }
        }else{
            dispatch(toastUp(data["msg"]));
        }
    }
    // function to submit a policy
    const submitPolicy = async () => {
        var data = validateAndGetFormData();
        if(data["status"] === "valid"){
            var resp = await savePolicy(data["data"]);
            if(resp["promiseStatus"] === "success"){
                if(resp["status"] === 200){
                    dispatch(toastUp("Policy Details created successfully"));
                    setTimeout(resetForm, 2000);
                }else{
                    dispatch(toastUp("unable to connect server"));
                }
            }else{
                dispatch(toastUp(getErrorMsg(resp)));
            }
        }else{
            dispatch(toastUp(data["msg"]));
        }
    }
    // function to save policy to database
    const savePolicy = (data) => new Promise((resolve, reject) => {
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}policy/detail`,data)
        .then(res => {
            dispatch(setSpinner(false));
            res["promiseStatus"] = "success";
            resolve(res);
        })
        .catch(err => {
            dispatch(setSpinner(false));
            err["promiseStatus"] = "error";
            reject(err);
        })
    })
    // function to edit policy
    const editPolicy = () => {
        var data = validateAndGetFormData();
        if(data["status"] === "valid"){
            if(props.policyDetails["policyId"] === "" || props.policyDetails["policyId"] === undefined){
                dispatch(toastUp("Issue editing policy details"));
                return false;
            }
            dispatch(setSpinner(true));
            axios.put(`${SERVICE_URL}policy/detail/edit?id=${props.policyDetails["policyId"]}`,data["data"])
            .then(res => {
                if(res.status === 200){
                    dispatch(toastUp("Policy Details edited successfully"));
                    setTimeout(()=>{
                        props.setPreConfiguredUI("policyAction");
                    }, 2000);
                }else{
                    dispatch(toastUp("unable to connect server"));
                }
            })
            .catch(err => {
                console.log(err)
                dispatch(toastUp(getErrorMsg(err)));
            })
            .finally(()=>{
                dispatch(setSpinner(false));
            })
        }else{
            dispatch(toastUp(data["msg"]));
        }
    }
    // function to reset form 
    const resetForm = () => {
        var temp = formData;
        temp["name"] = "";
        temp["number"] = "";
        temp["statement"] = "";
        temp["generalRequirements"] = "";
        temp["audience"] = "";
        temp["scope"] = "";
        temp["policy"] = "";
        temp["regulatoryCitation"] = null;
        temp["frameworkCitation"] = null;
        temp["standard"] = null;
        temp["consequence"] = "";
        temp["supportingPolicy"] = null;
        setFormData({...temp});
    }
    // function to validate and get data
    const validateAndGetFormData = () => {
        var data = {};
        data["status"] = "invalid";
        if(formData["name"] === ""){
            data["msg"] = "Policy Name field cannot be empty";
            return data;
        }
        if(formData["number"] === ""){
            data["msg"] = "Policy Number field cannot be empty";
            return data;
        }
        if(formData["statement"] === ""){
            data["msg"] = "Statement field cannot be empty";
            return data;
        }
        // if(formData["generalRequirements"] === ""){
        //     data["msg"] = "General Requirements field cannot be empty";
        //     return data;
        // }
        if(formData["audience"] === ""){
            data["msg"] = "Audience field cannot be empty";
            return data;
        }
        if(formData["scope"] === ""){
            data["msg"] = "Scope field cannot be empty";
            return data;
        }
        if(formData["policy"] === ""){
            data["msg"] = "policy field cannot be empty";
            return data;
        }
        if(formData["consequence"] === ""){
            data["msg"] = "Consequence field cannot be empty";
            return data;
        }

        var ruleOneCount = 0;
        if(formData["regulatoryCitation"] != null){
            ruleOneCount+=1;
        }
        if(formData["frameworkCitation"] != null){
            ruleOneCount+=1;
        }
        if(formData["standard"] != null){
            ruleOneCount+=1;
        }
        if(ruleOneCount === 0){
            data["msg"] = "Please select any one between Law/Regulatory Citation or Framework Citation or Standard";
            return data;
        }
        if(ruleOneCount > 1){
            data["msg"] = "Please select only one between Law/Regulatory Citation or Framework Citation or Standard";
            return data;
        }

        data["status"] = "valid";
        var formValue = {}
        formValue["audience"] = formData["audience"];
        formValue["companyId"] = loggedInUser["company_id"];
        formValue["consequence"] = formData["consequence"];
        formValue["frameworkCitation"] = getIdOrNullOfObject(formData["frameworkCitation"]);
        formValue["generalRequirement"] = formData["generalRequirements"];
        formValue["name"] = formData["name"];
        formValue["policy"] = formData["policy"];
        formValue["refNumber"] = formData["number"];
        formValue["referedPoliyId"] = getIdOrNullOfObject(formData["supportingPolicy"]);
        formValue["regulatoryCitation"] = getKeyOrNullOfObject(formData["regulatoryCitation"],'value');
        formValue["scope"] = formData["scope"];
        formValue["standard"] = getKeyOrNullOfObject(formData["standard"],'value');
        formValue["statement"] = formData["statement"];
        formValue["type"] = "default";
        formValue["userId"] = loggedInUser["id"];
        data["data"] = formValue;
        return data;
    }

    useEffect(()=>{
        dispatch(setSpinner(true));
        axios.get(`${SERVICE_URL}assessment/framework`)
        .then(res => {
            if(res.status === 200){
                var data = res["data"];
                var temp = formOptionsMetaData;
                temp["frameworkCitation"] = data["data"];
                setFormOptionsMetaData({...temp});
                if ('manipulate' in props){
                    if(props["manipulate"]["type"]==="autoPopulatePolicy" || props["manipulate"]["type"]==="editPolicy"){
                        var policyData = props["manipulate"]["data"];
                        setFormData((old)=>({...old,"frameworkCitation":getArrayOfObjectBasedOnKey(formOptionsMetaData["frameworkCitation"],'id',policyData["frameworkCitationId"])}))
                    }
                }
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });

        axios.get(`${SERVICE_URL}policy/search?companyId=${loggedInUser["company_id"]}&status=approved`)
        .then(res => {
            if(res.status === 200){
                var data = res["data"];
                var temp = formOptionsMetaData;
                temp["supportingPolicy"] = data["data"];
                setFormOptionsMetaData({...temp});
                if ('manipulate' in props){
                    if(props["manipulate"]["type"]==="autoPopulatePolicy" || props["manipulate"]["type"]==="editPolicy"){
                        var policyData = props["manipulate"]["data"];
                        setFormData((old)=>({...old,"supportingPolicy":getArrayOfObjectBasedOnKey(formOptionsMetaData["supportingPolicy"],'id',policyData["referedPolicyId"])}))
                    }
                }
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        })
        .finally(() => {
            dispatch(setSpinner(false));
        });
        
        if ('manipulate' in props){
            if(props["manipulate"]["type"]==="autoPopulatePolicy"){
                populatePolicyDetail(props["manipulate"]["data"]);
            }else if(props["manipulate"]["type"]==="editPolicy"){
                populatePolicyDetail(props["manipulate"]["data"]);
                setMode((old)=>({...old,"mode":"edit","props":{"manipulate":props["manipulate"]}}));
            }
            dispatch(resetCustomProp());
        }

        // eslint-disable-next-line
    },[]);

    // function to populate form
    const populatePolicyDetail = (data) => {
        var temp = formData;
        temp["name"] = data["name"];
        temp["number"] = data["refNumber"];
        temp["statement"] = data["statement"];
        temp["generalRequirements"] = data["generalRequiement"];
        temp["audience"] = data["audience"];
        temp["scope"] = data["scope"];
        temp["policy"] = data["policy"];
        temp["regulatoryCitation"] = getArrayOfObjectBasedOnKey(policyDropDown["regulatoryCitation"],'value',data["regulatoryCitation"]);
        temp["standard"] = getArrayOfObjectBasedOnKey(policyDropDown["standard"],'value',data["standard"]);
        temp["consequence"] = data["consequence"];
        setFormData({...temp});
    }

    return (
        <div>
            <FieldsetCustom legend="PRE-CONFIGURED POLICY - POLICY DETAILS">
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Policy Name </span><span className='required'>*</span><br/>
                        <TextField sx={textFieldStyle} value={formData["name"]} fullWidth size="small" onChange={handleFormValueChange("name")} variant="outlined" />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Policy Number </span><span className='required'>*</span><br/>
                        <TextField type='number' sx={textFieldStyle} value={formData["number"]} fullWidth size="small" onChange={handleFormValueChange("number")} variant="outlined" />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Policy Statement </span><span className='required'>*</span><br/>
                        <TextField multiline sx={textFieldStyle} value={formData["statement"]} fullWidth size="small" onChange={handleFormValueChange("statement")} variant="outlined" />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>General Requirements </span><span className='required'>*</span><br/>
                        <TextField multiline sx={textFieldStyle} value={formData["generalRequirements"]} fullWidth size="small" onChange={handleFormValueChange("generalRequirements")} variant="outlined" />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Audience </span><span className='required'>*</span><br/>
                        <TextField multiline sx={textFieldStyle} value={formData["audience"]} fullWidth size="small" onChange={handleFormValueChange("audience")} variant="outlined" />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Scope </span><span className='required'>*</span><br/>
                        <TextField multiline sx={textFieldStyle} value={formData["scope"]} fullWidth size="small" onChange={handleFormValueChange("scope")} variant="outlined" />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Policy </span><span className='required'>*</span><br/>
                        <TextField multiline sx={textFieldStyle} value={formData["policy"]} fullWidth size="small" onChange={handleFormValueChange("policy")} variant="outlined" />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Consequence </span><span className='required'>*</span><br/>
                        <TextField multiline sx={textFieldStyle} value={formData["consequence"]} fullWidth size="small" onChange={handleFormValueChange("consequence")} variant="outlined" />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Law/Regulatory Citation </span><br/>
                        <Autocomplete sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} value={formData["regulatoryCitation"]} options={formOptionsMetaData["regulatoryCitation"]} size="small" disablePortal getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.value === value.value} onChange={handleFormDataAutoCompleteChange("regulatoryCitation")} renderInput={renderDisplayValueForAutocomplete} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Framework Citation </span><br/>
                        <Autocomplete sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} value={formData["frameworkCitation"]} options={formOptionsMetaData["frameworkCitation"]} size="small" disablePortal getOptionLabel={(option) => option.code} isOptionEqualToValue={(option, value) => option.id === value.id} onChange={handleFormDataAutoCompleteChange("frameworkCitation")} renderInput={renderDisplayValueForAutocomplete} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Standard </span><br/>
                        <Autocomplete sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} value={formData["standard"]} options={formOptionsMetaData["standard"]} size="small" disablePortal getOptionLabel={(option) => option.name} isOptionEqualToValue={(option, value) => option.value === value.value} onChange={handleFormDataAutoCompleteChange("standard")} renderInput={renderDisplayValueForAutocomplete} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <span className='lable'>Supporting Policy Ref </span><br/>
                        <Autocomplete sx={autocompleteStyle.sx} ListboxProps={autocompleteStyle.ListboxProps} value={formData["supportingPolicy"]} options={formOptionsMetaData["supportingPolicy"]} size="small" disablePortal getOptionLabel={(option) => option.refNumber+" -- "+option.name} isOptionEqualToValue={(option, value) => option.id === value.id} onChange={handleFormDataAutoCompleteChange("supportingPolicy")} renderInput={renderDisplayValueForAutocomplete} />
                    </Grid>
                </Grid>
            </FieldsetCustom>
            <div className='mtb30 taCenter'>
                {
                    (mode === "add") ?
                        <>
                            <Tooltip arrow componentsProps={tooltipStyle} disableFocusListener disableTouchListener placement="bottom-start" title={"save and create another policy"}>
                                <Button className={`mlr5`} variant="contained" onClick={submitPolicy}>submit</Button>
                            </Tooltip>
                            <Button className={`mlr5`} variant="contained" onClick={saveAndContiuePolicy}>save and continue</Button>
                        </>
                    :
                        (mode === "edit") ?
                            <>
                                <Button className={`mlr5`} variant="contained" onClick={editPolicy}>save and continue</Button>
                                <Button className={`mlr5`} variant="contained" onClick={()=>props.setPreConfiguredUI("policyAction")}>next</Button>
                            </>
                        :
                        <></>
                }
                
            </div>
        </div>
    );
}

export default PolicyDetailsForm;