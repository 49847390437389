import React,{useState} from 'react';
import {Button} from '@mui/material';
import style from '../../css/RiskImpactClassification.module.css';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import { SERVICE_URL } from '../../config';
import { getErrorMsg } from '../../js/util';
import { useDispatch } from 'react-redux';
import { setSpinner } from '../../redux/actions/spinner';
import { toastUp } from '../../redux/actions/toast';

function RiskImpactClassification(props) {
    
    // get use dispatch from redux
    const dispatch = useDispatch();

    // risk impact classification checkbox style
    const checkboxStyle = {color: "white",'&.Mui-checked': {color: "white"}};
    
    // init state to handle check box of risk impact classification
    const [checked, setChecked] = useState({"highCritical":false,"severeHigh":false,"moderateMedium":false,"lowNegligible":false,"selected":""});
    // function to set form value to risk impact classification
    const handleChange = (event) => {
        var temp = checked;
        temp["highCritical"] = false;
        temp["severeHigh"] = false;
        temp["moderateMedium"] = false;
        temp["lowNegligible"] = false;
        temp[event.target.name] = event.target.checked;
        temp["selected"] = event.target.name;
        setChecked({...temp});
    };
    // function to save risk impact classification
    const saveRiskImpactClassification = () => {
        if(checked["selected"] === ""){
            dispatch(toastUp("select anyone of the risk impact classification check box"));
            return false;
        }
        if(props.riskRegisterDetails.id === ""){
            dispatch(toastUp("risk register is empty"));
            return false;
        }

        var data={}
        data["riskImpactClassification"] = checked["selected"]
        data["riskRegisterId"] = props.riskRegisterDetails.id;
        dispatch(setSpinner(true));
        axios.post(`${SERVICE_URL}risk/riskImpactClassification`,data)
        .then(res => {
            console.log(res)
            if(res.status === 201){
                dispatch(toastUp("risk impact classification created successfully"));
                var temp = checked;
                temp["highCritical"] = false;
                temp["severeHigh"] = false;
                temp["moderateMedium"] = false;
                temp["lowNegligible"] = false;
                temp["selected"] = "";
                setChecked({...temp});
                // setTimeout(()=>{props.riskRegisterUIHandler("riskImpactClassification");}, 2000);
            }else{
                dispatch(toastUp("unable to connect server"));
            }
        })
        .catch(err => {
            dispatch(toastUp(getErrorMsg(err)));
        }).finally(() => {
            dispatch(setSpinner(false));
        });
    }

    return (
        <div className='p15'>
            <div className='mt15'>
                <table className={`${style.table}`} style={{width:"100%"}}>
                    <thead>
                        <tr className={`${style.tableHeading}`}>
                            <th className={`${style.th}`} colSpan="2">Risk Impact Classification</th>
                        </tr>
                        <tr>
                            <th className={`${style.th}`}>Risk Classification</th>
                            <th className={`${style.th}`}>Impact Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={`${style.tr}`}>
                            <td className={`${style.critical} ${style.riskClassification} ${style.td}`}>
                                <span className={style.riskClassificationSpan}>
                                    High - Critical 
                                    <Checkbox name="highCritical" checked={checked["highCritical"]} sx={checkboxStyle} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }}/>
                                </span>
                            </td>
                            <td className={`${style.td}`}>The loss of confidentiality, integrity, or availability could be expected to have a critical effect on an entity’s business operation and System of Things (SysOT) Source: Consensus of Security Experts crowd sourced via Q&A by InnovaBE Technologies research arm</td>
                        </tr>
                        <tr className={`${style.tr}`}>
                            <td className={`${style.high} ${style.riskClassification} ${style.td}`}>
                                <span className={style.riskClassificationSpan}>
                                    Severe - High
                                    <Checkbox name="severeHigh" checked={checked["severeHigh"]} sx={checkboxStyle} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }}/>
                                </span>    
                            </td>
                            <td className={`${style.td}`}>The loss of confidentiality, integrity, or availability could be expected to have a severe or catastrophic adverse effect on organizational operations, organizational assets, or individuals. Source: <a target='_blank' rel="noreferrer" href='https://www.fismacenter.com/FIPS-PUB-199-final.pdf'>https://www.fismacenter.com/FIPS-PUB-199-final.pdf</a></td>
                        </tr>
                        <tr className={`${style.tr}`}>
                            <td className={`${style.moderate} ${style.riskClassification} ${style.td}`}>
                                <span className={style.riskClassificationSpan}>
                                    Moderate - Medium
                                    <Checkbox name="moderateMedium" checked={checked["moderateMedium"]} sx={checkboxStyle} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }}/>
                                </span>    
                            </td>
                            <td className={`${style.td}`}>The loss of confidentiality, integrity, or availability could be expected to have a serious adverse effect on organizational operations, organizational assets or individuals. Source: <a target='_blank' rel="noreferrer" href='https://www.fismacenter.com/FIPS-PUB-199-final.pdf'>https://www.fismacenter.com/FIPS-PUB-199-final.pdf</a></td>
                        </tr>
                        <tr className={`${style.tr}`}>
                            <td className={`${style.low} ${style.riskClassification} ${style.td}`}>
                                <span className={style.riskClassificationSpan}>
                                    Low - Negligible
                                    <Checkbox name="lowNegligible" checked={checked["lowNegligible"]} sx={checkboxStyle} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }}/>
                                </span>    
                            </td>
                            <td className={`${style.td}`}>The loss of confidentiality, integrity, or availability could be expected to have a limited adverse effect on organizational operations, organizational assets or individuals Source: <a target='_blank' rel="noreferrer" href="https://www.fismacenter.com/FIPS-PUB-199-final.pdf">https://www.fismacenter.com/FIPS-PUB-199-final.pdf</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='mtb30' style={{textAlign:"center"}}>
                <Button className='mlr5 roButton' variant="contained" onClick={saveRiskImpactClassification}>save and continue</Button>
            </div>
        </div>
    );
}

export default RiskImpactClassification;