import React, { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import FieldsetCustom from '../wrapper/FieldsetCustom';
import style from '../../css/ViewPolicyDetailsForModal.module.css';
import { getNameBasedOnValue } from '../../js/util';
import { policyDropDown } from '../../js/constants';
import { useDispatch } from 'react-redux';
import { setUiWithCustomProp } from '../../redux/actions/customProp';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import htmlParser from 'html-react-parser';

function ViewPolicyDetailsForModal(props) {

    // get policy details
    const {policyDetails,mode,deleteHandler,inactiveFlagHandler,editPolicyHandler} = props;
    // init use state to hide elements
    const [hideElement,setHideElement] = useState({"content":true});
    // function to toggle visibility for the hidden elements
    const toggleVisibility = () => {
        var updatedHideElement = hideElement;
        updatedHideElement["content"] = !hideElement["content"];
        setHideElement({...updatedHideElement});
    }
    // get use dispatch from redux
    const dispatch = useDispatch();
    // redirect ui
    const redirectUi = (ui,payload) => {
        if(policyDetails !== undefined){
            dispatch(setUiWithCustomProp({"ui":ui,"props":{"manipulate":payload}}));
        }
    }
    // function to add policy action
    const completePolicyActionForm = () => {
        if(policyDetails !== undefined){
            if(policyDetails["type"] === "default"){
                redirectUi("policy",{"type":"setPolicyAction","policyId":policyDetails["id"]});
            }else if(policyDetails["type"] === "customise"){
                redirectUi("policy",{"type":"setPolicyActionCustomise","policyId":policyDetails["id"]});
            }
        }
    }
    // function to reuse policy
    const reusePolicyHandler = () => {
        if(policyDetails !== undefined){
            if(policyDetails["type"] === "default"){
                redirectUi("policy",{"type":"autoPopulatePolicy","data":policyDetails})
            }else if(policyDetails["type"] === "customise"){
                redirectUi("policy",{"type":"autoPopulatePolicyCustomise","data":policyDetails})
            }
        }
    }

    return (
        <div style={{height:"100%"}}>
            <div>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6" component="h2">{policyDetails.refNumber} -- {policyDetails.name}</Typography>
                    </Grid>
                    <Grid style={{display : (mode === "reuse") ? "block" : "none"}} item>
                        <Button onClick={reusePolicyHandler} className='mlr5' size='small' variant='contained' startIcon={<FileCopyIcon/>}>re-use</Button>
                   </Grid>
                    <Grid style={{display : (mode === "edit") ? "block" : "none"}} item>
                        <Button style={{backgroundColor:"#ff9e00"}} onClick={editPolicyHandler} className='mlr5' size='small' variant='contained' startIcon={<ModeEditIcon/>}>edit policy</Button>
                    </Grid>
                    <Grid style={{display : (mode === "delete") ? "block" : "none"}} item>
                        <Button style={{backgroundColor:"#f60000"}} onClick={deleteHandler} className='mlr5' size='small' variant='contained' startIcon={<DeleteForeverIcon/>}>delete policy</Button>
                    </Grid>
                    <Grid style={{display : (mode === "move") ? "block" : "none"}} item>
                        {
                            (!policyDetails["inActiveFlag"]) ?
                                <Button onClick={()=>inactiveFlagHandler("inActive")} className='mlr5' size='small' variant='contained'>mark as inactive</Button>
                            :
                                <Button onClick={()=>inactiveFlagHandler("active")} className='mlr5' size='small' variant='contained'>mark as active</Button>
                        }
                        <Button style={{backgroundColor:"#f60000"}} onClick={deleteHandler} className='mlr5' size='small' variant='contained' startIcon={<DeleteForeverIcon/>}>delete policy</Button>
                    </Grid>
                </Grid>
            </div>
            <div className={style.content}>
                <div className={`${style.fieldsetSection} ptb15`}>
                    <FieldsetCustom legend={"POLICY DETAILS"} >
                        <Grid container spacing={2}>
                            <Grid item sm={4} md={3}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Policy Number</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={8} md={9}>
                                <Typography className={style.fieldContent}>{policyDetails.refNumber}</Typography>
                            </Grid>
                            <Grid item sm={4} md={3}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Policy Name</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={8} md={9}>
                                <Typography className={style.fieldContent}>{policyDetails.name}</Typography>
                            </Grid>
                            <Grid item sm={4} md={3}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Policy Created By</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={8} md={9}>
                                <Typography className={style.fieldContent}>{policyDetails.createdBy}</Typography>
                            </Grid>
                            <Grid item sm={4} md={3}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Policy Statement</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={8} md={9}>
                                <Typography component={'span'} style={{margin:"0px"}} className={style.fieldContent}>{htmlParser(policyDetails.statement)}</Typography>
                            </Grid>
                            <Grid item sm={4} md={3}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>General Requirements</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={8} md={9}>
                                <Typography component={'span'} className={style.fieldContent}>{htmlParser(policyDetails.generalRequiement)}</Typography>
                            </Grid>
                            <Grid item sm={4} md={3}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Audience</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={8} md={9}>
                                <Typography component={'span'} className={style.fieldContent}>{htmlParser(policyDetails.audience)}</Typography>
                            </Grid>
                            <Grid item sm={4} md={3}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Scope</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={8} md={9}>
                                <Typography component={'span'} className={style.fieldContent}>{htmlParser(policyDetails.scope)}</Typography>
                            </Grid>
                            <Grid item sm={4} md={3}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Policy</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={8} md={9}>
                                <Typography component={'span'} className={style.fieldContent}>{htmlParser(policyDetails.policy)}</Typography>
                            </Grid>
                            <Grid item sm={4} md={3}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Consequence</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={8} md={9}>
                                <Typography component={'span'} className={style.fieldContent}>{htmlParser(policyDetails.consequence)}</Typography>
                            </Grid>
                            <Grid item sm={4} md={3}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Law/Regulatory Citation</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={8} md={9}>
                                <Typography className={style.fieldContent}>{getNameBasedOnValue(policyDropDown["regulatoryCitation"],policyDetails.regulatoryCitation)}</Typography>
                            </Grid>
                            <Grid item sm={4} md={3}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Framework Citation</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={8} md={9}>
                                <Typography className={style.fieldContent}>{policyDetails.frameworkCitation}</Typography>
                            </Grid>
                            <Grid item sm={4} md={3}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Standard</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={8} md={9}>
                                <Typography className={style.fieldContent}>{getNameBasedOnValue(policyDropDown["standard"],policyDetails.standard)}</Typography>
                            </Grid>
                            <Grid item sm={4} md={3}>
                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Supporting Policy Ref</strong><strong>:</strong></Typography>
                            </Grid>
                            <Grid item sm={8} md={9}>
                                <Typography className={style.fieldContent}>{policyDetails.referedPolicy}</Typography>
                            </Grid>
                        </Grid>
                    </FieldsetCustom>
                    {
                        (policyDetails.priority !== null && policyDetails.situation !== null && policyDetails.assignedUsers !== null && policyDetails.notification !== null) ?
                            <>
                                <div className='mtb5' style={{textAlign:"right"}}>
                                    <Button className='mlr5 roButton' variant="text" onClick={toggleVisibility} >{hideElement["content"] ? 'show more' : 'show less'}</Button>
                                </div>
                                <div style={{display : (hideElement["content"]) ? "none" : "block"}} className={`${style.fieldsetSection} ptb15`}>
                                    <FieldsetCustom legend={"POLICY CONDITIONS AND ACTIONS"} >
                                        <Grid container spacing={2}>
                                            <Grid item sm={4} md={3}>
                                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Policy Request Severity</strong><strong>:</strong></Typography>
                                            </Grid>
                                            <Grid item sm={8} md={9}>
                                                <Typography className={style.fieldContent}>{getNameBasedOnValue(policyDropDown["severity"],policyDetails.priority)}</Typography>
                                            </Grid>
                                            <Grid item sm={4} md={3}>
                                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Policy Request Status</strong><strong>:</strong></Typography>
                                            </Grid>
                                            <Grid item sm={8} md={9}>
                                                <Typography className={style.fieldContent}>{getNameBasedOnValue(policyDropDown["situation"],policyDetails.situation)}</Typography>
                                            </Grid>
                                            <Grid item sm={4} md={3}>
                                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Send Policy</strong><strong>:</strong></Typography>
                                            </Grid>
                                            <Grid item sm={8} md={9}>
                                                <Typography className={style.fieldContent}>{policyDetails.assignedUsers.join(", ")}</Typography>
                                            </Grid>
                                            <Grid item sm={4} md={3}>
                                                <Typography className={`${style.fieldContent} ${style.fieldHeading}`}><strong>Send Notification</strong><strong>:</strong></Typography>
                                            </Grid>
                                            <Grid item sm={8} md={9}>
                                                <Typography className={style.fieldContent}>{policyDetails.notification.join(", ")}</Typography>
                                            </Grid>
                                        </Grid>
                                    </FieldsetCustom>
                                </div>
                            </>
                        :
                        <div className='buttonSection'>
                            <Button onClick={completePolicyActionForm} variant='contained'>complete policy conditions and actions</Button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ViewPolicyDetailsForModal;